/* eslint-disable @typescript-eslint/no-unused-vars,@typescript-eslint/no-use-before-define,@typescript-eslint/naming-convention,@typescript-eslint/no-use-before-define */
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** Represents an alert parameter name. */
  AlertParameterName: { input: string; output: string };
  /** Represents any value. */
  Any: { input: unknown; output: unknown };
  /** Represents a Date and Time in RFC 3339 format. */
  DateTime: { input: string; output: string };
  /** Represents a duration of time. */
  Duration: { input: string; output: string };
  /** Represents an IP address, either IPv4 or IPv6. */
  IP: { input: string; output: string };
  /** Represents an IP address prefix in CIDR notation. */
  IPPrefix: { input: string; output: string };
  /** Represents an IPv4 address. */
  IPV4: { input: string; output: string };
  /** Represents an IPv6 address. */
  IPV6: { input: string; output: string };
  /** Represents a generic JSON object. */
  JSONObject: { input: Record<string, unknown>; output: Record<string, unknown> };
  /** Represents a MAC Address. */
  MacAddress: { input: string; output: string };
  /** Represents a Universally Unique Identifier (UUID). */
  UUID: { input: string; output: string };
  /** Represents an unmasked IP address prefix in CIDR notation. */
  UnmaskedIPPrefix: { input: string; output: string };
};

export type ApChannelUtilizationMetricsValue = MetricsValue & {
  __typename?: 'APChannelUtilizationMetricsValue';
  /** The name of the Access Point (AP) that reported this measurement. */
  apName: Scalars['String']['output'];
  /** The time at which the measurement was recorded. */
  timestamp: Scalars['DateTime']['output'];
  /** The measured value of channel utilization at the given timestamp. */
  value: Scalars['Float']['output'];
};

export type ApChannelUtilizationResponse = MetricsResponse & {
  __typename?: 'APChannelUtilizationResponse';
  /** Metadata about the channel utilization metrics. */
  metadata?: Maybe<MetricsMetadata>;
  /** List of channel utilization metrics values. */
  values: Array<ApChannelUtilizationMetricsValue>;
};

export type ApChannelUtilizationTimeseriesValue = {
  __typename?: 'APChannelUtilizationTimeseriesValue';
  /**
   * What channel the AP's radio was on at the time of the observation.
   * For single AP queries, this will be the most-seen channel during the bucket window.
   * This will be null for network / multiple-AP queries (because network-level is
   * an aggregation of all APs, which will likely be on a long list of channels).
   */
  channel?: Maybe<Scalars['Int']['output']>;
  /**
   * The channel width of the AP's radio at the time of the observation.
   * For single AP queries, this will be the most-seen channel width during the bucket window.
   * This will be null for network / multiple-AP queries (because network-level is
   * an aggregation of all APs, which will likely be on different channel widths).
   */
  channelWidth?: Maybe<Scalars['Int']['output']>;
  /** The maximum signal strength of interference sources detected on the channel. (in dBm) */
  interferenceSignalStrengthMaxdBm: Scalars['Int']['output'];
  /** The median signal strength of interference sources detected on the channel. (in dBm). This is a median of medians of observations within a bucket window, floored to the nearest integer. */
  interferenceSignalStrengthMediandBm: Scalars['Int']['output'];
  /** The number of sources of high interference detected on the channel. (An average of data observations, floored to the nearest integer). */
  interferenceSourceCountHigh: Scalars['Int']['output'];
  /** The number of sources of low interference detected on the channel. (An average of data observations, floored to the nearest integer). */
  interferenceSourceCountLow: Scalars['Int']['output'];
  /** The number of sources of medium interference detected on the channel. (An average of data observations, floored to the nearest integer). */
  interferenceSourceCountMedium: Scalars['Int']['output'];
  /** The amount of utilization contributed by non-802.11 interference in the environment, e.g., Bluetooth devices, cameras. (from 0 to 1) */
  non80211Utilization: Scalars['Float']['output'];
  /** The amount of utilization as a result of 802.11 interference by neighboring devices. (from 0 to 1) */
  obssUtilization: Scalars['Float']['output'];
  /** The percentage of time the channel was utilized due to transmissions from clients. (from 0 to 1) */
  rxUtilization: Scalars['Float']['output'];
  /** The percentage of time the channel was utilized by the AP itself. (from 0 to 1) */
  selfUtilization: Scalars['Float']['output'];
  /** The time at which the measurement was recorded. */
  timestamp: Scalars['DateTime']['output'];
  /** The percentage of time the channel was utilized. (from 0 to 1) */
  totalUtilization: Scalars['Float']['output'];
  /** The percentage of time the channel was utilized due to transmissions from the AP. (from 0 to 1) */
  txUtilization: Scalars['Float']['output'];
};

export type ApUptimeMetricsValue = MetricsValue & {
  __typename?: 'APUptimeMetricsValue';
  /** The name of the AP that reported this measurement. */
  apName: Scalars['String']['output'];
  /** The time at which the measurement was recorded. */
  timestamp: Scalars['DateTime']['output'];
  /** The uptime value of the AP at the given timestamp, in seconds. */
  value: Scalars['Float']['output'];
};

export type ApUptimeResponse = MetricsResponse & {
  __typename?: 'APUptimeResponse';
  /** Metadata about the AP uptime metrics. */
  metadata?: Maybe<MetricsMetadata>;
  /** List of AP uptime metrics values. */
  values: Array<ApUptimeMetricsValue>;
};

/** An access point hardware device represents a physical access point. */
export type AccessPointHardwareDevice = HardwareDevice & {
  __typename?: 'AccessPointHardwareDevice';
  /** The list of BSSIDs (Basic Service Set Identifiers) that represent unique identifiers for the access point's SSID and radio band combinations. */
  BSSIDs: Array<Bssid>;
  /** Shows the most recent boot history of a device (buildName, bootCount). */
  bootHistory?: Maybe<Array<BootInfo>>;
  /** The timestamp when the hardware device was first created in the system. */
  createdAt: Scalars['DateTime']['output'];
  /** The model name of the hardware device. */
  deviceModel: DeviceModel;
  /** The type of the hardware device. */
  deviceType: DeviceType;
  /** If the device is no longer connected to the backend, this is the time at which it was last connected. */
  disconnectedFromBackendAt?: Maybe<Scalars['DateTime']['output']>;
  /** The IP address of the access point hardware device. */
  ipAddress?: Maybe<Scalars['String']['output']>;
  /**
   * True if the device has an active websocket connection to the backend. Note that this only works if the device has registered via the /v2/register endpoint (i.e., is a Config 2 device) - otherwise is always false.
   * @deprecated Refer to `isSpare` instead.
   */
  isActive: Scalars['Boolean']['output'];
  /** Indicates whether the hardware device is connected to the backend. */
  isConnectedToBackend: Scalars['Boolean']['output'];
  /** Indicates whether the hardware device's console port is enabled. */
  isConsoleEnabled: Scalars['Boolean']['output'];
  /** Indicates whether the hardware device is marked as a development (dev) device, moving it into the dev (staging) realm for testing purposes. */
  isDev: Scalars['Boolean']['output'];
  /** Indicates whether the hardware device is in factory mode. */
  isFactoryMode: Scalars['Boolean']['output'];
  /** True if the AP's MAC address exists in the most recent controller MAC table for the network. */
  isInCurrentControllerMACTable: Scalars['Boolean']['output'];
  /** Indicates whether the hardware device is an RMA (Return Merchandise Authorization) replacement device. */
  isRMA: Scalars['Boolean']['output'];
  /** Indicates whether the hardware device has been retired. */
  isRetired: Scalars['Boolean']['output'];
  /** A spare hardware device belongs to a network but not a virtual device. Used most often to keep track of spare hardware we ship to a customer but don't install immediately. */
  isSpare: Scalars['Boolean']['output'];
  /** The MAC address of the hardware device. */
  macAddress?: Maybe<Scalars['MacAddress']['output']>;
  /** The UUID of the network that the spare hardware device belongs to. Spare hardware devices that are not affiliated with a virtual device will have this field populated. */
  networkUUID?: Maybe<Scalars['UUID']['output']>;
  /** The physical interface (on a Meter Switch) that the access point is connected to, if applicable. */
  phyInterfaceConnectedTo?: Maybe<PhyInterface>;
  /** The public key used to add the device to the jumphost's WireGuard interface. */
  publicKey?: Maybe<Scalars['String']['output']>;
  /** The unique serial number of the hardware device. */
  serialNumber: Scalars['String']['output'];
  /** IPv6 address on the jumphost subnet. Used to send requests to the device. */
  tunnelIPAddress?: Maybe<Scalars['String']['output']>;
  /** The timestamp when the hardware device was last updated in the system. */
  updatedAt: Scalars['DateTime']['output'];
  /** The uptime metrics from the time series database for the device. */
  uptime?: Maybe<Scalars['Duration']['output']>;
  /** The virtual device associated with this hardware device, if any. */
  virtualDevice?: Maybe<VirtualDevice>;
  /** The UUID of the virtual device this hardware device is assigned to, if any. */
  virtualDeviceUUID?: Maybe<Scalars['UUID']['output']>;
};

/** An access point hardware device represents a physical access point. */
export type AccessPointHardwareDeviceBootHistoryArgs = {
  count?: InputMaybe<Scalars['Int']['input']>;
};

/** An access point virtual device is a logical representation of a physical access point. */
export type AccessPointVirtualDevice = VirtualDevice & {
  __typename?: 'AccessPointVirtualDevice';
  /** The unique identifier (UUID) of the virtual device. */
  UUID: Scalars['UUID']['output'];
  /** The wireless clients that are currently connected to the access point. */
  connectedClients: Array<WirelessClient>;
  /** The timestamp when the virtual device was first created in the system. */
  createdAt: Scalars['DateTime']['output'];
  /** An optional description for the virtual device. */
  description?: Maybe<Scalars['String']['output']>;
  /** The model name of the virtual device. */
  deviceModel: DeviceModel;
  /** The type of the virtual device. */
  deviceType: VirtualDeviceType;
  /** The hardware device associated with this virtual device, if any. */
  hardwareDevice?: Maybe<HardwareDevice>;
  /** Indicates whether the virtual device's console port is enabled. */
  isConsoleEnabled: Scalars['Boolean']['output'];
  /** Indicates whether debug logging is enabled on the virtual device. */
  isDebugLogEnabled: Scalars['Boolean']['output'];
  /** Indicates whether LED dark mode is enabled on the access point, which turns off all LED activity. */
  isLEDDarkModeEnabled: Scalars['Boolean']['output'];
  /** A human-friendly name for the device. */
  label: Scalars['String']['output'];
  /** The network that the virtual device belongs to. */
  network: Network;
  /** The unique identifier (UUID) of the network the virtual device belongs to. */
  networkUUID: Scalars['UUID']['output'];
  /** The current NOS firmware version that the virtual device is configured to run. */
  nosVersion?: Maybe<ConfiguredNosVersion>;
  /** The identifier of the NOS firmware version that the virtual device is configured to run. */
  nosVersionID: Scalars['Int']['output'];
  /** The NOS firmware version that the virtual device is scheduled to upgrade to. */
  pendingNosVersion?: Maybe<PendingNosVersion>;
  /** The radio profile associated with this access point. */
  radioProfile?: Maybe<RadioProfile>;
  /** The UUID of the radio profile associated with this access point. */
  radioProfileUUID?: Maybe<Scalars['UUID']['output']>;
  /** The radio settings (channel, power) specific to this access point. */
  radioSettings: RadioSettings;
  /** All of the enabled SSIDs (Service Set Identifiers) that the access point is broadcasting. */
  ssids: Array<Ssid>;
  /** The virtual device tags on this access point. */
  tags?: Maybe<Array<VirtualDeviceTag>>;
  /** The timestamp when the virtual device was last updated in the system. */
  updatedAt: Scalars['DateTime']['output'];
  /** The uptime from our time series database for the device. */
  uptime?: Maybe<Scalars['Duration']['output']>;
};

export type ActiveClientsInput = {
  /** Whether to include meter hardware in the metrics calculation. */
  includeMeterHardware?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ActiveClientsMetricsResponse = MetricsResponse & {
  __typename?: 'ActiveClientsMetricsResponse';
  /** Aggregated metadata for active clients over the specified time range. */
  metadata?: Maybe<MetricsMetadata>;
  /** A list of active clients metrics values. */
  values: Array<ActiveClientsMetricsValue>;
};

export type ActiveClientsMetricsValue = MetricsValue & {
  __typename?: 'ActiveClientsMetricsValue';
  /** The timestamp of the metric data point. */
  timestamp: Scalars['DateTime']['output'];
  /** The number of active clients at the given timestamp. */
  value: Scalars['Float']['output'];
};

export type AddNetworkToAutoVpnGroupInput = {
  /** Indicates if failover is enabled for the member's uplink. */
  isFailoverEnabled?: Scalars['Boolean']['input'];
  /** Unique identifier of the network to add as a member. */
  networkUUID: Scalars['UUID']['input'];
  /** List of permitted VLAN unique identifiers. */
  permittedVLANUUIDs?: InputMaybe<Array<Scalars['UUID']['input']>>;
  /** Unique identifier of the physical interface used by the member's uplink. */
  phyInterfaceUUID: Scalars['UUID']['input'];
};

/**
 * Address represents a physical address, used with an ISP. It encompasses the Location and Sublocation.
 *
 * WARNING: These fields are currently different from the fields used in the MailingAddress type. There is a plan to
 * consolidate these types in the future.
 */
export type Address = {
  __typename?: 'Address';
  /** Unique identifier for the location. */
  UUID: Scalars['UUID']['output'];
  /** First line of the address. Typically includes street number and name. */
  address1: Scalars['String']['output'];
  /** Second line of the address. Typically includes apartment, suite, unit, building, floor, etc. */
  address2: Scalars['String']['output'];
  /** City where the address is located. */
  city: Scalars['String']['output'];
  /** Company associated with this address. */
  company?: Maybe<Company>;
  /** ISO 3166-1 alpha-2 country code for the address. */
  country: Scalars['String']['output'];
  /** Postal or ZIP code for the address. */
  postalCode: Scalars['String']['output'];
  /** State or province where the address is located. */
  state: Scalars['String']['output'];
  /** Unique identifier for the sublocation, such as a specific site or building. */
  sublocationUUID: Scalars['UUID']['output'];
};

/** An AlertDefinition specifies parameters for different types of Alerts used to notify stakeholders of important events for a network. */
export type AlertDefinition = {
  __typename?: 'AlertDefinition';
  /** A description of the alert. */
  description?: Maybe<Scalars['String']['output']>;
  /** A user-friendly display name for the alert. */
  displayName: Scalars['String']['output'];
  /** The unique name of the alert. */
  name: Scalars['String']['output'];
  /** The parameters associated with the alert. */
  parameters: Array<AlertParameter>;
};

/** An interface representing a parameter for an alert. */
export type AlertParameter = {
  /** Description of the parameter. */
  description?: Maybe<Scalars['String']['output']>;
  /** The name of the parameter. */
  name: Scalars['AlertParameterName']['output'];
  /** Indicates whether the parameter is required. */
  required: Scalars['Boolean']['output'];
  /** The data type of the parameter. */
  type: AlertParameterType;
  /** Unique identifier of the alert parameter. */
  uuid: Scalars['UUID']['output'];
};

/** A boolean parameter for an alert. */
export type AlertParameterBoolean = AlertParameter & {
  __typename?: 'AlertParameterBoolean';
  /** The default value of the parameter. */
  defaultValue?: Maybe<Scalars['Boolean']['output']>;
  /** Description of the parameter. */
  description?: Maybe<Scalars['String']['output']>;
  /** An example value of the parameter. */
  exampleValue?: Maybe<Scalars['Boolean']['output']>;
  /** The name of the parameter. */
  name: Scalars['AlertParameterName']['output'];
  /** Indicates whether the parameter is required. */
  required: Scalars['Boolean']['output'];
  /** The data type of the parameter. */
  type: AlertParameterType;
  /** Unique identifier of the alert parameter. */
  uuid: Scalars['UUID']['output'];
};

/** A date parameter (without time) for an alert. */
export type AlertParameterDate = AlertParameter & {
  __typename?: 'AlertParameterDate';
  /** The default value of the parameter. */
  defaultValue?: Maybe<Scalars['DateTime']['output']>;
  /** Description of the parameter. */
  description?: Maybe<Scalars['String']['output']>;
  /** An example value of the parameter. */
  exampleValue?: Maybe<Scalars['DateTime']['output']>;
  /** The name of the parameter. */
  name: Scalars['AlertParameterName']['output'];
  /** Indicates whether the parameter is required. */
  required: Scalars['Boolean']['output'];
  /** The data type of the parameter. */
  type: AlertParameterType;
  /** Unique identifier of the alert parameter. */
  uuid: Scalars['UUID']['output'];
};

/** A date and time parameter for an alert. */
export type AlertParameterDateTime = AlertParameter & {
  __typename?: 'AlertParameterDateTime';
  /** The default value of the parameter. */
  defaultValue?: Maybe<Scalars['DateTime']['output']>;
  /** Description of the parameter. */
  description?: Maybe<Scalars['String']['output']>;
  /** An example value of the parameter. */
  exampleValue?: Maybe<Scalars['DateTime']['output']>;
  /** The name of the parameter. */
  name: Scalars['AlertParameterName']['output'];
  /** Indicates whether the parameter is required. */
  required: Scalars['Boolean']['output'];
  /** The data type of the parameter. */
  type: AlertParameterType;
  /** Unique identifier of the alert parameter. */
  uuid: Scalars['UUID']['output'];
};

/** A duration parameter for an alert. */
export type AlertParameterDuration = AlertParameter & {
  __typename?: 'AlertParameterDuration';
  /** The default value of the parameter. */
  defaultValue?: Maybe<Scalars['Duration']['output']>;
  /** Description of the parameter. */
  description?: Maybe<Scalars['String']['output']>;
  /** An example value of the parameter. */
  exampleValue?: Maybe<Scalars['Duration']['output']>;
  /** The name of the parameter. */
  name: Scalars['AlertParameterName']['output'];
  /** Indicates whether the parameter is required. */
  required: Scalars['Boolean']['output'];
  /** The data type of the parameter. */
  type: AlertParameterType;
  /** Unique identifier of the alert parameter. */
  uuid: Scalars['UUID']['output'];
};

/** A numeric parameter for an alert. */
export type AlertParameterNumber = AlertParameter & {
  __typename?: 'AlertParameterNumber';
  /** The default value of the parameter. */
  defaultValue?: Maybe<Scalars['Float']['output']>;
  /** Description of the parameter. */
  description?: Maybe<Scalars['String']['output']>;
  /** An example value of the parameter. */
  exampleValue?: Maybe<Scalars['Float']['output']>;
  /** The name of the parameter. */
  name: Scalars['AlertParameterName']['output'];
  /** Indicates whether the parameter is required. */
  required: Scalars['Boolean']['output'];
  /** The data type of the parameter. */
  type: AlertParameterType;
  /** Unique identifier of the alert parameter. */
  uuid: Scalars['UUID']['output'];
};

/** A string parameter for an alert. */
export type AlertParameterString = AlertParameter & {
  __typename?: 'AlertParameterString';
  /** The default value of the parameter. */
  defaultValue?: Maybe<Scalars['String']['output']>;
  /** Description of the parameter. */
  description?: Maybe<Scalars['String']['output']>;
  /** An example value of the parameter. */
  exampleValue?: Maybe<Scalars['String']['output']>;
  /** The name of the parameter. */
  name: Scalars['AlertParameterName']['output'];
  /** Indicates whether the parameter is required. */
  required: Scalars['Boolean']['output'];
  /** The data type of the parameter. */
  type: AlertParameterType;
  /** Unique identifier of the alert parameter. */
  uuid: Scalars['UUID']['output'];
};

/** Specifies the data type of an alert parameter. */
export enum AlertParameterType {
  /** A boolean parameter. */
  Boolean = 'BOOLEAN',
  /** A date parameter (without time). */
  Date = 'DATE',
  /** A date and time parameter. */
  Datetime = 'DATETIME',
  /** A duration parameter. */
  Duration = 'DURATION',
  /** A numeric parameter. */
  Number = 'NUMBER',
  /** A string parameter. */
  String = 'STRING',
}

/** An alert receiver is called when an alert is triggered. */
export type AlertReceiver = {
  __typename?: 'AlertReceiver';
  /** Unique identifier for the alert receiver. */
  UUID: Scalars['UUID']['output'];
  /** Unique identifier of the company that owns this alert receiver. */
  companyUUID: Scalars['UUID']['output'];
  /** Timestamp when the alert receiver was created. */
  createdAt: Scalars['DateTime']['output'];
  /** Timestamp when the alert receiver was deleted (null if not deleted). */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Descriptive label for the alert receiver. */
  label: Scalars['String']['output'];
  /** List of alert targets associated with this alert receiver. */
  targets: Array<AlertTarget>;
  /** Timestamp when the alert receiver was last updated. */
  updatedAt: Scalars['DateTime']['output'];
};

/** An interface representing a target to which alerts can be sent. This can be implemented by different types of alert targets, such as email or webhook. */
export type AlertTarget = {
  /** The unique identifier of the alert target. */
  UUID: Scalars['UUID']['output'];
  /** The unique identifier of the company that owns the alert target. */
  companyUUID: Scalars['UUID']['output'];
  /** The timestamp when the alert target was created. */
  createdAt: Scalars['DateTime']['output'];
  /** The timestamp when the alert target was deleted, if applicable. */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The type of the alert target. */
  type: AlertTargetType;
  /** The timestamp when the alert target was last updated. */
  updatedAt: Scalars['DateTime']['output'];
};

/** An email alert target that implements the AlertTarget interface. Used to send alerts to a specified email address. */
export type AlertTargetEmail = AlertTarget & {
  __typename?: 'AlertTargetEmail';
  /** The unique identifier of the email alert target. */
  UUID: Scalars['UUID']['output'];
  /** The unique identifier of the company that owns the email alert target. */
  companyUUID: Scalars['UUID']['output'];
  /** The timestamp when the email alert target was created. */
  createdAt: Scalars['DateTime']['output'];
  /** The timestamp when the email alert target was deleted, if applicable. */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The email address to which alerts will be sent. */
  emailAddress: Scalars['String']['output'];
  /** The type of the alert target, this should always be EMAIL. */
  type: AlertTargetType;
  /** The timestamp when the email alert target was last updated. */
  updatedAt: Scalars['DateTime']['output'];
};

export type AlertTargetInput = {
  /** Type of the alert target. */
  alertTargetType: AlertTargetType;
  /** Unique identifier of the alert target. Required for all alert target types except EMAIL. */
  alertTargetUUID?: InputMaybe<Scalars['String']['input']>;
  /** Email address for creating a new email alert target. Required if creating a new email target. */
  emailAddress?: InputMaybe<Scalars['String']['input']>;
};

/** An enumeration of possible alert target types. */
export enum AlertTargetType {
  /** Alert target type indicating an email address. */
  Email = 'EMAIL',
  /** Alert target type indicating a webhook endpoint. */
  Webhook = 'WEBHOOK',
}

/** A webhook alert target that implements the AlertTarget interface. Used to send alerts to a specified webhook URL. */
export type AlertTargetWebhook = AlertTarget & {
  __typename?: 'AlertTargetWebhook';
  /** The unique identifier of the webhook alert target. */
  UUID: Scalars['UUID']['output'];
  /** The unique identifier of the company that owns the webhook alert target. */
  companyUUID: Scalars['UUID']['output'];
  /** The timestamp when the webhook alert target was created. */
  createdAt: Scalars['DateTime']['output'];
  /** The timestamp when the webhook alert target was deleted, if applicable. */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** An optional label for the webhook alert target. */
  label?: Maybe<Scalars['String']['output']>;
  /** The secret used to sign webhook requests for verification. */
  signingSecret?: Maybe<Scalars['String']['output']>;
  /** The type of the alert target, indicating that it is a webhook. */
  type: AlertTargetType;
  /** The timestamp when the webhook alert target was last updated. */
  updatedAt: Scalars['DateTime']['output'];
  /** The URL endpoint of the webhook where alerts will be sent. */
  url: Scalars['String']['output'];
};

/** A response type containing lists of alert targets for both emails and webhooks. */
export type AlertTargetsResponse = {
  __typename?: 'AlertTargetsResponse';
  /** A list of email alert targets. */
  emails: Array<AlertTargetEmail>;
  /** A list of webhook alert targets. */
  webhooks: Array<AlertTargetWebhook>;
};

/** An enumeration of permitted folders where files can be uploaded to or downloaded from. */
export enum AllowedFolder {
  AccessPointPhotos = 'access_point_photos',
  FloorPlanImages = 'floor_plan_images',
  InternalNotesAttachments = 'internal_notes_attachments',
  NetworkTopologyPhotos = 'network_topology_photos',
  OnboardingDocumentUploads = 'onboarding_document_uploads',
}

/** DNS firewall rules for a DHCP rule. These are powered by Netstar. */
export type ApplicationDnsFirewallRule = {
  __typename?: 'ApplicationDNSFirewallRule';
  /** The unique identifier of the DNS firewall rule. */
  UUID: Scalars['UUID']['output'];
  /** The action to take when the rule is matched. */
  action: FirewallRuleAction;
  /** The application associated with the DNS firewall rule as specified by Netstar. */
  application?: Maybe<ApplicationDnsFirewallRuleApplication>;
  /** The category of the DNS firewall rule as specified by Netstar. */
  category?: Maybe<ApplicationDnsFirewallRuleCategory>;
  /** The timestamp when the rule was created. */
  createdAt: Scalars['DateTime']['output'];
  /** The DHCP rule associated with this DNS firewall rule. */
  dhcpRule: DhcpRule;
  /** The domain or FQDN that is matched by the rule, allowing for wildcards denoted by '*'. */
  domain?: Maybe<Scalars['String']['output']>;
  /** The group of the DNS firewall rule as specified by Netstar. */
  group?: Maybe<ApplicationDnsFirewallRuleGroup>;
  /** Indicates whether the rule is enabled. */
  isEnabled: Scalars['Boolean']['output'];
  /** The name of the DNS firewall rule. */
  name: Scalars['String']['output'];
  /** The priority of the rule, used for ordering. */
  priority: Scalars['Int']['output'];
  /** The timestamp when the rule was last updated. */
  updatedAt: Scalars['DateTime']['output'];
};

/** The application associated with a DNS firewall rule as specified by Netstar. */
export type ApplicationDnsFirewallRuleApplication = {
  __typename?: 'ApplicationDNSFirewallRuleApplication';
  /** The unique identifier of the application. */
  id: Scalars['Int']['output'];
  /** Indicates whether the application is visible to the customer. */
  isVisible: Scalars['Boolean']['output'];
  /** The name of the application. */
  name: Scalars['String']['output'];
};

/** Categories of DNS firewall rules as specified by Netstar. */
export type ApplicationDnsFirewallRuleCategory = {
  __typename?: 'ApplicationDNSFirewallRuleCategory';
  /** A description of the category. */
  description: Scalars['String']['output'];
  /** The group to which this category belongs. */
  group: ApplicationDnsFirewallRuleGroup;
  /** The unique identifier of the category. */
  id: Scalars['Int']['output'];
  /** The name of the category. */
  name: Scalars['String']['output'];
};

/** Groups multiple ApplicationDNSFirewallRuleApplications together, based on Netstar's categorizations. */
export type ApplicationDnsFirewallRuleGroup = {
  __typename?: 'ApplicationDNSFirewallRuleGroup';
  /** The unique identifier of the group. */
  id: Scalars['Int']['output'];
  /** The name of the group. */
  name: Scalars['String']['output'];
};

/** Specifies the hardware device / virtual device pairing when assigning hardware to a virtual device. */
export type AssignHardwareDeviceToVirtualDeviceInput = {
  /** The serial number of the hardware device to assign. */
  serialNumber: Scalars['String']['input'];
  /** The UUID of the virtual device to assign the hardware device to. */
  virtualDeviceUUID: Scalars['UUID']['input'];
};

/** Input type for attaching a device to a rack elevation. */
export type AttachDeviceToRackElevationInput = {
  /** Whether the device is front-facing or rear-facing. */
  isFrontFacing?: InputMaybe<Scalars['Boolean']['input']>;
  /** A friendly name for display purposes. */
  label?: InputMaybe<Scalars['String']['input']>;
  /** Additional context or comments about the device. */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** The number of ports on the device. Only for switches and patch panels; cannot be specified if it's a Meter device. */
  portCount?: InputMaybe<Scalars['Int']['input']>;
  /** The indexes of the rack units that the device occupies. */
  rackMountUnitIndexes: Array<Scalars['Int']['input']>;
  /** The type of the device. Cannot be specified if it's a Meter device. */
  type?: InputMaybe<RackElevationDeviceType>;
  /** If it's a Meter device, the virtual device's UUID. */
  virtualDeviceUUID?: InputMaybe<Scalars['UUID']['input']>;
};

/** An authentication token entity, representing a token issued to a user or device. */
export type AuthenticationToken = {
  __typename?: 'AuthenticationToken';
  /** The unique identifier of the token. */
  UUID: Scalars['UUID']['output'];
  /** The serial number of the hardware device associated with the token, if any. */
  hardwareDeviceSerial?: Maybe<Scalars['String']['output']>;
  /** The timestamp when the token was issued. */
  issuedAt: Scalars['DateTime']['output'];
  /** The timestamp when the token was revoked, if it has been revoked. */
  revokedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The ID of the user associated with the token, if any. */
  userID?: Maybe<Scalars['Int']['output']>;
};

/** Auto Channel Selection Jobs automatically select the best channel for Access Points (APs) in a network. */
export type AutoChannelSelectionJob = AutoSelectionJob & {
  __typename?: 'AutoChannelSelectionJob';
  /** The unique identifier of the job. */
  UUID: Scalars['UUID']['output'];
  /** The date and time when the job was completed. Null if not completed yet. */
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The UUID of the network where the job is executed. */
  networkUUID: Scalars['UUID']['output'];
  /**
   * The results of the Auto Channel Selection job.
   * If the job is still running (completedAt is null), then no results will be returned.
   * One result will be returned for each virtual device (AP) that had its channel changed or encountered a warning.
   * If the device did not change channel and there was no warning, it will not appear in the results.
   */
  results?: Maybe<Array<AutoChannelSelectionResult>>;
  /** The date and time when the job is scheduled to start. */
  scheduledAt: Scalars['DateTime']['output'];
  /** The date and time when the job will time out if not completed. */
  timeoutAt: Scalars['DateTime']['output'];
  /** List of UUIDs of virtual devices (APs) involved in the job. */
  virtualDeviceUUIDs: Array<Scalars['UUID']['output']>;
};

/** Input parameters for initializing an Auto Channel Selection Job. */
export type AutoChannelSelectionJobInput = {
  /**
   * The radio bands to run ACS for.
   * Currently, only the 5GHz band is supported, but this is required for future-proofing.
   */
  bands: Array<RadioBand>;
  /** Only for network-level runs: If true, only run ACS on APs that are experiencing high external interference. */
  excludeLowInterferenceAPs?: Scalars['Boolean']['input'];
  /**
   * If true, the ACS job will run during the network's next maintenance window.
   * Otherwise, it will run immediately.
   */
  scheduledAtNextMaintenanceWindow?: Scalars['Boolean']['input'];
};

/** The result of running Auto Channel Selection on a single virtual device (AP). */
export type AutoChannelSelectionResult = {
  __typename?: 'AutoChannelSelectionResult';
  /** The new 5GHz channel assigned to the AP after ACS ran. Null if no new channel was assigned. */
  new5GHzChannel?: Maybe<Scalars['Int']['output']>;
  /** The previous 5GHz channel assigned to the AP before ACS ran. Null if not applicable. */
  previous5GHzChannel?: Maybe<Scalars['Int']['output']>;
  /** The result type indicating the outcome of the Auto Channel Selection. */
  resultType: AutoChannelSelectionResultType;
  /** The date and time when the result was recorded. */
  timestamp: Scalars['DateTime']['output'];
  /** The UUID of the virtual device (AP) for which the result is reported. */
  virtualDeviceUUID: Scalars['UUID']['output'];
};

/** Enumeration of possible results for an Auto Channel Selection on an AP. */
export enum AutoChannelSelectionResultType {
  /** ACS ran and a channel was selected with the least number of BSSs, indicating minimal interference. */
  ChannelSelectedMinBss = 'CHANNEL_SELECTED_MIN_BSS',
  /** ACS ran but a random channel was selected, possibly due to insufficient data to make an informed choice. */
  ChannelSelectedRandom = 'CHANNEL_SELECTED_RANDOM',
  /** The Auto Channel Selection failed due to an unknown reason. */
  FailedUnknownReason = 'FAILED_UNKNOWN_REASON',
  /** The Auto Channel Selection was successful. */
  Success = 'SUCCESS',
}

/** An interface representing a generic Auto Selection Job with common fields. */
export type AutoSelectionJob = {
  /** The unique identifier of the job. */
  UUID: Scalars['UUID']['output'];
  /** The date and time when the job was completed. Null if not completed yet. */
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The UUID of the network where the job is executed. */
  networkUUID: Scalars['UUID']['output'];
  /** The date and time when the job is scheduled to start. */
  scheduledAt: Scalars['DateTime']['output'];
  /** The date and time when the job will time out if not completed. */
  timeoutAt: Scalars['DateTime']['output'];
  /** A list of UUIDs of virtual devices involved in the job. */
  virtualDeviceUUIDs: Array<Scalars['UUID']['output']>;
};

export type AutoVpnGroup = {
  __typename?: 'AutoVPNGroup';
  /** Unique identifier for the AutoVPN Group. */
  UUID: Scalars['UUID']['output'];
  /** Slug of the company the AutoVPN Group belongs to. */
  companySlug: Scalars['String']['output'];
  /** Timestamp when the AutoVPN Group was created. */
  createdAt: Scalars['DateTime']['output'];
  /** The network that acts as the hub in the AutoVPN Group. */
  hubNetwork: Network;
  /** Unique identifier of the network that acts as the hub in the AutoVPN Group. */
  hubNetworkUUID: Scalars['UUID']['output'];
  /** Configuration of the hub's uplink interface. */
  hubUplink: AutoVpnHubUplink;
  /** Indicates if the AutoVPN Group is enabled. */
  isEnabled: Scalars['Boolean']['output'];
  /** Indicates if hub failover is enabled. */
  isHubFailoverEnabled: Scalars['Boolean']['output'];
  /** List of members in the AutoVPN Group. */
  members?: Maybe<Array<AutoVpnMember>>;
  /** Name of the AutoVPN Group. */
  name: Scalars['String']['output'];
  /** List of routes configured in the AutoVPN Group. */
  routes?: Maybe<Array<AutoVpnRoute>>;
  /** Timestamp when the AutoVPN Group was last updated. */
  updatedAt: Scalars['DateTime']['output'];
};

export type AutoVpnHubUplink = {
  __typename?: 'AutoVPNHubUplink';
  /** Unique identifier for the hub's uplink. */
  UUID: Scalars['UUID']['output'];
  /** IP address assigned to the hub's uplink. */
  address: Scalars['IP']['output'];
  /** Prefix length of the uplink's IP address. */
  addressPrefixLength: Scalars['Int']['output'];
  /** Port number the hub listens on for VPN connections. */
  listenPort: Scalars['Int']['output'];
  /** Physical interface used by the hub's uplink. */
  phyInterface: PhyInterface;
  /** Unique identifier of the physical interface used by the hub's uplink. */
  phyInterfaceUUID: Scalars['UUID']['output'];
  /** Public key used for WireGuard VPN. */
  publicKey: Scalars['String']['output'];
};

export type AutoVpnMember = {
  __typename?: 'AutoVPNMember';
  /** Unique identifier for the AutoVPN member. */
  UUID: Scalars['UUID']['output'];
  /** Timestamp when the AutoVPN member was created. */
  createdAt: Scalars['DateTime']['output'];
  /** Indicates if failover is enabled for the member. */
  isFailoverEnabled: Scalars['Boolean']['output'];
  /** Network the member belongs to. */
  network: Network;
  /** Unique identifier of the network the member belongs to. */
  networkUUID: Scalars['UUID']['output'];
  /** VLANs permitted for the member. */
  permittedVLANs: Array<Vlan>;
  /** Timestamp when the AutoVPN member was last updated. */
  updatedAt: Scalars['DateTime']['output'];
  /** Configuration of the member's uplink interface. */
  uplink: AutoVpnMemberUplink;
};

export type AutoVpnMemberUplink = {
  __typename?: 'AutoVPNMemberUplink';
  /** Unique identifier for the member's uplink. */
  UUID: Scalars['UUID']['output'];
  /** IP address assigned to the member's uplink. */
  address: Scalars['IP']['output'];
  /** Prefix length of the uplink's IP address. */
  addressPrefixLength: Scalars['Int']['output'];
  /** The latest connection info an AutoVPN hub reported about a member. */
  connectionInfo?: Maybe<AutoVpnMemberUplinkConnectionInfo>;
  /** Unique identifier of the hub's uplink this member connects to. */
  hubUplinkUUID: Scalars['UUID']['output'];
  /** Port number the member listens on for VPN connections. */
  listenPort: Scalars['Int']['output'];
  /** Physical interface used by the member's uplink. */
  phyInterface: PhyInterface;
  /** Unique identifier of the physical interface used by the member's uplink. */
  phyInterfaceUUID: Scalars['UUID']['output'];
  /** Public key used for WireGuard VPN. */
  publicKey: Scalars['String']['output'];
};

export type AutoVpnMemberUplinkConnectionInfo = {
  __typename?: 'AutoVPNMemberUplinkConnectionInfo';
  /** The last time a handshake was received (from the hub's perspective). */
  lastHandshakeRx?: Maybe<Scalars['DateTime']['output']>;
  /** The last time a handshake was transmitted (from the hub's perspective). */
  lastHandshakeTx?: Maybe<Scalars['DateTime']['output']>;
  /** The last time a packet was received over the tunnel (from the hub's perspective). */
  lastPacketRx?: Maybe<Scalars['DateTime']['output']>;
  /** The last time a packet was transmitted over the tunnel (from the hub's perspective). */
  lastPacketTx?: Maybe<Scalars['DateTime']['output']>;
  /** Timestamp when the connection info was observed. */
  observedAt?: Maybe<Scalars['DateTime']['output']>;
};

/** An Auto VPN route defines a network route members of an Auto VPN group route through the hub. */
export type AutoVpnRoute = {
  __typename?: 'AutoVPNRoute';
  /** Unique identifier for the AutoVPN route. */
  UUID: Scalars['UUID']['output'];
  /** Timestamp when the route was created. */
  createdAt: Scalars['DateTime']['output'];
  /** Destination gateway IP address for the route. */
  dstGateway: Scalars['IPV4']['output'];
  /** Prefix length for the destination IP of the route. */
  dstPrefixLength: Scalars['Int']['output'];
  /** Indicates if the route is enabled. */
  isEnabled: Scalars['Boolean']['output'];
  /** Name of the route. */
  name: Scalars['String']['output'];
  /** Timestamp when the route was last updated. */
  updatedAt: Scalars['DateTime']['output'];
};

/**
 * BSSID represents a unique identifier for a wireless network, in the form of a MAC address.
 * Every radio band / SSID pair for an AP has its own BSSID, derived from the hardware device's MAC address.
 */
export type Bssid = {
  __typename?: 'BSSID';
  /** The MAC address uniquely identifying this BSSID on the wireless network. */
  BSSID: Scalars['MacAddress']['output'];
  /** The SSID object associated with this BSSID. */
  SSID: Ssid;
  /** The UUID of the SSID associated with this BSSID. */
  SSIDUUID: Scalars['UUID']['output'];
  /** The hardware device representing the access point associated with this BSSID. */
  accessPointHardwareDevice: AccessPointHardwareDevice;
  /** The serial number of the access point associated with this BSSID. */
  accessPointSerialNumber: Scalars['String']['output'];
  /** A BSSID is inactive if it is associated with a disabled SSID, or the SSID is not broadcast for its AP, or the SSID is not allowed for its radio band, or the radio band is disabled on its AP's radio profile. */
  isActive: Scalars['Boolean']['output'];
  /** The radio frequency band on which this BSSID operates. */
  radioBand: RadioBand;
};

export type Base64Image = {
  __typename?: 'Base64Image';
  /** The base64 encoded image data. */
  data: Scalars['String']['output'];
  /** The type of the image (e.g., PNG, JPEG, GIF, or SVG). */
  imageType: ImageType;
};

/** A bearer session allows a token to be created for a CLI session using the credentials of the validator. */
export type BearerSession = {
  __typename?: 'BearerSession';
  /** The unique identifier for the bearer session. This UUID is used to reference and manage the session during its lifecycle. */
  UUID: Scalars['UUID']['output'];
  /** The authentication token associated with the bearer session. This token is used for authenticated CLI requests after the session has been validated. */
  token?: Maybe<Scalars['String']['output']>;
};

/** Input type for binding a firewall rule to a physical interface. */
export type BindFirewallRuleToPhyInterface = {
  /** UUID of the firewall rule to bind. */
  firewallRuleUUID: Scalars['UUID']['input'];
  /** Metric to assign to the binding (must be greater than or equal to 0). */
  metric: Scalars['Int']['input'];
  /**
   * UUID of the physical interface to bind the firewall rule to.
   * The physical interface must be an uplink.
   */
  phyInterfaceUUID: Scalars['UUID']['input'];
};

/** Input type for binding a firewall rule to a VLAN. */
export type BindFirewallRuleToVlan = {
  /** UUID of the firewall rule to bind. */
  firewallRuleUUID: Scalars['UUID']['input'];
  /** Metric to assign to the binding (must be greater than or equal to 0). */
  metric: Scalars['Int']['input'];
  /** UUID of the VLAN to bind the firewall rule to. */
  vlanUUID: Scalars['UUID']['input'];
};

export type BindPolicyRoutingRuleInput = {
  /** The binding information for the policy routing rule. */
  binding: PolicyRoutingRuleBindingInput;
  /** The metric (priority) assigned to the binding. If not provided, will be 1 greater than current greatest metric. */
  metric?: InputMaybe<Scalars['Int']['input']>;
};

export type BindPolicyRoutingRulesInput = {
  /** The binding information to be applied. */
  binding: PolicyRoutingRuleBindingInput;
};

export type BindVlanToPhyInterfaceInput = {
  /** If true and there is already a Native VLAN bound to this interface, the Native VLAN will be overwritten and set to this VLAN. */
  isTagged: Scalars['Boolean']['input'];
  /** The UUID of the physical interface to which the VLAN will be bound. */
  phyInterfaceUUID: Scalars['UUID']['input'];
  /** The UUID of the VLAN to bind to the physical interface. */
  vlanUUID: Scalars['UUID']['input'];
};

/** BootInfo captures logs every time a device boots up. */
export type BootInfo = {
  __typename?: 'BootInfo';
  /** The last reported boot counter which the device increments every time it boots. */
  bootCount: Scalars['Int']['output'];
  /** The last reported build the device was running. */
  buildName: Scalars['String']['output'];
  /** The time at which the device first reported the information. */
  createdAt: Scalars['DateTime']['output'];
  /** If the device knows, this is the last reported reason that caused the device to reboot. */
  rebootReason: Scalars['String']['output'];
  /** The time at which the device reported the information. */
  updatedAt: Scalars['DateTime']['output'];
};

export type BulkNosUpgrade = {
  __typename?: 'BulkNOSUpgrade';
  /** Unique identifier of the bulk NOS upgrade. */
  UUID: Scalars['UUID']['output'];
  /** Timestamp when the bulk NOS upgrade was created. */
  createdAt: Scalars['DateTime']['output'];
  /** List of networks included in the bulk NOS upgrade. */
  networks: Array<Network>;
  /** Identifier of the NOS version to upgrade to. */
  nosVersionID: Scalars['Int']['output'];
  /** Timestamp when the bulk NOS upgrade was last updated. */
  updatedAt: Scalars['DateTime']['output'];
  /** Username or identifier of the user who last updated the bulk NOS upgrade. */
  updatedBy: Scalars['String']['output'];
};

export type BulkNosUpgradesFilterInput = {
  /** Maximum number of bulk NOS upgrades to return. */
  limit?: Scalars['Int']['input'];
  /** Filter to include bulk NOS upgrades created before or at this time. */
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter to include bulk NOS upgrades created at or after this time. */
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Number of bulk NOS upgrades to skip before starting to collect the result set. */
  offset?: Scalars['Int']['input'];
};

export type CaptivePortal = {
  __typename?: 'CaptivePortal';
  /** The unique identifier of the captive portal. */
  UUID: Scalars['UUID']['output'];
  /** Whitelisted IPs/URLs that are allowed on the network by default. */
  allowedHosts: Array<Scalars['String']['output']>;
  /** Authentication session duration in seconds. The amount of time a user remains authorized after accepting the terms and conditions. Defaults to 1 day (86400 seconds) if not set. */
  authLifetimeSec?: Maybe<Scalars['Int']['output']>;
  /** The heading to display on the captive portal page. */
  callToAction?: Maybe<Scalars['String']['output']>;
  /** Display settings for the captive portal page, including background and font colors and whether to hide the "Powered by Meter" text. */
  displaySettings?: Maybe<CaptivePortalDisplaySettings>;
  /** The URL to an external captive portal. If this is configured, it will override all configurations specific to internal captive portals. */
  externalPortalURL?: Maybe<Scalars['String']['output']>;
  /** HMAC secret used to sign and verify signatures. */
  hmacSecret?: Maybe<Scalars['String']['output']>;
  /** If true, the captive portal is enabled. */
  isEnabled: Scalars['Boolean']['output'];
  /** A base64 encoded image of the logo. This is used to limit the number of domains we need to allow for the logo image. */
  logoImageBase64?: Maybe<Base64Image>;
  /** The S3 key for where the logo image is stored. This is used for configuration and should not be used to display the image. */
  logoImageS3Key?: Maybe<Scalars['String']['output']>;
  /** A URL to a logo image that will be displayed on the captive portal page. */
  logoImageURL?: Maybe<Scalars['String']['output']>;
  /** A human-friendly name for the captive portal. */
  name?: Maybe<Scalars['String']['output']>;
  /** The UUID of the network to which this captive portal belongs. */
  networkUUID: Scalars['UUID']['output'];
  /** The URL to redirect to after a successful captive portal login. */
  redirectURL?: Maybe<Scalars['String']['output']>;
  /** The terms and conditions text that a user must agree to before accessing the network. */
  termsAndConditions?: Maybe<Scalars['String']['output']>;
  /** The VLANs that this captive portal is associated with. */
  vlans?: Maybe<Array<Vlan>>;
  /** UUID used to generate a unique URL for a webhook corresponding to this captive portal. */
  webhookKey: Scalars['UUID']['output'];
};

export type CaptivePortalClient = {
  __typename?: 'CaptivePortalClient';
  /** The IP address of the client device. */
  ipAddress: Scalars['IP']['output'];
  /** If true, the client has accepted the terms and conditions and has been authorized. */
  isAuthorized: Scalars['Boolean']['output'];
  /** The MAC address of the client device. */
  macAddress: Scalars['MacAddress']['output'];
  /** The raw user-agent string of the client device. */
  rawUserAgent: Scalars['String']['output'];
  /** The timestamp when the client accessed or interacted with the captive portal. */
  timestamp: Scalars['DateTime']['output'];
};

export type CaptivePortalDisplaySettings = {
  __typename?: 'CaptivePortalDisplaySettings';
  /** The background color of the captive portal page, specified as a hex color code. */
  backgroundColor?: Maybe<Scalars['String']['output']>;
  /** The color used on the call to action, specified as a hex color code. */
  callToActionColor?: Maybe<Scalars['String']['output']>;
  /** The font color used on the captive portal page, specified as a hex color code. */
  fontColor?: Maybe<Scalars['String']['output']>;
  /** If true, the "Powered by Meter" text will be hidden on the captive portal page. */
  hidePoweredByMeter?: Maybe<Scalars['Boolean']['output']>;
};

export type CaptivePortalDisplaySettingsInput = {
  /** The background color of the captive portal page, specified as a hex color code. */
  backgroundColor?: InputMaybe<Scalars['String']['input']>;
  /** A hex color code */
  callToActionColor?: InputMaybe<Scalars['String']['input']>;
  /** The font color used on the captive portal page, specified as a hex color code. */
  fontColor?: InputMaybe<Scalars['String']['input']>;
  /** If true, the "Powered by Meter" text will be hidden on the captive portal page. */
  hidePoweredByMeter?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CaptivePortalSettingsInput = {
  /** The IPs/URLs that are whitelisted by default. */
  allowedHosts?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Authentication session duration in seconds. The amount of time a user remains authorized after accepting the terms and conditions. Defaults to 1 day (86400 seconds) if not set. */
  authLifetimeSec?: InputMaybe<Scalars['Int']['input']>;
  /** The heading to display on the captive portal page. */
  callToAction?: InputMaybe<Scalars['String']['input']>;
  /** Display settings configuration for the captive portal page, including background and font colors and whether to hide the "Powered by Meter" text. */
  displaySettings?: InputMaybe<CaptivePortalDisplaySettingsInput>;
  /** Setting externalPortalURL will configure this captive portal to redirect users to an external portal and override any settings specific to internal captive portals. */
  externalPortalURL?: InputMaybe<Scalars['String']['input']>;
  /** If true, the captive portal is enabled. */
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Use the `createPreSignedUploadUrl` mutation to generate an S3 key and presigned URL for the logo image, and specify it here. */
  logoImageS3Key?: InputMaybe<Scalars['String']['input']>;
  /** A human-friendly name for the captive portal. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The URL to redirect to after a successful captive portal login. */
  redirectURL?: InputMaybe<Scalars['String']['input']>;
  /** The terms and conditions text that a user must agree to before accessing the network. */
  termsAndConditions?: InputMaybe<Scalars['String']['input']>;
  /** Setting vlanUUIDs will assign this captive portal to the specified VLANs. */
  vlanUUIDs?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

/** Enumeration of possible routes for a cellular failover event. */
export enum CellularFailoverEventRoute {
  /** Route the failover event through the cellular network. */
  Cellular = 'CELLULAR',
  /** Route the failover event through the Wide Area Network (WAN). */
  Wan = 'WAN',
}

/** Enumeration of possible states for the cellular modem as managed by the device manager. */
export enum CellularManagerModemState {
  /** The modem is attached to the network. */
  Attached = 'ATTACHED',
  /** The modem is detached from the network. */
  Detached = 'DETACHED',
  /** The state of the modem is unspecified or unknown. */
  Unspecified = 'UNSPECIFIED',
}

/** Represents the current cellular status of a device, including the modem state and the time this status was observed. */
export type CellularStatus = {
  __typename?: 'CellularStatus';
  /** The state of the device's cellular modem as managed by the device manager. */
  managerModemState: CellularManagerModemState;
  /** The timestamp when this status was last observed. */
  observedAt: Scalars['DateTime']['output'];
};

/** Represents the cellular data usage of a device, including uploaded and downloaded bytes. */
export type CellularUsage = {
  __typename?: 'CellularUsage';
  /** Number of bytes downloaded by the device over the cellular network. */
  downloadBytes?: Maybe<Scalars['Int']['output']>;
  /** Number of bytes uploaded by the device over the cellular network. */
  uploadBytes?: Maybe<Scalars['Int']['output']>;
};

export type ChannelUtilizationResponseV2 = {
  __typename?: 'ChannelUtilizationResponseV2';
  /** The radio band (e.g., 2.4 GHz, 5 GHz) for which the data is provided. */
  band: RadioBand;
  /** List of timeseries values of channel utilization metrics. */
  values: Array<ApChannelUtilizationTimeseriesValue>;
  /** The unique identifier of the virtual device (AP) from which the data originates. */
  virtualDeviceUUID: Scalars['UUID']['output'];
};

export type ChannelUtilizationTimeseriesFilterInput = {
  /** Specify the time range for the timeseries data. */
  timeFilter: MetricsFilterInput;
};

/** Input type for checking if the current user has a specific role in a given context. */
export type CheckRoleInput = {
  /**
   * The slug (unique identifier) of the company to check the role against.
   *
   * If the role is global or operator-level, this can be omitted.
   */
  companySlug?: InputMaybe<Scalars['String']['input']>;
  /**
   * The UUID of the network to check the role against.
   *
   * Provide this if checking for a network-specific role.
   */
  networkUUID?: InputMaybe<Scalars['UUID']['input']>;
  /** The name of the role to check for. */
  roleName: RoleName;
};

/** ClientAssignmentProtocol defines the method used to assign IP addresses to clients. */
export enum ClientAssignmentProtocol {
  Dhcp = 'DHCP',
  Static = 'STATIC',
}

export type ClientChannelUtilizationTimeseriesValue = {
  __typename?: 'ClientChannelUtilizationTimeseriesValue';
  /** The amount of utilization contributed by non-802.11 interference in the environment, e.g., Bluetooth, cameras (from 0 to 1). */
  non80211Utilization: Scalars['Float']['output'];
  /** The amount of utilization due to 802.11 interference by neighboring networks (from 0 to 1). */
  obssUtilization: Scalars['Float']['output'];
  /** The percentage of time the channel was utilized due to receptions from clients (from 0 to 1). */
  rxUtilization: Scalars['Float']['output'];
  /** The percentage of time the channel was utilized by the AP itself (from 0 to 1). */
  selfUtilization: Scalars['Float']['output'];
  /** The timestamp of the metric data point. */
  timestamp: Scalars['DateTime']['output'];
  /** The percentage of time the channel was utilized (from 0 to 1). */
  totalUtilization: Scalars['Float']['output'];
  /** The percentage of time the channel was utilized due to transmissions from the AP (from 0 to 1). */
  txUtilization: Scalars['Float']['output'];
};

/** Aggregated values (like min/max) over the given time range for all of the client metrics requested. */
export type ClientMetricsMetadata = {
  __typename?: 'ClientMetricsMetadata';
  /** Aggregated metadata for the association time in seconds */
  associationTimeSeconds?: Maybe<MetricsMetadata>;
  /** Aggregated metadata for the client count */
  clientCount?: Maybe<MetricsMetadata>;
  /** Aggregated metadata for the hardware mode of the client device. */
  hwMode?: Maybe<MetricsMetadata>;
  /** Aggregated metadata for the noise level in dBm. */
  noise?: Maybe<MetricsMetadata>;
  /** Aggregated metadata for the number of receive spatial streams. */
  numSpatialStreamsRx?: Maybe<MetricsMetadata>;
  /** Aggregated metadata for the number of transmit spatial streams. */
  numSpatialStreamsTx?: Maybe<MetricsMetadata>;
  /**
   * Deprecated. Prefer `rxBytes` instead.
   * @deprecated Prefer rxBytes instead
   */
  rx?: Maybe<MetricsMetadata>;
  /** Aggregated metadata for the total bytes received. */
  rxBytes?: Maybe<MetricsMetadata>;
  /** Aggregated metadata for the multicast bytes received. */
  rxMulticastBytes?: Maybe<MetricsMetadata>;
  /** Aggregated metadata for the receive rate in kilobits per second. */
  rxRate?: Maybe<MetricsMetadata>;
  /** Aggregated metadata for the unicast bytes received. */
  rxUnicastBytes?: Maybe<MetricsMetadata>;
  /** Aggregated metadata for the signal strength in dBm. */
  signal?: Maybe<MetricsMetadata>;
  /** Aggregated metadata for the signal-to-noise ratio in dB. */
  snr?: Maybe<MetricsMetadata>;
  /**
   * Deprecated. Prefer `txBytes` instead.
   * @deprecated Prefer txBytes instead
   */
  tx?: Maybe<MetricsMetadata>;
  /** Aggregated metadata for the total bytes transmitted. */
  txBytes?: Maybe<MetricsMetadata>;
  /** Aggregated metadata for the multicast bytes transmitted. */
  txMulticastBytes?: Maybe<MetricsMetadata>;
  /** Aggregated metadata for the transmit rate in kilobits per second. */
  txRate?: Maybe<MetricsMetadata>;
  /** Aggregated metadata for the unicast bytes transmitted. */
  txUnicastBytes?: Maybe<MetricsMetadata>;
};

/** Deprecated in favor of `ClientMetricsTimeseriesResponse`. */
export type ClientMetricsResponse = {
  __typename?: 'ClientMetricsResponse';
  /** Aggregated metadata for the client metrics over the specified time range. */
  metadata?: Maybe<ClientMetricsMetadata>;
  /** A list of client metric values. */
  values: Array<ClientMetricsValue>;
};

export type ClientMetricsTimeseriesFilterInput = {
  /** Specifying this field returns only metrics observed for the given radio band(s). */
  bands?: InputMaybe<Array<RadioBand>>;
  /** Specifying this field returns only metrics observed for the given radio channel(s). */
  channels?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Specifying this field returns only metrics observed for the given SSID(s), by UUID. */
  ssidUUIDs?: InputMaybe<Array<Scalars['UUID']['input']>>;
  /** Specify the time range for the timeseries data. */
  timeFilter: MetricsFilterInput;
};

export type ClientMetricsTimeseriesResponse = {
  __typename?: 'ClientMetricsTimeseriesResponse';
  /** Aggregated metadata for the client metrics timeseries. */
  metadata?: Maybe<ClientMetricsMetadata>;
  /** A list of client metrics timeseries values. */
  values: Array<ClientMetricsTimeseriesValue>;
};

/**
 * Time-series data for client connectivity metrics.
 * If requested at a per-AP level, the results will be aggregated over all clients connected to the AP.
 */
export type ClientMetricsTimeseriesValue = {
  __typename?: 'ClientMetricsTimeseriesValue';
  /**
   * Number of seconds that the client has been associated with an AP for.
   * @deprecated This isn't really timeseries data and a timestamp is more useful; refer to WirelessClient.associatedAt instead.
   */
  associationTimeSeconds?: Maybe<Scalars['Float']['output']>;
  /** The count of distinct clients (by MAC address) that connected to the AP during the time period. */
  clientCount: Scalars['Int']['output'];
  /** The Wi-Fi capabilities of the client device. */
  hwMode?: Maybe<Scalars['String']['output']>;
  /** Noise level in dBm. */
  noise: Scalars['Float']['output'];
  /** The number of receive spatial streams. */
  numSpatialStreamsRx?: Maybe<Scalars['Int']['output']>;
  /** The number of transmit spatial streams. */
  numSpatialStreamsTx?: Maybe<Scalars['Int']['output']>;
  /** Total number of bytes received in the given time period. */
  rxBytes: Scalars['Float']['output'];
  /** Total number of multicast bytes received in the given time period; a subset of `rxBytes`. */
  rxMulticastBytes: Scalars['Float']['output'];
  /** The rate at which the client is receiving data in kilobits per second. */
  rxRate: Scalars['Float']['output'];
  /** The ratio of the number of times a packet was retried to the total number of successful packets. Null if no packets succeeded. */
  rxRetryRatio?: Maybe<Scalars['Float']['output']>;
  /**
   * Deprecated. It's unreliable to determine this client stat from the AP's perspective.
   * @deprecated It's unreliable to determine this client stat from the AP's perspective.
   */
  rxSuccessRatio?: Maybe<Scalars['Float']['output']>;
  /** Total number of unicast bytes received in the given time period; a subset of `rxBytes`. */
  rxUnicastBytes: Scalars['Float']['output'];
  /** Received signal strength in dBm. */
  signal: Scalars['Float']['output'];
  /** Signal-to-noise ratio in dB. A higher value is better. SNR = signal - noise. */
  snr: Scalars['Float']['output'];
  /** The timestamp of the metric data point. */
  timestamp: Scalars['DateTime']['output'];
  /** Total number of bytes transmitted in the given time period. */
  txBytes: Scalars['Float']['output'];
  /** Total number of multicast bytes transmitted in the given time period; a subset of `txBytes`. */
  txMulticastBytes: Scalars['Float']['output'];
  /** The rate at which the client is transmitting data in kilobits per second. */
  txRate: Scalars['Float']['output'];
  /** The ratio of the number of times the client retried a packet transmission to the number of packets successfully transmitted. Null if no packets were transmitted. */
  txRetryRatio?: Maybe<Scalars['Float']['output']>;
  /** The ratio of the number of times the client successfully received a packet to the number of packets transmitted. Null if no packets were transmitted. */
  txSuccessRatio?: Maybe<Scalars['Float']['output']>;
  /** Total number of unicast bytes transmitted in the given time period; a subset of `txBytes`. */
  txUnicastBytes: Scalars['Float']['output'];
};

/** Deprecated in favor of `ClientMetricsTimeseriesValue`. */
export type ClientMetricsValue = {
  __typename?: 'ClientMetricsValue';
  /** The MAC address of the client device. */
  macAddress: Scalars['MacAddress']['output'];
  /** Noise level in dBm. */
  noise: Scalars['Float']['output'];
  /** The receive rate from the client in kilobits per second. */
  rxRate: Scalars['Float']['output'];
  /** Received signal strength in dBm. */
  signal: Scalars['Float']['output'];
  /** The timestamp of the metric data point. */
  timestamp: Scalars['DateTime']['output'];
  /** The transmit rate to the client in kilobits per second. */
  txRate: Scalars['Float']['output'];
};

/** A Client VPN client is an end user configuration for connecting to a Client VPN server. */
export type ClientVpnClient = {
  __typename?: 'ClientVPNClient';
  /** Unique identifier for the Client VPN client. */
  UUID: Scalars['UUID']['output'];
  /** The latest connection info a device reported about this client. */
  connectionInfo?: Maybe<ClientVpnClientConnectionInfo>;
  /** Timestamp when the client configuration was created. */
  createdAt: Scalars['DateTime']['output'];
  /** Identifier of the user who created the client configuration. */
  createdBy: Scalars['String']['output'];
  /** IP address assigned to the client by the VPN server. */
  ipAddress: Scalars['IP']['output'];
  /** Name of the client device (e.g., 'paulmbp'). */
  name: Scalars['String']['output'];
  /** WireGuard public key of the client. */
  publicKey: Scalars['String']['output'];
  /** Email address of the client user. */
  userEmail: Scalars['String']['output'];
  /** Unique identifier of the user associated with the client. */
  userSID: Scalars['UUID']['output'];
  /** Unique identifier of the VPN server this client connects to. */
  vpnServerUUID: Scalars['UUID']['output'];
};

/** Connection information reported by the client VPN client. */
export type ClientVpnClientConnectionInfo = {
  __typename?: 'ClientVPNClientConnectionInfo';
  /** The last time a handshake was received (from the controller's perspective). */
  lastHandshakeRx?: Maybe<Scalars['DateTime']['output']>;
  /** The last time a handshake was transmitted (from the controller's perspective). */
  lastHandshakeTx?: Maybe<Scalars['DateTime']['output']>;
  /** The last time a packet was received over the tunnel (from the controller's perspective). */
  lastPacketRx?: Maybe<Scalars['DateTime']['output']>;
  /** The last time a packet was transmitted over the tunnel (from the controller's perspective). */
  lastPacketTx?: Maybe<Scalars['DateTime']['output']>;
  /** Timestamp when this connection info was observed. */
  observedAt?: Maybe<Scalars['DateTime']['output']>;
};

/** A Client VPN server is a WireGuard server used on a security appliance for remote connection to the device. */
export type ClientVpnServer = {
  __typename?: 'ClientVPNServer';
  /** Unique identifier for the Client VPN server. */
  UUID: Scalars['UUID']['output'];
  /** IP address assigned to the WireGuard interface. */
  address?: Maybe<Scalars['IP']['output']>;
  /** Subnet mask length (CIDR notation) for the WireGuard interface address. */
  addressMask?: Maybe<Scalars['Int']['output']>;
  /** The Client VPN Clients for this server. */
  clients: Array<ClientVpnClient>;
  /** Sets the default value used for the WireGuard option 'AllowedIPs' for generated client configs. */
  defaultClientAllowedIPs?: Maybe<Array<Scalars['IPPrefix']['output']>>;
  /** An additional listen address other than `address` this server serves DNS traffic on. It must be a private IP. */
  dnsListenAddress?: Maybe<Scalars['IPV4']['output']>;
  /** The endpoint clients should connect to. */
  endpoint: Scalars['String']['output'];
  /** If failover between uplink phy interfaces (WANs) should be enabled. */
  isFailoverEnabled: Scalars['Boolean']['output'];
  /** The WireGuard interface will listen on this interface. If null, the server is not active. */
  phyInterface?: Maybe<PhyInterface>;
  /** Port number on which the WireGuard server listens for incoming connections. */
  port: Scalars['Int']['output'];
  /** The WireGuard public key. */
  publicKey: Scalars['String']['output'];
};

/** A company is a core Meter entity used to represent customers. Networks belong to companies. */
export type Company = {
  __typename?: 'Company';
  /**
   * The Salesforce Company ID corresponding to this company.
   * @deprecated Use `isCustomer` instead.
   */
  externalID?: Maybe<Scalars['String']['output']>;
  /** A company representing a Meter customer as opposed to an internal (test) company. */
  isCustomer: Scalars['Boolean']['output'];
  /** A prospective company is used for sales purposes. */
  isProspective: Scalars['Boolean']['output'];
  /** The name of the company. */
  name: Scalars['String']['output'];
  /** The networks associated with this company. */
  networks: Array<Network>;
  /** List of email domains permitted for authentication for this company. */
  permittedEmailDomains?: Maybe<Array<PermittedEmailDomain>>;
  /** A unique, URL-friendly identifier for the company, used in APIs and URLs. */
  slug: Scalars['String']['output'];
  /** Indicates whether Single Sign-On (SSO) is enabled for the company. */
  ssoEnabled?: Maybe<Scalars['Boolean']['output']>;
  supportGroup?: Maybe<Scalars['Int']['output']>;
  /** The Customer Success team segments customers by their tier, which dictates their level of support / sensitivity. */
  supportTier?: Maybe<CompanySupportTier>;
  /** The unique UUID identifier for the company. */
  uuid: Scalars['UUID']['output'];
};

/** Defines the various membership roles a user can have within a company. (not RBAC) */
export enum CompanyMembershipRole {
  /** An administrator with full permissions in the company. */
  Admin = 'ADMIN',
  /** A guest member with limited access to company resources. */
  Guest = 'GUEST',
  /** A standard member of the company. */
  Member = 'MEMBER',
}

/** The support tier indicates the customer's level of support / sensitivity. */
export enum CompanySupportTier {
  /** The highest level of support; critical support for the most sensitive customers. */
  Critical = 'CRITICAL',
  /** The high level of support; elevated support for important customers. */
  High = 'HIGH',
  /** The lowest level of support; minimal support required. */
  Low = 'LOW',
  /** The medium level of support; standard support. */
  Medium = 'MEDIUM',
}

/** A company user provides access for a user to a company's networks. */
export type CompanyUser = {
  __typename?: 'CompanyUser';
  /** The unique identifier for the company user. */
  UUID: Scalars['UUID']['output'];
  /** The company the user is associated with. */
  company: Company;
  /** The role of the user within the company. */
  companyMembershipRole: CompanyMembershipRole;
  /** The date and time when the company user was created. */
  createdAt: Scalars['DateTime']['output'];
  /** The date and time when the company user was deleted, if applicable. */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The roles assigned to the company user. */
  roles: Array<RoleAssignment>;
  /** The user who is associated with the company. */
  user: User;
};

/** Input for configuring SSO for a company. */
export type ConfigureSsoInput = {
  /** The slug (unique identifier) of the company. */
  companySlug: Scalars['String']['input'];
  /** The type of admin portal link to generate. */
  type: SsoAdminPortalType;
};

/** Result of configuring SSO for a company. */
export type ConfigureSsoResult = {
  __typename?: 'ConfigureSSOResult';
  /** The company for which SSO has been configured. */
  company: Company;
  /** The URL of the admin portal for SSO configuration. */
  portalURL: Scalars['String']['output'];
};

/** ConfiguredNOSVersion represents the NOS firmware version that a device is configured to run. */
export type ConfiguredNosVersion = {
  __typename?: 'ConfiguredNOSVersion';
  /** The build name specified in the NOS version (could be different from that reported by the device in bootinfo table). */
  buildName: Scalars['String']['output'];
  /** The unique identifier for the NOS version configuration. */
  id: Scalars['Int']['output'];
  /** The NOS firmware version string. */
  version: Scalars['String']['output'];
};

/** A console login record represents a user logging into a device via the console. */
export type ConsoleLogin = {
  __typename?: 'ConsoleLogin';
  /** Unique identifier for the console login record. */
  UUID: Scalars['UUID']['output'];
  /** Timestamp of when the console login was recorded. */
  createdAt: Scalars['DateTime']['output'];
  /** Device that the user logged into. */
  hardwareDevice: HardwareDevice;
  /** Username of the user that logged into the device. */
  username: Scalars['String']['output'];
};

/** Time-series data point for controller DHCP server uptime metrics. */
export type ControllerDhcpUptimeMetricsValue = MetricsValue & {
  __typename?: 'ControllerDHCPUptimeMetricsValue';
  /** Timestamp for the metrics data point. */
  timestamp: Scalars['DateTime']['output'];
  /** Uptime value in seconds. */
  value: Scalars['Float']['output'];
};

/** Response containing metadata and values for DHCP server uptime metrics for a controller. */
export type ControllerDhcpUptimeResponse = MetricsResponse & {
  __typename?: 'ControllerDHCPUptimeResponse';
  /** Metadata about the metrics. */
  metadata?: Maybe<MetricsMetadata>;
  /** Array of DHCP uptime metrics values. */
  values: Array<ControllerDhcpUptimeMetricsValue>;
};

/** Response containing an array of DNS request rate values. */
export type ControllerDnsRequestRatesResponse = {
  __typename?: 'ControllerDNSRequestRatesResponse';
  /** Array of DNS request rate values per timestamp and DNS server UUID. */
  values: Array<ControllerDnsRequestRatesValue>;
};

/** Metrics data point containing DNS request rates for a controller's DNS server. */
export type ControllerDnsRequestRatesValue = MetricsValue & {
  __typename?: 'ControllerDNSRequestRatesValue';
  /** Timestamp for the metrics data point. */
  timestamp: Scalars['DateTime']['output'];
  /** UUID of the DNS server. */
  uuid: Scalars['UUID']['output'];
  /** Requests per second. */
  value: Scalars['Float']['output'];
};

/** A controller hardware device represents a physical controller (aka Security Appliance). */
export type ControllerHardwareDevice = HardwareDevice & {
  __typename?: 'ControllerHardwareDevice';
  /** Shows the most recent boot history of a device (buildName, bootCount). */
  bootHistory?: Maybe<Array<BootInfo>>;
  /** The timestamp when the hardware device was first created in the system. */
  createdAt: Scalars['DateTime']['output'];
  /** The model name of the hardware device. */
  deviceModel: DeviceModel;
  /** The type of the hardware device. */
  deviceType: DeviceType;
  /** If the device is no longer connected to the backend, this is the time at which it was last connected. */
  disconnectedFromBackendAt?: Maybe<Scalars['DateTime']['output']>;
  /**
   * True if the device has an active websocket connection to the backend. Note that this only works if the device has registered via the /v2/register endpoint (i.e., is a Config 2 device) - otherwise is always false.
   * @deprecated Refer to `isSpare` instead.
   */
  isActive: Scalars['Boolean']['output'];
  /** Indicates whether the hardware device is connected to the backend. */
  isConnectedToBackend: Scalars['Boolean']['output'];
  /** Indicates whether the hardware device's console port is enabled. */
  isConsoleEnabled: Scalars['Boolean']['output'];
  /** Indicates whether the hardware device is marked as a development (dev) device, moving it into the dev (staging) realm for testing purposes. */
  isDev: Scalars['Boolean']['output'];
  /** Indicates whether the hardware device is in factory mode. */
  isFactoryMode: Scalars['Boolean']['output'];
  /** Indicates whether the hardware device is an RMA (Return Merchandise Authorization) replacement device. */
  isRMA: Scalars['Boolean']['output'];
  /** Indicates whether the hardware device has been retired. */
  isRetired: Scalars['Boolean']['output'];
  /** A spare hardware device belongs to a network but not a virtual device. Used most often to keep track of spare hardware we ship to a customer but don't install immediately. */
  isSpare: Scalars['Boolean']['output'];
  /** The MAC address of the hardware device. */
  macAddress?: Maybe<Scalars['MacAddress']['output']>;
  /** The UUID of the network that the spare hardware device belongs to. Spare hardware devices that are not affiliated with a virtual device will have this field populated. */
  networkUUID?: Maybe<Scalars['UUID']['output']>;
  /** The public key used to add the device to the jumphost's WireGuard interface. */
  publicKey?: Maybe<Scalars['String']['output']>;
  /** The unique serial number of the hardware device. */
  serialNumber: Scalars['String']['output'];
  /** IPv6 address on the jumphost subnet. Used to send requests to the device. */
  tunnelIPAddress?: Maybe<Scalars['String']['output']>;
  /** The timestamp when the hardware device was last updated in the system. */
  updatedAt: Scalars['DateTime']['output'];
  /** The uptime metrics from the time series database for the device. */
  uptime?: Maybe<Scalars['Duration']['output']>;
  /** The virtual device associated with this hardware device, if any. */
  virtualDevice?: Maybe<VirtualDevice>;
  /** The UUID of the virtual device this hardware device is assigned to, if any. */
  virtualDeviceUUID?: Maybe<Scalars['UUID']['output']>;
};

/** A controller hardware device represents a physical controller (aka Security Appliance). */
export type ControllerHardwareDeviceBootHistoryArgs = {
  count?: InputMaybe<Scalars['Int']['input']>;
};

/** Response object containing an array of controller port metrics rate values. */
export type ControllerPortMetricsRateResponse = {
  __typename?: 'ControllerPortMetricsRateResponse';
  /** Array of metrics rate values per timestamp and port. */
  values: Array<ControllerPortMetricsRateValue>;
};

/** Metrics data point containing rate statistics (per second) for a controller port over a specific time interval. */
export type ControllerPortMetricsRateValue = {
  __typename?: 'ControllerPortMetricsRateValue';
  /** Total number of broadcast packets per second received during the time interval; a subset of totalRxPackets. */
  broadcastRxPacketsPerSecond: Scalars['Float']['output'];
  /** Total number of broadcast packets per second transmitted during the time interval; a subset of totalTxPackets. */
  broadcastTxPacketsPerSecond: Scalars['Float']['output'];
  /** The number of packets per second that were dropped during the time interval. */
  dropsPerSecond: Scalars['Float']['output'];
  /** Total number of multicast packets per second received during the time interval; a subset of totalRxPackets. */
  multicastRxPacketsPerSecond: Scalars['Float']['output'];
  /** Total number of multicast packets per second transmitted during the time interval; a subset of totalTxPackets. */
  multicastTxPacketsPerSecond: Scalars['Float']['output'];
  /** The physical port number on the controller. */
  portNumber: Scalars['Int']['output'];
  /** The number of packets per second that encountered errors being received in the time interval. */
  rxErrPerSecond: Scalars['Float']['output'];
  /** Timestamp for the metrics data point. */
  timestamp: Scalars['DateTime']['output'];
  /** Total number of bytes per second received during the time interval. */
  totalRxBytesPerSecond: Scalars['Float']['output'];
  /** Total number of bytes per second transmitted during the time interval. */
  totalTxBytesPerSecond: Scalars['Float']['output'];
  /** The number of packets per second that encountered errors during transmission in the time interval. */
  txErrPerSecond: Scalars['Float']['output'];
};

/** Response object containing an array of controller port metrics values. */
export type ControllerPortMetricsResponse = {
  __typename?: 'ControllerPortMetricsResponse';
  /** Array of metrics values per timestamp and port. */
  values: Array<ControllerPortMetricsValue>;
};

/** Metrics data point containing counter statistics for a controller port over a specific time interval. */
export type ControllerPortMetricsValue = {
  __typename?: 'ControllerPortMetricsValue';
  /** Total number of broadcast packets received during the time interval; a subset of totalRxPackets. */
  broadcastRxPackets: Scalars['Float']['output'];
  /** Total number of broadcast packets transmitted during the time interval; a subset of totalTxPackets. */
  broadcastTxPackets: Scalars['Float']['output'];
  /** The number of packets that were dropped during the time interval. */
  drops: Scalars['Float']['output'];
  /** Total number of multicast packets received during the time interval; a subset of totalRxPackets. */
  multicastRxPackets: Scalars['Float']['output'];
  /** Total number of multicast packets transmitted during the time interval; a subset of totalTxPackets. */
  multicastTxPackets: Scalars['Float']['output'];
  /** The physical port number on the controller. */
  portNumber: Scalars['Int']['output'];
  /** The number of packets that encountered errors being received in the time interval. */
  rxErr: Scalars['Float']['output'];
  /** Timestamp for the metrics data point. */
  timestamp: Scalars['DateTime']['output'];
  /** Total number of bytes received during the time interval. */
  totalRxBytes: Scalars['Float']['output'];
  /** Total number of bytes transmitted during the time interval. */
  totalTxBytes: Scalars['Float']['output'];
  /** The number of packets that encountered errors during transmission in the time interval. */
  txErr: Scalars['Float']['output'];
};

/** Summed stats (unless otherwise specified) per controller port over a lookback period. */
export type ControllerPortStat = {
  __typename?: 'ControllerPortStat';
  /** Total number of broadcast bytes received in the given time period; a subset of totalRxBytes. */
  broadcastRxBytes: Scalars['Int']['output'];
  /** Total number of broadcast packets received in the given time period; a subset of totalRxPackets. */
  broadcastRxPackets: Scalars['Int']['output'];
  /** Total number of broadcast bytes transmitted in the given time period; a subset of totalTxBytes. */
  broadcastTxBytes: Scalars['Int']['output'];
  /** Total number of broadcast packets transmitted in the given time period; a subset of totalTxPackets. */
  broadcastTxPackets: Scalars['Int']['output'];
  /** The number of packets that were dropped in the time interval. */
  drops: Scalars['Int']['output'];
  /** Most recent hardware up state. Indicates that a physical connection is established, typically meaning a cable is properly connected and active. */
  hwUp: Scalars['Boolean']['output'];
  /** The number of IPv4 packets transmitted or received. */
  ipv4: Scalars['Int']['output'];
  /** The number of IPv6 packets transmitted or received. */
  ipv6: Scalars['Int']['output'];
  /** Total number of multicast bytes received in the given time period; a subset of totalRxBytes. */
  multicastRxBytes: Scalars['Int']['output'];
  /** Total number of multicast packets received in the given time period; a subset of totalRxPackets. */
  multicastRxPackets: Scalars['Int']['output'];
  /** Total number of multicast bytes transmitted in the given time period; a subset of totalTxBytes. */
  multicastTxBytes: Scalars['Int']['output'];
  /** Total number of multicast packets transmitted in the given time period; a subset of totalTxPackets. */
  multicastTxPackets: Scalars['Int']['output'];
  /** The physical port number on the controller. */
  portNumber: Scalars['Int']['output'];
  /** The number of packets punted to the CPU for processing (currently not reported by firmware). */
  punts: Scalars['Int']['output'];
  /** The number of packets that encountered errors being received. */
  rxErr: Scalars['Int']['output'];
  /** Total number of bytes received in the given time period. */
  totalRxBytes: Scalars['Int']['output'];
  /** Total number of packets received in the given time period. */
  totalRxPackets: Scalars['Int']['output'];
  /** Total number of bytes transmitted in the given time period. */
  totalTxBytes: Scalars['Int']['output'];
  /** Total number of packets transmitted in the given time period. */
  totalTxPackets: Scalars['Int']['output'];
  /** The number of packets that encountered errors during transmission. */
  txErr: Scalars['Int']['output'];
  /** Total number of unicast bytes received in the given time period; a subset of totalRxBytes. */
  unicastRxBytes: Scalars['Int']['output'];
  /** Total number of unicast packets received in the given time period; a subset of totalRxPackets. */
  unicastRxPackets: Scalars['Int']['output'];
  /** Total number of unicast bytes transmitted in the given time period; a subset of totalTxBytes. */
  unicastTxBytes: Scalars['Int']['output'];
  /** Total number of unicast packets transmitted in the given time period; a subset of totalTxPackets. */
  unicastTxPackets: Scalars['Int']['output'];
};

/** A controller virtual device is a logical representation of a physical controller. */
export type ControllerVirtualDevice = VirtualDevice & {
  __typename?: 'ControllerVirtualDevice';
  /** The unique identifier (UUID) of the virtual device. */
  UUID: Scalars['UUID']['output'];
  /** The timestamp when the virtual device was first created in the system. */
  createdAt: Scalars['DateTime']['output'];
  /** An optional description for the virtual device. */
  description?: Maybe<Scalars['String']['output']>;
  /** The model name of the virtual device. */
  deviceModel: DeviceModel;
  /** The type of the virtual device. */
  deviceType: VirtualDeviceType;
  /** The hardware device associated with this virtual device, if any. */
  hardwareDevice?: Maybe<HardwareDevice>;
  /** The high availability (HA) configuration information associated with this controller, if configured in HA mode. */
  highAvailability?: Maybe<HighAvailabilityControllerVirtualDeviceInfo>;
  /** Indicates whether the virtual device's console port is enabled. */
  isConsoleEnabled: Scalars['Boolean']['output'];
  /** Indicates whether debug logging is enabled on the virtual device. */
  isDebugLogEnabled: Scalars['Boolean']['output'];
  /** A human-friendly name for the device. */
  label: Scalars['String']['output'];
  /** The network that the virtual device belongs to. */
  network: Network;
  /** The unique identifier (UUID) of the network the virtual device belongs to. */
  networkUUID: Scalars['UUID']['output'];
  /** The current NOS firmware version that the virtual device is configured to run. */
  nosVersion?: Maybe<ConfiguredNosVersion>;
  /** The identifier of the NOS firmware version that the virtual device is configured to run. */
  nosVersionID: Scalars['Int']['output'];
  /** The NOS firmware version that the virtual device is scheduled to upgrade to. */
  pendingNosVersion?: Maybe<PendingNosVersion>;
  /** The physical interfaces associated with the controller. */
  phyInterfaces: Array<PhyInterface>;
  /** The security appliance profile associated with the controller. */
  securityApplianceProfile: SecurityApplianceProfile;
  /** The unique identifier of the security appliance profile associated with the controller. */
  securityApplianceProfileUUID: Scalars['UUID']['output'];
  /** The timestamp when the virtual device was last updated in the system. */
  updatedAt: Scalars['DateTime']['output'];
  /** The uptime from our time series database for the device. */
  uptime?: Maybe<Scalars['Duration']['output']>;
};

/** Represents geographical coordinates with latitude and longitude. */
export type Coordinates = {
  __typename?: 'Coordinates';
  /** The latitude component of the coordinates. */
  latitude: Scalars['Float']['output'];
  /** The longitude component of the coordinates. */
  longitude: Scalars['Float']['output'];
};

/** Input type for copying an IPSec tunnel from config1 to config2. */
export type CopyIpSecTunnelFromConfig1ToConfig2Input = {
  /** The name of the tunnel within the config1 configuration. It is the portion after `meter.v1.network.vpn.ipsec.`. */
  config1Name: Scalars['String']['input'];
  /** The pre-shared key for the tunnel, stored on disk on a controller at `/etc/ipsec.d/$config1Name.secrets`. */
  presharedKey: Scalars['String']['input'];
};

/** Input type for creating a new address. */
export type CreateAddressInput = {
  /** First line of the address. Typically includes street number and name. */
  address1: Scalars['String']['input'];
  /** Second line of the address. Typically includes apartment, suite, unit, building, floor, etc. */
  address2?: InputMaybe<Scalars['String']['input']>;
  /** City where the address is located. */
  city: Scalars['String']['input'];
  /** ISO 3166-1 alpha-2 country code for the address. */
  country: Scalars['String']['input'];
  /** Postal or ZIP code for the address. */
  postalCode: Scalars['String']['input'];
  /** State or province where the address is located. */
  state: Scalars['String']['input'];
};

export type CreateAlertReceiverWithTargetsInput = {
  /** Label for the new alert receiver. */
  label: Scalars['String']['input'];
  /** List of alert targets to associate with the new alert receiver. */
  targets: Array<AlertTargetInput>;
};

/** Input type for creating a new webhook alert target. */
export type CreateAlertTargetWebhookInput = {
  /** An optional label for the webhook alert target. */
  label?: InputMaybe<Scalars['String']['input']>;
  /** An optional secret used to sign webhook requests for verification. */
  signingSecret?: InputMaybe<Scalars['String']['input']>;
  /** The URL endpoint of the webhook where alerts will be sent. */
  url: Scalars['String']['input'];
};

export type CreateApplicationDnsFirewallRuleInput = {
  /** The action to take when the rule is matched. */
  action: FirewallRuleAction;
  /** The ID of the Netstar application to associate with the rule. */
  applicationID?: InputMaybe<Scalars['Int']['input']>;
  /** The ID of the Netstar category to associate with the rule. */
  categoryID?: InputMaybe<Scalars['Int']['input']>;
  /** The domain or FQDN that is matched by the rule, allowing for wildcards denoted by '*'. */
  domain?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the Netstar group to associate with the rule. */
  groupID?: InputMaybe<Scalars['Int']['input']>;
  /** Indicates whether the rule is enabled; defaults to true. */
  isEnabled?: Scalars['Boolean']['input'];
  /** The name of the DNS firewall rule. */
  name: Scalars['String']['input'];
  /** The priority of the rule; if not provided, will default to 1 greater than the highest existing priority. */
  priority?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateAutoVpnGroupHubUplinkInput = {
  /** IP address assigned to the hub's uplink. */
  address: Scalars['IP']['input'];
  /** Prefix length of the uplink's IP address. */
  addressPrefixLength: Scalars['Int']['input'];
  /** Indicates if failover is enabled for the hub's uplink. */
  isFailoverEnabled?: Scalars['Boolean']['input'];
  /** Unique identifier of the physical interface used by the hub's uplink. */
  phyInterfaceUUID: Scalars['UUID']['input'];
};

export type CreateAutoVpnGroupInput = {
  /** Unique identifier of the hub network. */
  hubNetworkUUID: Scalars['UUID']['input'];
  /** Indicates if hub failover is enabled. Defaults to true. */
  isHubFailoverEnabled?: Scalars['Boolean']['input'];
  /** Optionally adds specified members to the group. */
  members?: InputMaybe<Array<AddNetworkToAutoVpnGroupInput>>;
  /** Name of the AutoVPN Group. */
  name: Scalars['String']['input'];
  /** Configuration of the hub's uplink. */
  uplink: CreateAutoVpnGroupHubUplinkInput;
};

export type CreateAutoVpnRouteInput = {
  /** Destination gateway IP address for the route. */
  dstGateway: Scalars['IPV4']['input'];
  /** Prefix length for the destination IP of the route. Must be between 1 and 32. */
  dstPrefixLength: Scalars['Int']['input'];
  /** Indicates if the route is enabled. Defaults to true. */
  isEnabled?: Scalars['Boolean']['input'];
  /** Name of the route. */
  name: Scalars['String']['input'];
};

export type CreateBulkNosUpgradeInput = {
  /** List of UUIDs of the networks to include in the bulk NOS upgrade. */
  networkUUIDs: Array<Scalars['UUID']['input']>;
  /** Identifier of the NOS version to upgrade to. */
  nosVersionID: Scalars['Int']['input'];
};

/** Input parameters for creating a Client VPN client. */
export type CreateClientVpnClientInput = {
  /** End user's company email associated with the VPN server's controller, e.g., 'angie@meter.com'. */
  email: Scalars['String']['input'];
  /** End user's device name, e.g., 'paulmbp'. */
  name: Scalars['String']['input'];
  /** A Curve25519 public key generated by the end user using WireGuard. */
  publicKey: Scalars['String']['input'];
};

/** Input parameters for creating a Client VPN server. */
export type CreateClientVpnServerInput = {
  /** IPv4 address to assign to the WireGuard interface. */
  address: Scalars['IPV4']['input'];
  /** Subnet mask length (CIDR notation) for the WireGuard interface address. */
  addressMask: Scalars['Int']['input'];
  /** Default list of IP prefixes that clients are allowed to access. */
  defaultClientAllowedIPs?: Array<Scalars['IPPrefix']['input']>;
  /** An additional listen address other than `address` this server serves DNS traffic on. It must be a private IP. */
  dnsListenAddress?: InputMaybe<Scalars['IPV4']['input']>;
  /** Set to true to enable failover between uplink physical interfaces (WANs). */
  isFailoverEnabled?: Scalars['Boolean']['input'];
  /** Port number on which the WireGuard server listens for incoming connections. */
  listenPort?: InputMaybe<Scalars['Int']['input']>;
  /** UUID of the uplink physical interface that the WireGuard server will listen on. */
  phyInterfaceUUID?: InputMaybe<Scalars['UUID']['input']>;
};

export type CreateCompanyInput = {
  /** A prospective company is used for sales purposes. */
  isProspective?: InputMaybe<Scalars['Boolean']['input']>;
  /** The name of the company. */
  name: Scalars['String']['input'];
  /** A unique, URL-friendly identifier for the company. */
  slug: Scalars['String']['input'];
};

/** Create a new user for the specified company, with the provided attributes. */
export type CreateCompanyUserInput = {
  /**
   * The membership role for the new user within the company. This refers to the old style of granting permissions. This will be deprecated in the future,
   * but is currently required as only those with an admin `CompanyMembershipRole` can access the dashboard.
   */
  companyMembershipRole: CompanyMembershipRole;
  /** The email address of the new user. */
  email: Scalars['String']['input'];
  /** The first name of the new user. */
  firstName: Scalars['String']['input'];
  /** The last name of the new user. */
  lastName: Scalars['String']['input'];
};

/** Input for creating a new DHCP option. */
export type CreateDhcpOptionInput = {
  /**
   * The DHCP option code (also called tag). Refer to the IANA DHCP options list:
   * https://www.iana.org/assignments/bootp-dhcp-parameters/bootp-dhcp-parameters.xhtml
   */
  code: Scalars['Int']['input'];
  /** The data value for the DHCP option. */
  data: Scalars['String']['input'];
  /** An optional description for the DHCP option. */
  description?: InputMaybe<Scalars['String']['input']>;
};

/** Input for creating a new DHCP reserved range. */
export type CreateDhcpReservedRangeInput = {
  /** The last reserved IP address in the range. */
  endIPAddress: Scalars['IP']['input'];
  /** The first reserved IP address in the range. */
  startIPAddress: Scalars['IP']['input'];
};

/** Input for creating a new DHCP rule. */
export type CreateDhcpRuleInput = {
  /** Enables or disables DNS caching on the DHCP server. */
  dnsCacheIsEnabled?: Scalars['Boolean']['input'];
  /** Specifies the maximum Time-To-Live (TTL) in seconds for cached DNS entries. */
  dnsCacheMaxTTL?: Scalars['Int']['input'];
  /** Specifies the maximum number of DNS records to cache. */
  dnsCacheSize?: Scalars['Int']['input'];
  /** A list of DNS search domains provided to DHCP clients. */
  dnsSearchDomains?: Array<Scalars['String']['input']>;
  /** List of upstream DNS servers to use.. */
  dnsUpstreamServers?: Array<Scalars['IP']['input']>;
  /** Determines if the gateway acts as a DNS proxy for clients. */
  dnsUseGatewayProxy?: Scalars['Boolean']['input'];
  /** The last available IP address in the DHCP range, ascending and inclusive. */
  endIPAddress: Scalars['IP']['input'];
  /** The gateway IP address provided to DHCP clients. */
  gatewayIPAddress: Scalars['IP']['input'];
  /** The prefix length of the gateway IP address. */
  gatewayPrefixLength: Scalars['Int']['input'];
  /** Indicates whether this DHCP rule is for IPv6 (`true`) or IPv4 (`false`). */
  isIPv6: Scalars['Boolean']['input'];
  /** The duration (in seconds) of the DHCP lease assigned to clients. */
  leaseDurationSeconds: Scalars['Int']['input'];
  /** The first available IP address in the DHCP range, ascending and inclusive. */
  startIPAddress: Scalars['IP']['input'];
};

/** Input for creating a new DHCP static mapping. */
export type CreateDhcpStaticMappingInput = {
  /** The hostname to assign to the client device. */
  hostname?: InputMaybe<Scalars['String']['input']>;
  /** The fixed IP address to assign to the client device. */
  ipAddress: Scalars['IP']['input'];
  /** The MAC address of the client device. */
  macAddress: Scalars['MacAddress']['input'];
  /** A name or label for this static mapping. */
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Input for creating a new DNS host mapping. */
export type CreateDnsHostMappingInput = {
  /** The domain to which `overrideDomain` should resolve. Acts like a 'CNAME' record if specified. */
  destinationDomain?: InputMaybe<Scalars['String']['input']>;
  /** The IP address to which `overrideDomain` should resolve. Acts like an 'A' record if specified. */
  destinationIPAddress?: InputMaybe<Scalars['IP']['input']>;
  /** Indicates whether this DNS host mapping is enabled. Defaults to `true`. */
  isEnabled?: Scalars['Boolean']['input'];
  /** The domain name to override. This can be a fully qualified domain name (FQDN) or a domain name. */
  overrideDomain: Scalars['String']['input'];
};

/** Used to mark a device as 'dev', moving it into the dev (staging) realm for testing purposes. */
export type CreateDevHardwareDeviceInput = {
  /** The model of the hardware device. */
  deviceModel: DeviceModel;
  /** The type of the hardware device. */
  deviceType: DeviceType;
  /** The MAC address of the hardware device. */
  macAddress?: InputMaybe<Scalars['MacAddress']['input']>;
  /** The serial number of the hardware device to mark as 'dev'. */
  serialNumber: Scalars['String']['input'];
};

export type CreateDeviceHeartbeatInput = {
  /** The serial number of the device for which to create a heartbeat configuration. */
  serialNumber: Scalars['String']['input'];
};

/** Input type for creating a new 802.1X encryption configuration. */
export type CreateEncryption8021XInput = {
  /** The IP address of the accounting server. If provided, accountingServerPort and accountingServerSecret must also be provided. */
  accountingServerIPAddress?: InputMaybe<Scalars['IP']['input']>;
  /** The port of the accounting server. Required if accountingServerIPAddress is provided. */
  accountingServerPort?: InputMaybe<Scalars['Int']['input']>;
  /** The shared secret used to authenticate with the accounting server. Required if accountingServerIPAddress is provided. */
  accountingServerSecret?: InputMaybe<Scalars['String']['input']>;
  /** The IP address of the authentication server. */
  authServerIPAddress: Scalars['IP']['input'];
  /** The port of the authentication server. */
  authServerPort: Scalars['Int']['input'];
  /** The shared secret used to authenticate with the authentication server. */
  authServerSecret: Scalars['String']['input'];
  /** A user-friendly label for the encryption configuration. */
  label: Scalars['String']['input'];
  /** The UUID of the network where the encryption configuration will be created. */
  networkUUID: Scalars['UUID']['input'];
};

/** Input type for creating a new firewall rule. */
export type CreateFirewallRuleInput = {
  /** The action to take when the firewall rule matches (e.g., DENY or PERMIT). */
  action: FirewallRuleAction;
  /**
   * List of UUIDs of physical interfaces to which the firewall rule is bound.
   * The physical interfaces must be uplinks.
   */
  boundPhyInterfaceUUIDs: Array<Scalars['UUID']['input']>;
  /** List of UUIDs of VLANs to which the firewall rule is bound. */
  boundVLANUUIDs: Array<Scalars['UUID']['input']>;
  /** A description of the firewall rule. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** UUID of the destination client VPN server to match traffic to. */
  dstClientVPNServerUUID?: InputMaybe<Scalars['UUID']['input']>;
  /** UUID of the destination IPSec tunnel to match traffic to. */
  dstIPSecTunnelUUID?: InputMaybe<Scalars['UUID']['input']>;
  /**
   * Deprecated. Use `dstPortRanges` instead. Single destination port range to match.
   * @deprecated Use `dstPortRanges` instead.
   */
  dstPortRange?: InputMaybe<PortRangeInput>;
  /** List of destination port ranges to match traffic to. */
  dstPortRanges?: InputMaybe<Array<PortRangeInput>>;
  /** Destination IP prefix to match traffic to. */
  dstPrefix?: InputMaybe<Scalars['IPPrefix']['input']>;
  /** UUID of the destination VLAN to match traffic to. */
  dstVLANUUID?: InputMaybe<Scalars['UUID']['input']>;
  /**
   * Indicates if the firewall rule should be applied bidirectionally.
   * @deprecated Bidirectional rules are now implicit, enabled if the rule is between two VLANs.
   */
  isBidirectional?: InputMaybe<Scalars['Boolean']['input']>;
  /** Indicates if the firewall rule is currently enabled. */
  isEnabled: Scalars['Boolean']['input'];
  /** Indicates if the firewall rule is internal to Meter. */
  isMeterInternal: Scalars['Boolean']['input'];
  /** The name of the firewall rule. */
  name: Scalars['String']['input'];
  /**
   * Deprecated. Use `protocols` instead. Single IP protocol to match.
   * @deprecated Use `protocols` instead.
   */
  protocol?: InputMaybe<IpProtocol>;
  /** List of IP protocols to match. */
  protocols?: InputMaybe<Array<IpProtocol>>;
  /** UUID of the source client VPN server to match traffic from. */
  srcClientVPNServerUUID?: InputMaybe<Scalars['UUID']['input']>;
  /** UUID of the source IPSec tunnel to match traffic from. */
  srcIPSecTunnelUUID?: InputMaybe<Scalars['UUID']['input']>;
  /**
   * Deprecated. Use `srcPortRanges` instead. Single source port range to match.
   * @deprecated Use `srcPortRanges` instead.
   */
  srcPortRange?: InputMaybe<PortRangeInput>;
  /** List of source port ranges to match traffic from. */
  srcPortRanges?: InputMaybe<Array<PortRangeInput>>;
  /** Source IP prefix to match traffic from. */
  srcPrefix?: InputMaybe<Scalars['IPPrefix']['input']>;
  /**
   * UUID of the source VLAN to match traffic from.
   * @deprecated Source VLAN is now implied from `boundVLANUUIDs`.
   */
  srcVLANUUID?: InputMaybe<Scalars['UUID']['input']>;
  /** List of tags associated with the firewall rule. */
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CreateFloorPlanInput = {
  /** A description of the new floor plan. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The S3 key (path) of the image file representing the floor plan to be uploaded. */
  imageS3Key: Scalars['String']['input'];
  /** A human-readable name or label for the new floor plan. */
  label: Scalars['String']['input'];
};

export type CreateHighAvailabilityPairInput = {
  /** Interval at which the controllers will advertise to one another, in milliseconds. */
  advertisementIntervalMs?: Scalars['Int']['input'];
  /** VLAN ID to use for the advertisement VLAN. */
  advertisementVLANID?: Scalars['Int']['input'];
  /** Enable or disable preemption for the HA controllers in VRRP. */
  preempt: Scalars['Boolean']['input'];
};

/** Input type for creating a new host monitoring target. */
export type CreateHostMonitoringTargetInput = {
  /** A descriptive text about the host monitoring target. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Indicates whether the host monitoring target is enabled. */
  isEnabled?: Scalars['Boolean']['input'];
  /**
   * The target host to monitor.
   *
   * Currently, only IPv4 addresses are permitted.
   */
  targetHost: Scalars['IPV4']['input'];
  /** The type of monitoring to perform on the target host. */
  type: HostMonitoringType;
};

/** Input type for creating a new IPSec tunnel. */
export type CreateIpSecTunnelInput = {
  /** The authentication algorithm to use for the IPSec connection. */
  authenticationAlgorithm?: InputMaybe<IpSecAuthenticationAlgorithm>;
  /** The encryption algorithm to use for the IPSec connection. */
  encryptionAlgorithm?: InputMaybe<IpSecEncryptionAlgorithm>;
  /** Indicates whether the IPSec tunnel should be enabled upon creation. */
  isEnabled: Scalars['Boolean']['input'];
  /** Indicates whether this tunnel should initiate the connection to the right participant; defaults to false. */
  isInitiator?: Scalars['Boolean']['input'];
  /** The Diffie-Hellman group to use for key exchange in the IPSec connection. */
  keyExchangeDHGroup?: InputMaybe<IpSecDhGroup>;
  /** Identifier for the left participant (this side) for authentication purposes. Must be a FQDN or IPv4 address. */
  leftID: Scalars['String']['input'];
  /** The name of the IPSec tunnel. */
  name: Scalars['String']['input'];
  /** UUID of the physical interface to bind the IPSec tunnel to; must be an uplink interface. */
  phyInterfaceUUID: Scalars['UUID']['input'];
  /** The pre-shared key for authentication; can be base64 encoded or plaintext. */
  presharedKey: Scalars['String']['input'];
  /** The public IP address or hostname of the right participant's gateway. */
  right?: InputMaybe<Scalars['String']['input']>;
  /** Identifier for the right participant (remote side) for authentication purposes. Must be a FQDN or IPv4 address. */
  rightID: Scalars['String']['input'];
  /** List of IP prefixes representing the private subnets behind the right participant. */
  rightPrefixes: Array<Scalars['IPPrefix']['input']>;
  /** List of VLAN UUIDs to bind the IPSec tunnel to. */
  vlanUUIDs: Array<Scalars['UUID']['input']>;
};

export type CreateInternalNoteInput = {
  /** Indicates whether the note is marked as important. */
  isImportant?: Scalars['Boolean']['input'];
  /** Content of the internal note. */
  note: Scalars['String']['input'];
  /** Use the `createPreSignedUploadUrl` mutation to generate S3 keys and presigned URLs for the photos, and specify them here. */
  photoS3Keys?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Input type for creating a new Internet Service Plan. */
export type CreateInternetServicePlanInput = {
  /** Physical address associated with the Internet Service Plan. */
  address?: InputMaybe<CreateAddressInput>;
  /** Connection details, including product and status. */
  connection: InternetServicePlanConnectionInput;
  /** IP configuration details provided by the ISP. */
  ipConfiguration: InternetServicePlanIpInput;
  /** Unique identifier of the ISP provider. */
  providerUUID: Scalars['UUID']['input'];
};

/** Input type for creating a mailing address. */
export type CreateMailingAddressInput = {
  /** The city of the mailing address. */
  city: Scalars['String']['input'];
  /** The two-letter ISO code of the country. */
  countryISO2: Scalars['String']['input'];
  /** The first line of the mailing address, usually the street address. */
  line1: Scalars['String']['input'];
  /** The second line of the mailing address, such as apartment or suite number. */
  line2?: InputMaybe<Scalars['String']['input']>;
  /** The postal code or ZIP code of the mailing address. */
  postalCode?: InputMaybe<Scalars['String']['input']>;
  /** The subdivision code, such as the state or province code. */
  subdivisionCode?: InputMaybe<Scalars['String']['input']>;
};

/** Input type for creating a network. */
export type CreateNetworkInput = {
  /** The slug identifier of the company that this network belongs to. */
  companySlug?: InputMaybe<Scalars['String']['input']>;
  /** Indicates whether the network is currently active. Defaults to true. */
  isActive?: Scalars['Boolean']['input'];
  /** Enables controller to upgrade config-1 APs deployed to the network. */
  isConfig1WosUpgradesEnabled?: Scalars['Boolean']['input'];
  /** Indicates whether the network is a template for creating new networks. Defaults to false. */
  isTemplate?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * Deprecated: Not a network property anymore.
   * @deprecated Not a network property anymore
   */
  isTest?: Scalars['Boolean']['input'];
  /** A label to quickly identify the network. */
  label: Scalars['String']['input'];
  /**
   * Deprecated: Use `mailingAddressInput` instead.
   * @deprecated Use `mailingAddressInput` instead.
   */
  mailingAddress?: InputMaybe<Scalars['String']['input']>;
  /** The mailing address details for the network. */
  mailingAddressInput?: InputMaybe<CreateMailingAddressInput>;
  /** The UUID of the mailing address associated with the network. */
  mailingAddressUUID?: InputMaybe<Scalars['UUID']['input']>;
  /** The algorithm used for multi-WAN load balancing. */
  multiWANAlgorithm?: InputMaybe<MultiWanAlgorithm>;
  /** The URL of the patch panel diagram for the network. */
  patchPanelDiagramURL?: InputMaybe<Scalars['String']['input']>;
  /** A NOS version should be specified. If not, the default no-upgrades NOS version is assigned. */
  pinnedNOSVersionID?: InputMaybe<Scalars['Int']['input']>;
  /** A human-readable identifier for the network. */
  slug: Scalars['String']['input'];
  /**
   * Deprecated: Not a network property anymore.
   * @deprecated Not a network property anymore
   */
  squareFeet?: InputMaybe<Scalars['Int']['input']>;
  /** The interval in minutes used to stagger device upgrades within the network. */
  staggeredUpgradeIntervalMin?: InputMaybe<Scalars['Int']['input']>;
  /** The S3 key for the network topology image. */
  topologyImageS3Key?: InputMaybe<Scalars['String']['input']>;
};

export type CreateNosFeatureInput = {
  /** A detailed description of the NOS feature. */
  description: Scalars['String']['input'];
  /** If the feature is also behind a feature flag, this is the name of the feature flag. */
  featureFlag?: InputMaybe<Scalars['String']['input']>;
  /** A unique key that identifies the NOS feature. */
  key: Scalars['String']['input'];
  /** The maximum major version of NOS firmware that supports this feature. If null, there is no upper major version limit. */
  maxMajorVersion?: InputMaybe<Scalars['Int']['input']>;
  /** The maximum minor version of NOS firmware that supports this feature. If null, there is no upper minor version limit. */
  maxMinorVersion?: InputMaybe<Scalars['Int']['input']>;
  /** The maximum patch version of NOS firmware that supports this feature. If null, there is no upper patch version limit. */
  maxPatchVersion?: InputMaybe<Scalars['Int']['input']>;
  /** The minimum major version of NOS firmware required to support this feature. */
  minMajorVersion: Scalars['Int']['input'];
  /** The minimum minor version of NOS firmware required to support this feature. */
  minMinorVersion: Scalars['Int']['input'];
  /** The minimum patch version of NOS firmware required to support this feature. */
  minPatchVersion: Scalars['Int']['input'];
  /** The display name of the NOS feature. */
  name: Scalars['String']['input'];
};

/** Input type for creating a new notification setting. */
export type CreateNotificationSettingInput = {
  /** The UUID of the alert receiver who will receive the notifications. */
  alertReceiverUUID: Scalars['UUID']['input'];
  /** A list of arguments providing specific parameters or context required by the notification definition. */
  arguments: Array<NotificationSettingArgumentInput>;
  /** The ID of the user who is creating this notification setting. */
  createdBy?: InputMaybe<Scalars['Int']['input']>;
  /** Indicates whether the notification setting is enabled. Defaults to true. */
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** The name of the notification definition specifying the type of alert. */
  notificationDefinitionName: Scalars['String']['input'];
};

export type CreateOneToOneNatRuleInput = {
  /** Optional description of the 1:1 NAT rule. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** External IP address to be mapped by the NAT rule. */
  externalIPAddr: Scalars['IPV4']['input'];
  /** UUID of the physical interface associated with the external IP address. */
  externalPhyInterfaceUUID: Scalars['UUID']['input'];
  /** Internal IP address that the external IP address will be mapped to. */
  internalIPAddr: Scalars['IPV4']['input'];
  /** Indicates whether the NAT rule is enabled upon creation. */
  isEnabled: Scalars['Boolean']['input'];
  /** Name of the 1:1 NAT rule. */
  name: Scalars['String']['input'];
};

export type CreatePermittedEmailDomainInput = {
  /** The slug of the company to which the permitted email domain will be added. */
  companySlug: Scalars['String']['input'];
  /** The email domain to allow (e.g., "example.com"). */
  domain: Scalars['String']['input'];
};

export type CreatePolicyRoutingRuleInput = {
  /** The list of bindings to associate with this policy routing rule. */
  bindings: Array<PolicyRoutingRuleBindingInput>;
  /** The list of destination port ranges for the traffic matching this rule. */
  dstPortRanges: Array<PortRangeInput>;
  /** The destination IP prefix for the traffic matching this rule. */
  dstPrefix: Scalars['IPPrefix']['input'];
  /** Indicates whether the policy routing rule is enabled. */
  isEnabled: Scalars['Boolean']['input'];
  /** The name of the policy routing rule. */
  name: Scalars['String']['input'];
  /** The list of IP protocols (e.g., TCP, UDP) for the traffic matching this rule. */
  protocols: Array<IpProtocol>;
  /** The list of source port ranges for the traffic matching this rule. */
  srcPortRanges: Array<PortRangeInput>;
  /** The source IP prefix for the traffic matching this rule (optional). */
  srcPrefix?: InputMaybe<Scalars['IPPrefix']['input']>;
  /** The UUID of the source VLAN for the traffic matching this rule (optional). */
  srcVLANUUID?: InputMaybe<Scalars['UUID']['input']>;
};

export type CreatePortForwardingRuleInput = {
  /** Restricts the port forwarding rule to only allow traffic to be forwarded if it originates from any of these IP addresses. */
  allowedRemoteIPAddresses?: InputMaybe<Array<Scalars['IP']['input']>>;
  /** Description of the port forwarding rule. */
  description?: InputMaybe<Scalars['String']['input']>;
  /**
   * The external IP address to which this rule applies.
   * One of `externalPhyInterfaceUUID` or `externalIPAddress` must be provided.
   */
  externalIPAddress?: InputMaybe<Scalars['IP']['input']>;
  /**
   * UUID of the external uplink physical interface.
   * One of `externalPhyInterfaceUUID` or `externalIPAddress` must be provided.
   */
  externalPhyInterfaceUUID?: InputMaybe<Scalars['UUID']['input']>;
  /** The external port from which incoming traffic is forwarded. */
  externalPort: Scalars['Int']['input'];
  /** Indicates whether the port forwarding rule is enabled. */
  isEnabled: Scalars['Boolean']['input'];
  /** The local IP address to which incoming traffic is forwarded. */
  localIPAddress: Scalars['IP']['input'];
  /** The local port to which the external port forwards traffic. */
  localPort: Scalars['Int']['input'];
  /** Name of the port forwarding rule. */
  name: Scalars['String']['input'];
  /** The IP protocol to apply this rule on. These are the IANA-defined protocol numbers (e.g., 6 for TCP, 17 for UDP). */
  protocol: PortIpProtocol;
};

/** Input type for creating a pre-signed URL for file upload. */
export type CreatePreSignedUploadUrlInput = {
  /** The extension of the file being uploaded (e.g., 'jpg', 'png', 'pdf'). Do not include the leading dot. */
  fileExtension: Scalars['String']['input'];
  /** The folder where the file will be uploaded. */
  folder: AllowedFolder;
};

/** Input type for creating a new rack elevation. */
export type CreateRackElevationInput = {
  /** The left-to-right position of the rack relative to others. */
  horizontalIndex?: InputMaybe<Scalars['Int']['input']>;
  /** Human-readable name for the rack elevation. */
  label: Scalars['String']['input'];
  /** Notes associated with the rack elevation. */
  notes?: InputMaybe<Array<RackElevationNoteInput>>;
  /** The number of rack units (like slots) for the rack elevation. */
  rackMountUnitCount: Scalars['Int']['input'];
};

export type CreateRadioProfileInput = {
  /**
   * Required if band2_4GAutoChannelIsEnabled is true.
   * The list of allowed channels for auto channel selection on the 2.4 GHz band.
   */
  band2_4GAllowedChannels?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Indicates whether auto channel selection is enabled for the 2.4 GHz band. */
  band2_4GAutoChannelIsEnabled: Scalars['Boolean']['input'];
  /** Indicates whether auto channel width selection is enabled for the 2.4 GHz band. */
  band2_4GAutoChannelWidthIsEnabled: Scalars['Boolean']['input'];
  /** Indicates whether automatic transmit power adjustment is enabled for the 2.4 GHz band. */
  band2_4GAutoTxPowerIsEnabled: Scalars['Boolean']['input'];
  /**
   * Required if band2_4GAutoTxPowerIsEnabled is true.
   * The maximum transmit power in dBm for the 2.4 GHz band when auto transmit power is enabled.
   */
  band2_4GAutoTxPowerMaxdBm?: InputMaybe<Scalars['Int']['input']>;
  /**
   * Required if band2_4GAutoTxPowerIsEnabled is true.
   * The minimum transmit power in dBm for the 2.4 GHz band when auto transmit power is enabled.
   */
  band2_4GAutoTxPowerMindBm?: InputMaybe<Scalars['Int']['input']>;
  /**
   * Required if band2_4GAutoChannelWidthIsEnabled is false.
   * The channel width in MHz for the 2.4 GHz band when auto channel width is disabled.
   */
  band2_4GChannelWidthMHz?: InputMaybe<Scalars['Int']['input']>;
  /** Indicates whether the 2.4 GHz band is enabled. */
  band2_4GIsEnabled: Scalars['Boolean']['input'];
  /**
   * Required if band5GAutoChannelIsEnabled is true.
   * The list of allowed channels for auto channel selection on the 5 GHz band.
   */
  band5GAllowedChannels?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Indicates whether auto channel selection is enabled for the 5 GHz band. */
  band5GAutoChannelIsEnabled: Scalars['Boolean']['input'];
  /** Indicates whether auto channel width selection is enabled for the 5 GHz band. */
  band5GAutoChannelWidthIsEnabled: Scalars['Boolean']['input'];
  /** Indicates whether automatic transmit power adjustment is enabled for the 5 GHz band. */
  band5GAutoTxPowerIsEnabled: Scalars['Boolean']['input'];
  /**
   * Required if band5GAutoTxPowerIsEnabled is true.
   * The maximum transmit power in dBm for the 5 GHz band when auto transmit power is enabled.
   */
  band5GAutoTxPowerMaxdBm?: InputMaybe<Scalars['Int']['input']>;
  /**
   * Required if band5GAutoTxPowerIsEnabled is true.
   * The minimum transmit power in dBm for the 5 GHz band when auto transmit power is enabled.
   */
  band5GAutoTxPowerMindBm?: InputMaybe<Scalars['Int']['input']>;
  /**
   * Required if band5GAutoChannelWidthIsEnabled is false.
   * The channel width in MHz for the 5 GHz band when auto channel width is disabled.
   */
  band5GChannelWidthMHz?: InputMaybe<Scalars['Int']['input']>;
  /** Indicates whether the 5 GHz band is enabled. */
  band5GIsEnabled: Scalars['Boolean']['input'];
  /**
   * Setting this to true will set all other profiles to false.
   * Indicates whether this profile should be set as the default for the network.
   */
  isDefault: Scalars['Boolean']['input'];
  /** The name of the radio profile to create. */
  name: Scalars['String']['input'];
};

/** The metadata required to create radio settings for an access point. */
export type CreateRadioSettingsInput = {
  /** The primary channel for the 2.4 GHz radio band. */
  band2_4GPrimaryChannel: Scalars['Int']['input'];
  /** The secondary channel for the 2.4 GHz radio band, if applicable. */
  band2_4GSecondaryChannel?: InputMaybe<Scalars['Int']['input']>;
  /** The transmit power in dBm for the 2.4 GHz radio band. */
  band2_4GTransmitPowerdBm: Scalars['Int']['input'];
  /** The primary channel for the 5 GHz radio band. */
  band5GPrimaryChannel: Scalars['Int']['input'];
  /** The secondary channel for the 5 GHz radio band, if applicable. */
  band5GSecondaryChannel?: InputMaybe<Scalars['Int']['input']>;
  /** The transmit power in dBm for the 5 GHz radio band. */
  band5GTransmitPowerdBm: Scalars['Int']['input'];
};

export type CreateRateLimitRuleInput = {
  /** A list of UUIDs of physical interfaces to which this rate limit rule is bound. */
  boundPhyInterfaceUUIDs?: InputMaybe<Array<Scalars['UUID']['input']>>;
  /** A list of UUIDs of VLANs to which this rate limit rule is bound. */
  boundVLANUUIDs?: InputMaybe<Array<Scalars['UUID']['input']>>;
  /** A description of the rate limit rule. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Destination MAC address to match. */
  dstMac?: InputMaybe<Scalars['MacAddress']['input']>;
  /** Destination port range to match. */
  dstPortRange?: InputMaybe<PortRangeInput>;
  /** Destination IP address prefix to match. */
  dstPrefix?: InputMaybe<Scalars['IPPrefix']['input']>;
  /** Indicates whether the rate limit rule is enabled. */
  isEnabled: Scalars['Boolean']['input'];
  /** Specifies if the rate limit is applied per source IP/MAC address. */
  isPerSource?: InputMaybe<Scalars['Boolean']['input']>;
  /** The name of the rate limit rule. */
  name: Scalars['String']['input'];
  /** IANA protocol number to match. */
  protocol?: InputMaybe<IpProtocol>;
  /** Maximum download rate limit in kilobits per second. */
  rateLimitKbpsDownload?: InputMaybe<Scalars['Int']['input']>;
  /** Maximum upload rate limit in kilobits per second. */
  rateLimitKbpsUpload?: InputMaybe<Scalars['Int']['input']>;
  /** Source MAC address to match. */
  srcMac?: InputMaybe<Scalars['MacAddress']['input']>;
  /** Source port range to match. */
  srcPortRange?: InputMaybe<PortRangeInput>;
  /** Source IP address prefix to match. */
  srcPrefix?: InputMaybe<Scalars['IPPrefix']['input']>;
};

export type CreateSsidInput = {
  /**
   * The lowest rate at which a client can connect to the 2.4 GHz radio, in kilobits per second.
   * If not set, will be set to a default value.
   */
  band2GMinimumBasicRateKbps?: InputMaybe<Scalars['Int']['input']>;
  /**
   * The lowest rate at which a client can connect to the 5 GHz radio, in kilobits per second.
   * If not set, will be set to a default value.
   */
  band5GMinimumBasicRateKbps?: InputMaybe<Scalars['Int']['input']>;
  /** Enable or disable client steering for association. */
  clientSteeringAssociationSteeringIsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** The minimum number of clients on an SSID before client steering is attempted. */
  clientSteeringLoadBalancingClientMinThreshold?: InputMaybe<Scalars['Int']['input']>;
  /** Enable or disable post-association client steering. */
  clientSteeringPostassociationSteeringIsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** The minimum RSSI signal strength (in dBm) before pre-association client steering is attempted. */
  clientSteeringPreassociationRSSIMinThreshold?: InputMaybe<Scalars['Int']['input']>;
  /** Description of the SSID. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** List of access point virtual device UUIDs on which the SSID is disabled. */
  disabledAccessPointVirtualDeviceUUIDs?: InputMaybe<Array<Scalars['UUID']['input']>>;
  /** The DTIM period for the SSID. */
  dtimPeriod?: InputMaybe<Scalars['Int']['input']>;
  /** Dynamic VLAN mode setting. */
  dynamicVLANMode?: InputMaybe<SsidDynamicVlanMode>;
  /**
   * Required if isEnabledForAllAccessPoints is false.
   * List of access point virtual device UUIDs on which the SSID is enabled.
   */
  enabledAccessPointVirtualDeviceUUIDs?: InputMaybe<Array<Scalars['UUID']['input']>>;
  /** 802.11w Protected Management Frames mode. */
  encryption80211wProtectedManagementFramesMode?: InputMaybe<SsidEncryption80211wProtectedManagementFramesMode>;
  /** Encryption protocol used by the SSID. */
  encryptionProtocol?: InputMaybe<SsidEncryptionProtocol>;
  /** Enable or disable 802.11ax (Wi-Fi 6) for the SSID. */
  is80211axEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Enable or disable broadcasting the SSID on the 2.4 GHz band. */
  isBand2GAllowed: Scalars['Boolean']['input'];
  /** Enable or disable broadcasting the SSID on the 5 GHz band. */
  isBand5GAllowed: Scalars['Boolean']['input'];
  /** Enable or disable the SSID. */
  isEnabled: Scalars['Boolean']['input'];
  /** Enable the SSID for all access points or only specified ones. */
  isEnabledForAllAccessPoints: Scalars['Boolean']['input'];
  /** Mark the SSID as a guest network. */
  isGuest?: Scalars['Boolean']['input'];
  /** Hide the SSID from being broadcasted. */
  isHidden: Scalars['Boolean']['input'];
  /** Enable client isolation on the SSID. */
  isIsolateClientsEnabled: Scalars['Boolean']['input'];
  /** Enable or disable 802.11k roaming. */
  isRoaming80211kEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Enable or disable 802.11r fast roaming. */
  isRoaming80211rEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Enable or disable 802.11v roaming. */
  isRoaming80211vEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Pre-shared key (PSK) for the SSID. */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Cadence for automatic password rotation. */
  passwordRotationCadence?: InputMaybe<SsidPasswordRotationCadence>;
  /** The day of the month for password rotation in the network's local time zone. */
  passwordRotationDayOfMonthLocal?: InputMaybe<Scalars['Int']['input']>;
  /** The day of the week for password rotation in the network's local time zone (0 = Sunday). */
  passwordRotationDayOfWeekLocal?: InputMaybe<Scalars['Int']['input']>;
  /** The hour of the day for password rotation in the network's local time zone. */
  passwordRotationHourOfDayLocal?: InputMaybe<Scalars['Int']['input']>;
  /**
   * Set the primary 802.1X encryption configuration.
   * Required if encryptionProtocol is WPA2_ENTERPRISE.
   */
  primaryEncryption8021XUUID?: InputMaybe<Scalars['UUID']['input']>;
  /** Enable or disable RADIUS Change of Authorization (CoA). */
  radiusCoaEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** A port where RADIUS servers can send CoA requests. If unset, default port will be 3799 as per RFC 3576. */
  radiusCoaPort?: InputMaybe<Scalars['Int']['input']>;
  /**
   * Set the secondary 802.1X encryption configuration.
   * Required if encryptionProtocol is WPA2_ENTERPRISE.
   */
  secondaryEncryption8021XUUID?: InputMaybe<Scalars['UUID']['input']>;
  /** Name of the SSID. */
  ssid: Scalars['String']['input'];
  /** UUID of the VLAN to associate with the SSID. */
  vlanUUID?: InputMaybe<Scalars['UUID']['input']>;
};

/** Result of creating a SSO dashboard for a company. */
export type CreateSsoDashboardForCompanyResult = {
  __typename?: 'CreateSSODashboardForCompanyResult';
  /** The company for which the SSO dashboard was created. */
  company: Company;
  /** The URL of the SSO dashboard. */
  dashboardURL: Scalars['String']['output'];
};

/** Result of creating an SSO redirect URI. */
export type CreateSsoRedirectUriResult = {
  __typename?: 'CreateSSORedirectURIResult';
  /** The URI to redirect to for SSO login. */
  uri: Scalars['String']['output'];
};

/** Input for verifying an SSO authentication. */
export type CreateSsoVerifyInput = {
  /** The authorization code returned by the SSO provider. */
  code: Scalars['String']['input'];
  /**
   * Deprecated. The source of the verification request.
   * @deprecated Source is no longer important.
   */
  source?: InputMaybe<SsoVerificationSource>;
  /** Optional state parameter for CSRF protection. */
  state?: InputMaybe<Scalars['String']['input']>;
};

/** Result returned after verifying an SSO authentication. */
export type CreateSsoVerifyResult = {
  __typename?: 'CreateSSOVerifyResult';
  /** The email address of the authenticated user. */
  email: Scalars['String']['output'];
  /** The first name of the authenticated user. */
  firstName: Scalars['String']['output'];
  /** If the session was created via impersonation, this field will be set. */
  impersonator?: Maybe<Impersonator>;
  /** The last name of the authenticated user. */
  lastName: Scalars['String']['output'];
  /** The path to redirect the user to after successful authentication. */
  path?: Maybe<Scalars['String']['output']>;
  /** Indicates if the user is accessing a preview environment. */
  preview?: Maybe<Scalars['Boolean']['output']>;
  /** The method used to authenticate the user. */
  via?: Maybe<Scalars['String']['output']>;
};

/** Result of creating SSO OAuth URIs for Google and Microsoft authentication. */
export type CreateSsoOAuthUriResult = {
  __typename?: 'CreateSsoOAuthURIResult';
  /** The OAuth URI for Google authentication. */
  googleUri: Scalars['String']['output'];
  /** The OAuth URI for Microsoft authentication. */
  microsoftUri: Scalars['String']['output'];
};

export type CreateStaticRouteInput = {
  /** Destination IP prefix for the static route. */
  dstPrefix: Scalars['IPPrefix']['input'];
  /** UUID of the DHCP Static Mapping to be used as gateway. */
  gatewayDHCPStaticMappingUUID?: InputMaybe<Scalars['UUID']['input']>;
  /** Gateway IP address for the static route. */
  gatewayIP?: InputMaybe<Scalars['IPV4']['input']>;
  /** Indicates whether the static route is enabled. */
  isEnabled?: Scalars['Boolean']['input'];
  /** Name of the static route. */
  name: Scalars['String']['input'];
};

export type CreateSwitchAutoConfigMacAddressVlanBindingInput = {
  /** The OUI (Organizationally Unique Identifier) to bind to a VLAN. Either OUI or macAddress must be provided. */
  OUI?: InputMaybe<Scalars['String']['input']>;
  /** The UUID of the VLAN to assign to the specified MAC address or OUI. */
  VLANUUID: Scalars['UUID']['input'];
  /** The MAC address to bind to a VLAN. Either macAddress or OUI must be provided. */
  macAddress?: InputMaybe<Scalars['MacAddress']['input']>;
};

/** Input type for creating a switch profile. */
export type CreateSwitchProfileInput = {
  flipIsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** The name of the switch profile. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The unique identifier of the network where the switch profile will be created. */
  networkUUID: Scalars['UUID']['input'];
  /**
   * The STP bridge priority for the switch.
   *
   * The lower the value, the more likely the switch is to be elected as the root bridge.
   * The default is 32768.
   * The range is 0 to 61440. Valid values are multiples of 4096.
   */
  stpBridgePriority?: InputMaybe<Scalars['Int']['input']>;
};

/** Input type containing parameters required to create user access to a network. */
export type CreateUserNetworkAccessInput = {
  /** The date and time when the access should expire. */
  expiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** The unique identifier (UUID) of the network to which access is being granted. */
  networkUUID: Scalars['UUID']['input'];
};

export type CreateVlanInput = {
  /** A list of DHCP rules to create for this VLAN. */
  createDHCPRules?: InputMaybe<Array<CreateDhcpRuleInput>>;
  /** An optional description of the VLAN. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Specifies the protocol for assigning IPv4 addresses to clients on this VLAN. */
  ipV4ClientAssignmentProtocol?: InputMaybe<ClientAssignmentProtocol>;
  /** Specifies the IPv4 gateway address for this VLAN. */
  ipV4ClientGateway?: InputMaybe<Scalars['IPV4']['input']>;
  /** Specifies the IPv4 prefix length (subnet mask) for this VLAN. */
  ipV4ClientPrefixLength?: InputMaybe<Scalars['Int']['input']>;
  /** Specifies the protocol for assigning IPv6 addresses to clients on this VLAN. */
  ipV6ClientAssignmentProtocol?: InputMaybe<ClientAssignmentProtocol>;
  /** Specifies the IPv6 gateway address for this VLAN. */
  ipV6ClientGateway?: InputMaybe<Scalars['IPV6']['input']>;
  /** Specifies the IPv6 prefix length (subnet mask) for this VLAN. */
  ipV6ClientPrefixLength?: InputMaybe<Scalars['Int']['input']>;
  /** If true, the VLAN is enabled upon creation. */
  isEnabled?: Scalars['Boolean']['input'];
  /** If true, this VLAN is internal to the network and should not be displayed to or editable by the customer. */
  isInternal?: Scalars['Boolean']['input'];
  /** If true, the VLAN participates in multicast/mDNS reflection. */
  isMulticastReflectionEnabled?: Scalars['Boolean']['input'];
  /** A human-friendly name to identify the VLAN. */
  name: Scalars['String']['input'];
  /**
   * [Deprecated] The UUID of the network where the VLAN will be created. Use `networkUUID` in `createVLAN` instead.
   * @deprecated Use `networkUUID` in `createVLAN` instead.
   */
  networkUUID?: InputMaybe<Scalars['UUID']['input']>;
  /** The VLAN ID. Must be between 1 and 4094 and is unique per network. */
  vlanID: Scalars['Int']['input'];
};

/** The metadata required to create a virtual device. */
export type CreateVirtualDeviceInput = {
  /** An optional description for the virtual device. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The model of the virtual device. */
  deviceModel: DeviceModel;
  /** The type of the virtual device to create. */
  deviceType: VirtualDeviceType;
  /** A human-friendly name for the virtual device. */
  label: Scalars['String']['input'];
  /** [Access Points only] The radio settings to apply to the access point. */
  radioSettings?: InputMaybe<CreateRadioSettingsInput>;
  /** [Switches only] The UUID of the switch profile to associate with the switch. */
  switchProfileUUID?: InputMaybe<Scalars['UUID']['input']>;
  /** [Access Points only] The virtual device tags to apply to the device, if any. */
  tagUUIDs?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type CreateVirtualDeviceTagInput = {
  name: Scalars['String']['input'];
  networkUUID: Scalars['UUID']['input'];
};

/** Create many virtual devices at once, with the same type and model. */
export type CreateVirtualDevicesInput = {
  /** How many virtual devices to create, up to 100. */
  count: Scalars['Int']['input'];
  /** The model of the virtual devices to create. */
  deviceModel: DeviceModel;
  /** The type of the virtual devices to create. */
  deviceType: VirtualDeviceType;
  /** The labels of the devices will all start with the prefix, followed by an increasing suffix number (e.g., "AP" -> "AP01", "AP02", "AP03", ...). */
  labelPrefix: Scalars['String']['input'];
  /** The starting number to suffix the labels with, defaults to 1. The numbers will be zero-padded at the front. */
  labelSuffixIndex?: Scalars['Int']['input'];
  /** The virtual device tags to apply to the devices, if any. */
  tagUUIDs?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

/** Input type for creating a new Wifi Observer Setting. */
export type CreateWifiObserverSettingInput = {
  /** The UUID of the SSID to use for the observer test. */
  SSIDUUID: Scalars['UUID']['input'];
  /** The radio band on which the test should be run. */
  band: RadioBand;
  /** List of commands to run during the observer test. */
  commands?: InputMaybe<Array<WosObserverCommandInput>>;
  /** Duration of the test in milliseconds. */
  durationMsec: Scalars['Int']['input'];
  /** Number of spatial streams to be used in the test. */
  numSpatialStreams?: InputMaybe<Scalars['Int']['input']>;
  /** 802.1X password for authentication. */
  password8021X?: InputMaybe<Scalars['String']['input']>;
  /** The UUID of the virtual device (access point) that the observer will target or get results for. */
  targetVirtualDeviceUUID?: InputMaybe<Scalars['UUID']['input']>;
  /** 802.1X username for authentication. */
  username8021X?: InputMaybe<Scalars['String']['input']>;
  /** The UUID of the virtual device on which to run the observer test. */
  virtualDeviceUUID: Scalars['UUID']['input'];
};

/** Arbitrary DHCP option values to pass to Kea configuration that we do not yet have dedicated fields for. */
export type DhcpOption = {
  __typename?: 'DHCPOption';
  /** The unique identifier of the DHCP option. */
  UUID: Scalars['UUID']['output'];
  /**
   * The DHCP option code (also called tag). Refer to the IANA DHCP options list:
   * https://www.iana.org/assignments/bootp-dhcp-parameters/bootp-dhcp-parameters.xhtml
   */
  code: Scalars['Int']['output'];
  /** The timestamp when this DHCP option was created. */
  createdAt: Scalars['DateTime']['output'];
  /** The data value for the DHCP option. */
  data: Scalars['String']['output'];
  /** An optional description for the DHCP option. */
  description?: Maybe<Scalars['String']['output']>;
  /** The UUID of the DHCP rule this option belongs to. */
  dhcpRuleUUID: Scalars['UUID']['output'];
  /** The timestamp when this DHCP option was last updated. */
  updatedAt: Scalars['DateTime']['output'];
};

/** Sub-ranges within the `DHCPRule` range to reserve for static IP assignment and not issue DHCP leases within. */
export type DhcpReservedRange = {
  __typename?: 'DHCPReservedRange';
  /** The unique identifier of the DHCP reserved range. */
  UUID: Scalars['UUID']['output'];
  /** The timestamp when this DHCP reserved range was created. */
  createdAt: Scalars['DateTime']['output'];
  /** The UUID of the DHCP rule this reserved range belongs to. */
  dhcpRuleUUID: Scalars['UUID']['output'];
  /** The last reserved IP address in the range, ascending and inclusive. */
  endIPAddress: Scalars['IP']['output'];
  /** The first reserved IP address in the range, ascending and inclusive. */
  startIPAddress: Scalars['IP']['output'];
  /** The timestamp when this DHCP reserved range was last updated. */
  updatedAt: Scalars['DateTime']['output'];
};

/** Stores specific information about a DHCP server for a specific interface, equivalent to a Kea 'subnet'. */
export type DhcpRule = {
  __typename?: 'DHCPRule';
  /** The unique identifier of the DHCP rule. */
  UUID: Scalars['UUID']['output'];
  /** A list of application-level DNS firewall rules applied to this DHCP rule. */
  applicationDNSFirewallRules: Array<ApplicationDnsFirewallRule>;
  /** The timestamp when this DHCP rule was created. */
  createdAt: Scalars['DateTime']['output'];
  /** Enables or disables DNS caching on the DHCP server. If `false`, DNS caching is disabled regardless of other settings. */
  dnsCacheIsEnabled: Scalars['Boolean']['output'];
  /** Specifies the maximum Time-To-Live (TTL) in seconds for cached DNS entries. */
  dnsCacheMaxTTL: Scalars['Int']['output'];
  /** Specifies the maximum number of DNS records to cache when DNS caching is enabled. */
  dnsCacheSize: Scalars['Int']['output'];
  /** A list of DNS host mappings that override regular DNS lookups for specific domains. */
  dnsHostMappings: Array<DnsHostMapping>;
  /** A list of DNS search domains provided to DHCP clients (e.g., 'local.meter'). */
  dnsSearchDomains?: Maybe<Array<Scalars['String']['output']>>;
  /** List of upstream DNS servers to use when `dnsUseGatewayProxy` is `false`, or to forward queries to when enabled. */
  dnsUpstreamServers: Array<Scalars['IP']['output']>;
  /** Determines if the gateway acts as a DNS proxy for clients. If `true`, DNS queries are sent via the gateway. */
  dnsUseGatewayProxy: Scalars['Boolean']['output'];
  /** The last available IP address in the DHCP range, ascending and inclusive. */
  endIPAddress: Scalars['IP']['output'];
  /** The gateway IP address provided to DHCP clients (commonly known as the router option or DHCP option 3). */
  gatewayIPAddress: Scalars['IP']['output'];
  /** The prefix length of the gateway IP address (e.g., 24 for a subnet mask of 255.255.255.0 in IPv4). */
  gatewayPrefixLength: Scalars['Int']['output'];
  /** Indicates whether this DHCP rule is for IPv6 (`true`) or IPv4 (`false`). */
  isIPv6: Scalars['Boolean']['output'];
  /** The duration (in seconds) of the DHCP lease assigned to clients. */
  leaseDurationSeconds: Scalars['Int']['output'];
  /** A list of additional DHCP options to pass to the DHCP server configuration. */
  options: Array<DhcpOption>;
  /** A list of IP ranges within the DHCP range that are reserved and not assigned to clients dynamically. */
  reservedRanges: Array<DhcpReservedRange>;
  /** The first available IP address in the DHCP range, ascending and inclusive. */
  startIPAddress: Scalars['IP']['output'];
  /** A list of static mappings linking specific MAC addresses to fixed IP addresses. */
  staticMappings: Array<DhcpStaticMapping>;
  /** The timestamp when this DHCP rule was last updated. */
  updatedAt: Scalars['DateTime']['output'];
  /** The VLAN associated with this DHCP rule. */
  vlan: Vlan;
  /** The UUID of the VLAN associated with this DHCP rule. */
  vlanUUID: Scalars['UUID']['output'];
};

/** DHCP static mappings between MAC addresses and IP addresses to ensure clients receive predefined IP addresses. */
export type DhcpStaticMapping = {
  __typename?: 'DHCPStaticMapping';
  /** The unique identifier of the DHCP static mapping. */
  UUID: Scalars['UUID']['output'];
  /** The timestamp when this DHCP static mapping was created. */
  createdAt: Scalars['DateTime']['output'];
  /** The UUID of the DHCP rule this static mapping belongs to. */
  dhcpRuleUUID: Scalars['UUID']['output'];
  /** The hostname to assign to the client device. */
  hostname?: Maybe<Scalars['String']['output']>;
  /** Assigned IP address for client device through static DHCP. Application logic will ensure this ip is within the associated `dhcp_rule.gateway_prefix`. */
  ipAddress?: Maybe<Scalars['IP']['output']>;
  /** The MAC address of the client device. */
  macAddress: Scalars['MacAddress']['output'];
  /** A name or label for this static mapping (for organizational purposes). */
  name?: Maybe<Scalars['String']['output']>;
  /** The timestamp when this DHCP static mapping was last updated. */
  updatedAt: Scalars['DateTime']['output'];
};

/** Predefined mappings between a domain and IP address, overriding regular DNS lookups for the given domain. */
export type DnsHostMapping = {
  __typename?: 'DNSHostMapping';
  /** The unique identifier of the DNS host mapping. */
  UUID: Scalars['UUID']['output'];
  /** The timestamp when this DNS host mapping was created. */
  createdAt: Scalars['DateTime']['output'];
  /** The domain to which `overrideDomain` should resolve. If specified, this mapping acts like a 'CNAME' record. */
  destinationDomain?: Maybe<Scalars['String']['output']>;
  /** The IP address to which `overrideDomain` should resolve. If specified, this mapping acts like an 'A' record. */
  destinationIPAddress?: Maybe<Scalars['IP']['output']>;
  /** The UUID of the DHCP rule this DNS host mapping belongs to. */
  dhcpRuleUUID: Scalars['UUID']['output'];
  /** Indicates whether this DNS host mapping is enabled. */
  isEnabled: Scalars['Boolean']['output'];
  /** The domain name to override. This can be a fully qualified domain name (FQDN) or a domain name (e.g., 'example.com'). */
  overrideDomain: Scalars['String']['output'];
  /** The timestamp when this DNS host mapping was last updated. */
  updatedAt: Scalars['DateTime']['output'];
};

export type DeleteDeviceHeartbeatInput = {
  /** The unique identifier of the device heartbeat to delete. Either UUID or token must be provided. */
  UUID?: InputMaybe<Scalars['UUID']['input']>;
  /** The authentication token of the device heartbeat to delete. Either token or UUID must be provided. */
  token?: InputMaybe<Scalars['String']['input']>;
};

export type DeletePermittedEmailDomainInput = {
  /** The slug of the company from which the permitted email domain will be deleted. */
  companySlug: Scalars['String']['input'];
  /** The UUID of the permitted email domain to delete. */
  permittedEmailDomainSID: Scalars['UUID']['input'];
};

/** Result of deleting a permitted email domain, indicating the number of rows deleted. */
export type DeletePermittedEmailDomainResult = {
  __typename?: 'DeletePermittedEmailDomainResult';
  /** The number of permitted email domains that were deleted. */
  numRowsDeleted: Scalars['Int']['output'];
};

/** Input type for deleting an existing Wifi Observer Setting. */
export type DeleteWifiObserverSettingInput = {
  /** The UUID of the SSID used in the observer setting. */
  SSIDUUID: Scalars['UUID']['input'];
  /** The radio band of the observer setting to delete. */
  band: RadioBand;
  /** The UUID of the virtual device (access point) that was targeted by the observer. */
  targetVirtualDeviceUUID?: InputMaybe<Scalars['UUID']['input']>;
  /** The UUID of the virtual device from which to delete the observer setting. */
  virtualDeviceUUID: Scalars['UUID']['input'];
};

/** The union of a hardware device and a virtual device. */
export type Device = {
  __typename?: 'Device';
  /** The hardware device component of the device. */
  hardwareDevice: HardwareDevice;
  /** The virtual device component of the device. */
  virtualDevice: VirtualDevice;
};

/** Represents a rendered configuration for a device. */
export type DeviceConfig = {
  __typename?: 'DeviceConfig';
  /**
   * The timestamp when the device acknowledged this configuration.
   * If null, the device has not acknowledged this version of config.
   */
  acknowledgedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The config data in JSON format. */
  configJSON: Scalars['JSONObject']['output'];
  /** The timestamp when this configuration was created. */
  createdAt: Scalars['DateTime']['output'];
  /** The version number of the configuration. */
  version: Scalars['Int']['output'];
};

/** Represents an override configuration for a device. */
export type DeviceConfigOverride = {
  __typename?: 'DeviceConfigOverride';
  /** The overridden configuration data in JSON format. */
  configJSON: Scalars['JSONObject']['output'];
};

/** A device heartbeat is a deadmanssnitch configuration for a device. The device periodically sends heartbeats to the heartbeat URL. */
export type DeviceHeartbeat = {
  __typename?: 'DeviceHeartbeat';
  /** The unique identifier of the device heartbeat. */
  UUID: Scalars['UUID']['output'];
  /** The interval, in seconds, at which the device is expected to send heartbeats. */
  heartbeatIntervalSeconds: Scalars['Int']['output'];
  /** The URL where the device should send its heartbeat signals. */
  heartbeatURL: Scalars['String']['output'];
  /** The serial number of the device associated with the heartbeat. */
  serialNumber: Scalars['String']['output'];
  /** The authentication token required to validate the heartbeat. */
  token: Scalars['String']['output'];
};

/** DeviceModel enumerates the different model names of devices that Meter deploys. */
export enum DeviceModel {
  Mc01 = 'MC01',
  Mc05 = 'MC05',
  Mc06 = 'MC06',
  Mc11 = 'MC11',
  Mp01 = 'MP01',
  Ms07 = 'MS07',
  Ms10 = 'MS10',
  Ms11 = 'MS11',
  Ms12 = 'MS12',
  Mw02 = 'MW02',
  Mw03 = 'MW03',
  Mw04 = 'MW04',
  Mw05 = 'MW05',
  Mw06 = 'MW06',
  Mw07 = 'MW07',
  Mw08 = 'MW08',
  Mw09 = 'MW09',
  Psgs1518Af = 'PSGS1518AF',
  Psgs1526F = 'PSGS1526F',
  Psgs1626Gf = 'PSGS1626GF',
  Psgs2326Kf = 'PSGS2326KF',
  Psgs2852Bf = 'PSGS2852BF',
  Psgs2852Xbf = 'PSGS2852XBF',
  Psgs2852Xbl = 'PSGS2852XBL',
}

/** DeviceType enumerates the different types of hardware devices that Meter deploys. */
export enum DeviceType {
  /** Represents an Access Point device type. */
  AccessPoint = 'ACCESS_POINT',
  /** Represents a Controller device type. */
  Controller = 'CONTROLLER',
  /** Represents a Power Distribution Unit device type. */
  PowerDistributionUnit = 'POWER_DISTRIBUTION_UNIT',
  /** Represents a Switch device type. */
  Switch = 'SWITCH',
}

/** Filter criteria for devices in a network. */
export type DevicesForNetworkFilter = {
  /** Filter devices by virtual device type. */
  deviceType?: InputMaybe<VirtualDeviceType>;
};

/** Represents a service that has been discovered on the network, advertising via mDNS (multicast DNS), such as printers, AirPlay devices, speakers, etc. */
export type DiscoveredService = {
  __typename?: 'DiscoveredService';
  /** IP address of the discovered service. */
  ipAddress: Scalars['IP']['output'];
  /** MAC address of the device hosting the service. */
  macAddress: Scalars['MacAddress']['output'];
  /** Name assigned to the discovered service, typically provided by the device itself. */
  name: Scalars['String']['output'];
  /** Unique identifier of the network where the service was discovered. */
  networkUUID: Scalars['UUID']['output'];
  /** Type of the discovered service, indicating its functionality (e.g., AIRPLAY, PRINTER, SPEAKER). */
  serviceType: ServiceType;
  /** Timestamp indicating when the service was last detected or reported. */
  timestamp: Scalars['DateTime']['output'];
};

/** Input parameters for querying discovered services. */
export type DiscoveredServicesInput = {
  /**
   * Number of minutes to look back when querying for available services.
   * Specifies how far back in time the query should search for services that were discovered.
   * Must be between 1 and 180 minutes.
   */
  numMinutesLookback: Scalars['Int']['input'];
  /** List of service types to filter for in the query. Only services of these types will be returned. */
  serviceTypes: Array<ServiceType>;
};

/** Input type for generating a pre-signed URL for file download. */
export type DownloadUrlInput = {
  /**
   * The S3 key (path and filename) of the file to be downloaded.
   * Example: 'access_point_photos/20231010_abcd1234.jpg'
   */
  s3Key: Scalars['String']['input'];
};

/** Represents the 802.1X encryption configuration for an SSID. */
export type Encryption8021X = {
  __typename?: 'Encryption8021X';
  /** The unique identifier of this 802.1X encryption configuration. */
  UUID: Scalars['UUID']['output'];
  /** The IP address of the accounting server. */
  accountingServerIPAddress?: Maybe<Scalars['IP']['output']>;
  /** The port of the accounting server. */
  accountingServerPort?: Maybe<Scalars['Int']['output']>;
  /** The shared secret used to authenticate with the accounting server. */
  accountingServerSecret?: Maybe<Scalars['String']['output']>;
  /** The IP address of the authentication server. */
  authServerIPAddress: Scalars['IP']['output'];
  /** The port of the authentication server. */
  authServerPort: Scalars['Int']['output'];
  /** The shared secret used to authenticate with the authentication server. */
  authServerSecret: Scalars['String']['output'];
  /** A user-friendly label for the encryption configuration. */
  label: Scalars['String']['output'];
  /** The UUID of the network to which this encryption configuration belongs. */
  networkUUID: Scalars['UUID']['output'];
  /** The SSIDs associated with this 802.1X encryption configuration. */
  ssids?: Maybe<Array<Ssid>>;
};

/** An event in the event log. */
export type EventLogEvent = {
  __typename?: 'EventLogEvent';
  /** The type of event. */
  eventType: EventType;
  /** The API name representation of the event type. */
  eventTypeAPIName: Scalars['String']['output'];
  /** The timestamp when the event was generated. */
  generatedAt: Scalars['DateTime']['output'];
  /** The timestamp when the event was inserted into the database. */
  insertedAt: Scalars['DateTime']['output'];
  /** The UUID of the network where the event occurred. */
  networkUUID: Scalars['UUID']['output'];
  /** The properties associated with the event. */
  properties: EventLogProperties;
};

/** Properties associated with an event log event. */
export type EventLogProperties = WanDownProperties | WanStatusChangeProperties | WanUpProperties;

/** Enumerate the known event types that exist in the event log. */
export enum EventType {
  /** The event type is unspecified. */
  Unspecified = 'UNSPECIFIED',
  /** Event indicating that the WAN connection is down. */
  WanDown = 'WAN_DOWN',
  /** Event indicating a change in WAN status. */
  WanStatusChange = 'WAN_STATUS_CHANGE',
  /** Event indicating that the WAN connection is up. */
  WanUp = 'WAN_UP',
}

/** Payload containing the pre-signed URL for file download. */
export type FileDownloadPayload = {
  __typename?: 'FileDownloadPayload';
  /** The pre-signed URL for downloading the file from S3. */
  presignedUrl: Scalars['String']['output'];
};

/** Payload containing the pre-signed URL for file upload. */
export type FileUploadPayload = {
  __typename?: 'FileUploadPayload';
  /**
   * The key (path and filename) for the file in S3.
   * Format: <folder>/<timestamp>_<uuid>.<extension>
   */
  key: Scalars['String']['output'];
  /** The pre-signed URL for uploading the file to S3. */
  presignedUrl: Scalars['String']['output'];
};

/** Represents a binding of a firewall rule to a physical interface with a metric. */
export type FirewallPhyInterfaceBinding = {
  __typename?: 'FirewallPhyInterfaceBinding';
  /** The metric associated with the binding. */
  metric: Scalars['Int']['output'];
  /** The physical interface to which the firewall rule is bound. */
  phyInterface: PhyInterface;
};

/** Represents a firewall rule in the network configuration. */
export type FirewallRule = {
  __typename?: 'FirewallRule';
  /** Unique identifier for the firewall rule. */
  UUID: Scalars['UUID']['output'];
  /** The action to take when the firewall rule matches (e.g., DENY or PERMIT). */
  action: FirewallRuleAction;
  /** The date and time when the firewall rule was created. */
  createdAt: Scalars['DateTime']['output'];
  /** A description of the firewall rule. */
  description?: Maybe<Scalars['String']['output']>;
  /** Destination client VPN server to match traffic to. */
  dstClientVPNServer?: Maybe<ClientVpnServer>;
  /** Destination IPSec tunnel to match traffic to. */
  dstIPSecTunnel?: Maybe<IpSecTunnel>;
  /**
   * Deprecated. Use `dstPortRanges` instead. Single destination port range to match.
   * @deprecated Use `dstPortRanges` instead.
   */
  dstPortRange: PortRange;
  /** List of destination port ranges to match traffic to. */
  dstPortRanges: Array<PortRange>;
  /** Destination IP prefix to match traffic to. */
  dstPrefix?: Maybe<Scalars['IPPrefix']['output']>;
  /** Destination VLAN to match traffic to. */
  dstVLAN?: Maybe<Vlan>;
  /**
   * If a firewall rule is bidirectional then the config renderer will generate an additional rule(s)
   * where the src and dst options are swapped.
   * @deprecated Bidirectional rules are now implicit, enabled if the rule is between two VLANs.
   */
  isBidirectional?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates if the firewall rule is currently enabled. */
  isEnabled: Scalars['Boolean']['output'];
  /** Indicates if the firewall rule is internal to Meter. */
  isMeterInternal: Scalars['Boolean']['output'];
  /** The name of the firewall rule. */
  name: Scalars['String']['output'];
  /** Unique identifier of the network to which the firewall rule belongs. */
  networkUUID: Scalars['UUID']['output'];
  /** List of physical interface bindings for the firewall rule. */
  phyInterfaceBindings?: Maybe<Array<FirewallPhyInterfaceBinding>>;
  /**
   * Deprecated. Use `protocols` instead. Single IP protocol to match.
   * @deprecated Use `protocols` instead.
   */
  protocol: IpProtocol;
  /** List of IP protocols to match. */
  protocols: Array<IpProtocol>;
  /** Source client VPN server to match traffic from. */
  srcClientVPNServer?: Maybe<ClientVpnServer>;
  /** Source IPSec tunnel to match traffic from. */
  srcIPSecTunnel?: Maybe<IpSecTunnel>;
  /**
   * Deprecated. Use `srcPortRanges` instead. Single source port range to match.
   * @deprecated Use `srcPortRanges` instead.
   */
  srcPortRange: PortRange;
  /** List of source port ranges to match traffic from. */
  srcPortRanges: Array<PortRange>;
  /** Source IP prefix to match traffic from. */
  srcPrefix?: Maybe<Scalars['IPPrefix']['output']>;
  /** Source VLAN to match traffic from. */
  srcVLAN?: Maybe<Vlan>;
  /** List of tags associated with the firewall rule. */
  tags?: Maybe<Array<Scalars['String']['output']>>;
  /** The date and time when the firewall rule was last updated. */
  updatedAt: Scalars['DateTime']['output'];
  /** List of VLAN bindings for the firewall rule. */
  vlanBindings?: Maybe<Array<FirewallVlanBinding>>;
};

/** Possible actions for a firewall rule. */
export enum FirewallRuleAction {
  /** Deny the traffic. */
  Deny = 'DENY',
  /** Permit the traffic. */
  Permit = 'PERMIT',
}

/** Represents a binding of a firewall rule to a VLAN with a metric. */
export type FirewallVlanBinding = {
  __typename?: 'FirewallVLANBinding';
  /** The metric associated with the binding. */
  metric: Scalars['Int']['output'];
  /** The VLAN to which the firewall rule is bound. */
  vlan: Vlan;
};

/**
 * A floor plan is an overhead view of a physical space. A floor plan is one-to-many to floor plan locations,
 * which are x/y coordinates for Meter devices in the physical space.
 */
export type FloorPlan = {
  __typename?: 'FloorPlan';
  /** The unique identifier of the floor plan. */
  UUID: Scalars['UUID']['output'];
  /** The timestamp when the floor plan was created. */
  createdAt: Scalars['DateTime']['output'];
  /** A description of the floor plan. */
  description?: Maybe<Scalars['String']['output']>;
  /** A pre-signed URL to download the floor plan image. */
  imageDownloadURL: Scalars['String']['output'];
  /** The S3 key (path) of the image file representing the floor plan, stored in Amazon S3. */
  imageS3Key: Scalars['String']['output'];
  /** A human-readable name or label for the floor plan. */
  label: Scalars['String']['output'];
  /** The unique identifier of the network that this floor plan belongs to. */
  networkUUID: Scalars['UUID']['output'];
  /** The timestamp when the floor plan was last updated. */
  updatedAt: Scalars['DateTime']['output'];
};

export type GetCaptivePortalInput = {
  /** This field does not do anything; this is a placeholder for future use and will be deprecated once this input is actually used. */
  TODO?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Additional options that can be used to customize the results of a query for company users. */
export type GetCompanyUserInput = {
  /** If true, include company users that have been deleted. */
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Input parameters to retrieve notification preferences for a company and controller. */
export type GetNotificationPreferencesInput = {
  /** The slug (unique identifier) of the company. */
  companySlug: Scalars['String']['input'];
  /** The name of the controller. */
  controllerName: Scalars['String']['input'];
};

/** A hardware device represents a physical device. */
export type HardwareDevice = {
  /** Shows the most recent boot history of a device. */
  bootHistory?: Maybe<Array<BootInfo>>;
  /** The timestamp when the hardware device was first created. */
  createdAt: Scalars['DateTime']['output'];
  /** The model name of the hardware device. */
  deviceModel: DeviceModel;
  /** The type of the hardware device. */
  deviceType: DeviceType;
  /** If the device is no longer connected to the backend, this is the time at which it was last connected. */
  disconnectedFromBackendAt?: Maybe<Scalars['DateTime']['output']>;
  /**
   * True if the device has an active websocket connection to the backend. Note that this only works if the device has registered via the /v2/register endpoint (i.e., is a Config 2 device) - otherwise is always false.
   * @deprecated Refer to `isSpare` instead.
   */
  isActive: Scalars['Boolean']['output'];
  /** Indicates whether the hardware device is connected to the backend. */
  isConnectedToBackend: Scalars['Boolean']['output'];
  /** Indicates whether the hardware device's console port is enabled. */
  isConsoleEnabled: Scalars['Boolean']['output'];
  /** Indicates whether the hardware device is marked as a development (dev) device, moving it into the dev (staging) realm for testing purposes. */
  isDev: Scalars['Boolean']['output'];
  /** Indicates whether the hardware device is in factory mode. */
  isFactoryMode: Scalars['Boolean']['output'];
  /** Indicates whether the hardware device is an RMA (Return Merchandise Authorization) replacement device. */
  isRMA: Scalars['Boolean']['output'];
  /** Indicates whether the hardware device has been retired. */
  isRetired: Scalars['Boolean']['output'];
  /** A spare hardware device belongs to a network but not a virtual device. Used most often to keep track of spare hardware we ship to a customer but don't install immediately. */
  isSpare: Scalars['Boolean']['output'];
  /** The MAC address of the hardware device. */
  macAddress?: Maybe<Scalars['MacAddress']['output']>;
  /** The UUID of the network that the spare hardware device belongs to. Spare hardware devices that are not affiliated with a virtual device will have this field populated. */
  networkUUID?: Maybe<Scalars['UUID']['output']>;
  /** The public key used to add the device to the jumphost's WireGuard interface. */
  publicKey?: Maybe<Scalars['String']['output']>;
  /** The unique serial number of the hardware device. */
  serialNumber: Scalars['String']['output'];
  /** IPv6 address on the jumphost subnet. Used to send requests to the device. */
  tunnelIPAddress?: Maybe<Scalars['String']['output']>;
  /** The timestamp when the hardware device was last updated. */
  updatedAt: Scalars['DateTime']['output'];
  /** The uptime of the hardware device, retrieved from the time series database. */
  uptime?: Maybe<Scalars['Duration']['output']>;
  /** The virtual device associated with this hardware device, if any. */
  virtualDevice?: Maybe<VirtualDevice>;
  /** The UUID of the virtual device this hardware device is assigned to, if any. */
  virtualDeviceUUID?: Maybe<Scalars['UUID']['output']>;
};

/** A hardware device represents a physical device. */
export type HardwareDeviceBootHistoryArgs = {
  count?: InputMaybe<Scalars['Int']['input']>;
};

/** Filters the response when fetching multiple hardware devices. */
export type HardwareDevicesFilter = {
  /** Filter hardware devices by device model. */
  deviceModel?: InputMaybe<DeviceModel>;
  /** Filter hardware devices by device type. */
  deviceType?: InputMaybe<DeviceType>;
  /** The maximum number of hardware devices to return. Default is 200. */
  limit?: InputMaybe<Scalars['Int']['input']>;
  /** The offset from where to start returning hardware devices. */
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** Specifies the role of the high availability controller. */
export enum HighAvailabilityControllerRole {
  /** The controller is configured as backup. */
  Backup = 'BACKUP',
  /** The controller is configured as primary. */
  Primary = 'PRIMARY',
}

export type HighAvailabilityControllerVirtualDeviceInfo = {
  __typename?: 'HighAvailabilityControllerVirtualDeviceInfo';
  /** Configuration associated with the HA configuration of this virtual device. */
  config: HighAvailabilityControllersConfig;
  /**
   * The role this device is configured to serve as.
   * This does not indicate whether the device is currently serving traffic.
   */
  role: HighAvailabilityControllerRole;
  /** The status of the HA device, reported by the device. */
  status: HighAvailabilityStatus;
};

/** Configuration of the high availability controllers. */
export type HighAvailabilityControllersConfig = {
  __typename?: 'HighAvailabilityControllersConfig';
  /** Unique identifier of the high availability configuration. */
  UUID: Scalars['UUID']['output'];
  /** Interval at which the controllers advertise to one another, in milliseconds. */
  advertisementIntervalMs: Scalars['Int']['output'];
  /**
   * Port the controllers use to communicate over.
   * This is currently _required_ to be the same port on both controllers.
   */
  advertisementPort: Scalars['Int']['output'];
  /** VLAN whose sole purpose is for the HA controllers to communicate with each other. */
  advertisementVLAN: Vlan;
  /** PhyInterface for the controller configured as backup in the HA setup. */
  backupPhyInterface: PhyInterface;
  /** The current status of the backup controller in the HA pair. */
  backupStatus: HighAvailabilityStatus;
  /** Network that is configured with HA controllers. */
  network: Network;
  /** Whether or not VRRP is configured for preemption between the controllers in the HA pair. */
  preemption: Scalars['Boolean']['output'];
  /** PhyInterface for the controller configured as primary in the HA setup. */
  primaryPhyInterface: PhyInterface;
  /** The current status of the primary controller in the HA pair. */
  primaryStatus: HighAvailabilityStatus;
};

/** Possible status values for high availability controllers. */
export enum HighAvailabilityStatus {
  /** The controller is currently active and serving traffic. */
  Active = 'ACTIVE',
  /** The controller is ready but not currently serving traffic. */
  Ready = 'READY',
  /** The status is unspecified. */
  Unspecified = 'UNSPECIFIED',
}

/** Represents the response containing metadata and host monitoring values. */
export type HostMonitoringResponse = MetricsResponse & {
  __typename?: 'HostMonitoringResponse';
  /** Metadata about the metrics, such as aggregation intervals. */
  metadata: MetricsMetadata;
  /** A list of host monitoring data points. */
  values: Array<HostMonitoringValue>;
};

/** Represents the status of host monitoring. */
export enum HostMonitoringStatus {
  /** The host monitoring failed. */
  Failed = 'FAILED',
  /** The host is reachable and responding. */
  Ok = 'OK',
  /** The host monitoring request timed out. */
  Timeout = 'TIMEOUT',
}

/** Host monitoring targets are used to specify hosts for which to monitor availability/reachability on a network. */
export type HostMonitoringTarget = {
  __typename?: 'HostMonitoringTarget';
  /** The unique identifier of the host monitoring target. */
  UUID: Scalars['UUID']['output'];
  /** The timestamp when the host monitoring target was created. */
  createdAt: Scalars['DateTime']['output'];
  /** A descriptive text about the host monitoring target. */
  description?: Maybe<Scalars['String']['output']>;
  /** Indicates whether the host monitoring target is enabled. */
  isEnabled: Scalars['Boolean']['output'];
  /** The UUID of the network to which this host monitoring target belongs. */
  networkUUID: Scalars['UUID']['output'];
  /**
   * The target host to monitor.
   *
   * Currently, only IPv4 addresses are permitted, but FQDNs (Fully Qualified Domain Names) will be supported in the future.
   */
  targetHost: Scalars['IPV4']['output'];
  /** The type of monitoring to perform on the target host. */
  type: HostMonitoringType;
  /** The timestamp when the host monitoring target was last updated. */
  updatedAt: Scalars['DateTime']['output'];
};

/** Defines the types of host monitoring available. */
export enum HostMonitoringType {
  /** Perform ICMP ping monitoring. */
  Ping = 'PING',
  /** Perform traceroute monitoring. */
  Traceroute = 'TRACEROUTE',
}

/** Represents a single host monitoring data point. */
export type HostMonitoringValue = MetricsValue & {
  __typename?: 'HostMonitoringValue';
  /** The IP address of the monitored host. */
  hostIPAddr: Scalars['String']['output'];
  /** The source Ethernet port number used for the monitoring. */
  srcEthPortNum: Scalars['Int']['output'];
  /** The status of the host monitoring. */
  status: HostMonitoringStatus;
  /** The time at which the measurement was taken. */
  timestamp: Scalars['DateTime']['output'];
  /** The average round-trip time (RTT) to the host in nanoseconds. */
  value: Scalars['Float']['output'];
  /** The UUID of the virtual device where the measurement was taken. */
  virtualDeviceUUID: Scalars['UUID']['output'];
};

export type Hs20AccessNetworkTypes = {
  __typename?: 'Hs20AccessNetworkTypes';
  /** Description of the access network type. */
  description: Scalars['String']['output'];
  /** Access network type as defined in Table 9-214 of 802.11-2016 IEEE Standard. */
  type: Scalars['Int']['output'];
};

export type Hs20AuthParam = {
  __typename?: 'Hs20AuthParam';
  /** Description of the authentication parameter. */
  description: Scalars['String']['output'];
  /** Authentication parameter. */
  param: Scalars['Int']['output'];
  /** Valid authentication values for this parameter. */
  validValues: Array<Hs20AuthValue>;
};

/** Hs20AuthValue and Hs20AuthParam are the authentication parameters and values as defined in Table 9-335 of 802.11-2020 IEEE Standard. */
export type Hs20AuthValue = {
  __typename?: 'Hs20AuthValue';
  /** Description of the authentication value. */
  description: Scalars['String']['output'];
  /** Authentication value. */
  value: Scalars['Int']['output'];
};

export type Hs20EapMethod = {
  __typename?: 'Hs20EapMethod';
  /** Description of the EAP method. */
  description: Scalars['String']['output'];
  /** An EAP method, as defined by IANA (http://www.iana.org/assignments/eap-numbers/eap-numbers.xhtml#eap-numbers-4) */
  method: Scalars['Int']['output'];
  /** Indicates if the method is commonly used. */
  mostUsed: Scalars['Boolean']['output'];
};

export type Hs20ValidTypesResponse = {
  __typename?: 'Hs20ValidTypesResponse';
  /** List of valid Hotspot 2.0 access network types. */
  accessNetworkTypes: Array<Hs20AccessNetworkTypes>;
  /** List of valid authentication parameters. */
  authParams: Array<Hs20AuthParam>;
  /** List of valid EAP methods. */
  eapMethods: Array<Hs20EapMethod>;
  /** List of valid Hotspot 2.0 venue types. */
  venueTypes: Array<Hs20VenueTypes>;
};

export type Hs20VenueTypes = {
  __typename?: 'Hs20VenueTypes';
  /** Description of the venue. */
  description: Scalars['String']['output'];
  /** Venue group as defined in Table 9-62 of 802.11-2016 IEEE Standard. */
  group: Scalars['Int']['output'];
  /** Venue type as defined in Table 9-62 of 802.11-2016 IEEE Standard. */
  type: Scalars['Int']['output'];
};

/** Supported IP protocols for firewall rules. */
export enum IpProtocol {
  /** All protocols. */
  All = 'ALL',
  /** Internet Control Message Protocol. */
  Icmp = 'ICMP',
  /** Transmission Control Protocol. */
  Tcp = 'TCP',
  /** User Datagram Protocol. */
  Udp = 'UDP',
}

/** Enumeration of possible IPSec authentication algorithms. */
export enum IpSecAuthenticationAlgorithm {
  /** AES-CMAC-96 authentication algorithm. */
  Cmac_96 = 'CMAC_96',
  /** SHA-1 HMAC with 96-bit truncation. */
  Sha1_96 = 'SHA1_96',
  /** SHA-256 HMAC with 128-bit truncation. */
  Sha2_256_128 = 'SHA2_256_128',
  /** SHA-384 HMAC with 192-bit truncation. */
  Sha2_384_192 = 'SHA2_384_192',
  /** SHA-512 HMAC with 256-bit truncation. */
  Sha2_512_256 = 'SHA2_512_256',
  /** AES-XCBC-MAC-96 authentication algorithm. */
  Xcbc_96 = 'XCBC_96',
}

/** Enumeration of possible states for the CHILD Security Association. */
export enum IpSecChildSaStatus {
  /** The CHILD Security Association is created but not yet installed. */
  Created = 'CREATED',
  /** The CHILD Security Association has been deleted. */
  Deleted = 'DELETED',
  /** The CHILD Security Association is being deleted. */
  Deleting = 'DELETING',
  /** The CHILD Security Association is being destroyed. */
  Destroying = 'DESTROYING',
  /** The CHILD Security Association is installed and operational. */
  Installed = 'INSTALLED',
  /** The CHILD Security Association is being installed. */
  Installing = 'INSTALLING',
  /** The CHILD Security Association has been rekeyed. */
  Rekeyed = 'REKEYED',
  /** The CHILD Security Association is rekeying. */
  Rekeying = 'REKEYING',
  /** The CHILD Security Association is retrying after failure. */
  Retrying = 'RETRYING',
  /** The CHILD Security Association is routed. */
  Routed = 'ROUTED',
  /** Unspecified status. */
  Unspecified = 'UNSPECIFIED',
  /** The CHILD Security Association is updating its configuration. */
  Updating = 'UPDATING',
}

/** Enumeration of possible Diffie-Hellman groups for key exchange. */
export enum IpSecDhGroup {
  /** Diffie-Hellman group 2 (MODP 1024). */
  Dh2 = 'DH2',
  /** Diffie-Hellman group 14 (MODP 2048). */
  Dh14 = 'DH14',
  /** Diffie-Hellman group 19 (ECP 256). */
  Dh19 = 'DH19',
  /** Diffie-Hellman group 20 (ECP 384). */
  Dh20 = 'DH20',
}

/** Enumeration of possible IPSec encryption algorithms. */
export enum IpSecEncryptionAlgorithm {
  /** AES in CBC mode with a 128-bit key. */
  AesCbc_128 = 'AES_CBC_128',
  /** AES in CBC mode with a 192-bit key. */
  AesCbc_192 = 'AES_CBC_192',
  /** AES in CBC mode with a 256-bit key. */
  AesCbc_256 = 'AES_CBC_256',
  /** AES in CTR mode with a 128-bit key. */
  AesCtr_128 = 'AES_CTR_128',
  /** AES in CTR mode with a 192-bit key. */
  AesCtr_192 = 'AES_CTR_192',
  /** AES in CTR mode with a 256-bit key. */
  AesCtr_256 = 'AES_CTR_256',
  /** AES in Galois/Counter Mode (GCM) with a 128-bit key and 16-byte ICV. */
  AesGcm_16128 = 'AES_GCM_16128',
  /** AES in Galois/Counter Mode (GCM) with a 192-bit key and 16-byte ICV. */
  AesGcm_16192 = 'AES_GCM_16192',
  /** AES in Galois/Counter Mode (GCM) with a 256-bit key and 16-byte ICV. */
  AesGcm_16256 = 'AES_GCM_16256',
  /** Triple DES encryption with a 112-bit effective key length. */
  Algo_3Des_112 = 'ALGO_3DES_112',
  /** Triple DES encryption with a 168-bit key. */
  Algo_3Des_168 = 'ALGO_3DES_168',
}

/** Enumeration of possible states for the IKE Security Association. */
export enum IpSecSaStatus {
  /** The IKE Security Association is in the process of connecting. */
  Connecting = 'CONNECTING',
  /** The IKE Security Association is created but not yet connected. */
  Created = 'CREATED',
  /** The IKE Security Association is being deleted. */
  Deleting = 'DELETING',
  /** The IKE Security Association is being destroyed. */
  Destroying = 'DESTROYING',
  /** The IKE Security Association is established and operational. */
  Established = 'ESTABLISHED',
  /** The IKE Security Association is in passive mode, waiting for incoming connections. */
  Passive = 'PASSIVE',
  /** The IKE Security Association has been rekeyed. */
  Rekeyed = 'REKEYED',
  /** The IKE Security Association is rekeying. */
  Rekeying = 'REKEYING',
  /** Unspecified status. */
  Unspecified = 'UNSPECIFIED',
}

export type IpSecTunnel = {
  __typename?: 'IPSecTunnel';
  /** The unique identifier for the IPSec tunnel. */
  UUID: Scalars['UUID']['output'];
  /** The authentication algorithm used for the IPSec connection. */
  authenticationAlgorithm?: Maybe<IpSecAuthenticationAlgorithm>;
  /** The physical interface bound to this IPSec tunnel. */
  boundPhyInterface: PhyInterface;
  /** The VLANs bound to this IPSec tunnel. */
  boundVLANs: Array<Vlan>;
  /** Timestamp when the IPSec tunnel was created. */
  createdAt: Scalars['DateTime']['output'];
  /** The encryption algorithm used for the IPSec connection. */
  encryptionAlgorithm?: Maybe<IpSecEncryptionAlgorithm>;
  /** Indicates whether the IPSec tunnel is currently enabled. */
  isEnabled: Scalars['Boolean']['output'];
  /** Indicates whether this tunnel should initiate the connection to the right participant. */
  isInitiator: Scalars['Boolean']['output'];
  /** The Diffie-Hellman group used for key exchange in the IPSec connection. */
  keyExchangeDHGroup?: Maybe<IpSecDhGroup>;
  /** How the left participant should be identified for authentication. Can be an IP address or FQDN which will be validated in application logic. If this is not set, defaults to `%defaultroute`. */
  leftID: Scalars['String']['output'];
  /** A user-defined name for the IPSec tunnel. */
  name: Scalars['String']['output'];
  /** The unique identifier of the network to which this IPSec tunnel belongs. */
  networkUUID: Scalars['UUID']['output'];
  /** The public IP address or DNS hostname where the right participant can be reached. Can be an IP address or FQDN which will be validated in application logic. */
  right?: Maybe<Scalars['String']['output']>;
  /** Identifier for the right participant (remote side) for authentication purposes. Can be an IP address or FQDN which will be validated in application logic. */
  rightID: Scalars['String']['output'];
  /** The private subnets behind the right participant. This is usually 0.0.0.0/0 but in some cases may be different. */
  rightPrefixes: Array<Scalars['IPPrefix']['output']>;
  /** The last reported status of the IPSec tunnel. */
  status?: Maybe<IpSecTunnelStatus>;
  /** Timestamp when the IPSec tunnel was last updated. */
  updatedAt: Scalars['DateTime']['output'];
};

/** Status information for an IPSec tunnel. */
export type IpSecTunnelStatus = {
  __typename?: 'IPSecTunnelStatus';
  /** Timestamp when the CHILD Security Association will expire. */
  childLifetime: Scalars['DateTime']['output'];
  /** Timestamp when the CHILD Security Association will be rekeyed. */
  childRekeyAt: Scalars['DateTime']['output'];
  /** Status of the CHILD Security Association. */
  childSAStatus: IpSecChildSaStatus;
  /** Timestamp when the IKE Security Association was established. */
  establishedAt: Scalars['DateTime']['output'];
  /** Latency to the remote IKE endpoint in milliseconds. */
  latencyMs: Scalars['Int']['output'];
  /** The timestamp when the status was observed. */
  observedAt: Scalars['DateTime']['output'];
  /** Timestamp when the IKE Security Association will be re-authenticated. */
  reauthAt: Scalars['DateTime']['output'];
  /** Timestamp when the IKE Security Association will be rekeyed. */
  rekeyAt: Scalars['DateTime']['output'];
  /** IPv4 address of the remote IKE endpoint. */
  remoteHostIP4Address: Scalars['String']['output'];
  /** Number of bytes received. */
  rxBytes: Scalars['Int']['output'];
  /** Timestamp of the last inbound packet, if any. */
  rxLast: Scalars['DateTime']['output'];
  /** Number of packets received. */
  rxPackets: Scalars['Int']['output'];
  /** The state of the IKE Security Association. */
  saStatus: IpSecSaStatus;
  /** Number of bytes transmitted. */
  txBytes: Scalars['Int']['output'];
  /** Timestamp of the last outbound packet, if any. */
  txLast: Scalars['DateTime']['output'];
  /** Unique identifier of the IKE Security Association. */
  uniqueID: Scalars['String']['output'];
};

/** Category of service provided by the ISP connection. */
export type IspConnectionType = {
  __typename?: 'ISPConnectionType';
  /** Unique identifier for the ISP connection type. */
  UUID: Scalars['UUID']['output'];
  /** Name of the ISP connection type. Examples: "Dedicated Fiber", "Shared Fiber", "Coax", "Point to Point Wireless", etc. */
  name: Scalars['String']['output'];
};

/** The category of service provided by the ISP. */
export enum IspProduct {
  /** Business cable connection */
  BusinessCable = 'BUSINESS_CABLE',
  /** Dedicated fiber optic connection */
  DedicatedFiber = 'DEDICATED_FIBER',
  /** Point-to-point wireless connection */
  PointToPointWireless = 'POINT_TO_POINT_WIRELESS',
  /** Residential cable connection */
  ResidentialCable = 'RESIDENTIAL_CABLE',
  /** Residential fiber optic connection */
  ResidentialFiber = 'RESIDENTIAL_FIBER',
  /** Shared fiber optic connection */
  SharedFiber = 'SHARED_FIBER',
}

/** Whether the ISP is intended to function as the primary or backup connection. */
export enum IspStatus {
  /** The ISP is configured as the fallback ISP connection. */
  Backup = 'BACKUP',
  /** The ISP is configured as the preferred ISP connection. */
  Primary = 'PRIMARY',
}

/** An enumeration of supported image types. */
export enum ImageType {
  Gif = 'GIF',
  Jpeg = 'JPEG',
  Png = 'PNG',
  Svg = 'SVG',
}

/** Information about the user who performed the impersonation. */
export type Impersonator = {
  __typename?: 'Impersonator';
  /** The email address of the impersonating user. */
  email: Scalars['String']['output'];
};

/** Incidents are deprecated. */
export type Incident = {
  __typename?: 'Incident';
  /** Unique identifier for the incident. */
  UUID: Scalars['UUID']['output'];
  /** Category of the incident. */
  category?: Maybe<IncidentCategory>;
  /** End time of the incident. */
  endTime: Scalars['DateTime']['output'];
  /** Network associated with the incident. */
  network: Network;
  /** UUID of the network associated with the incident. */
  networkUUID: Scalars['UUID']['output'];
  /** Additional notes about the incident. */
  notes?: Maybe<Scalars['String']['output']>;
  /** Start time of the incident. */
  startTime: Scalars['DateTime']['output'];
};

/** An enumeration of incident categories. */
export enum IncidentCategory {
  /** An incident related to deinstallation of equipment or services. */
  Deinstallation = 'DEINSTALLATION',
  /** An incident related to the installation process. */
  Installation = 'INSTALLATION',
  /** An incident involving maintenance activities. */
  Maintenance = 'MAINTENANCE',
  /** An incident caused by or resulting in service outages. */
  Outage = 'OUTAGE',
  /** An incident related to provisioning of services. */
  Provisioning = 'PROVISIONING',
  /** An incident that is scheduled in advance. */
  Scheduled = 'SCHEDULED',
}

/** A pair of VLANs that are permitted to communicate with each other. */
export type InterVlanCommunicationPermittedPair = {
  __typename?: 'InterVLANCommunicationPermittedPair';
  /** The unique identifier of the inter-VLAN communication pair. */
  UUID: Scalars['UUID']['output'];
  /** The other VLAN in the permitted communication pair. */
  higherVLAN: Vlan;
  /** One VLAN in the permitted communication pair. */
  lowerVLAN: Vlan;
  /** The UUID of the network to which the VLANs belong. */
  networkUUID: Scalars['UUID']['output'];
};

/** An internal note is a note for Meter teams to share information about a network. */
export type InternalNote = {
  __typename?: 'InternalNote';
  /** Unique identifier for the internal note. */
  UUID: Scalars['UUID']['output'];
  /** Timestamp indicating when the note was created */
  createdAt: Scalars['DateTime']['output'];
  /** The email address of the user who created the note. */
  createdBy: Scalars['String']['output'];
  /** The user who created the note. */
  createdByUser: User;
  /** Important notes can be highlighted in the UI. */
  isImportant: Scalars['Boolean']['output'];
  /** UUID of the network to which this internal note belongs */
  networkUUID: Scalars['UUID']['output'];
  /** Content of the internal note. */
  note: Scalars['String']['output'];
  /** AWS S3 keys for photos attached to the note. */
  photoS3Keys?: Maybe<Array<Scalars['String']['output']>>;
  /** URLs for photos attached to the note. */
  photoURLs?: Maybe<Array<Scalars['String']['output']>>;
  /** Timestamp indicating when the note was last updated. */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The email address of the user who updated the note most recently. */
  updatedBy?: Maybe<Scalars['String']['output']>;
  /** The user who updated the note most recently. */
  updatedByUser?: Maybe<User>;
};

/**
 * InternetServicePlan represents the configuration associated with an Internet Service Provider (ISP). This includes
 * information about the provider itself, as well as the IP address configurations for the network.
 */
export type InternetServicePlan = {
  __typename?: 'InternetServicePlan';
  /** Unique identifier for the Internet Service Plan. */
  UUID: Scalars['UUID']['output'];
  /** The physical address associated with this Internet Service Plan. */
  address?: Maybe<Address>;
  /** The category of service provided by the ISP (e.g., Dedicated Fiber, Shared Fiber). */
  connectionProduct: IspProduct;
  /** Indicates whether this ISP connection is the primary or backup connection. */
  connectionStatus: IspStatus;
  /**
   * ControllerIP is dynamically resolved from stats coming in to Clickhouse. These stats are reported
   * by the controller.
   */
  controllerIP?: Maybe<Scalars['IPV4']['output']>;
  /** Name of the controller device associated with this Internet Service Plan. */
  controllerName?: Maybe<Scalars['String']['output']>;
  /** Timestamp when the Internet Service Plan was created. */
  createdAt: Scalars['DateTime']['output'];
  /** First usable IP address in the static IP range provided by the ISP. */
  firstUsableIP?: Maybe<Scalars['IPV4']['output']>;
  /** Gateway IP address provided by the ISP for routing. */
  gatewayAddr?: Maybe<Scalars['IPV4']['output']>;
  /** Indicates whether the ISP connection has a static IP address or range. */
  hasStaticIP: Scalars['Boolean']['output'];
  /** Last usable IP address in the static IP range provided by the ISP. */
  lastUsableIP?: Maybe<Scalars['IPV4']['output']>;
  /** Unique identifier of the network associated with this Internet Service Plan. */
  networkUUID?: Maybe<Scalars['UUID']['output']>;
  /** The ISP provider associated with this Internet Service Plan. */
  provider?: Maybe<InternetServicePlanProvider>;
  /** CIDR notation of the static IP address range provided by the ISP. */
  staticIPRange?: Maybe<Scalars['IPPrefix']['output']>;
  /** Timestamp when the Internet Service Plan was last updated. */
  updatedAt: Scalars['DateTime']['output'];
};

/** Input type for connection details of an Internet Service Plan. */
export type InternetServicePlanConnectionInput = {
  /** The category of service provided by the ISP (e.g., Dedicated Fiber, Shared Fiber). */
  product: IspProduct;
  /** Indicates whether the ISP connection is the primary or backup connection. */
  status: IspStatus;
};

/** Input type for IP configuration details of an Internet Service Plan. */
export type InternetServicePlanIpInput = {
  /** The IP address of the controller device. */
  controllerIP?: InputMaybe<Scalars['IPV4']['input']>;
  /** Name of the controller device. */
  controllerName?: InputMaybe<Scalars['String']['input']>;
  /** First usable IP address in the ISP-provided static IP range. */
  firstUsableIP?: InputMaybe<Scalars['IPV4']['input']>;
  /** Gateway IP address for routing provided by the ISP. */
  gatewayAddr?: InputMaybe<Scalars['IPV4']['input']>;
  /** Last usable IP address in the ISP-provided static IP range. */
  lastUsableIP?: InputMaybe<Scalars['IPV4']['input']>;
  /** CIDR notation of the static IP address range provided by the ISP. */
  staticIPRange?: InputMaybe<Scalars['IPPrefix']['input']>;
};

/** InternetServicePlanProvider represents the Internet Service Provider (ISP) that services an InternetServicePlan. */
export type InternetServicePlanProvider = {
  __typename?: 'InternetServicePlanProvider';
  /** Unique identifier for the Internet Service Plan Provider. */
  UUID: Scalars['UUID']['output'];
  /** The URL of the provider's logo. */
  logoURL?: Maybe<Scalars['String']['output']>;
  /** The name of the provider. */
  name: Scalars['String']['output'];
};

export type LatestDeviceCpuLoad = {
  __typename?: 'LatestDeviceCPULoad';
  /** The timestamp when the CPU load was recorded. */
  timestamp: Scalars['DateTime']['output'];
  /**
   * The used CPU percentage represented as a decimal value between 0 and 1.
   * This value is computed by summing the latest percentages of user, system, soft_irq, and irq CPU times.
   */
  usedPercentage: Scalars['Float']['output'];
};

export type LatestDeviceMemoryUsage = {
  __typename?: 'LatestDeviceMemoryUsage';
  /** The amount of memory currently available for use on the device, in bytes. */
  bytesAvailable: Scalars['Int']['output'];
  /** The total amount of memory on the device, in bytes. */
  bytesTotal: Scalars['Int']['output'];
  /** The amount of memory currently used on the device, in bytes. */
  bytesUsed: Scalars['Int']['output'];
  /** The timestamp when the memory usage was recorded. */
  timestamp: Scalars['DateTime']['output'];
};

/** Represents a mapping between a MAC address and its human-friendly alias within a specific company. */
export type MacAddressAlias = {
  __typename?: 'MACAddressAlias';
  /** The MAC address of the device. */
  MACAddress: Scalars['MacAddress']['output'];
  /** The human-friendly name assigned to the MAC address. */
  alias: Scalars['String']['output'];
  /** The unique slug identifier of the company that owns this MAC address alias. */
  companySlug: Scalars['String']['output'];
};

/** The Switch and PhyInterface that a client MAC address is associated with. */
export type MacAddressSwitchEntry = {
  __typename?: 'MACAddressSwitchEntry';
  /** The physical interface on the switch that the client MAC address is connected to. */
  phyInterface: PhyInterface;
  /** The switch virtual device that the client MAC address is associated with. */
  virtualDevice: VirtualDevice;
};

/** Represents a mailing address with optional coordinates and timezone. */
export type MailingAddress = {
  __typename?: 'MailingAddress';
  /** The unique identifier of the mailing address. */
  UUID: Scalars['UUID']['output'];
  /** The city of the mailing address. */
  city?: Maybe<Scalars['String']['output']>;
  /** The geographical coordinates of the mailing address. */
  coordinates?: Maybe<Coordinates>;
  /** The two-letter ISO code of the country. */
  countryISO2?: Maybe<Scalars['String']['output']>;
  /** The first line of the mailing address. */
  line1?: Maybe<Scalars['String']['output']>;
  /** The second line of the mailing address. */
  line2?: Maybe<Scalars['String']['output']>;
  /** The postal code or ZIP code of the mailing address. */
  postalCode?: Maybe<Scalars['String']['output']>;
  squareFeet?: Maybe<Scalars['Int']['output']>;
  /** The subdivision code, such as the state or province code. */
  subdivisionCode?: Maybe<Scalars['String']['output']>;
  /** The timezone associated with the mailing address. */
  timezone?: Maybe<Scalars['String']['output']>;
};

/** Defines a periodic window of time during which customers prefer that maintenance be performed on a network. */
export type MaintenanceWindow = {
  __typename?: 'MaintenanceWindow';
  /** The unique identifier for the maintenance window. */
  UUID: Scalars['UUID']['output'];
  /** The ending day of the week for the maintenance window, in local time (0-6, where 0 is Sunday). */
  endDayOfWeekLocal?: Maybe<Scalars['Int']['output']>;
  /** The ending hour of the maintenance window, in local time (0-23). */
  endHourOfDayLocal?: Maybe<Scalars['Int']['output']>;
  /** For DAILY recurrence windows only. Specifies days of the week (0-6, where 0 is Sunday) to exclude from the maintenance window. */
  excludedDaysOfWeek?: Maybe<Array<Scalars['Int']['output']>>;
  /** Indicates whether the maintenance window is opted out (true) or not (false). If true, maintenance should not be performed during this window. */
  isOptedOut: Scalars['Boolean']['output'];
  /** The unique identifier of the network associated with this maintenance window. */
  networkUUID: Scalars['UUID']['output'];
  /** The recurrence pattern of the maintenance window (daily or weekly). */
  recurrence: MaintenanceWindowRecurrence;
  /** The starting day of the week for the maintenance window, in local time (0-6, where 0 is Sunday). */
  startDayOfWeekLocal?: Maybe<Scalars['Int']['output']>;
  /** The starting hour of the maintenance window, in local time (0-23). */
  startHourOfDayLocal?: Maybe<Scalars['Int']['output']>;
  /** The IANA timezone identifier (e.g., 'America/New_York') for the maintenance window. */
  timezone?: Maybe<Scalars['String']['output']>;
};

/** Defines the recurrence patterns for maintenance windows. */
export enum MaintenanceWindowRecurrence {
  /** Indicates a daily recurrence pattern. */
  Daily = 'DAILY',
  /** Indicates a weekly recurrence pattern. */
  Weekly = 'WEEKLY',
}

export type MetricsFilterInput = {
  /** The total duration in seconds for which to retrieve metrics data. */
  durationSeconds: Scalars['Int']['input'];
  /** If not provided, endTime defaults to now. */
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** The interval in seconds between data points in the metrics response. */
  stepSeconds: Scalars['Int']['input'];
};

export type MetricsMetadata = {
  __typename?: 'MetricsMetadata';
  /** The maximum value among all metrics data points. */
  maxValue: Scalars['Float']['output'];
  /** The minimum value among all metrics data points. */
  minValue: Scalars['Float']['output'];
};

export type MetricsResponse = {
  /** Metadata providing additional information about the metrics data. */
  metadata?: Maybe<MetricsMetadata>;
  /** The list of metrics data points. */
  values: Array<MetricsValue>;
};

export type MetricsSerialNumberDirectionInput = {
  /** Specify which traffic directions are queried for. */
  directions: Array<TrafficDirection>;
  /** The serial number of the device for which the metrics are requested. */
  serialNumber: Scalars['String']['input'];
};

export type MetricsValue = {
  /** The timestamp for this metrics data point. */
  timestamp: Scalars['DateTime']['output'];
  /** The value of the metric at the given timestamp. */
  value: Scalars['Float']['output'];
};

export type MintTokenInput = {
  /** The permissions that this token should contain. */
  permissions: Array<Permission>;
  /** The principal name for the token. For devices, the serial number; for machines, the machine name; for users, the user name. */
  principalName: Scalars['String']['input'];
  /** The principal type for the token. */
  principalType: PrincipalType;
  /** How long from now the token is valid. If null, the token does not expire. */
  validityDuration?: InputMaybe<Scalars['Duration']['input']>;
};

/** Represents a newly minted (issued) token. */
export type MintedToken = {
  __typename?: 'MintedToken';
  /** The encoded string of the token. */
  encoded: Scalars['String']['output'];
  /** The epoch time when the token was issued. */
  epoch: Scalars['String']['output'];
  /** The permissions associated with the token. */
  permissions: Array<Permission>;
  /** The principal associated with the token. */
  principal: TokenPrincipal;
  /** The realm associated with the token. */
  realm: Scalars['String']['output'];
  /** The unique identifier of the token. */
  tokenId: Scalars['String']['output'];
  /** The type of the token (e.g., BEARER). */
  tokenType: TokenType;
};

/** The algorithm used for multi-WAN load balancing. */
export enum MultiWanAlgorithm {
  /** Use the first active WAN interface. */
  FirstActive = 'FIRST_ACTIVE',
  /** Distribute traffic across WAN interfaces in a round-robin fashion. */
  RoundRobin = 'ROUND_ROBIN',
}

export type Mutation = {
  __typename?: 'Mutation';
  /** Re-activates a previously deleted company user, allowing them to now log in and access the dashboard for that company as before. */
  activateCompanyUser: CompanyUser;
  /** Activates a Twilio Cellular SIM card. */
  activateDeviceTwilioCellular?: Maybe<TwilioCellularActivationResponse>;
  /** Adds a network to an Auto VPN Group. */
  addNetworkToAutoVPNGroup: AutoVpnMember;
  /** Adds networks to an Auto VPN Group. */
  addNetworksToAutoVPNGroup: Array<AutoVpnMember>;
  /**
   * This assigns hardware to any virtual device in a network that matches the same type/model as the hardware.
   * Not to be confused with assignSpareHardwareDeviceToNetwork.
   * If the hardware was already associated to the network as a spare, then it will be removed from the spare list.
   */
  assignHardwareDeviceToNetwork: HardwareDevice;
  /**
   * This does not assign the hardware to a virtual device, but rather to the network as a spare device.
   * Need to call assignHardwareDeviceToVirtualDevice / assignHardwareDeviceToNetwork to actually put the hardware to use.
   */
  assignHardwareDeviceToNetworkAsSpare: HardwareDevice;
  /**
   * This assigns hardware to a specific virtual device.
   * Not to be confused with assignSpareHardwareDeviceToNetwork.
   * If the hardware was already associated to the network as a spare, then it will be removed from the spare list.
   */
  assignHardwareDeviceToVirtualDevice: HardwareDevice;
  /**
   * This assigns hardware to specific virtual devices, respectively.
   * If any hardware were already associated to the network as a spare, then they will be removed from the spare list.
   */
  assignHardwareDevicesToVirtualDevices: Array<HardwareDevice>;
  /** Assigns the role of operator to the user represented by this company user. */
  assignOperatorRole: CompanyUser;
  /** Assign a switch profile to a virtual device. */
  assignSwitchProfleToVirtualDevice: Scalars['Boolean']['output'];
  /** Add a device to a rack elevation. */
  attachDeviceToRackElevation: RackElevation;
  /** Attaches an AP to a radio profile so that the AP can inherit the radio settings. */
  attachVirtualDeviceToRadioProfile: VirtualDevice;
  /** Binds a firewall rule to a phy interface with a given metric. Subsequent calls act as an upsert. */
  bindFirewallRuleToPhyInterface: FirewallRule;
  /** Binds a firewall rule to a VLAN with a given metric. Subsequent calls act as an upsert. */
  bindFirewallRuleToVLAN: FirewallRule;
  /**
   * Binds firewall rules to a phy interface, assigning metrics based on the UUIDs' order in `orderedRuleUUIDs`.
   * Any existing bound rules not present in `orderedRuleUUIDs` will be unbound.
   */
  bindFirewallRulesToPhyInterface: Array<FirewallPhyInterfaceBinding>;
  /**
   * Binds firewall rules to a VLAN, assigning metrics based on the UUIDs' order in `orderedRuleUUIDs`.
   * Any existing bound rules not present in `orderedRuleUUIDs` will be unbound.
   */
  bindFirewallRulesToVLAN: Array<FirewallVlanBinding>;
  /** Bind a policy routing rule to a physical interface. */
  bindPolicyRoutingRule: PolicyRoutingRule;
  /**
   * Binds `input` to `orderedRuleUUIDs`, assigning metrics based on the order in `orderedRuleUUIDs`.
   * Any existing bound rules to `input` not present in `orderedRuleUUIDs` will be unbound.
   */
  bindPolicyRoutingRules: Array<PolicyRoutingRuleBinding>;
  /** Attach a rate limit rule to a physical interface (port). */
  bindRateLimitRuleToPhyInterface: RateLimitRule;
  /** Attach a rate limit rule to a VLAN. */
  bindRateLimitRuleToVLAN: RateLimitRule;
  /** Add a virtual device tag to one or many virtual devices. For now this is only supported for APs. */
  bindTagToVirtualDevices: Array<VirtualDevice>;
  /** Bind a VLAN to a physical interface to permit traffic for the VLAN to flow through the port. */
  bindVLANtoPhyInterface: Scalars['Boolean']['output'];
  /** Cancel a scheduled Auto Switch Configuration job that hasn't run yet. */
  cancelAutoSwitchConfigurationJob: SwitchAutoConfigurationJob;
  /** User-facing mutation that performs the full flow of enabling SSO for a company, creating a permitted email domain, and generating an admin portal URI for SSO configuration. */
  configureSSO: ConfigureSsoResult;
  /** A utility method used in the Config 1 -> Config 2 migration process to copy the access point's config 1 location names to the config 2 labels. */
  copyAccessPointConfig1LocationsToConfig2Labels: Array<VirtualDevice>;
  /** A migration utility to copy all DNS host mappings from a Config1 setup to Config2 for a specific network. */
  copyDNSHostMappingsFromConfig1ToConfig2: Array<DnsHostMapping>;
  /** A config1 to config2 migration utility to copy all firewall rules. */
  copyFirewallRulesFromConfig1ToConfig2: Array<FirewallRule>;
  /** A config1 to config2 migration helper for IPSec tunnels. */
  copyIPSecTunnelFromConfig1ToConfig2: IpSecTunnel;
  /** Syncs inter-VLAN communication with Config 1 controller config. This will remove any existing Config 2 inter-VLAN communication settings. */
  copyInterVLANCommunicationFromConfig1ToConfig2: Array<InterVlanCommunicationPermittedPair>;
  /**
   * Copies any IP configurations that existed only in the Config1 configuration to InternetServicePlan
   * fields which will be used in Config2.
   */
  copyInternetServicePlanIPsFromConfigToConfig2: Array<InternetServicePlan>;
  /** A config1->config2 migration helper. Copies the multiWAN algorithm from one network to another. */
  copyNetworkMultiWANAlgorithmFromConfig1ToConfig2: Network;
  /** A migration helper to copy port forwarding rules from config1 to config2 for a network. */
  copyPortForwardingRulesFromConfig1ToConfig2: Array<PortForwardingRule>;
  /** A config 1 -> config 2 migration function that copies radio settings. */
  copyRadioSettingsForNetworkFromConfig1: Array<RadioSettings>;
  /** Copies any SSIDs that are not in the Config 2 SSIDs table from Config 1 and returns the newly created SSIDs. */
  copySSIDsFromConfig1ToConfig2: Array<Ssid>;
  /** A config1 to config2 migration helper to copy uplink physical interfaces. */
  copyUplinkPhyInterfacesFromConfig1ToConfig2: Array<PhyInterface>;
  /** Copies any VLANs that are not in the Config 2 VLANs table from Config 1 and returns the newly created VLANs. */
  copyVLANsFromConfig1ToConfig2: Array<Vlan>;
  /**
   * Creates an alert receiver and mappings between an alert receiver and one or more alert targets
   * Alert targets must be predefined unless the alert target type is EMAIL. In that case, the email address must be
   * provided and alertTargetUUID can be left empty. An email target will be created automatically.
   */
  createAlertReceiverWithTargets: AlertReceiver;
  /** Create a new webhook alert target to notify users via webhook of network events. */
  createAlertTargetWebhook: AlertTargetWebhook;
  /** Creates a new application DNS firewall rule for a DHCP rule. */
  createApplicationDNSFirewallRule: ApplicationDnsFirewallRule;
  /** Creates an Auto VPN Group. */
  createAutoVPNGroup: AutoVpnGroup;
  /** Creates an Auto VPN route on an Auto VPN Group. */
  createAutoVPNRoute: AutoVpnRoute;
  /** Creates a new bearer session. This mutation initiates a CLI bearer session that can be verified to obtain an authentication token. */
  createBearerSession: BearerSession;
  /** Create a new bulk NOS upgrade for a group of networks. */
  createBulkNOSUpgrade: BulkNosUpgrade;
  /** Creates a captive portal for a network. */
  createCaptivePortal: CaptivePortal;
  /** Creates a Client VPN client on a Client VPN server. */
  createClientVPNClient: ClientVpnClient;
  /** Creates a Client VPN server for a network. */
  createClientVPNServer: ClientVpnServer;
  /** Create a new internal-only company. Customer companies cannot be created; they are created by the Salesforce -> Backend data sync process. */
  createCompany: Company;
  /** Creates a company user with the specified fields. If a user with the specified email already exists, this will be a no-op. */
  createCompanyUser: CompanyUser;
  /** Create a new DHCP option. */
  createDHCPOption: DhcpOption;
  /** Create a new DHCP reserved range. */
  createDHCPReservedRange: DhcpReservedRange;
  /** Create a new DHCP rule. */
  createDHCPRule: DhcpRule;
  /** Create a new DHCP static mapping. */
  createDHCPStaticMapping: DhcpStaticMapping;
  /** Create a new DNS host mapping. */
  createDNSHostMapping: DnsHostMapping;
  /** Invoke this method in dev to create a new device in the dev realm. This is done before invoking `setHardwareDeviceIsDev` above. */
  createDevHardwareDevice: HardwareDevice;
  /** Creates a device heartbeat configuration for a device. */
  createDeviceHeartbeat: DeviceHeartbeat;
  /** Creates a new 802.1X encryption configuration for a network. */
  createEncryption8021X: Encryption8021X;
  /** Create a new firewall rule. */
  createFirewallRule: FirewallRule;
  /** Create a new floor plan within a specific network. */
  createFloorPlan: FloorPlan;
  /** Combine two controllers on the same network into an HA pair using VRRP. */
  createHighAvailabilityControllersConfiguration: HighAvailabilityControllersConfig;
  /** Create a new host monitoring target. */
  createHostMonitoringTarget: HostMonitoringTarget;
  /** Create a new IPSec tunnel. */
  createIPSecTunnel: IpSecTunnel;
  /** Create a new internal note. */
  createInternalNote: InternalNote;
  /** Creates a new InternetServicePlan for the given network. */
  createInternetServicePlan: InternetServicePlan;
  /**
   * This is a deprecated mutation.
   * @deprecated We no longer support direct creation of mailing addresses.
   */
  createMailingAddress: MailingAddress;
  /** Create an internal network for an internal company. Users cannot create customer networks under customer companies; they are created by the Salesforce -> Backend data sync process. */
  createNetwork: Network;
  /** Creates a new network onboarding document associated with the specified network. */
  createNetworkOnboarding: NetworkOnboarding;
  /** Create a new NOS feature. */
  createNosFeature: NosFeature;
  /** Creates a new NOS (Network Operating System) version, including the build strings for various device types. */
  createNosVersion: NosVersion;
  /** Create a new notification setting. */
  createNotificationSetting: NotificationSetting;
  /** Create a new 1:1 NAT rule in the specified network. */
  createOneToOneNATRule: OneToOneNatRule;
  /** Create a new permitted email domain for a company. This feature is largely out-of-use in favor of SSO. */
  createPermittedEmailDomain: PermittedEmailDomain;
  /** Create a policy routing rule for a network. */
  createPolicyRoutingRule: PolicyRoutingRule;
  /** Create a port forwarding rule for a network. */
  createPortForwardingRule: PortForwardingRule;
  /** Generates a pre-signed URL for uploading a file to S3. */
  createPreSignedUploadUrl: FileUploadPayload;
  /** Create a rack elevation for a network. */
  createRackElevation: RackElevation;
  /** Create a radio profile for a network. */
  createRadioProfile: RadioProfile;
  /** Create a rate limit rule for a network. */
  createRateLimitRule: RateLimitRule;
  /** Create an SSID for a network. */
  createSSID: Ssid;
  /** Creates a WorkOS admin portal link for the specified company and purpose. */
  createSSOAdminPortalLink: ConfigureSsoResult;
  /**
   * Creates an SSO dashboard for the specified company.
   * @deprecated Field no longer supported
   */
  createSSODashboardForCompany: CreateSsoDashboardForCompanyResult;
  /**
   * Creates a redirect URI for SSO login. Prefer to use login.meter.* instead.
   * @deprecated Field no longer supported
   */
  createSSORedirectURI: CreateSsoRedirectUriResult;
  /** Verifies the token provided via the WorkOS callback. */
  createSSOVerify: CreateSsoVerifyResult;
  /** Creates SSO OAuth URIs for Google and Microsoft authentication. */
  createSsoOAuthURI: CreateSsoOAuthUriResult;
  /** Create a new static route. */
  createStaticRoute: StaticRoute;
  /** Bind MAC addresses or OUIs to VLANs before running Switch Auto Config. */
  createSwitchAutoConfigMACAddressVLANBindings: Array<SwitchAutoConfigMacAddressVlanBinding>;
  /** Create a new switch profile. */
  createSwitchProfile: SwitchProfile;
  /** Provides an internal Operator user access to a network to provide elevated permissions like SSH access. */
  createUserNetworkAccess: Scalars['Boolean']['output'];
  /** Create a new VLAN. */
  createVLAN: Vlan;
  /** Create a virtual device. */
  createVirtualDevice: VirtualDevice;
  /** Create a virtual device tag. */
  createVirtualDeviceTag: VirtualDeviceTag;
  /** Create many virtual devices of the same type and model. */
  createVirtualDevices: Array<VirtualDevice>;
  /** Create a new Wifi Observer Setting. */
  createWifiObserverSetting: WifiObserverSetting;
  /** Deactivates a Twilio Cellular SIM card. */
  deactivateDeviceTwilioCellular?: Maybe<TwilioCellularActivationResponse>;
  /** Deletes an alert receiver and all of its targets. */
  deleteAlertReceiverWithTargets: AlertReceiver;
  /** Deletes a webhook alert target specified by its UUID. */
  deleteAlertTargetWebhook: AlertTargetWebhook;
  /** Deletes an application DNS firewall rule. */
  deleteApplicationDNSFirewallRule: ApplicationDnsFirewallRule;
  /** Deletes an Auto VPN Group. Deleting an Auto VPN Group deletes all group members. */
  deleteAutoVPNGroup: AutoVpnGroup;
  /** Deletes an Auto VPN route on an Auto VPN Group. */
  deleteAutoVPNRoute: AutoVpnRoute;
  /** Delete a bulk NOS upgrade. Cannot delete a bulk upgrade that has non-PENDING scheduled NOS upgrades. */
  deleteBulkNOSUpgrade: BulkNosUpgrade;
  /** Deletes a captive portal. */
  deleteCaptivePortal: CaptivePortal;
  /** Deletes a Client VPN client. */
  deleteClientVPNClient: Scalars['Boolean']['output'];
  /** Deletes a Client VPN server. */
  deleteClientVPNServer: Scalars['Boolean']['output'];
  /** Deletes the specified company user, preventing them from logging in and accessing the dashboard for that company. */
  deleteCompanyUser: CompanyUser;
  /** Delete a DHCP option by its UUID. */
  deleteDHCPOption: DhcpOption;
  /** Delete a DHCP reserved range by its UUID. */
  deleteDHCPReservedRange: DhcpReservedRange;
  /** Delete a DHCP rule by its UUID. */
  deleteDHCPRule: DhcpRule;
  /** Delete a DHCP static mapping by its UUID. */
  deleteDHCPStaticMapping: DhcpStaticMapping;
  /** Delete a DNS host mapping by its UUID. */
  deleteDNSHostMapping: DnsHostMapping;
  /** Deletes a device heartbeat configuration. */
  deleteDeviceHeartbeat: Scalars['Boolean']['output'];
  /** Deletes an existing 802.1X encryption configuration. */
  deleteEncryption8021X: Encryption8021X;
  /** Delete a firewall rule. */
  deleteFirewallRule: FirewallRule;
  /** Delete an existing floor plan. */
  deleteFloorPlan: FloorPlan;
  /** Delete the HA configuration for the given network. */
  deleteHighAvailabilityControllersConfiguration: HighAvailabilityControllersConfig;
  /** Delete an existing host monitoring target. */
  deleteHostMonitoringTarget: HostMonitoringTarget;
  /** Delete an IPSec tunnel. */
  deleteIPSecTunnel: IpSecTunnel;
  /** Delete an internal note. */
  deleteInternalNote: InternalNote;
  /** Deletes an existing InternetServicePlan. */
  deleteInternetServicePlan: Scalars['Boolean']['output'];
  /** Mark a network as soft-deleted. Customer networks cannot be deleted. Careful, this is a very dangerous operation! */
  deleteNetwork: Network;
  /** Deletes a network onboarding document. */
  deleteNetworkOnboardingDocument: NetworkOnboardingDocument;
  /** Deletes a field delegation. */
  deleteNetworkOnboardingFieldDelegation: NetworkOnboardingFieldDelegation;
  /**
   * Deletes a notification preference by UUID.
   * @deprecated No longer supported.
   */
  deleteNotificationPreference: NotificationPreferences;
  /** Delete a notification setting. */
  deleteNotificationSetting: NotificationSetting;
  /** Delete an existing 1:1 NAT rule by its UUID. */
  deleteOneToOneNATRule: OneToOneNatRule;
  /** Delete a permitted email domain for a company. */
  deletePermittedEmailDomain: DeletePermittedEmailDomainResult;
  /** Delete a policy routing rule. */
  deletePolicyRoutingRule: PolicyRoutingRule;
  /**
   * Delete a port forwarding rule.
   * @deprecated Use deletePortForwardingRules instead
   */
  deletePortForwardingRule: PortForwardingRule;
  /** Delete multiple port forwarding rules. */
  deletePortForwardingRules: Array<PortForwardingRule>;
  /** Delete a rack elevation. */
  deleteRackElevation: Scalars['Boolean']['output'];
  /** Delete a radio profile. */
  deleteRadioProfile: RadioProfile;
  /** Delete a rate limit rule. */
  deleteRateLimitRule: RateLimitRule;
  /** Delete an SSID. */
  deleteSSID: Ssid;
  /** Delete a static route. */
  deleteStaticRoute: StaticRoute;
  /** Un-bind a MAC address or OUI from a VLAN before running Switch Auto Config. */
  deleteSwitchAutoConfigMACAddressVLANBinding: SwitchAutoConfigMacAddressVlanBinding;
  /** Delete a VLAN. */
  deleteVLAN: Scalars['Boolean']['output'];
  /** Delete a virtual device and all associated entities. Be sure you know what you're doing, this is a hard deletion! Returns an error if the virtual device is assigned to hardware. */
  deleteVirtualDevice: VirtualDevice;
  /** Delete a virtual device tag. */
  deleteVirtualDeviceTag: VirtualDeviceTag;
  /** Delete multiple virtual devices and all associated entities. Be sure you know what you're doing! Returns an error if any of the virtual devices are assigned to hardware. */
  deleteVirtualDevices: Array<VirtualDevice>;
  /** Delete a Wifi Observer Setting. */
  deleteWifiObserverSetting: WifiObserverSetting;
  /** Remove a device from a rack elevation. */
  detachDeviceFromRackElevation: Scalars['Boolean']['output'];
  /** Detaches an AP from a radio profile. The AP will inherit its network's default radio profile settings. */
  detachVirtualDeviceFromRadioProfile: VirtualDevice;
  /** Edit the reason specified for why a mutation was made in the audit log. */
  editMutationAuditLogEntryReason: Scalars['Boolean']['output'];
  /** Enables SSO for a company. */
  enableSSOForCompany: Company;
  /**
   * Force a sync of the Salesforce -> Backend data sync process for all existing companies.
   * Used when backfilling a new synced field that needs to be populated for all companies.
   * Returns the River Job ID.
   * This should only be invoked by engineers who know what they are doing.
   */
  forceSalesforceSyncCompanies: Scalars['Int']['output'];
  /**
   * Force a sync of the Salesforce -> Backend data sync process for all existing networks and their mailing addresses.
   * Used when backfilling a new synced field that needs to be populated for all networks.
   * Returns the River Job ID.
   * This should only be invoked by engineers who know what they are doing.
   */
  forceSalesforceSyncNetworks: Scalars['Int']['output'];
  /**
   * Initialize and start a job to run Auto Channel Selection for a single AP.
   * The process takes some time, so an AutoChannelSelectionJob is returned to track the progress.
   */
  initializeAutoChannelSelectionForAccessPoint: AutoChannelSelectionJob;
  /**
   * Initialize and start a job to run Auto Channel Selection for all APs in a network.
   * The process takes some time, so an AutoChannelSelectionJob is returned to track the progress.
   */
  initializeAutoChannelSelectionForNetwork: AutoChannelSelectionJob;
  /** Initialize and start a job to run Auto Switch Configuration for a network. */
  initializeAutoSwitchConfigurationForNetwork: SwitchAutoConfigurationJob;
  /** Add fake stats to a demo company for sales demonstration purposes. */
  insertStatsForDemo: Scalars['Boolean']['output'];
  /** Mint a new authentication token. */
  mintAuthenticationToken: MintedToken;
  /** A utility method used for testing purposes to downgrade a device to Config 1; should not be used for customer devices, be very careful. */
  moveDeviceFromConfig2ToConfig1: Scalars['Boolean']['output'];
  /** Moves an internet service plan to a given network. The network must belong to the company currently associated with the ISP. */
  moveInternetServicePlanToNetwork: InternetServicePlan;
  /** Always returns true and does nothing. */
  noop: Scalars['Boolean']['output'];
  /** Pins the configurations for devices in a network to the versions at the given timestamp. Be very careful; use only in outage situations! */
  pinDeviceConfigsForNetwork: Network;
  /** Pins configurations globally for all unpinned networks up to the specified timestamp. Be very careful; use only in outage situations! Only pins networks that are not already pinned. */
  pinUnpinnedDeviceConfigsGlobally: Array<Network>;
  /** Reactivates a Twilio Cellular SIM card. */
  reactivateDeviceTwilioCellular?: Maybe<TwilioCellularActivationResponse>;
  /** Removes a network from an Auto VPN Group. */
  removeNetworkFromAutoVPNGroup: Scalars['Boolean']['output'];
  /** Removes networks from an Auto VPN Group. */
  removeNetworksFromAutoVPNGroup: Scalars['Boolean']['output'];
  /** Remove networks that were previously added to a pending NOS upgrade. */
  removeNetworksFromBulkNOSUpgrade: BulkNosUpgrade;
  /** Removes the role of operator from the user represented by this company user. */
  removeOperatorRole: CompanyUser;
  /**
   * Invoke a rendering of the latest configuration for a device.
   * Does the same thing as the device-facing `GET /v2/devices/:serial_number/config` endpoint.
   */
  renderDeviceConfig: DeviceConfig;
  /** Revoke an existing authentication token. */
  revokeAuthenticationToken: AuthenticationToken;
  /** Rotates the HMAC secret for a given captive portal. */
  rotateCaptivePortalSecret: CaptivePortal;
  /** Blink the LEDs on a device. */
  rpcBlinkLEDs: Scalars['Boolean']['output'];
  /** Instruct a device to clear its list of configs that were marked as bad. */
  rpcClearBlockedConfigs: Scalars['Boolean']['output'];
  /** Cycle an outlet on a Power Distribution Unit (PDU). */
  rpcPDUOutletCycle: Scalars['Boolean']['output'];
  /** Instruct a device to ping out to a host. */
  rpcPing: RpcPingResponse;
  /** Instruct a device to run a cable test on a port. */
  rpcPortCableTest: RpcCableTestResponse;
  /** Instruct a device to reboot.. */
  rpcRebootDevice: Scalars['Boolean']['output'];
  /** Instruct a device to pull its latest config from the backend. */
  rpcRefreshConfig: Scalars['Boolean']['output'];
  /** Instruct a device to recreate a specified IPSec tunnel. */
  rpcRestartIPSecTunnel: Scalars['Boolean']['output'];
  /** Cycle a port on a switch. */
  rpcSwitchPortCycle: Scalars['Boolean']['output'];
  /**
   * Instruct a device to run a WAN speed test.
   * @deprecated Unused due to issues with implementation. Use startWANSpeedtestJob on config 2 networks.
   */
  rpcWANSpeedtest: RpcwanSpeedtestResponse;
  /** Disconnect a client from an AP. */
  rpcWosDisconnectClient: Scalars['Boolean']['output'];
  /** Instruct a device to run an EAPOL test to check RADIUS connectivity. */
  rpcWosEapolTestForSerial: RpcEapolTestForSerialResponse;
  /** Instruct devices to run EAPOL tests to check RADIUS connectivity. */
  rpcWosEapolTestForSerials: Array<RpcEapolTestForSerialResponse>;
  /** Instruct devices to run or get results for an Observer test. */
  rpcWosObserver: RpcWosObserverResponse;
  /** Instruct an AP to start a packet capture on specified monitor interfaces. */
  rpcWosPacketCaptureStart: RpcWosPacketCaptureResponse;
  /** Instruct an AP to stop a packet capture on specified monitor interfaces. */
  rpcWosPacketCaptureStop: RpcWosPacketCaptureResponse;
  /** Reset the radio for a specific band on a device. */
  rpcWosRadioReset: Scalars['Boolean']['output'];
  /** Reset Dynamic Frequency Selection (DFS) channels on an AP. */
  rpcWosResetDfs: Scalars['Boolean']['output'];
  /** Invoke this method in prod to route a device to the dev realm. This clears the registration info and requires the device to re-register. */
  setHardwareDeviceIsDev: Scalars['Boolean']['output'];
  /** Sets the NOS version for a network, resulting in a firmware upgrade or downgrade being applied to all devices within the network. */
  setNetworkNosVersion: Scalars['Boolean']['output'];
  /**
   * Binds a policy routing rule to `input`, assigning metrics based on the order in `input`.
   * Any existing bound rules not present in `input` will be unbound.
   */
  setPolicyRoutingRuleBindings: Array<PolicyRoutingRuleBinding>;
  /** Sign a console login challenge, allowing the user to log in to the device. */
  signConsoleLoginChallenge: SignedConsoleLoginChallenge;
  /** Begin a WAN speed test job with the given input parameters. */
  startWANSpeedtestJob: WanSpeedtestJob;
  /**
   * Test triggering a webhook alert target by simulating an event.
   *
   * This mutation is intended for testing with the CLI and is not for frontend use.
   * The API is unstable and may change or be removed at any time.
   * @deprecated Internal use only
   */
  testTriggerWebhookAlertTarget: AlertTargetWebhook;
  /** Unassign the given hardware from its virtual device, if any. */
  unassignHardwareDeviceFromVirtualDevice: HardwareDevice;
  /** Unassigns hardware from the list of virtual devices. Be sure you know what you're doing! */
  unassignHardwareFromVirtualDevices: Array<VirtualDevice>;
  /** Removes the association of an internet service plan from any network it was previously assigned to. */
  unassignInternetServicePlanFromNetwork: InternetServicePlan;
  /** Unassign the hardware from the network as a spare device. */
  unassignSpareHardwareDeviceFromNetwork: HardwareDevice;
  /** Unbinds a firewall rule from a physical interface. */
  unbindFirewallRuleFromPhyInterface: FirewallRule;
  /** Unbinds a firewall rule from a VLAN. */
  unbindFirewallRuleFromVLAN: FirewallRule;
  /** Unbind a policy routing rule from a physical interface. */
  unbindPolicyRoutingRule: PolicyRoutingRule;
  /** Detach a rate limit rule from a physical interface (port). */
  unbindRateLimitRuleFromPhyInterface: RateLimitRule;
  /** Detach a rate limit rule from a VLAN. */
  unbindRateLimitRuleFromVLAN: RateLimitRule;
  /** Remove a virtual device tag from one or many virtual devices. For now this is only supported for APs. */
  unbindTagFromVirtualDevices: Array<VirtualDevice>;
  /** Un-soft-delete a network. Should only be used in special circumstances. */
  undeleteNetwork: Network;
  /** Unpins the configurations for devices in a network if they were previously pinned. Be very careful; use only in outage situations! */
  unpinDeviceConfigsForNetwork: Network;
  /** Unpins configurations globally for all networks that were pinned by the given timestamp. Be very careful; use only in outage situations! Only unpins networks that are pinned by the given maxDeviceConfigCreatedAt datetime (to avoid unpinning networks that were pinned by other processes). */
  unpinDeviceConfigsGlobally: Array<Network>;
  /** Invoke this method in prod to route a dev device back to the prod realm. This clears the registration info and requires the device to re-register. */
  unsetHardwareDeviceIsDev: Scalars['Boolean']['output'];
  /** Updates an alert receiver and its targets. */
  updateAlertReceiverWithTargets: AlertReceiver;
  /** Update an existing webhook alert target. */
  updateAlertTargetWebhook: AlertTargetWebhook;
  /** Updates an existing application DNS firewall rule. */
  updateApplicationDNSFirewallRule: ApplicationDnsFirewallRule;
  /** Updates the visibility of applications for DNS firewall rules. */
  updateApplicationDNSFirewallRuleApplicationVisibilities: Array<
    Maybe<ApplicationDnsFirewallRuleApplication>
  >;
  /**
   * Updates a list of rules to the specified priorities. Returns all updated rules.
   * Rules must be associated with the same network and DHCP rule.
   * If any provided priority is already taken, the request fails.
   */
  updateApplicationDNSFirewallRulesPriorities: Array<ApplicationDnsFirewallRule>;
  /** Updates an Auto VPN Group, only allowing updates to the hub. */
  updateAutoVPNGroup: AutoVpnGroup;
  /** Updates an Auto VPN Member. */
  updateAutoVPNMember: AutoVpnMember;
  /** Updates an Auto VPN route on an Auto VPN Group. */
  updateAutoVPNRoute: AutoVpnRoute;
  /** Updates a captive portal. */
  updateCaptivePortal: CaptivePortal;
  /** Updates a Client VPN server. */
  updateClientVPNServer: ClientVpnServer;
  /** Update a limited set of fields on a company. Many fields are owned by Salesforce and cannot be updated via this endpoint. */
  updateCompany: Company;
  /** Updates the specified company user with the provided fields. Note that the email field cannot be updated. */
  updateCompanyUser: CompanyUser;
  /** Update an existing DHCP option by its UUID. */
  updateDHCPOption: DhcpOption;
  /** Update an existing DHCP reserved range by its UUID. */
  updateDHCPReservedRange: DhcpReservedRange;
  /** Update an existing DHCP rule by its UUID. */
  updateDHCPRule: DhcpRule;
  /** Update an existing DHCP static mapping by its UUID. */
  updateDHCPStaticMapping: DhcpStaticMapping;
  /** Update an existing DNS host mapping by its UUID. */
  updateDNSHostMapping: DnsHostMapping;
  /** Updates an existing 802.1X encryption configuration. */
  updateEncryption8021X: Encryption8021X;
  /** Update a firewall rule. */
  updateFirewallRule: FirewallRule;
  /** Update the details of an existing floor plan. */
  updateFloorPlan: FloorPlan;
  /** Consumes a cellular failover event in the stats pipeline and triggers an alert to escalate the issue to the support team. */
  updateForCellularFailoverEvent: Scalars['Boolean']['output'];
  /**
   * Update the status for a high availability event.
   * This is intended to only be called by the stat-consumer.
   */
  updateForHighAvailabilityEvent: Scalars['Boolean']['output'];
  /** Update the metadata of a hardware device. */
  updateHardwareDevice: HardwareDevice;
  /** Update an existing host monitoring target. */
  updateHostMonitoringTarget: HostMonitoringTarget;
  /** Update an IPSec tunnel. */
  updateIPSecTunnel: IpSecTunnel;
  /** Sets the permitted pairs for this network to these pairs, discarding others. Equivalent to PUT semantics. */
  updateInterVLANCommunicationPermittedPairs: Array<InterVlanCommunicationPermittedPair>;
  /** Update an internal note. */
  updateInternalNote: InternalNote;
  /** Updates an existing InternetServicePlan with the provided fields. */
  updateInternetServicePlan: InternetServicePlan;
  /**
   * This is a deprecated mutation.
   * @deprecated We no longer support direct updating of mailing addresses.
   */
  updateMailingAddress: MailingAddress;
  /** Updates the maintenance window for a network. */
  updateMaintenanceWindowForNetwork: MaintenanceWindow;
  /** Update a network. */
  updateNetwork: Network;
  /** Update an existing NOS feature. */
  updateNosFeature: NosFeature;
  /** Updates an existing NOS version with new data. */
  updateNosVersion: NosVersion;
  /**
   * Updates a notification preference by UUID.
   * @deprecated No longer supported.
   */
  updateNotificationPreference: NotificationPreferences;
  /** Update a notification setting. */
  updateNotificationSetting: NotificationSetting;
  /** Update an existing 1:1 NAT rule by its UUID. */
  updateOneToOneNATRule: OneToOneNatRule;
  /** Update an outlet interface. */
  updateOutletInterface: OutletInterface;
  /** Update multiple outlet interfaces. */
  updateOutletInterfaces: Array<OutletInterface>;
  /** Update a physical interface. */
  updatePhyInterface: PhyInterface;
  /** This mutation is intended to be called only by the Kafka consumer. It updates the physical interface for a Multi-WAN event. */
  updatePhyInterfaceForMultiWANEvent: Scalars['Boolean']['output'];
  /** Update multiple physical interfaces to the same values. */
  updatePhyInterfaces: Array<PhyInterface>;
  /** Update a policy routing rule. */
  updatePolicyRoutingRule: PolicyRoutingRule;
  /** Update a port forwarding rule. */
  updatePortForwardingRule: PortForwardingRule;
  /** Update a rack elevation. */
  updateRackElevation: RackElevation;
  /** Update a device in a rack elevation. */
  updateRackElevationDevice: RackElevation;
  /** Update a radio profile. */
  updateRadioProfile: RadioProfile;
  /** Update a rate limit rule. */
  updateRateLimitRule: RateLimitRule;
  /** Update an SSID. */
  updateSSID: Ssid;
  /** Update all security appliance profiles belonging to a given network. */
  updateSecurityApplianceProfilesForNetwork: Array<SecurityApplianceProfile>;
  /** Update a static route. */
  updateStaticRoute: StaticRoute;
  /** Update an existing switch profile. */
  updateSwitchProfile: SwitchProfile;
  /** This mutation is intended to be called only by the Kafka consumer. It updates the external addresses of an uplink physical interface. */
  updateUplinkPhyInterfaceExternalAddresses: Scalars['Boolean']['output'];
  /**
   * Updates a user's roles to be only those specified by the input.
   *
   * Any existing roles not included in the input will be removed.
   */
  updateUserRoles: CompanyUser;
  /** Update an existing VLAN. */
  updateVLAN: Vlan;
  /** Sets the permitted pairs for this VLAN to these other VLANs, discarding others. Equivalent to PUT semantics. */
  updateVLANInterVLANCommunicationPermittedVLANs: Vlan;
  /** Update many VLANs at once, applying different settings to each. */
  updateVLANsIndependently: Array<Vlan>;
  /** Update the metadata of a virtual device. */
  updateVirtualDevice: VirtualDevice;
  /** Update a virtual device tag. */
  updateVirtualDeviceTag: VirtualDeviceTag;
  /** Update the metadata of multiple virtual devices at once, to the same values for all devices. */
  updateVirtualDevices: Array<VirtualDevice>;
  /** Update the metadata of multiple virtual devices at once, to different values for each device. */
  updateVirtualDevicesIndependently: Array<VirtualDevice>;
  /**
   * Upserts notification preferences for a company.
   * @deprecated No longer supported.
   */
  upsertCompanyNotificationPreference: NotificationPreferences;
  /** Only use this in development or extreme outage situations! Overrides the rendered configuration for a device with the given JSON. */
  upsertDeviceConfigOverride: Scalars['Boolean']['output'];
  /** Creates a new alias for a MAC address or updates an existing one within a specified company. */
  upsertMACAddressAlias: MacAddressAlias;
  /**
   * Upserts notification preferences for a network.
   * @deprecated No longer supported.
   */
  upsertNetworkNotificationPreference: NotificationPreferences;
  /**
   * Creates or updates the network onboarding document using PUT semantics.
   * The document is keyed by `networkUUID` for convenience.
   */
  upsertNetworkOnboardingDocument: NetworkOnboardingDocument;
  /** Delegates specific fields of the network onboarding document to a user. */
  upsertNetworkOnboardingFieldDelegation: NetworkOnboardingFieldDelegation;
  /** Validates a bearer session using its UUID. Should only be called a single time. Once validated, the bearer session can be used to authenticate. */
  validateBearerSession: BearerSession;
};

export type MutationActivateCompanyUserArgs = {
  companyUserUUID: Scalars['UUID']['input'];
};

export type MutationActivateDeviceTwilioCellularArgs = {
  input: TwilioCellularActivationInput;
  serialNumber: Scalars['String']['input'];
};

export type MutationAddNetworkToAutoVpnGroupArgs = {
  groupUUID: Scalars['UUID']['input'];
  input: AddNetworkToAutoVpnGroupInput;
};

export type MutationAddNetworksToAutoVpnGroupArgs = {
  groupUUID: Scalars['UUID']['input'];
  inputs: Array<AddNetworkToAutoVpnGroupInput>;
};

export type MutationAssignHardwareDeviceToNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
  serialNumber: Scalars['String']['input'];
};

export type MutationAssignHardwareDeviceToNetworkAsSpareArgs = {
  networkUUID: Scalars['UUID']['input'];
  serialNumber: Scalars['String']['input'];
};

export type MutationAssignHardwareDeviceToVirtualDeviceArgs = {
  serialNumber: Scalars['String']['input'];
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type MutationAssignHardwareDevicesToVirtualDevicesArgs = {
  inputs: Array<AssignHardwareDeviceToVirtualDeviceInput>;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationAssignOperatorRoleArgs = {
  companyUserUUID: Scalars['UUID']['input'];
};

export type MutationAssignSwitchProfleToVirtualDeviceArgs = {
  UUID: Scalars['UUID']['input'];
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type MutationAttachDeviceToRackElevationArgs = {
  input: AttachDeviceToRackElevationInput;
  rackElevationUUID: Scalars['UUID']['input'];
};

export type MutationAttachVirtualDeviceToRadioProfileArgs = {
  radioProfileUUID: Scalars['UUID']['input'];
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type MutationBindFirewallRuleToPhyInterfaceArgs = {
  input: BindFirewallRuleToPhyInterface;
};

export type MutationBindFirewallRuleToVlanArgs = {
  input: BindFirewallRuleToVlan;
};

export type MutationBindFirewallRulesToPhyInterfaceArgs = {
  orderedRuleUUIDs: Array<Scalars['UUID']['input']>;
  phyInterfaceUUID: Scalars['UUID']['input'];
};

export type MutationBindFirewallRulesToVlanArgs = {
  orderedRuleUUIDs: Array<Scalars['UUID']['input']>;
  vlanUUID: Scalars['UUID']['input'];
};

export type MutationBindPolicyRoutingRuleArgs = {
  UUID: Scalars['UUID']['input'];
  input: BindPolicyRoutingRuleInput;
};

export type MutationBindPolicyRoutingRulesArgs = {
  input: BindPolicyRoutingRulesInput;
  orderedRuleUUIDs: Array<Scalars['UUID']['input']>;
};

export type MutationBindRateLimitRuleToPhyInterfaceArgs = {
  input: RateLimitPhyInterfaceBindingInput;
};

export type MutationBindRateLimitRuleToVlanArgs = {
  input: RateLimitVlanBindingInput;
};

export type MutationBindTagToVirtualDevicesArgs = {
  tagUUID: Scalars['UUID']['input'];
  virtualDeviceUUIDs: Array<Scalars['UUID']['input']>;
};

export type MutationBindVlaNtoPhyInterfaceArgs = {
  input: BindVlanToPhyInterfaceInput;
};

export type MutationCancelAutoSwitchConfigurationJobArgs = {
  jobUUID: Scalars['UUID']['input'];
};

export type MutationConfigureSsoArgs = {
  input: ConfigureSsoInput;
};

export type MutationCopyAccessPointConfig1LocationsToConfig2LabelsArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCopyDnsHostMappingsFromConfig1ToConfig2Args = {
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCopyFirewallRulesFromConfig1ToConfig2Args = {
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCopyIpSecTunnelFromConfig1ToConfig2Args = {
  input: CopyIpSecTunnelFromConfig1ToConfig2Input;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCopyInterVlanCommunicationFromConfig1ToConfig2Args = {
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCopyInternetServicePlanIPsFromConfigToConfig2Args = {
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCopyNetworkMultiWanAlgorithmFromConfig1ToConfig2Args = {
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCopyPortForwardingRulesFromConfig1ToConfig2Args = {
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCopyRadioSettingsForNetworkFromConfig1Args = {
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCopySsiDsFromConfig1ToConfig2Args = {
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCopyUplinkPhyInterfacesFromConfig1ToConfig2Args = {
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCopyVlaNsFromConfig1ToConfig2Args = {
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCreateAlertReceiverWithTargetsArgs = {
  companyUUID: Scalars['UUID']['input'];
  input: CreateAlertReceiverWithTargetsInput;
};

export type MutationCreateAlertTargetWebhookArgs = {
  companyUUID: Scalars['UUID']['input'];
  input: CreateAlertTargetWebhookInput;
};

export type MutationCreateApplicationDnsFirewallRuleArgs = {
  dhcpRuleUUID: Scalars['UUID']['input'];
  input: CreateApplicationDnsFirewallRuleInput;
};

export type MutationCreateAutoVpnGroupArgs = {
  companySlug: Scalars['String']['input'];
  input: CreateAutoVpnGroupInput;
};

export type MutationCreateAutoVpnRouteArgs = {
  groupUUID: Scalars['UUID']['input'];
  input: CreateAutoVpnRouteInput;
};

export type MutationCreateBulkNosUpgradeArgs = {
  input: CreateBulkNosUpgradeInput;
};

export type MutationCreateCaptivePortalArgs = {
  input: CaptivePortalSettingsInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCreateClientVpnClientArgs = {
  input: CreateClientVpnClientInput;
  serverUUID: Scalars['UUID']['input'];
};

export type MutationCreateClientVpnServerArgs = {
  input: CreateClientVpnServerInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCreateCompanyArgs = {
  input: CreateCompanyInput;
};

export type MutationCreateCompanyUserArgs = {
  companySlug: Scalars['String']['input'];
  input: CreateCompanyUserInput;
};

export type MutationCreateDhcpOptionArgs = {
  dhcpRuleUUID: Scalars['UUID']['input'];
  input: CreateDhcpOptionInput;
};

export type MutationCreateDhcpReservedRangeArgs = {
  dhcpRuleUUID: Scalars['UUID']['input'];
  input: CreateDhcpReservedRangeInput;
};

export type MutationCreateDhcpRuleArgs = {
  input: CreateDhcpRuleInput;
  vlanUUID: Scalars['UUID']['input'];
};

export type MutationCreateDhcpStaticMappingArgs = {
  dhcpRuleUUID: Scalars['UUID']['input'];
  input: CreateDhcpStaticMappingInput;
};

export type MutationCreateDnsHostMappingArgs = {
  dhcpRuleUUID: Scalars['UUID']['input'];
  input: CreateDnsHostMappingInput;
};

export type MutationCreateDevHardwareDeviceArgs = {
  input: CreateDevHardwareDeviceInput;
};

export type MutationCreateDeviceHeartbeatArgs = {
  input: CreateDeviceHeartbeatInput;
};

export type MutationCreateEncryption8021XArgs = {
  input: CreateEncryption8021XInput;
};

export type MutationCreateFirewallRuleArgs = {
  input: CreateFirewallRuleInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCreateFloorPlanArgs = {
  input: CreateFloorPlanInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCreateHighAvailabilityControllersConfigurationArgs = {
  backupPhyInterfaceUUID: Scalars['UUID']['input'];
  input?: InputMaybe<CreateHighAvailabilityPairInput>;
  networkUUID: Scalars['UUID']['input'];
  primaryPhyInterfaceUUID: Scalars['UUID']['input'];
};

export type MutationCreateHostMonitoringTargetArgs = {
  input: CreateHostMonitoringTargetInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCreateIpSecTunnelArgs = {
  input: CreateIpSecTunnelInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCreateInternalNoteArgs = {
  input: CreateInternalNoteInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCreateInternetServicePlanArgs = {
  companySlug: Scalars['String']['input'];
  input: CreateInternetServicePlanInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCreateMailingAddressArgs = {
  input: CreateMailingAddressInput;
};

export type MutationCreateNetworkArgs = {
  input: CreateNetworkInput;
};

export type MutationCreateNetworkOnboardingArgs = {
  jobTrackerID: Scalars['String']['input'];
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCreateNosFeatureArgs = {
  input: CreateNosFeatureInput;
};

export type MutationCreateNosVersionArgs = {
  input: NosVersionInput;
};

export type MutationCreateNotificationSettingArgs = {
  input: CreateNotificationSettingInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCreateOneToOneNatRuleArgs = {
  input: CreateOneToOneNatRuleInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCreatePermittedEmailDomainArgs = {
  input: CreatePermittedEmailDomainInput;
};

export type MutationCreatePolicyRoutingRuleArgs = {
  input: CreatePolicyRoutingRuleInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCreatePortForwardingRuleArgs = {
  input: CreatePortForwardingRuleInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCreatePreSignedUploadUrlArgs = {
  input: CreatePreSignedUploadUrlInput;
};

export type MutationCreateRackElevationArgs = {
  input: CreateRackElevationInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCreateRadioProfileArgs = {
  input: CreateRadioProfileInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCreateRateLimitRuleArgs = {
  input: CreateRateLimitRuleInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCreateSsidArgs = {
  input: CreateSsidInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCreateSsoAdminPortalLinkArgs = {
  input?: InputMaybe<ConfigureSsoInput>;
};

export type MutationCreateSsoDashboardForCompanyArgs = {
  companySlug: Scalars['String']['input'];
};

export type MutationCreateSsoRedirectUriArgs = {
  targetApp: Scalars['String']['input'];
  userEmail: Scalars['String']['input'];
};

export type MutationCreateSsoVerifyArgs = {
  input: CreateSsoVerifyInput;
};

export type MutationCreateSsoOAuthUriArgs = {
  targetApp: Scalars['String']['input'];
};

export type MutationCreateStaticRouteArgs = {
  input: CreateStaticRouteInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCreateSwitchAutoConfigMacAddressVlanBindingsArgs = {
  input: Array<CreateSwitchAutoConfigMacAddressVlanBindingInput>;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCreateSwitchProfileArgs = {
  input: CreateSwitchProfileInput;
};

export type MutationCreateUserNetworkAccessArgs = {
  input: CreateUserNetworkAccessInput;
};

export type MutationCreateVlanArgs = {
  input: CreateVlanInput;
  networkUUID?: InputMaybe<Scalars['UUID']['input']>;
};

export type MutationCreateVirtualDeviceArgs = {
  input: CreateVirtualDeviceInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCreateVirtualDeviceTagArgs = {
  input: CreateVirtualDeviceTagInput;
};

export type MutationCreateVirtualDevicesArgs = {
  input: CreateVirtualDevicesInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCreateWifiObserverSettingArgs = {
  input: CreateWifiObserverSettingInput;
};

export type MutationDeactivateDeviceTwilioCellularArgs = {
  serialNumber: Scalars['String']['input'];
};

export type MutationDeleteAlertReceiverWithTargetsArgs = {
  alertReceiverUUID: Scalars['UUID']['input'];
};

export type MutationDeleteAlertTargetWebhookArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteApplicationDnsFirewallRuleArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteAutoVpnGroupArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteAutoVpnRouteArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteBulkNosUpgradeArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteCaptivePortalArgs = {
  uuid: Scalars['UUID']['input'];
};

export type MutationDeleteClientVpnClientArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteClientVpnServerArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteCompanyUserArgs = {
  companyUserUUID: Scalars['UUID']['input'];
};

export type MutationDeleteDhcpOptionArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteDhcpReservedRangeArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteDhcpRuleArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteDhcpStaticMappingArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteDnsHostMappingArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteDeviceHeartbeatArgs = {
  input: DeleteDeviceHeartbeatInput;
};

export type MutationDeleteEncryption8021XArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteFirewallRuleArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteFloorPlanArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteHighAvailabilityControllersConfigurationArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type MutationDeleteHostMonitoringTargetArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteIpSecTunnelArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteInternalNoteArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteInternetServicePlanArgs = {
  internetServicePlanUUID: Scalars['UUID']['input'];
};

export type MutationDeleteNetworkArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteNetworkOnboardingDocumentArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteNetworkOnboardingFieldDelegationArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteNotificationPreferenceArgs = {
  uuid: Scalars['UUID']['input'];
};

export type MutationDeleteNotificationSettingArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteOneToOneNatRuleArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeletePermittedEmailDomainArgs = {
  input: DeletePermittedEmailDomainInput;
};

export type MutationDeletePolicyRoutingRuleArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeletePortForwardingRuleArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeletePortForwardingRulesArgs = {
  UUIDs: Array<Scalars['UUID']['input']>;
};

export type MutationDeleteRackElevationArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteRadioProfileArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteRateLimitRuleArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteSsidArgs = {
  uuid: Scalars['UUID']['input'];
};

export type MutationDeleteStaticRouteArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteSwitchAutoConfigMacAddressVlanBindingArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteVlanArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteVirtualDeviceArgs = {
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type MutationDeleteVirtualDeviceTagArgs = {
  tagUUID: Scalars['UUID']['input'];
};

export type MutationDeleteVirtualDevicesArgs = {
  virtualDeviceUUIDs: Array<Scalars['UUID']['input']>;
};

export type MutationDeleteWifiObserverSettingArgs = {
  input: DeleteWifiObserverSettingInput;
};

export type MutationDetachDeviceFromRackElevationArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDetachVirtualDeviceFromRadioProfileArgs = {
  radioProfileUUID: Scalars['UUID']['input'];
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type MutationEditMutationAuditLogEntryReasonArgs = {
  UUID: Scalars['UUID']['input'];
  reason: Scalars['String']['input'];
};

export type MutationEnableSsoForCompanyArgs = {
  companySlug: Scalars['String']['input'];
};

export type MutationInitializeAutoChannelSelectionForAccessPointArgs = {
  input: AutoChannelSelectionJobInput;
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type MutationInitializeAutoChannelSelectionForNetworkArgs = {
  input: AutoChannelSelectionJobInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationInitializeAutoSwitchConfigurationForNetworkArgs = {
  input: SwitchAutoConfigurationJobInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationInsertStatsForDemoArgs = {
  companySlug: Scalars['String']['input'];
  duration?: InputMaybe<StatInjectionDuration>;
};

export type MutationMintAuthenticationTokenArgs = {
  input?: InputMaybe<MintTokenInput>;
};

export type MutationMoveDeviceFromConfig2ToConfig1Args = {
  serialNumber: Scalars['String']['input'];
};

export type MutationMoveInternetServicePlanToNetworkArgs = {
  internetServicePlanUUID: Scalars['UUID']['input'];
  networkUUID: Scalars['UUID']['input'];
};

export type MutationPinDeviceConfigsForNetworkArgs = {
  maxDeviceConfigCreatedAt: Scalars['DateTime']['input'];
  networkUUID: Scalars['UUID']['input'];
};

export type MutationPinUnpinnedDeviceConfigsGloballyArgs = {
  maxDeviceConfigCreatedAt: Scalars['DateTime']['input'];
};

export type MutationReactivateDeviceTwilioCellularArgs = {
  serialNumber: Scalars['String']['input'];
};

export type MutationRemoveNetworkFromAutoVpnGroupArgs = {
  groupUUID: Scalars['UUID']['input'];
  networkUUID: Scalars['UUID']['input'];
};

export type MutationRemoveNetworksFromAutoVpnGroupArgs = {
  groupUUID: Scalars['UUID']['input'];
  networkUUIDs: Array<Scalars['UUID']['input']>;
};

export type MutationRemoveNetworksFromBulkNosUpgradeArgs = {
  UUID: Scalars['UUID']['input'];
  networkUUIDs: Array<Scalars['UUID']['input']>;
};

export type MutationRemoveOperatorRoleArgs = {
  companyUserUUID: Scalars['UUID']['input'];
};

export type MutationRenderDeviceConfigArgs = {
  serialNumber: Scalars['String']['input'];
};

export type MutationRevokeAuthenticationTokenArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationRotateCaptivePortalSecretArgs = {
  uuid: Scalars['UUID']['input'];
};

export type MutationRpcBlinkLeDsArgs = {
  durationSec?: Scalars['Int']['input'];
  serialNumber: Scalars['String']['input'];
};

export type MutationRpcClearBlockedConfigsArgs = {
  serialNumber: Scalars['String']['input'];
};

export type MutationRpcPduOutletCycleArgs = {
  input: RpcpduOutletCycleInput;
  serialNumber: Scalars['String']['input'];
};

export type MutationRpcPingArgs = {
  input: RpcPingInput;
};

export type MutationRpcPortCableTestArgs = {
  portNumber: Scalars['Int']['input'];
  serialNumber: Scalars['String']['input'];
};

export type MutationRpcRebootDeviceArgs = {
  serialNumber: Scalars['String']['input'];
};

export type MutationRpcRefreshConfigArgs = {
  serialNumber: Scalars['String']['input'];
};

export type MutationRpcRestartIpSecTunnelArgs = {
  ipSecTunnelUUID: Scalars['UUID']['input'];
  serialNumber: Scalars['String']['input'];
};

export type MutationRpcSwitchPortCycleArgs = {
  input: RpcSwitchPortCycleInput;
};

export type MutationRpcWanSpeedtestArgs = {
  input: RpcwanSpeedtestInput;
};

export type MutationRpcWosDisconnectClientArgs = {
  mac: Scalars['String']['input'];
  serialNumber: Scalars['String']['input'];
  ssid: Scalars['String']['input'];
};

export type MutationRpcWosEapolTestForSerialArgs = {
  input?: InputMaybe<RpcEapolTestForSerialInput>;
};

export type MutationRpcWosEapolTestForSerialsArgs = {
  input: RpcEapolTestForSerialsInput;
};

export type MutationRpcWosObserverArgs = {
  input: RpcWosObserverInput;
};

export type MutationRpcWosPacketCaptureStartArgs = {
  input: RpcWosPacketCaptureStartInput;
  serialNumber: Scalars['String']['input'];
};

export type MutationRpcWosPacketCaptureStopArgs = {
  input: RpcWosPacketCaptureStopInput;
  serialNumber: Scalars['String']['input'];
};

export type MutationRpcWosRadioResetArgs = {
  band: RadioBand;
  serialNumber: Scalars['String']['input'];
};

export type MutationRpcWosResetDfsArgs = {
  serialNumber: Scalars['String']['input'];
};

export type MutationSetHardwareDeviceIsDevArgs = {
  serialNumber: Scalars['String']['input'];
};

export type MutationSetNetworkNosVersionArgs = {
  input: SetNetworkNosVersionInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationSetPolicyRoutingRuleBindingsArgs = {
  UUID: Scalars['UUID']['input'];
  input: SetPolicyRoutingRuleBindingsInput;
};

export type MutationSignConsoleLoginChallengeArgs = {
  challenge: Scalars['String']['input'];
  serialNumber: Scalars['String']['input'];
};

export type MutationStartWanSpeedtestJobArgs = {
  input: StartWanSpeedtestJobInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationTestTriggerWebhookAlertTargetArgs = {
  UUID: Scalars['UUID']['input'];
  input: TestTriggerAlertTargetWebhookInput;
};

export type MutationUnassignHardwareDeviceFromVirtualDeviceArgs = {
  serialNumber: Scalars['String']['input'];
};

export type MutationUnassignHardwareFromVirtualDevicesArgs = {
  virtualDeviceUUIDs: Array<Scalars['UUID']['input']>;
};

export type MutationUnassignInternetServicePlanFromNetworkArgs = {
  internetServicePlanUUID: Scalars['UUID']['input'];
};

export type MutationUnassignSpareHardwareDeviceFromNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
  serialNumber: Scalars['String']['input'];
};

export type MutationUnbindFirewallRuleFromPhyInterfaceArgs = {
  input: UnbindFirewallRuleFromPhyInterface;
};

export type MutationUnbindFirewallRuleFromVlanArgs = {
  input: UnbindFirewallRuleFromVlan;
};

export type MutationUnbindPolicyRoutingRuleArgs = {
  UUID: Scalars['UUID']['input'];
  input: UnbindPolicyRoutingRuleInput;
};

export type MutationUnbindRateLimitRuleFromPhyInterfaceArgs = {
  input: RateLimitPhyInterfaceBindingInput;
};

export type MutationUnbindRateLimitRuleFromVlanArgs = {
  input: RateLimitVlanBindingInput;
};

export type MutationUnbindTagFromVirtualDevicesArgs = {
  tagUUID: Scalars['UUID']['input'];
  virtualDeviceUUIDs: Array<Scalars['UUID']['input']>;
};

export type MutationUndeleteNetworkArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationUnpinDeviceConfigsForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type MutationUnpinDeviceConfigsGloballyArgs = {
  maxDeviceConfigCreatedAt: Scalars['DateTime']['input'];
};

export type MutationUnsetHardwareDeviceIsDevArgs = {
  serialNumber: Scalars['String']['input'];
};

export type MutationUpdateAlertReceiverWithTargetsArgs = {
  alertReceiverUUID: Scalars['UUID']['input'];
  input: UpdateAlertReceiverWithTargetsInput;
};

export type MutationUpdateAlertTargetWebhookArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateAlertTargetWebhookInput;
};

export type MutationUpdateApplicationDnsFirewallRuleArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateApplicationDnsFirewallRuleInput;
};

export type MutationUpdateApplicationDnsFirewallRuleApplicationVisibilitiesArgs = {
  input: Array<UpdateApplicationDnsFirewallRuleApplicationVisibilities>;
};

export type MutationUpdateApplicationDnsFirewallRulesPrioritiesArgs = {
  input: Array<UpdateApplicationDnsFirewallRulesPrioritiesInput>;
};

export type MutationUpdateAutoVpnGroupArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateAutoVpnGroupInput;
};

export type MutationUpdateAutoVpnMemberArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateAutoVpnMemberInput;
};

export type MutationUpdateAutoVpnRouteArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateAutoVpnRouteInput;
};

export type MutationUpdateCaptivePortalArgs = {
  input: CaptivePortalSettingsInput;
  uuid: Scalars['UUID']['input'];
};

export type MutationUpdateClientVpnServerArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateClientVpnServerInput;
};

export type MutationUpdateCompanyArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateCompanyInput;
};

export type MutationUpdateCompanyUserArgs = {
  companyUserUUID: Scalars['UUID']['input'];
  input: UpdateCompanyUserInput;
};

export type MutationUpdateDhcpOptionArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateDhcpOptionInput;
};

export type MutationUpdateDhcpReservedRangeArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateDhcpReservedRangeInput;
};

export type MutationUpdateDhcpRuleArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateDhcpRuleInput;
};

export type MutationUpdateDhcpStaticMappingArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateDhcpStaticMappingInput;
};

export type MutationUpdateDnsHostMappingArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateDnsHostMappingInput;
};

export type MutationUpdateEncryption8021XArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateEncryption8021XInput;
};

export type MutationUpdateFirewallRuleArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateFirewallRuleInput;
};

export type MutationUpdateFloorPlanArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateFloorPlanInput;
};

export type MutationUpdateForCellularFailoverEventArgs = {
  input: UpdateForCellularFailoverEventInput;
  networkUUID: Scalars['UUID']['input'];
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type MutationUpdateForHighAvailabilityEventArgs = {
  input: UpdateForHighAvailabilityEventInput;
  networkUUID: Scalars['UUID']['input'];
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type MutationUpdateHardwareDeviceArgs = {
  input: UpdateHardwareDeviceInput;
  serialNumber: Scalars['String']['input'];
};

export type MutationUpdateHostMonitoringTargetArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateHostMonitoringTargetInput;
};

export type MutationUpdateIpSecTunnelArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateIpSecTunnelInput;
};

export type MutationUpdateInterVlanCommunicationPermittedPairsArgs = {
  networkUUID: Scalars['UUID']['input'];
  vlanPairs: Array<VlanPairInput>;
};

export type MutationUpdateInternalNoteArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateInternalNoteInput;
};

export type MutationUpdateInternetServicePlanArgs = {
  input: UpdateInternetServicePlanInput;
  internetServicePlanUUID: Scalars['UUID']['input'];
};

export type MutationUpdateMailingAddressArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateMailingAddressInput;
};

export type MutationUpdateMaintenanceWindowForNetworkArgs = {
  input: UpdateMaintenanceWindowInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationUpdateNetworkArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateNetworkInput;
};

export type MutationUpdateNosFeatureArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateNosFeatureInput;
};

export type MutationUpdateNosVersionArgs = {
  id: Scalars['Int']['input'];
  input: NosVersionInput;
};

export type MutationUpdateNotificationPreferenceArgs = {
  input?: InputMaybe<NotificationPreferenceInput>;
  uuid: Scalars['UUID']['input'];
};

export type MutationUpdateNotificationSettingArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateNotificationSettingInput;
};

export type MutationUpdateOneToOneNatRuleArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateOneToOneNatRuleInput;
};

export type MutationUpdateOutletInterfaceArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateOutletInterfaceInput;
};

export type MutationUpdateOutletInterfacesArgs = {
  input: UpdateOutletInterfaceInput;
  outletInterfaceUUIDs: Array<Scalars['UUID']['input']>;
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type MutationUpdatePhyInterfaceArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdatePhyInterfaceInput;
};

export type MutationUpdatePhyInterfaceForMultiWanEventArgs = {
  input: Array<UpdatePhyInterfaceForMultiWanEventInput>;
  networkUUID: Scalars['UUID']['input'];
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type MutationUpdatePhyInterfacesArgs = {
  input: UpdatePhyInterfaceInput;
  phyInterfaceUUIDs: Array<Scalars['UUID']['input']>;
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type MutationUpdatePolicyRoutingRuleArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdatePolicyRoutingRuleInput;
};

export type MutationUpdatePortForwardingRuleArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdatePortForwardingRuleInput;
};

export type MutationUpdateRackElevationArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateRackElevationInput;
};

export type MutationUpdateRackElevationDeviceArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateRackElevationDeviceInput;
};

export type MutationUpdateRadioProfileArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateRadioProfileInput;
};

export type MutationUpdateRateLimitRuleArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateRateLimitRuleInput;
};

export type MutationUpdateSsidArgs = {
  input: UpdateSsidInput;
  uuid: Scalars['UUID']['input'];
};

export type MutationUpdateSecurityApplianceProfilesForNetworkArgs = {
  input: UpdateSecurityApplianceProfileInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationUpdateStaticRouteArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateStaticRouteInput;
};

export type MutationUpdateSwitchProfileArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateSwitchProfileInput;
};

export type MutationUpdateUplinkPhyInterfaceExternalAddressesArgs = {
  input: UpdateUplinkPhyInterfaceExternalAddressesInput;
  networkUUID: Scalars['UUID']['input'];
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type MutationUpdateUserRolesArgs = {
  companyUserUUID: Scalars['UUID']['input'];
  input: Array<UserRoleInput>;
};

export type MutationUpdateVlanArgs = {
  UUID?: InputMaybe<Scalars['UUID']['input']>;
  input: UpdateVlanInput;
};

export type MutationUpdateVlanInterVlanCommunicationPermittedVlaNsArgs = {
  otherVLANUUIDs: Array<Scalars['UUID']['input']>;
  vlanUUID: Scalars['UUID']['input'];
};

export type MutationUpdateVlaNsIndependentlyArgs = {
  inputs: Array<UpdateVlaNsIndependentlyInput>;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationUpdateVirtualDeviceArgs = {
  input: UpdateVirtualDeviceInput;
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type MutationUpdateVirtualDeviceTagArgs = {
  input: UpdateVirtualDeviceTagInput;
  tagUUID: Scalars['UUID']['input'];
};

export type MutationUpdateVirtualDevicesArgs = {
  input: UpdateVirtualDeviceInput;
  networkUUID: Scalars['UUID']['input'];
  virtualDeviceUUIDs: Array<Scalars['UUID']['input']>;
};

export type MutationUpdateVirtualDevicesIndependentlyArgs = {
  inputs: Array<UpdateVirtualDeviceIndependentlyInput>;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationUpsertCompanyNotificationPreferenceArgs = {
  companySlug: Scalars['String']['input'];
  input?: InputMaybe<NotificationPreferenceInput>;
};

export type MutationUpsertDeviceConfigOverrideArgs = {
  configJSON: Scalars['String']['input'];
  serialNumber: Scalars['String']['input'];
};

export type MutationUpsertMacAddressAliasArgs = {
  alias: Scalars['String']['input'];
  companySlug: Scalars['String']['input'];
  macAddress: Scalars['MacAddress']['input'];
};

export type MutationUpsertNetworkNotificationPreferenceArgs = {
  controllerName: Scalars['String']['input'];
  input?: InputMaybe<NotificationPreferenceInput>;
};

export type MutationUpsertNetworkOnboardingDocumentArgs = {
  document: Scalars['JSONObject']['input'];
  networkUUID: Scalars['UUID']['input'];
};

export type MutationUpsertNetworkOnboardingFieldDelegationArgs = {
  fieldName: Scalars['String']['input'];
  input: UpsertNetworkOnboardingFieldDelegationInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationValidateBearerSessionArgs = {
  UUID: Scalars['UUID']['input'];
};

/** Tracks the mutations that have been made to the network's configuration over time. */
export type MutationAuditLogEntry = {
  __typename?: 'MutationAuditLogEntry';
  /** The unique identifier for this audit log entry. */
  UUID: Scalars['UUID']['output'];
  /** The action or mutation that was performed, typically the name of the mutation function invoked. */
  action: Scalars['String']['output'];
  /** The arguments provided to the mutation, represented as a JSON object. */
  args: Scalars['JSONObject']['output'];
  /** The date and time when the mutation was performed, in ISO 8601 format */
  createdAt: Scalars['DateTime']['output'];
  /** The type of identity that performed the mutation. */
  identityType: Scalars['String']['output'];
  /** The unique identifier of the network where the mutation occurred */
  networkUUID: Scalars['UUID']['output'];
  /** An optional reason specified for why the mutation was made. */
  reason?: Maybe<Scalars['String']['output']>;
  /** The username or identifier of the entity (user or machine) that performed the mutation. */
  username: Scalars['String']['output'];
};

export type MutationAuditLogFilter = {
  /** Whether to include audit log entries where the identityType is 'MACHINE'. */
  includeMachine?: Scalars['Boolean']['input'];
  /** The maximum number of audit log entries to return. */
  limit?: Scalars['Int']['input'];
  /** Only include audit log entries that were created before or at this date and time. */
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Only include audit log entries that were created at or after this date and time. */
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** The number of audit log entries to skip before starting to collect the result set. */
  offset?: Scalars['Int']['input'];
};

/** A network is a grouping of devices in a single location belonging to a company. A core Meter entity. */
export type Network = {
  __typename?: 'Network';
  /** The unique identifier of the network. */
  UUID: Scalars['UUID']['output'];
  /** The company that this network belongs to. */
  company?: Maybe<Company>;
  /** The slug identifier of the company that this network belongs to. */
  companySlug?: Maybe<Scalars['String']['output']>;
  /** The date and time when the network was created. */
  createdAt: Scalars['DateTime']['output'];
  /** The time when the network was deactivated, if applicable. Allows differentiation between pre-live and post-live networks. */
  deactivatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The date and time when the network was deleted, if applicable. */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The service types enabled for service discovery in this network. */
  enabledServiceDiscoveryTypes: Array<ServiceType>;
  /** The date the network first started serving traffic. */
  goLiveAt?: Maybe<Scalars['DateTime']['output']>;
  /** Configuration of the HA controllers for this network. */
  highAvailabilityConfig?: Maybe<HighAvailabilityControllersConfig>;
  /** Whether or not the network is currently serving traffic. */
  isActive: Scalars['Boolean']['output'];
  /** Indicates whether the controller can upgrade config-1 APs deployed to the network. */
  isConfig1WosUpgradesEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Whether or not the network services a customer (as opposed to being an internal / test network). */
  isCustomer: Scalars['Boolean']['output'];
  /**
   * A "template" network is one that does not represent a real physical network, but rather a template for creating new networks.
   * It is not associated with any physical devices, and is not intended to be used for any real-world operations.
   */
  isTemplate: Scalars['Boolean']['output'];
  /** Whether the network is participating in the Meter Trial Program. */
  isTrial: Scalars['Boolean']['output'];
  /** Indicates whether a network is sensitive to firmware updates. */
  isUpgradeSensitive: Scalars['Boolean']['output'];
  /** A short code to quickly identify the network. Useful for customers with many networks and a need to quickly identify them. */
  label: Scalars['String']['output'];
  /** The last time this network was upgraded. It is the time when pending NOS was copied into pinned NOS by the NOS scheduler. */
  lastUpgradedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The mailing address associated with the network. */
  mailingAddress?: Maybe<MailingAddress>;
  /** The unique identifier of the network's mailing address. */
  mailingAddressUUID?: Maybe<Scalars['UUID']['output']>;
  /** The algorithm used for multi-WAN load balancing. */
  multiWANAlgorithm?: Maybe<MultiWanAlgorithm>;
  /** Somewhat legacy onboarding information from Airtable job. Different from `onboardingDocument`. */
  onboarding?: Maybe<NetworkOnboarding>;
  /** Onboarding document form completed prior to network going live. Different from `onboarding`. */
  onboardingDocument?: Maybe<NetworkOnboardingDocument>;
  /** The URL of the patch panel diagram for the network. */
  patchPanelDiagramURL?: Maybe<Scalars['String']['output']>;
  /** The last time an operator set the NOS version for the network. */
  pendingNOSLastSetAt?: Maybe<Scalars['DateTime']['output']>;
  /** The pending NOS version that this network is scheduled to be upgraded to. */
  pendingNOSVersionID?: Maybe<Scalars['Int']['output']>;
  /** A way to pin a network's devices to a known version of config by the config's max created at time. */
  pinnedDeviceConfigMaxCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The current NOS version for all devices in the network. */
  pinnedNOSVersionID: Scalars['Int']['output'];
  /** The rack elevations associated with the network. */
  rackElevations: Array<RackElevation>;
  /** A human-readable identifier for the network. Used in URLs to identify the network. */
  slug: Scalars['String']['output'];
  /**
   * Deprecated: Not a network property anymore.
   * @deprecated Not a network property anymore
   */
  spaceCategory?: Maybe<Scalars['String']['output']>;
  /** The interval in minutes used to stagger device upgrades within the network. */
  staggeredUpgradeIntervalMin: Scalars['Int']['output'];
  /** The URL of the network topology image. */
  topologyImageURL?: Maybe<Scalars['String']['output']>;
  /** The date and time when the network was last updated. */
  updatedAt: Scalars['DateTime']['output'];
  /** The virtual devices associated with the network, optionally filtered. */
  virtualDevices: Array<VirtualDevice>;
};

/** A network is a grouping of devices in a single location belonging to a company. A core Meter entity. */
export type NetworkVirtualDevicesArgs = {
  filter?: InputMaybe<DevicesForNetworkFilter>;
};

/** A network client is a large container for all of the data that constitutes a client connected to a network. */
export type NetworkClient = {
  __typename?: 'NetworkClient';
  /** A user-defined MACAddressAlias for the client if one is defined for the company. */
  alias?: Maybe<Scalars['String']['output']>;
  /** The location of the access point (AP) to which the client is connected, if applicable. */
  apLocation?: Maybe<Scalars['String']['output']>;
  /** The serial number of the access point (AP) to which the client is connected, if applicable. */
  apSerialNumber?: Maybe<Scalars['String']['output']>;
  /** When the client associated with the AP, if applicable. */
  associatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The channel number used by the client's wireless connection. */
  channel?: Maybe<Scalars['Int']['output']>;
  /** The friendly name of the client device, if available from DHCP. */
  clientName?: Maybe<Scalars['String']['output']>;
  /** The VLAN to which the client is connected. */
  connectedVLAN?: Maybe<Vlan>;
  /** The timestamp when the DHCP lease assigned to the client expires. */
  dhcpLeaseExpiresAt?: Maybe<Scalars['DateTime']['output']>;
  /** The IP address assigned to the client. */
  ip: Scalars['String']['output'];
  /** If false, the client is connected via a wired connection. */
  isWireless: Scalars['Boolean']['output'];
  /** The timestamp when the client was last seen on the network. */
  lastSeen: Scalars['DateTime']['output'];
  /**
   * The latency between the controller and the client, measured in milliseconds. Deprecated in favor of 'latencyNs'.
   * @deprecated Use `latencyNs` instead.
   */
  latencyMs?: Maybe<Scalars['Int']['output']>;
  /** The latency between the controller and the client, measured in nanoseconds. */
  latencyNs?: Maybe<Scalars['Int']['output']>;
  /** The MAC address of the client. */
  macAddress: Scalars['MacAddress']['output'];
  /** The UUID of the network to which this client is connected. */
  networkUUID: Scalars['UUID']['output'];
  /** The noise level of the client's connection, measured in dBm. */
  noise?: Maybe<Scalars['Int']['output']>;
  /** The receive rate of the client connection, in bits per second. */
  rxRate?: Maybe<Scalars['Int']['output']>;
  /** List of mdns service types that this client offers (e.g. printer, airplay, etc). */
  serviceTypes?: Maybe<Array<ServiceType>>;
  /** The signal strength of the client's connection, measured in dBm. */
  signal?: Maybe<Scalars['Int']['output']>;
  /** The SSID of the wireless network to which the client is connected. */
  ssid?: Maybe<Scalars['String']['output']>;
  /** The average network throughput of the client during the lookback period. */
  throughput?: Maybe<NetworkClientThroughput>;
  /** The total number of bytes received by the client over the requested lookback period. */
  totalRxBytes?: Maybe<Scalars['Int']['output']>;
  /** The total number of bytes transmitted by the client over the requested lookback period. */
  totalTxBytes?: Maybe<Scalars['Int']['output']>;
  /** The transmit rate of the client connection, in bits per second. */
  txRate?: Maybe<Scalars['Int']['output']>;
  /**
   * The VLAN the client is connected to.
   * @deprecated Use `connectedVLAN` instead.
   */
  vlan?: Maybe<Scalars['String']['output']>;
  /** vlanUUID is only supported for Config 2 networks. */
  vlanUUID?: Maybe<Scalars['UUID']['output']>;
  /** Wireless score computed based on the current health of the client. */
  wirelessScore?: Maybe<Scalars['Float']['output']>;
};

export type NetworkClientThroughput = {
  __typename?: 'NetworkClientThroughput';
  /** The average receive rate of the client, in bits per second. */
  rxRate?: Maybe<Scalars['Float']['output']>;
  /** The average transmit rate of the client, in bits per second. */
  txRate?: Maybe<Scalars['Float']['output']>;
};

export type NetworkClientTxRxPerDay = {
  __typename?: 'NetworkClientTxRxPerDay';
  /** The date to which the data is grouped by. */
  dateString: Scalars['String']['output'];
  /** The IP address of the client. */
  ip: Scalars['String']['output'];
  /** The MAC address of the client. */
  macAddress: Scalars['MacAddress']['output'];
  /** The total number of bytes received by the client on this date. */
  rxBytes: Scalars['Int']['output'];
  /** The total number of bytes transmitted by the client on this date. */
  txBytes: Scalars['Int']['output'];
};

export type NetworkClientsFilter = {
  /**
   * If provided, the latest connected clients for a network are returned
   * that were reported to be connected to the given AP serial number.
   * At most one of `macAddress`, `apSerialNumber`, `vlanID`, or `ssid` can be provided.
   */
  apSerialNumber?: InputMaybe<Scalars['String']['input']>;
  /** If true, clients identified as Meter hardware devices will be excluded from the results. */
  excludeMeterHardware?: Scalars['Boolean']['input'];
  /**
   * If provided, then the ping latency from controller to client is included
   * in the response.
   */
  includeLatency?: Scalars['Boolean']['input'];
  /**
   * If provided, the average usage for all returned clients in the last
   * 30 minutes will be included in the response. If macAddress is provided this field has no effect.
   */
  includeThroughput?: Scalars['Boolean']['input'];
  /**
   * When no macAddress and apSerialNumber are provided, filters resulting clients' lastSeen
   * to this many minutes before now.
   */
  lookbackMinutes?: Scalars['Int']['input'];
  /**
   * If a mac address is provided, this will get the history of clients with this mac address.
   * At most one of `macAddress`, `apSerialNumber`, `vlanID`, or `ssid` can be provided.
   */
  macAddress?: InputMaybe<Scalars['MacAddress']['input']>;
  /**
   * If provided, the latest connected clients for a network are returned
   * that were reported to be connected to the given SSID.
   * Only supported for config 2 COS networks.
   * At most one of `macAddress`, `apSerialNumber`, `vlanID`, or `ssid` can be provided.
   */
  ssid?: InputMaybe<Scalars['String']['input']>;
  /**
   * If provided, the latest connected clients for a network are returned
   * that were reported to be connected to the given VLAN.
   * Only supported for config 2 COS networks.
   * At most one of `macAddress`, `apSerialNumber`, `vlanID`, or `ssid` can be provided.
   */
  vlanID?: InputMaybe<Scalars['Int']['input']>;
};

/** An enumeration of possible network event types that can trigger notifications. */
export enum NetworkEventType {
  /** The status of an access point has changed. */
  AccessPointChangeStatus = 'ACCESS_POINT_CHANGE_STATUS',
  /** The status of the controller has changed. */
  ControllerChangeStatus = 'CONTROLLER_CHANGE_STATUS',
  /** The controller is offline. */
  ControllerOffline = 'CONTROLLER_OFFLINE',
  /** The network has failed over to an LTE connection. */
  LteFailover = 'LTE_FAILOVER',
  /** An unexpected access point has registered on the network. */
  UnexpectedAccessPointRegistration = 'UNEXPECTED_ACCESS_POINT_REGISTRATION',
  /** The VPN connection failed over. */
  VpnFailover = 'VPN_FAILOVER',
  /** The status of the WAN connection has changed. */
  WanChangeStatus = 'WAN_CHANGE_STATUS',
  /** The network has failed over to a another WAN connection. */
  WanFailover = 'WAN_FAILOVER',
}

/** An interface representing a network job. */
export type NetworkJob = {
  /** The unique identifier for the job. */
  UUID: Scalars['UUID']['output'];
  /** The time at which the job was completed. */
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The unique identifier of the network associated with this job. */
  networkUUID: Scalars['UUID']['output'];
  /** The time at which the job was scheduled. */
  scheduledAt: Scalars['DateTime']['output'];
};

/** Represents the onboarding process for a network. */
export type NetworkOnboarding = {
  __typename?: 'NetworkOnboarding';
  /** Unique identifier for the network onboarding entry. */
  UUID: Scalars['UUID']['output'];
  /** Timestamp when the network onboarding was created. */
  createdAt: Scalars['DateTime']['output'];
  /** Job data associated with the network onboarding. */
  jobData?: Maybe<NetworkOnboardingJobData>;
  /** Identifier of the associated job data. */
  jobDataID: Scalars['String']['output'];
  /** Unique identifier of the associated network. */
  networkUUID: Scalars['UUID']['output'];
  /** Timestamp when the network onboarding was last updated. */
  updatedAt: Scalars['DateTime']['output'];
};

/** Document containing information required for network onboarding. */
export type NetworkOnboardingDocument = {
  __typename?: 'NetworkOnboardingDocument';
  /** Unique identifier for the network onboarding document. */
  UUID: Scalars['UUID']['output'];
  /** Timestamp when the document was created. */
  createdAt: Scalars['DateTime']['output'];
  /** List of fields that have been delegated to other users for completion. */
  delegations: Array<NetworkOnboardingFieldDelegation>;
  /** JSON object containing the onboarding document data. */
  document: Scalars['JSONObject']['output'];
  /** Unique identifier of the associated network. */
  networkUUID: Scalars['UUID']['output'];
  /** Timestamp when the document was last updated. */
  updatedAt: Scalars['DateTime']['output'];
};

/** Represents the delegation of a specific field of the network onboarding document to a user. */
export type NetworkOnboardingFieldDelegation = {
  __typename?: 'NetworkOnboardingFieldDelegation';
  /** Unique identifier of the field delegation. */
  UUID: Scalars['UUID']['output'];
  /** Description of the delegation. */
  description?: Maybe<Scalars['String']['output']>;
  /** Email of the user to whom the field is delegated. */
  email: Scalars['String']['output'];
  /** Name of the field being delegated. */
  fieldName: Scalars['String']['output'];
  /** Unique identifier of the associated network. */
  networkUUID: Scalars['UUID']['output'];
};

/** Stores logistical data for tracking the installation and onboarding of a network. */
export type NetworkOnboardingJobData = {
  __typename?: 'NetworkOnboardingJobData';
  /** Full address of the network location, possibly including address lines, city, state, and ZIP code. */
  fullAddress?: Maybe<Array<Scalars['String']['output']>>;
  /** Unique identifier of the job data. */
  id: Scalars['String']['output'];
  /** End date of the installation. */
  installationEndDate?: Maybe<Scalars['DateTime']['output']>;
  /** Start date of the installation. */
  installationStartDate?: Maybe<Scalars['DateTime']['output']>;
  /** Identifier of the job. */
  jobID?: Maybe<Scalars['String']['output']>;
  /** User who owns the job. */
  jobOwnerUser?: Maybe<Scalars['String']['output']>;
  /** Current stage of the job. */
  jobStage?: Maybe<Scalars['String']['output']>;
  /** Current status of the job. */
  jobStatus?: Maybe<Scalars['String']['output']>;
  /** Names of the technicians assigned to the job. */
  jobTechnicianName?: Maybe<Array<Scalars['String']['output']>>;
  /** Phone numbers of the technicians assigned to the job. */
  jobTechnicianPhone?: Maybe<Array<Scalars['String']['output']>>;
  /** Code identifying the location. */
  locationCode?: Maybe<Scalars['String']['output']>;
  /** Information about the onsite contact person. */
  onsiteContactInformation?: Maybe<Scalars['String']['output']>;
  /** Links for scheduling a site survey. */
  scheduleSiteSurveyLink?: Maybe<Array<Scalars['String']['output']>>;
  /** Dates of the site survey.. */
  siteSurveyDate?: Maybe<Array<Scalars['String']['output']>>;
  /** Status updates of the site survey. */
  siteSurveyStatus?: Maybe<Array<Scalars['String']['output']>>;
  /** Target date for the network to go live. */
  targetGoLiveDate?: Maybe<Scalars['DateTime']['output']>;
  /** Total square footage of the network location. */
  totalSqFt?: Maybe<Array<Scalars['String']['output']>>;
};

/** A network route defines a route within the networking stack on a device. It can refer to either a physical interface, VLAN interface, or another Linux interface. */
export type NetworkRoute = {
  __typename?: 'NetworkRoute';
  /** The network attachment for the route; one of the attachment fields will be non-null. */
  attachment: NetworkRouteAttachment;
  /** The destination IP prefix of the network route. */
  destination: Scalars['IPPrefix']['output'];
  /** The gateway IP address for the network route. */
  gateway?: Maybe<Scalars['IPV4']['output']>;
};

/** One field of NetworkRouteAttachment will be non-null */
export type NetworkRouteAttachment = {
  __typename?: 'NetworkRouteAttachment';
  /** Attachment to an Auto VPN group. */
  autoVPNGroup?: Maybe<AutoVpnGroup>;
  /** Attachment to a Client VPN server. */
  clientVPNServer?: Maybe<ClientVpnServer>;
  /** Attachment to an IPSec tunnel. */
  ipSecTunnel?: Maybe<IpSecTunnel>;
  /** Attachment to a physical network interface. */
  phyInterface?: Maybe<PhyInterface>;
  /** Attachment to a static route. */
  staticRoute?: Maybe<StaticRoute>;
  /** Attachment to a VLAN interface. */
  vlan?: Maybe<Vlan>;
};

export type NetworkStatsFilter = {
  /** The lookback time to consider stats for, starting at `endTime`. Maximum allowed value is 7776000, 90 days. */
  durationSeconds: Scalars['Int']['input'];
  /** The latest observation time to consider stats for, inclusive. Defaults to the current time if not set. */
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** The maximum number of results to return, between 1 and 1000. Defaults to 100 if not set. */
  limit?: Scalars['Int']['input'];
  /** The number of results to skip before returning results. Defaults to 0 if not set. */
  offset?: Scalars['Int']['input'];
  /** The list of stat types to return. */
  types: Array<StatType>;
  /** The unique identifiers of the virtual devices to fetch stats for. */
  virtualDeviceUUIDs: Array<Scalars['UUID']['input']>;
};

/** The response containing quality metrics for a network uplink. */
export type NetworkUplinkQualityResponse = MetricsResponse & {
  __typename?: 'NetworkUplinkQualityResponse';
  /** Metadata about the metrics. */
  metadata?: Maybe<MetricsMetadata>;
  /** A list of quality metric values. */
  values: Array<NetworkUplinkQualityValue>;
};

/** Represents the quality metrics value for a network uplink. */
export type NetworkUplinkQualityValue = MetricsValue & {
  __typename?: 'NetworkUplinkQualityValue';
  /**
   * The UUID of the physical interface associated with this quality metric.
   * Note: Since there are many data points, we reference the physical interface by UUID instead of embedding it.
   */
  phyInterfaceUUID: Scalars['UUID']['output'];
  /** The timestamp when the metric was recorded. */
  timestamp: Scalars['DateTime']['output'];
  /** The quality value measured at the given timestamp, typically represented as a percentage of successful pings. */
  value: Scalars['Float']['output'];
};

/** The response containing throughput metrics for a network uplink. */
export type NetworkUplinkThroughputMetricsResponse = MetricsResponse & {
  __typename?: 'NetworkUplinkThroughputMetricsResponse';
  /** Metadata about the metrics, such as time range and aggregation interval. */
  metadata?: Maybe<MetricsMetadata>;
  /** A list of throughput metric values. */
  values: Array<NetworkUplinkThroughputMetricsValue>;
};

/** Represents the throughput metrics value for a network uplink. */
export type NetworkUplinkThroughputMetricsValue = MetricsValue & {
  __typename?: 'NetworkUplinkThroughputMetricsValue';
  /** Indicates the direction of the traffic. */
  direction: TrafficDirection;
  /**
   * The UUID of the physical interface associated with this throughput metric.
   * Note: Since there are many data points, we reference the physical interface by UUID instead of embedding it.
   */
  phyInterfaceUUID: Scalars['UUID']['output'];
  /** The timestamp when the metric was recorded. */
  timestamp: Scalars['DateTime']['output'];
  /** The throughput value measured at the given timestamp, in bits per second. */
  value: Scalars['Float']['output'];
};

/** Filter for boolean fields in networks. */
export type NetworksBooleanFilter = {
  /** Equals comparison for boolean fields. */
  eq: Scalars['Boolean']['input'];
};

/** Filter networks by company support tiers. */
export type NetworksCompanySupportTierFilter = {
  /** Company support tiers to include. */
  in: Array<CompanySupportTier>;
  /** Invert the filter. */
  not?: Scalars['Boolean']['input'];
};

/** Filter networks by date-time fields. */
export type NetworksDateTimeFilter = {
  /** Greater than comparison. */
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Less than comparison. */
  lt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Input type for filtering networks based on various criteria. */
export type NetworksFilter = {
  /** Filter networks by whether they are active. */
  active?: InputMaybe<NetworksBooleanFilter>;
  /** Filter networks by company support tier. */
  companySupportTier?: InputMaybe<NetworksCompanySupportTierFilter>;
  /** Filter networks by hardware model. */
  hardwareModel?: InputMaybe<NetworksHardwareModelFilter>;
  /** Filter networks by last upgrade date. */
  lastUpgradedAt?: InputMaybe<NetworksDateTimeFilter>;
  mailingAddressSquareFeet?: InputMaybe<NetworksIntFilter>;
  /** Filter networks by NOS version ID. */
  nosVersionID?: InputMaybe<NetworksNosVersionIdFilter>;
  /** Filter networks by whether they have a pending upgrade. */
  pendingUpgrade?: InputMaybe<NetworksBooleanFilter>;
  /** Filter networks by upgrade sensitivity. */
  upgradeSensitivity?: InputMaybe<NetworksBooleanFilter>;
};

/** Filter networks by hardware models. */
export type NetworksHardwareModelFilter = {
  /** Hardware models to include. */
  in: Array<DeviceModel>;
  /** Invert the filter */
  not?: Scalars['Boolean']['input'];
};

export type NetworksIntFilter = {
  gt?: InputMaybe<Scalars['Int']['input']>;
  lt?: InputMaybe<Scalars['Int']['input']>;
};

/** Filter networks by NOS version IDs. */
export type NetworksNosVersionIdFilter = {
  /** NOS version IDs to include. */
  in: Array<Scalars['Int']['input']>;
  /** Invert the filter. */
  not?: Scalars['Boolean']['input'];
};

/** A NOS feature is a feature flag that tells us what minimum/maximum version of NOS firmware is needed to be installed on the network to support a given feature. */
export type NosFeature = {
  __typename?: 'NosFeature';
  /** A detailed description of the NOS feature. */
  description: Scalars['String']['output'];
  /** If the feature is also behind a feature flag, this is the name of the feature flag. */
  featureFlag?: Maybe<Scalars['String']['output']>;
  /** A unique key that identifies the NOS feature. */
  key: Scalars['String']['output'];
  /** The maximum major version of NOS firmware that supports this feature. */
  maxMajorVersion?: Maybe<Scalars['Int']['output']>;
  /** The maximum minor version of NOS firmware that supports this feature. */
  maxMinorVersion?: Maybe<Scalars['Int']['output']>;
  /** The maximum patch version of NOS firmware that supports this feature. */
  maxPatchVersion?: Maybe<Scalars['Int']['output']>;
  /** The minimum major version of NOS firmware required to support this feature. */
  minMajorVersion: Scalars['Int']['output'];
  /** The minimum minor version of NOS firmware required to support this feature. */
  minMinorVersion: Scalars['Int']['output'];
  /** The minimum patch version of NOS firmware required to support this feature. */
  minPatchVersion: Scalars['Int']['output'];
  /** The display name of the NOS feature. */
  name: Scalars['String']['output'];
  /** Unique identifier of the NOS feature. */
  uuid: Scalars['UUID']['output'];
};

/** Represents whether a NOS feature is enabled or disabled. */
export type NosFeatureEnabled = {
  __typename?: 'NosFeatureEnabled';
  /** Indicates whether the feature is enabled or disabled. */
  enabled: Scalars['Boolean']['output'];
  /** A unique key that identifies the NOS feature. */
  key: Scalars['String']['output'];
};

/** A NOS (Network Operating System) version is a grouping of build strings for various device types. Together they constitute a network-wide firmware release. */
export type NosVersion = {
  __typename?: 'NosVersion';
  /** Timestamp when the NOS version was created. */
  createdAt: Scalars['DateTime']['output'];
  /** Unique identifier for the NOS version. */
  id: Scalars['Int']['output'];
  /** Indicates if this NOS version is the default version for new networks. */
  isDefault: Scalars['Boolean']['output'];
  /** Indicates whether the NOS version is deprecated and should no longer be used. */
  isDeprecated: Scalars['Boolean']['output'];
  /** Indicates whether the NOS version is Generally Available (GA) for all users. */
  isGA: Scalars['Boolean']['output'];
  /** Indicates whether the NOS version is locked and cannot be modified or deleted. */
  isLocked: Scalars['Boolean']['output'];
  /** Major version number component of the NOS version. */
  major: Scalars['Int']['output'];
  /** Build string for the 'mc01' controller device type. */
  mc01Build?: Maybe<Scalars['String']['output']>;
  /** Minor version number component of the NOS version. */
  minor: Scalars['Int']['output'];
  /** Build string for the 'mp01' power distribution unit device type. */
  mp01Build?: Maybe<Scalars['String']['output']>;
  /** Build string for the 'ms10' switch device type. */
  ms10Build?: Maybe<Scalars['String']['output']>;
  /** Build string for the 'ms11' switch device type. */
  ms11Build?: Maybe<Scalars['String']['output']>;
  /** Build string for the 'ms12' switch device type. */
  ms12Build?: Maybe<Scalars['String']['output']>;
  /** Build string for the 'mw03' wireless device type. */
  mw03Build?: Maybe<Scalars['String']['output']>;
  /** Build string for the 'mw04' wireless device type. */
  mw04Build?: Maybe<Scalars['String']['output']>;
  /** Build string for the 'mw06' wireless device type. */
  mw06Build?: Maybe<Scalars['String']['output']>;
  /** Build string for the 'mw07' wireless device type. */
  mw07Build?: Maybe<Scalars['String']['output']>;
  /** Build string for the 'mw08' wireless device type. */
  mw08Build?: Maybe<Scalars['String']['output']>;
  /** Build string for the 'mw09' wireless device type. */
  mw09Build?: Maybe<Scalars['String']['output']>;
  /** Optional name for the NOS version, used for display purposes. */
  name?: Maybe<Scalars['String']['output']>;
  /** Build string for the 'observer' device type. */
  observerBuild?: Maybe<Scalars['String']['output']>;
  /** Patch version number component of the NOS version. */
  patch: Scalars['Int']['output'];
  /** Release notes or description associated with the NOS version. */
  releaseNotes?: Maybe<Scalars['String']['output']>;
  /** Timestamp when the NOS version was last updated. */
  updatedAt: Scalars['DateTime']['output'];
  /** String representing the overall version of the NOS, typically in 'major.minor.patch' format. */
  version: Scalars['String']['output'];
};

/** Input data for creating or updating a NOS version. */
export type NosVersionInput = {
  /** Indicates if this NOS version should be set as the default version for new networks. */
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  /** Indicates whether the NOS version is deprecated and should no longer be used. */
  isDeprecated?: InputMaybe<Scalars['Boolean']['input']>;
  /** Indicates whether the NOS version is Generally Available (GA) for all users. */
  isGA?: InputMaybe<Scalars['Boolean']['input']>;
  /** Indicates whether the NOS version is locked and cannot be modified or deleted. */
  isLocked?: InputMaybe<Scalars['Boolean']['input']>;
  /** Major version number component of the NOS version. */
  major?: InputMaybe<Scalars['Int']['input']>;
  /** Build string for the 'mc01' controller device type. */
  mc01Build?: InputMaybe<Scalars['String']['input']>;
  /** Minor version number component of the NOS version. */
  minor?: InputMaybe<Scalars['Int']['input']>;
  /** Build string for the 'mp01' power distribution unit device type. */
  mp01Build?: InputMaybe<Scalars['String']['input']>;
  /** Build string for the 'ms10' switch device type. */
  ms10Build?: InputMaybe<Scalars['String']['input']>;
  /** Build string for the 'ms11' switch device type. */
  ms11Build?: InputMaybe<Scalars['String']['input']>;
  /** Build string for the 'ms12' switch device type. */
  ms12Build?: InputMaybe<Scalars['String']['input']>;
  /** Build string for the 'mw03' wireless device type. */
  mw03Build?: InputMaybe<Scalars['String']['input']>;
  /** Build string for the 'mw04' wireless device type. */
  mw04Build?: InputMaybe<Scalars['String']['input']>;
  /** Build string for the 'mw06' wireless device type. */
  mw06Build?: InputMaybe<Scalars['String']['input']>;
  /** Build string for the 'mw07' wireless device type. */
  mw07Build?: InputMaybe<Scalars['String']['input']>;
  /** Build string for the 'mw08' wireless device type. */
  mw08Build?: InputMaybe<Scalars['String']['input']>;
  /** Build string for the 'mw09' wireless device type. */
  mw09Build?: InputMaybe<Scalars['String']['input']>;
  /** Optional name for the NOS version, used for display purposes. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Build string for the 'observer' device type. */
  observerBuild?: InputMaybe<Scalars['String']['input']>;
  /** Patch version number component of the NOS version. */
  patch?: InputMaybe<Scalars['Int']['input']>;
  /** Release notes or description associated with the NOS version. */
  releaseNotes?: InputMaybe<Scalars['String']['input']>;
  /** String representing the overall version of the NOS, typically in 'major.minor.patch' format. */
  version: Scalars['String']['input'];
};

/** Input type for creating or updating notification preferences. */
export type NotificationPreferenceInput = {
  /** List of additional network event types to receive notifications for. */
  additionalEventTypes?: InputMaybe<Array<NetworkEventType>>;
  /** List of additional Slack channels to receive notifications. */
  additionalSlackChannels?: InputMaybe<Array<Scalars['String']['input']>>;
  /** List of email addresses to receive notifications. */
  emailAddresses?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** This feature is deprecated. Represents the notification preferences for a company or network. */
export type NotificationPreferences = {
  __typename?: 'NotificationPreferences';
  /** List of additional network event types to receive notifications for. */
  additionalEventTypes?: Maybe<Array<NetworkEventType>>;
  /** List of additional Slack channels to receive notifications. */
  additionalSlackChannels?: Maybe<Array<Scalars['String']['output']>>;
  /** Company associated with the notification preference. */
  company: Company;
  /** Unique identifier of the company associated with the notification preference. */
  companySID: Scalars['UUID']['output'];
  /** Timestamp when the notification preference was created. */
  createdAt: Scalars['DateTime']['output'];
  /** List of email addresses to receive notifications. */
  emailAddresses?: Maybe<Array<Scalars['String']['output']>>;
  /** Unique identifier of the network associated with the notification preference. May be null if the preference is for a company. */
  networkUUID?: Maybe<Scalars['UUID']['output']>;
  /** Timestamp when the notification preference was last updated. */
  updatedAt: Scalars['DateTime']['output'];
  /** Unique identifier for the notification preference. */
  uuid: Scalars['UUID']['output'];
};

/** Response containing notification preferences at both the company and network levels. */
export type NotificationPreferencesResponse = {
  __typename?: 'NotificationPreferencesResponse';
  /** Notification preferences set at the company level. */
  companyPreferences?: Maybe<NotificationPreferences>;
  /** Notification preferences set at the network level. */
  networkPreferences?: Maybe<NotificationPreferences>;
};

/**
 * Notification settings contain preferences for how a user should be notified when events happen on a network.
 * They specify a receiver of the alert, the delivery mechanism, the type of alert, and alert-specific metadata to provide further context.
 */
export type NotificationSetting = {
  __typename?: 'NotificationSetting';
  /** The unique identifier of the notification setting. */
  UUID: Scalars['UUID']['output'];
  /** The UUID of the alert receiver who will receive the notifications generated by this setting. */
  alertReceiverUUID: Scalars['UUID']['output'];
  /** A list of arguments providing specific parameters or context required by the notification definition. */
  arguments: Array<NotificationSettingArgument>;
  /** The timestamp when the notification setting was created. */
  createdAt: Scalars['DateTime']['output'];
  /** The ID of the user who created this notification setting. */
  createdBy?: Maybe<Scalars['Int']['output']>;
  /** The timestamp when the notification setting was deleted. */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Indicates whether the notification setting is enabled. */
  isEnabled: Scalars['Boolean']['output'];
  /** The UUID of the network to which this notification setting belongs. */
  networkUUID: Scalars['UUID']['output'];
  /** The AlertDefinition associated with this notification setting, providing details about the alert type and parameters. */
  notificationDefinition?: Maybe<AlertDefinition>;
  /** The unique name of the notification definition that defines the type of alert for this setting. */
  notificationDefinitionName: Scalars['String']['output'];
  /** The timestamp when the notification setting was last updated. */
  updatedAt: Scalars['DateTime']['output'];
  /** The ID of the user who last updated this notification setting. */
  updatedBy?: Maybe<Scalars['Int']['output']>;
};

/** An argument for a notification setting, providing a value for a specific parameter required by the notification definition. */
export type NotificationSettingArgument = {
  __typename?: 'NotificationSettingArgument';
  /** The UUID of the parameter this argument corresponds to. */
  parameterUUID: Scalars['UUID']['output'];
  /** The value assigned to this parameter in the notification setting. */
  value: Scalars['Any']['output'];
};

/** Input type for specifying arguments when creating or updating a notification setting. */
export type NotificationSettingArgumentInput = {
  /** The UUID of the parameter for which the value is being provided. */
  parameterUUID: Scalars['UUID']['input'];
  /** Value is required. Only marked as nullable as a workaround for client zod validation. */
  value?: InputMaybe<Scalars['Any']['input']>;
};

/** An observer virtual device is a logical representation of a physical observer. */
export type ObserverVirtualDevice = VirtualDevice & {
  __typename?: 'ObserverVirtualDevice';
  /** The unique identifier (UUID) of the virtual device. */
  UUID: Scalars['UUID']['output'];
  /** The timestamp when the virtual device was first created in the system. */
  createdAt: Scalars['DateTime']['output'];
  /** An optional description for the virtual device. */
  description?: Maybe<Scalars['String']['output']>;
  /** The model name of the virtual device. */
  deviceModel: DeviceModel;
  /** The type of the virtual device. */
  deviceType: VirtualDeviceType;
  /** The hardware device associated with this virtual device, if any. */
  hardwareDevice?: Maybe<HardwareDevice>;
  /** Indicates whether the virtual device's console port is enabled. */
  isConsoleEnabled: Scalars['Boolean']['output'];
  /** Indicates whether debug logging is enabled on the virtual device. */
  isDebugLogEnabled: Scalars['Boolean']['output'];
  /** Indicates whether LED dark mode is enabled on the observer, which turns off all LED activity. */
  isLEDDarkModeEnabled: Scalars['Boolean']['output'];
  /** A human-friendly name for the device. */
  label: Scalars['String']['output'];
  /** The network that the virtual device belongs to. */
  network: Network;
  /** The unique identifier (UUID) of the network the virtual device belongs to. */
  networkUUID: Scalars['UUID']['output'];
  /** The current NOS firmware version that the virtual device is configured to run. */
  nosVersion?: Maybe<ConfiguredNosVersion>;
  /** The identifier of the NOS firmware version that the virtual device is configured to run. */
  nosVersionID: Scalars['Int']['output'];
  /** The NOS firmware version that the virtual device is scheduled to upgrade to. */
  pendingNosVersion?: Maybe<PendingNosVersion>;
  /** The timestamp when the virtual device was last updated in the system. */
  updatedAt: Scalars['DateTime']['output'];
  /** The uptime from our time series database for the device. */
  uptime?: Maybe<Scalars['Duration']['output']>;
};

/** 1:1 NAT Rules are used to map a single external IP address to a single internal IP address. */
export type OneToOneNatRule = {
  __typename?: 'OneToOneNATRule';
  /** Unique identifier of the 1:1 NAT rule. */
  UUID: Scalars['UUID']['output'];
  /** Timestamp when the NAT rule was created. */
  createdAt: Scalars['DateTime']['output'];
  /** Optional description of the 1:1 NAT rule. */
  description?: Maybe<Scalars['String']['output']>;
  /** The external IP address that is mapped by the NAT rule. */
  externalIPAddr: Scalars['IPV4']['output'];
  /** Physical interface associated with the external IP address. */
  externalPhyInterface: PhyInterface;
  /** UUID of the physical interface associated with the external IP address. */
  externalPhyInterfaceUUID: Scalars['UUID']['output'];
  /** The internal IP address that the external IP address is mapped to. */
  internalIPAddr: Scalars['IPV4']['output'];
  /** Indicates whether the NAT rule is currently enabled. */
  isEnabled: Scalars['Boolean']['output'];
  /** Name of the 1:1 NAT rule. */
  name: Scalars['String']['output'];
  /** UUID of the network that this NAT rule applies to. */
  networkUUID: Scalars['UUID']['output'];
  /** Timestamp when the NAT rule was last updated. */
  updatedAt: Scalars['DateTime']['output'];
};

/** Specifies settings for a power outlet on a Power Distribution Unit (PDU). */
export type OutletInterface = {
  __typename?: 'OutletInterface';
  /** Globally unique identifier for the outlet interface. */
  UUID: Scalars['UUID']['output'];
  /** The virtual device connected to the power outlet. */
  connectedVirtualDevice?: Maybe<VirtualDevice>;
  /** UUID of the virtual device connected to the power outlet. */
  connectedVirtualDeviceUUID?: Maybe<Scalars['UUID']['output']>;
  /** Timestamp when the outlet interface was created. */
  createdAt: Scalars['DateTime']['output'];
  /** The current being drawn from the outlet in Amperes. */
  currentAmps?: Maybe<Scalars['Float']['output']>;
  /** Description of the outlet interface. */
  description?: Maybe<Scalars['String']['output']>;
  /** Whether or not the interface is enabled. */
  isEnabled: Scalars['Boolean']['output'];
  /** User-defined label for the outlet interface. */
  label?: Maybe<Scalars['String']['output']>;
  /** Number identifying the outlet on the PDU. */
  outletNumber: Scalars['Int']['output'];
  /**
   * Number of seconds to wait when performing a power cycle.
   * The outlet turns off, waits for this duration, and then turns back on.
   */
  powerCycleDelaySec: Scalars['Int']['output'];
  /** The power being drawn from the outlet in Watts. */
  powerWatts?: Maybe<Scalars['Float']['output']>;
  /** Timestamp when the outlet interface was last updated. */
  updatedAt: Scalars['DateTime']['output'];
  /** The virtual device associated with this outlet interface. */
  virtualDevice?: Maybe<VirtualDevice>;
  /** UUID of the virtual device associated with this outlet interface. */
  virtualDeviceUUID: Scalars['UUID']['output'];
};

/** Specifies the NOS firmware version that a device is scheduled to upgrade to. */
export type PendingNosVersion = {
  __typename?: 'PendingNosVersion';
  /** The NOS firmware version that the device is scheduled to upgrade to. */
  nosVersion: NosVersion;
  /** The timestamp when the device is scheduled to upgrade to the specified NOS version. */
  scheduledAt: Scalars['DateTime']['output'];
};

/** An enumeration of v1 permissions (not RBAC) that can be assigned to tokens. */
export enum Permission {
  Admin = 'ADMIN',
  Api = 'API',
  Bastion = 'BASTION',
  Ci = 'CI',
  Cmd = 'CMD',
  Connect = 'CONNECT',
  ConnectAdmin = 'CONNECT_ADMIN',
  Controller = 'CONTROLLER',
  Dashboard = 'DASHBOARD',
  InventoryApp = 'INVENTORY_APP',
  Jumphost = 'JUMPHOST',
  Logs = 'LOGS',
  Noc = 'NOC',
  Ops = 'OPS',
  Portal = 'PORTAL',
  ProvisionController = 'PROVISION_CONTROLLER',
  Rpcbroker = 'RPCBROKER',
  Stats = 'STATS',
  StatConsumer = 'STAT_CONSUMER',
  StatUpload = 'STAT_UPLOAD',
  Worker = 'WORKER',
}

/**
 * A PermissionAssignment denotes the permissions of a user in the context of a company or company & network.
 *
 * In the case of an Operator role, the networkUUID field will be null.
 */
export type PermissionAssignment = {
  __typename?: 'PermissionAssignment';
  /**
   * The UUID of the network associated with these permissions.
   *
   * Null if the permissions are not network-specific or are company-wide.
   */
  networkUUID?: Maybe<Scalars['UUID']['output']>;
  /**
   * The list of permissions assigned to the user in this context.
   *
   * PermissionType represents the specific permissions granted.
   */
  permissions: Array<PermissionType>;
};

/** Result type indicating whether the user has the specified permission. */
export type PermissionCheckResult = {
  __typename?: 'PermissionCheckResult';
  /** True if the user has the specified permission, false otherwise. */
  hasPermission: Scalars['Boolean']['output'];
};

/**
 * Code generated by mctl. DO NOT EDIT.
 * permissions are generated from src/auth/permissions/permissions.go
 */
export enum PermissionType {
  PermAlertReceiverRead = 'PERM_ALERT___RECEIVER_READ',
  PermAlertReceiverWrite = 'PERM_ALERT___RECEIVER_WRITE',
  PermAuditLogRead = 'PERM_AUDIT___LOG_READ',
  PermAuditLogWrite = 'PERM_AUDIT___LOG_WRITE',
  PermAuthenticationTokenRead = 'PERM_AUTHENTICATION___TOKEN_READ',
  PermAuthenticationTokenWrite = 'PERM_AUTHENTICATION___TOKEN_WRITE',
  PermAutoSelectionJobRead = 'PERM_AUTO___SELECTION___JOB_READ',
  PermAutoSelectionJobWrite = 'PERM_AUTO___SELECTION___JOB_WRITE',
  PermAutoVpnRead = 'PERM_AUTO___VPN_READ',
  PermAutoVpnWrite = 'PERM_AUTO___VPN_WRITE',
  PermBearerSessionWrite = 'PERM_BEARER___SESSION_WRITE',
  PermBulkNosUpgradeRead = 'PERM_BULK___NOS___UPGRADE_READ',
  PermBulkNosUpgradeWrite = 'PERM_BULK___NOS___UPGRADE_WRITE',
  PermCaptivePortalRead = 'PERM_CAPTIVE___PORTAL_READ',
  PermCaptivePortalWrite = 'PERM_CAPTIVE___PORTAL_WRITE',
  PermCellularWrite = 'PERM_CELLULAR_WRITE',
  PermClientVpnClientRead = 'PERM_CLIENT___VPN_CLIENT_READ',
  PermClientVpnClientWrite = 'PERM_CLIENT___VPN_CLIENT_WRITE',
  PermClientVpnRead = 'PERM_CLIENT___VPN_READ',
  PermClientVpnWrite = 'PERM_CLIENT___VPN_WRITE',
  PermCompanyAdminAssign = 'PERM_COMPANY_ADMIN_ASSIGN',
  PermCompanyGet = 'PERM_COMPANY_GET',
  PermCompanyGetRestricted = 'PERM_COMPANY_GET_RESTRICTED',
  PermCompanyList = 'PERM_COMPANY_LIST',
  PermCompanyNetworkAdminAssign = 'PERM_COMPANY_NETWORK___ADMIN_ASSIGN',
  PermCompanySsoRead = 'PERM_COMPANY_SSO_READ',
  PermCompanySsoWrite = 'PERM_COMPANY_SSO_WRITE',
  PermCompanySupportTierReadRestricted = 'PERM_COMPANY_SUPPORT___TIER_READ_RESTRICTED',
  PermCompanyUpdate = 'PERM_COMPANY_UPDATE',
  PermCompanyUsersViewMeterEmployees = 'PERM_COMPANY_USERS_VIEW__METER__EMPLOYEES',
  PermCompanyUserAssign = 'PERM_COMPANY_USER_ASSIGN',
  PermCompanyWriteRestricted = 'PERM_COMPANY_WRITE_RESTRICTED',
  /** Allows a user to access the dashboard. */
  PermDashboardAccess = 'PERM_DASHBOARD_ACCESS',
  PermDhcpDnsRead = 'PERM_DHCP___DNS_READ',
  PermDhcpDnsWrite = 'PERM_DHCP___DNS_WRITE',
  PermEventLogRead = 'PERM_EVENT___LOG_READ',
  PermFirewallRead = 'PERM_FIREWALL_READ',
  PermFirewallWrite = 'PERM_FIREWALL_WRITE',
  PermFirewallWriteRestricted = 'PERM_FIREWALL_WRITE_RESTRICTED',
  PermFloorPlanRead = 'PERM_FLOOR___PLAN_READ',
  PermFloorPlanWrite = 'PERM_FLOOR___PLAN_WRITE',
  PermHardwareDeviceAssign = 'PERM_HARDWARE___DEVICE_ASSIGN',
  PermHardwareDeviceDevWrite = 'PERM_HARDWARE___DEVICE_DEV_WRITE',
  PermHardwareDeviceLogin = 'PERM_HARDWARE___DEVICE_LOGIN',
  PermHardwareDeviceRead = 'PERM_HARDWARE___DEVICE_READ',
  PermHardwareDeviceReadLimited = 'PERM_HARDWARE___DEVICE_READ_LIMITED',
  PermHardwareDeviceRpcBlinkLeds = 'PERM_HARDWARE___DEVICE_RPC_BLINK___LEDS',
  PermHardwareDeviceRpcCableTest = 'PERM_HARDWARE___DEVICE_RPC_CABLE___TEST',
  PermHardwareDeviceRpcPortCycle = 'PERM_HARDWARE___DEVICE_RPC_PORT___CYCLE',
  PermHardwareDeviceRpcRefreshConfig = 'PERM_HARDWARE___DEVICE_RPC_REFRESH___CONFIG',
  PermHighAvailabilityRead = 'PERM_HIGH___AVAILABILITY_READ',
  PermHighAvailabilityWrite = 'PERM_HIGH___AVAILABILITY_WRITE',
  PermHostMonitoringRead = 'PERM_HOST___MONITORING_READ',
  PermHostMonitoringWrite = 'PERM_HOST___MONITORING_WRITE',
  PermIpsecTunnelRead = 'PERM_IPSEC___TUNNEL_READ',
  PermIpsecTunnelWrite = 'PERM_IPSEC___TUNNEL_WRITE',
  PermIspRead = 'PERM_ISP_READ',
  PermIspWrite = 'PERM_ISP_WRITE',
  PermIspWriteRestricted = 'PERM_ISP_WRITE_RESTRICTED',
  PermMacAddressAliasRead = 'PERM_MAC___ADDRESS___ALIAS_READ',
  PermMacAddressAliasWrite = 'PERM_MAC___ADDRESS___ALIAS_WRITE',
  PermMaintenanceWindowRead = 'PERM_MAINTENANCE___WINDOW_READ',
  PermMaintenanceWindowWrite = 'PERM_MAINTENANCE___WINDOW_WRITE',
  PermNetworkInternalNoteRead = 'PERM_NETWORK_INTERNAL___NOTE_READ',
  PermNetworkInternalNoteWrite = 'PERM_NETWORK_INTERNAL___NOTE_WRITE',
  PermNetworkMetricsRead = 'PERM_NETWORK_METRICS_READ',
  PermNetworkNosWrite = 'PERM_NETWORK_NOS_WRITE',
  PermNetworkOnboardingRead = 'PERM_NETWORK_ONBOARDING_READ',
  PermNetworkOnboardingWrite = 'PERM_NETWORK_ONBOARDING_WRITE',
  PermNetworkOnboardingWriteRestricted = 'PERM_NETWORK_ONBOARDING_WRITE_RESTRICTED',
  PermNetworkRead = 'PERM_NETWORK_READ',
  PermNetworkReadLimited = 'PERM_NETWORK_READ_LIMITED',
  PermNetworkReadRestricted = 'PERM_NETWORK_READ_RESTRICTED',
  PermNetworkSettingsRead = 'PERM_NETWORK_SETTINGS_READ',
  PermNetworkSettingsUpdate = 'PERM_NETWORK_SETTINGS_UPDATE',
  PermNetworkSettingsWrite = 'PERM_NETWORK_SETTINGS_WRITE',
  PermNetworkSshRead = 'PERM_NETWORK_SSH_READ',
  PermNetworkSshWrite = 'PERM_NETWORK_SSH_WRITE',
  PermNetworkWriteRestricted = 'PERM_NETWORK_WRITE_RESTRICTED',
  PermNetworkDevicesRead = 'PERM_NETWORK___DEVICES_READ',
  PermNetworkDevicesReadRestricted = 'PERM_NETWORK___DEVICES_READ_RESTRICTED',
  PermNetworkDevicesWrite = 'PERM_NETWORK___DEVICES_WRITE',
  PermNetworkDevicesWriteRestricted = 'PERM_NETWORK___DEVICES_WRITE_RESTRICTED',
  PermNetworkRouteRead = 'PERM_NETWORK___ROUTE_READ',
  PermNetworkRouteWrite = 'PERM_NETWORK___ROUTE_WRITE',
  PermNosRead = 'PERM_NOS_READ',
  PermNosWrite = 'PERM_NOS_WRITE',
  PermNosFeatureRead = 'PERM_NOS___FEATURE_READ',
  PermNosFeatureWrite = 'PERM_NOS___FEATURE_WRITE',
  PermNotificationPreferenceRead = 'PERM_NOTIFICATION___PREFERENCE_READ',
  PermNotificationPreferenceWrite = 'PERM_NOTIFICATION___PREFERENCE_WRITE',
  PermOneToOneNatRead = 'PERM_ONE___TO___ONE___NAT_READ',
  PermOneToOneNatWrite = 'PERM_ONE___TO___ONE___NAT_WRITE',
  PermOperatorAssign = 'PERM_OPERATOR_ASSIGN',
  PermPermittedEmailDomainRead = 'PERM_PERMITTED___EMAIL___DOMAIN_READ',
  PermPermittedEmailDomainWrite = 'PERM_PERMITTED___EMAIL___DOMAIN_WRITE',
  PermPhyInterfaceRead = 'PERM_PHY___INTERFACE_READ',
  PermPhyInterfaceReadLimited = 'PERM_PHY___INTERFACE_READ_LIMITED',
  PermPhyInterfaceWrite = 'PERM_PHY___INTERFACE_WRITE',
  PermPolicyRoutingRead = 'PERM_POLICY___ROUTING_READ',
  PermPolicyRoutingWrite = 'PERM_POLICY___ROUTING_WRITE',
  PermPortForwardRead = 'PERM_PORT___FORWARD_READ',
  PermPortForwardWrite = 'PERM_PORT___FORWARD_WRITE',
  PermRackElevationRead = 'PERM_RACK___ELEVATION_READ',
  PermRackElevationWrite = 'PERM_RACK___ELEVATION_WRITE',
  PermRadioProfileRead = 'PERM_RADIO___PROFILE_READ',
  PermRadioProfileWrite = 'PERM_RADIO___PROFILE_WRITE',
  PermRateLimitRead = 'PERM_RATE___LIMIT_READ',
  PermRateLimitWrite = 'PERM_RATE___LIMIT_WRITE',
  PermSecurityApplianceProfileRead = 'PERM_SECURITY___APPLIANCE___PROFILE_READ',
  PermSecurityApplianceProfileWrite = 'PERM_SECURITY___APPLIANCE___PROFILE_WRITE',
  PermSsidsRead = 'PERM_SSIDS_READ',
  PermSsidsWrite = 'PERM_SSIDS_WRITE',
  PermStatEventWrite = 'PERM_STAT___EVENT_WRITE',
  PermSwitchProfileRead = 'PERM_SWITCH___PROFILE_READ',
  PermSwitchProfileWrite = 'PERM_SWITCH___PROFILE_WRITE',
  PermUserCreate = 'PERM_USER_CREATE',
  PermUserRead = 'PERM_USER_READ',
  PermUserUpdate = 'PERM_USER_UPDATE',
  PermVirtualDeviceCreate = 'PERM_VIRTUAL___DEVICE_CREATE',
  PermVirtualDeviceReadLimited = 'PERM_VIRTUAL___DEVICE_READ_LIMITED',
  PermVlanRead = 'PERM_VLAN_READ',
  PermVlanWrite = 'PERM_VLAN_WRITE',
  PermVlanWriteRestricted = 'PERM_VLAN_WRITE_RESTRICTED',
}

/** Input type specifying the context for fetching a user's permissions. */
export type PermissionsInput = {
  /**
   * The slug of the company to fetch permissions for.
   *
   * Required if permissions are company-specific.
   */
  companySlug?: InputMaybe<Scalars['String']['input']>;
  /**
   * The UUID of the network to fetch permissions for.
   *
   * Provide this if fetching network-specific permissions.
   */
  networkUUID?: InputMaybe<Scalars['UUID']['input']>;
};

/** Represents an email domain that is permitted for authentication for a company. */
export type PermittedEmailDomain = {
  __typename?: 'PermittedEmailDomain';
  /** The timestamp when the permitted email domain was created. */
  createdAt: Scalars['DateTime']['output'];
  /** The email domain that is permitted (e.g., "example.com"). */
  domain: Scalars['String']['output'];
  /** The unique UUID identifier for the permitted email domain. */
  sid?: Maybe<Scalars['UUID']['output']>;
};

export type PhyInterface = {
  __typename?: 'PhyInterface';
  /** The maximum transmission unit (MTU) - max size in bytes of frames that the port can transmit/receive. */
  MTU?: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for the physical interface. */
  UUID: Scalars['UUID']['output'];
  /** The allowed (tagged) VLANs that are permitted to have traffic on this interface. */
  allowedVLANs?: Maybe<Array<Vlan>>;
  /** Devices connected to this physical interface. */
  connectedDevices?: Maybe<Array<PhyInterfaceConnectedDevice>>;
  /** The date and time when the physical interface was created. */
  createdAt: Scalars['DateTime']['output'];
  /** A description of the physical interface. */
  description?: Maybe<Scalars['String']['output']>;
  /** The maximum amount of data that can be transmitted through the interface, in Mbps. */
  forcedPortSpeedMbps?: Maybe<Scalars['Int']['output']>;
  /** An optional filter to only accept certain frame types on this port. If null, all frame types are accepted. */
  frameAcceptTypeFilter?: Maybe<PhyInterfaceFrameAcceptTypeFilter>;
  /** Static hardware port label, if present. */
  hardwareLabel?: Maybe<Scalars['String']['output']>;
  /** If this interface represents a WAN port, indicates if there is any recent activity representing a connection to an ISP. */
  hasWANActivity?: Maybe<Scalars['Boolean']['output']>;
  /** Configuration for high availability associated with this interface, if on a controller configured in HA mode for this port. */
  highAvailability?: Maybe<HighAvailabilityControllerVirtualDeviceInfo>;
  /** If this interface represents a WAN port, attempts to match the most recently reported IP for the port against the IP address information from the provider. */
  internetServicePlan?: Maybe<InternetServicePlan>;
  /** If assigning this interface as a client, this field indicates the IPv4 addresses to assign to the interface without netmasks. */
  ipv4ClientAddresses?: Maybe<Array<Scalars['IPV4']['output']>>;
  /** If assigning this interface as a client, this field indicates the protocol to use for IPv4 addresses. */
  ipv4ClientAssignmentProtocol?: Maybe<ClientAssignmentProtocol>;
  /** If assigning this interface as a client, this field indicates the gateway IPv4 address without a netmask. */
  ipv4ClientGateway?: Maybe<Scalars['IPV4']['output']>;
  /** If assigning this interface as a client, this field indicates the subnet prefix length for the IPv4 addresses. */
  ipv4ClientPrefixLength?: Maybe<Scalars['Int']['output']>;
  /** If assigning this interface as a client, this field indicates the IPv6 addresses to assign to the interface without netmasks. */
  ipv6ClientAddresses?: Maybe<Array<Scalars['IPV6']['output']>>;
  /** If assigning this interface as a client, this field indicates the protocol to use for IPv6 addresses. */
  ipv6ClientAssignmentProtocol?: Maybe<ClientAssignmentProtocol>;
  /** If assigning this interface as a client, this field indicates the gateway IPv6 address without a netmask. */
  ipv6ClientGateway?: Maybe<Scalars['IPV6']['output']>;
  /** If assigning this interface as a client, this field indicates the subnet prefix length for the IPv6 addresses. */
  ipv6ClientPrefixLength?: Maybe<Scalars['Int']['output']>;
  /** Indicates if the port is blocked due to too many port flaps (link down/up). */
  isBlockedDueToFlaps: Scalars['Boolean']['output'];
  /** Whether or not the interface is implicitly bound to all VLANs, including any future VLANs that are created. If set to true, all VLAN bindings (excluding the native VLAN) will be tagged on this interface. */
  isBoundToAllVLANs: Scalars['Boolean']['output'];
  /** Whether or not the interface is enabled. Interfaces that are not enabled are still visible to the user. */
  isEnabled: Scalars['Boolean']['output'];
  /** Indicates if the port is an Ethernet port. */
  isEthernet: Scalars['Boolean']['output'];
  /** If true, flap link intervention protocol is enabled on the port. Both this flag and isFlipEnabled in SwitchProfile must be true for FLIP to be enabled on a port. */
  isFLIPEnabled: Scalars['Boolean']['output'];
  /** If true, tagged frames are discarded when the VLAN ID of the frame does not match the VLAN ID of the port. */
  isIngressFilteringEnabled: Scalars['Boolean']['output'];
  /** If true, clients on isolated ports cannot communicate with other isolated ports; they can only communicate with non-isolated ports. */
  isIsolationEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates if the port is blocked due to loopback detection. */
  isLoopbackDetected?: Maybe<Scalars['Boolean']['output']>;
  /** Whether or not the interface is enabled to deliver Power over Ethernet. */
  isPOEEnabled: Scalars['Boolean']['output'];
  /** Indicates if the port is an SFP port. */
  isSFP: Scalars['Boolean']['output'];
  /** If true, this interface will be configured as an edge port in STP (Spanning Tree Protocol). */
  isSTPEdgePortEnabled: Scalars['Boolean']['output'];
  /** Whether or not the interface is enabled to participate in Spanning Tree Protocol. */
  isSTPEnabled: Scalars['Boolean']['output'];
  /** Indicates if the port is forwarding (true) or blocking (false) due to STP. */
  isSTPForwarding?: Maybe<Scalars['Boolean']['output']>;
  /** Whether or not storm control is enabled on the port. */
  isStormControlEnabled: Scalars['Boolean']['output'];
  /** A trunk port is a port that is assigned to carry traffic for multiple VLANs. */
  isTrunkPort: Scalars['Boolean']['output'];
  /** An uplink interface connects the device to other networks or network devices. */
  isUplink: Scalars['Boolean']['output'];
  /** Only populated if `isUplink` is true. Indicates if this uplink physical interface is currently active. */
  isUplinkActive?: Maybe<Scalars['Boolean']['output']>;
  /** A custom label for the physical interface. */
  label?: Maybe<Scalars['String']['output']>;
  /** For switch port security, the maximum number of MAC addresses allowed on the port. */
  maxMACAddressesAllowed?: Maybe<Scalars['Int']['output']>;
  /** Maximum speed capability of the port from hardware specifications, in Mbps. */
  maxSpeedMbps?: Maybe<Scalars['Int']['output']>;
  /** The native (untagged) VLAN that is assigned to this interface. */
  nativeVLAN?: Maybe<Vlan>;
  /** The physical port number on the device where the interface is located. */
  portNumber: Scalars['Int']['output'];
  /** The link speed on the port, populated from our time series database. */
  portSpeedMbps?: Maybe<Scalars['Int']['output']>;
  /**
   * The type of port (Ethernet, SFP, or Unknown).
   * @deprecated Use `isEthernet` or `isSFP` instead
   */
  portType: PortType;
  /** The power draw on the port, populated from our time series database for PoE. */
  powerDraw?: Maybe<Scalars['Float']['output']>;
  /** Receive rate limit for the port, in Kbps. */
  rxRateLimitKbps?: Maybe<Scalars['Int']['output']>;
  /** SFP module information (if an SFP module is plugged into the port). */
  sfpModuleInfo?: Maybe<SfpModuleInfo>;
  /** The percent of broadcast traffic to drop if storm control is enabled. */
  stormControlBroadcastTrafficPercent: Scalars['Int']['output'];
  /** The percent of unknown multicast traffic to drop if storm control is enabled. */
  stormControlUnknownMulticastTrafficPercent: Scalars['Int']['output'];
  /** The percent of unknown unicast traffic to drop if storm control is enabled. */
  stormControlUnknownUnicastTrafficPercent: Scalars['Int']['output'];
  /** STP Path cost for the port (1-200000000). */
  stpPathCost?: Maybe<Scalars['Int']['output']>;
  /** The Spanning Tree Protocol (STP) role of the port. */
  stpPortRole?: Maybe<StpPortRole>;
  /** STP Priority for the port (0-240 in multiples of 16, default 128). */
  stpPriority?: Maybe<Scalars['Int']['output']>;
  /** The throughput (RX/TX) for the last 24 hours on the port, populated from our time series database. */
  throughputLastDay?: Maybe<Array<ThroughputValueWithDirection>>;
  /** Transmit rate limit for the port, in Kbps. */
  txRateLimitKbps?: Maybe<Scalars['Int']['output']>;
  /** The date and time when the physical interface was last updated. */
  updatedAt: Scalars['DateTime']['output'];
  /** Only populated if `isUplink` is true. Holds the statically reported addresses of the physical interface. */
  uplinkExternalAddresses?: Maybe<Array<Scalars['UnmaskedIPPrefix']['output']>>;
  /** Can only be populated if `isUplink` is true. The MAC Address of the upstream device this physical interface is connected to. */
  uplinkGatewayMACAddress?: Maybe<Scalars['MacAddress']['output']>;
  /** Can only be populated if `isUplink` is true. The priority order in which the uplink should be used. */
  uplinkPriority?: Maybe<Scalars['Int']['output']>;
  /** Can only be populated if `isUplink` is true. The VLAN ID used for the uplink. */
  uplinkVLANID?: Maybe<Scalars['Int']['output']>;
  /** The virtual device associated with this physical interface. */
  virtualDevice: VirtualDevice;
  /** The UUID of the virtual device that this physical interface belongs to. */
  virtualDeviceUUID: Scalars['UUID']['output'];
};

export type PhyInterfaceConnectedDevice = {
  __typename?: 'PhyInterfaceConnectedDevice';
  /**
   * If the macAddress belongs to a Meter device, this field will load it from the database. Deprecated: Use `hardwareDevice` instead.
   * @deprecated Use `hardwareDevice` instead.
   */
  device?: Maybe<Device>;
  /** If the macAddress belongs to a hardware device, this field will load it from the database. */
  hardwareDevice?: Maybe<HardwareDevice>;
  /** MAC Address of the device that is plugged into the port. Populated via LLDP. */
  macAddress: Scalars['MacAddress']['output'];
  /** A reference to the PhyInterface the device is connected to. */
  phyInterface: PhyInterface;
};

/** Specifies the frame types to accept on a port. */
export enum PhyInterfaceFrameAcceptTypeFilter {
  /** Accept only tagged frames. */
  TaggedOnly = 'TAGGED_ONLY',
  /** Accept only untagged frames. */
  UntaggedOnly = 'UNTAGGED_ONLY',
}

/** Contains info about the PoE (Power over Ethernet) usage of a hardware device. */
export type PoEInfo = {
  __typename?: 'PoEInfo';
  /** The maximum PoE power usage value (in Watts). */
  max?: Maybe<Scalars['Float']['output']>;
  /** The timestamp when the maximum PoE power usage was recorded. */
  maxCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The current PoE power usage value (in Watts). */
  used?: Maybe<Scalars['Float']['output']>;
  /** The timestamp when the current PoE power usage was recorded. */
  usedCreatedAt?: Maybe<Scalars['DateTime']['output']>;
};

/** A policy routing rule sets a policy for some source traffic to some destination traffic over a specific physical interface binding. */
export type PolicyRoutingRule = {
  __typename?: 'PolicyRoutingRule';
  /** The unique identifier for the policy routing rule. */
  UUID: Scalars['UUID']['output'];
  /** The physical interfaces and metrics this rule is bound to. A lower metric takes higher priority. */
  bindings?: Maybe<Array<PolicyRoutingRuleBinding>>;
  /** The timestamp when the policy routing rule was created. */
  createdAt: Scalars['DateTime']['output'];
  /** The list of destination port ranges for the traffic matching this rule. */
  dstPortRanges: Array<PortRange>;
  /** The destination IP prefix for the traffic matching this rule. */
  dstPrefix: Scalars['IPPrefix']['output'];
  /** Indicates whether the policy routing rule is enabled. */
  isEnabled: Scalars['Boolean']['output'];
  /** The name of the policy routing rule. */
  name: Scalars['String']['output'];
  /** The unique identifier of the network to which this policy routing rule belongs. */
  networkUUID: Scalars['UUID']['output'];
  /** The list of IP protocols (e.g., TCP, UDP) for the traffic matching this rule. */
  protocols: Array<IpProtocol>;
  /** The list of source port ranges for the traffic matching this rule. */
  srcPortRanges: Array<PortRange>;
  /** The source IP prefix for the traffic matching this rule (optional). */
  srcPrefix?: Maybe<Scalars['IPPrefix']['output']>;
  /** The source VLAN for the traffic matching this rule (optional). */
  srcVLAN?: Maybe<Vlan>;
  /** The timestamp when the policy routing rule was last updated. */
  updatedAt: Scalars['DateTime']['output'];
};

export type PolicyRoutingRuleBinding = {
  __typename?: 'PolicyRoutingRuleBinding';
  /** The unique identifier for the policy routing rule binding. */
  UUID: Scalars['UUID']['output'];
  /** The metric (priority) assigned to the binding. Lower metrics have higher priority. */
  metric: Scalars['Int']['output'];
  /** The physical interface to which the policy routing rule is bound. */
  phyInterface: PhyInterface;
};

export type PolicyRoutingRuleBindingInput = {
  /** The UUID of the physical interface to bind the policy routing rule to. The physical interface must be an uplink. */
  phyInterfaceUUID: Scalars['UUID']['input'];
};

/** Port forwarding rules for a network define how incoming traffic is forwarded to a local destination. */
export type PortForwardingRule = {
  __typename?: 'PortForwardingRule';
  /** Unique identifier for the port forwarding rule. */
  UUID: Scalars['UUID']['output'];
  /** Restricts the port forwarding rule to only allow traffic to be forwarded if it originates from any of these IP addresses. */
  allowedRemoteIPAddresses?: Maybe<Array<Scalars['IP']['output']>>;
  /** Timestamp when the port forwarding rule was created. */
  createdAt: Scalars['DateTime']['output'];
  /** Description of the port forwarding rule. */
  description?: Maybe<Scalars['String']['output']>;
  /** The external IP address to which this rule applies. */
  externalIPAddress?: Maybe<Scalars['IP']['output']>;
  /** The external physical interface associated with this rule. */
  externalPhyInterface?: Maybe<PhyInterface>;
  /**
   * One of `externalPhyInterfaceUUID` or `externalIPAddress` must be set.
   * If `externalPhyInterfaceUUID` is provided, the port forwarding rule only applies to this external physical interface.
   * If `externalIPAddress` is provided, the rule applies to the specified external IP address.
   * Otherwise, the rule will apply to all external interfaces on a device.
   * Application logic will ensure that this interface is an uplink interface.
   */
  externalPhyInterfaceUUID?: Maybe<Scalars['UUID']['output']>;
  /** The external port from which incoming traffic is forwarded. */
  externalPort: Scalars['Int']['output'];
  /** Indicates whether the port forwarding rule is enabled. */
  isEnabled: Scalars['Boolean']['output'];
  /** The local IP address to which incoming traffic is forwarded. This must be a single IP. */
  localIPAddress: Scalars['IP']['output'];
  /** The local port to which the external port forwards traffic. */
  localPort: Scalars['Int']['output'];
  /** Name of the port forwarding rule. */
  name: Scalars['String']['output'];
  /** Unique identifier of the network to which this port forwarding rule belongs. */
  networkUUID: Scalars['UUID']['output'];
  /** The IP protocol to apply this rule on. These are the IANA-defined protocol numbers. 6 = TCP, 17 = UDP. A value of 256 is reserved by IANA and will be used to denote all protocols. */
  protocol: PortIpProtocol;
  /** Timestamp when the port forwarding rule was last updated. */
  updatedAt: Scalars['DateTime']['output'];
};

/** Supported IP protocols for specifying port ranges. */
export enum PortIpProtocol {
  /** Transmission Control Protocol. */
  Tcp = 'TCP',
  /** User Datagram Protocol. */
  Udp = 'UDP',
}

/** Represents a range of ports. */
export type PortRange = {
  __typename?: 'PortRange';
  /** Lower bound of the port range. */
  lower: Scalars['Int']['output'];
  /** Upper bound of the port range. */
  upper: Scalars['Int']['output'];
};

/** Input type for specifying a port range. */
export type PortRangeInput = {
  /** Lower bound of the port range (1-65535). */
  lower: Scalars['Int']['input'];
  /** Upper bound of the port range (1-65535). */
  upper: Scalars['Int']['input'];
};

/** Indicates the type of port. */
export enum PortType {
  /** An Ethernet port. */
  Ethernet = 'ETHERNET',
  /** An SFP port. */
  Sfp = 'SFP',
  /** Unknown port type. */
  Unknown = 'UNKNOWN',
}

/** A power distribution unit hardware device represents a physical power distribution unit. */
export type PowerDistributionUnitHardwareDevice = HardwareDevice & {
  __typename?: 'PowerDistributionUnitHardwareDevice';
  /** Shows the most recent boot history of a device (buildName, bootCount). */
  bootHistory?: Maybe<Array<BootInfo>>;
  /** The timestamp when the hardware device was first created in the system. */
  createdAt: Scalars['DateTime']['output'];
  /** The model name of the hardware device. */
  deviceModel: DeviceModel;
  /** The type of the hardware device. */
  deviceType: DeviceType;
  /** If the device is no longer connected to the backend, this is the time at which it was last connected. */
  disconnectedFromBackendAt?: Maybe<Scalars['DateTime']['output']>;
  /**
   * True if the device has an active websocket connection to the backend. Note that this only works if the device has registered via the /v2/register endpoint (i.e., is a Config 2 device) - otherwise is always false.
   * @deprecated Refer to `isSpare` instead.
   */
  isActive: Scalars['Boolean']['output'];
  /** Indicates whether the hardware device is connected to the backend. */
  isConnectedToBackend: Scalars['Boolean']['output'];
  /** Indicates whether the hardware device's console port is enabled. */
  isConsoleEnabled: Scalars['Boolean']['output'];
  /** Indicates whether the hardware device is marked as a development (dev) device, moving it into the dev (staging) realm for testing purposes. */
  isDev: Scalars['Boolean']['output'];
  /** Indicates whether the hardware device is in factory mode. */
  isFactoryMode: Scalars['Boolean']['output'];
  /** Indicates whether the hardware device is an RMA (Return Merchandise Authorization) replacement device. */
  isRMA: Scalars['Boolean']['output'];
  /** Indicates whether the hardware device has been retired. */
  isRetired: Scalars['Boolean']['output'];
  /** A spare hardware device belongs to a network but not a virtual device. Used most often to keep track of spare hardware we ship to a customer but don't install immediately. */
  isSpare: Scalars['Boolean']['output'];
  /** The MAC address of the hardware device. */
  macAddress?: Maybe<Scalars['MacAddress']['output']>;
  /** The UUID of the network that the spare hardware device belongs to. Spare hardware devices that are not affiliated with a virtual device will have this field populated. */
  networkUUID?: Maybe<Scalars['UUID']['output']>;
  /** The public key used to add the device to the jumphost's WireGuard interface. */
  publicKey?: Maybe<Scalars['String']['output']>;
  /** The unique serial number of the hardware device. */
  serialNumber: Scalars['String']['output'];
  /** IPv6 address on the jumphost subnet. Used to send requests to the device. */
  tunnelIPAddress?: Maybe<Scalars['String']['output']>;
  /** The timestamp when the hardware device was last updated in the system. */
  updatedAt: Scalars['DateTime']['output'];
  /** The uptime metrics from the time series database for the device. */
  uptime?: Maybe<Scalars['Duration']['output']>;
  /** The virtual device associated with this hardware device, if any. */
  virtualDevice?: Maybe<VirtualDevice>;
  /** The UUID of the virtual device this hardware device is assigned to, if any. */
  virtualDeviceUUID?: Maybe<Scalars['UUID']['output']>;
};

/** A power distribution unit hardware device represents a physical power distribution unit. */
export type PowerDistributionUnitHardwareDeviceBootHistoryArgs = {
  count?: InputMaybe<Scalars['Int']['input']>;
};

/** A power distribution unit virtual device is a logical representation of a physical power distribution unit. */
export type PowerDistributionUnitVirtualDevice = VirtualDevice & {
  __typename?: 'PowerDistributionUnitVirtualDevice';
  /** The unique identifier (UUID) of the virtual device. */
  UUID: Scalars['UUID']['output'];
  /** The timestamp when the virtual device was first created in the system. */
  createdAt: Scalars['DateTime']['output'];
  /** An optional description for the virtual device. */
  description?: Maybe<Scalars['String']['output']>;
  /** The model name of the virtual device. */
  deviceModel: DeviceModel;
  /** The device temperature of the PDU in degrees Celsius. */
  deviceTemperatureCelsius?: Maybe<Scalars['Float']['output']>;
  /** The type of the virtual device. */
  deviceType: VirtualDeviceType;
  /** The Ethernet interface speed of the PDU in Mbps. */
  ethernetSpeedMbps?: Maybe<Scalars['Int']['output']>;
  /** The hardware device associated with this virtual device, if any. */
  hardwareDevice?: Maybe<HardwareDevice>;
  /** The IPv4 address of the power distribution unit (PDU). */
  ipAddress?: Maybe<Scalars['IPV4']['output']>;
  /** Indicates whether the PDU's circuit breaker is functioning properly. */
  isCircuitBreakerGood?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates whether the virtual device's console port is enabled. */
  isConsoleEnabled: Scalars['Boolean']['output'];
  /** Indicates whether debug logging is enabled on the virtual device. */
  isDebugLogEnabled: Scalars['Boolean']['output'];
  /** Indicates whether the PDU is properly grounded. */
  isGrounded?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates whether the PDU's surge protection is functioning properly. */
  isSurgeProtectionGood?: Maybe<Scalars['Boolean']['output']>;
  /** A human-friendly name for the device. */
  label: Scalars['String']['output'];
  /** The maximum allowed power for the PDU in watts. */
  maxPowerAllowedWatts?: Maybe<Scalars['Float']['output']>;
  /** The network that the virtual device belongs to. */
  network: Network;
  /** The unique identifier (UUID) of the network the virtual device belongs to. */
  networkUUID: Scalars['UUID']['output'];
  /** The current NOS firmware version that the virtual device is configured to run. */
  nosVersion?: Maybe<ConfiguredNosVersion>;
  /** The identifier of the NOS firmware version that the virtual device is configured to run. */
  nosVersionID: Scalars['Int']['output'];
  /** The outlet interfaces of the PDU. */
  outletInterfaces: Array<OutletInterface>;
  /** The NOS firmware version that the virtual device is scheduled to upgrade to. */
  pendingNosVersion?: Maybe<PendingNosVersion>;
  /** The total power usage of the PDU in watts. */
  totalPowerUsedWatts?: Maybe<Scalars['Float']['output']>;
  /** The timestamp when the virtual device was last updated in the system. */
  updatedAt: Scalars['DateTime']['output'];
  /** The uptime from our time series database for the device. */
  uptime?: Maybe<Scalars['Duration']['output']>;
};

/** An enumeration of possible principal types for authentication tokens. */
export enum PrincipalType {
  /** A device principal, representing a hardware device. */
  Device = 'DEVICE',
  /** A machine principal, a virtual or physical machine. */
  Machine = 'MACHINE',
  /** A user principal, representing a human user. */
  User = 'USER',
}

export type Query = {
  __typename?: 'Query';
  /** Active clients are clients that currently appear both in the MAC and ARP tables of a network. */
  activeClients: ActiveClientsMetricsResponse;
  /** Fetch all of the known types of Alerts that notify stakeholders of important events for a network. */
  alertDefinitions: Array<AlertDefinition>;
  /** Get an alert receiver and its targets. */
  alertReceiver: AlertReceiver;
  /** Get all alert receivers and their targets for a company. */
  alertReceiversForCompany: Array<AlertReceiver>;
  /** Get a specific webhook alert target by its UUID. */
  alertTargetWebhook: AlertTargetWebhook;
  /** Get all alert targets for a specified company UUID. */
  alertTargets: AlertTargetsResponse;
  /** Get all webhook alert targets for a company. */
  alertTargetsWebhook: Array<AlertTargetWebhook>;
  /**
   * Retrieve the channel utilization metrics for the given network.
   *
   * This resolver is only for Config1 networks and is deprecated in favor of 'channelUtilizationByAP' for Config2 networks.
   * @deprecated Use channelUtilizationByAP for config 2 networks
   */
  apChannelUtilization: ApChannelUtilizationResponse;
  /** Returns the uptime (how long since a device last booted) of the APs in the network. */
  apUptime: ApUptimeResponse;
  /** Get an application DNS firewall rule. */
  applicationDNSFirewallRule: ApplicationDnsFirewallRule;
  /** Given a hostname, returns the application that would be applied to it. */
  applicationDNSFirewallRuleApplicationForHostname: ApplicationDnsFirewallRuleApplication;
  /**
   * Fetch all applications that DNS firewall rules can be associated with.
   * This currently returns all applications – in the future this will only return visible applications for customers.
   */
  applicationDNSFirewallRuleApplications: Array<ApplicationDnsFirewallRuleApplication>;
  /** Fetch all available Categories of DNS firewall rules. */
  applicationDNSFirewallRuleCategories: Array<ApplicationDnsFirewallRuleCategory>;
  /** Given a hostname, returns the category of DNS firewall rule that would be applied to it. */
  applicationDNSFirewallRuleCategoryForHostname: ApplicationDnsFirewallRuleCategory;
  /** Fetch all groups of DNS firewall rules. */
  applicationDNSFirewallRuleGroups: Array<ApplicationDnsFirewallRuleGroup>;
  /** Fetch all application DNS firewall rules for a network. */
  applicationDNSFirewallRulesForNetwork: Array<ApplicationDnsFirewallRule>;
  /**
   * Check if multiple NOS features are enabled for any network in a company.
   * In development environments, this always returns true for a key if it is not recognized on the backend.
   * This checks if *any* network for the specified company supports the NOS features.
   */
  areNosFeaturesEnabledForCompany: Array<NosFeatureEnabled>;
  /**
   * Check if multiple NOS features are enabled for a network.
   * In development environments, this always returns true for a key if it is not recognized on the backend.
   */
  areNosFeaturesEnabledForNetwork: Array<NosFeatureEnabled>;
  /** Fetch an authentication token by its unique identifier (UUID). */
  authenticationToken: AuthenticationToken;
  /** Get an Auto Channel Selection Job by its UUID. */
  autoChannelSelectionJob: AutoChannelSelectionJob;
  /** List all Auto Channel Selection Jobs for a network. */
  autoChannelSelectionJobsForNetwork?: Maybe<Array<AutoChannelSelectionJob>>;
  /** Fetch a Switch Auto Configuration Job by its UUID. */
  autoSwitchConfigurationJob: SwitchAutoConfigurationJob;
  /** Fetch all Switch Auto Configuration Jobs for a network. */
  autoSwitchConfigurationJobsForNetwork?: Maybe<Array<SwitchAutoConfigurationJob>>;
  /** Get an Auto VPN Group by its UUID. */
  autoVPNGroup: AutoVpnGroup;
  /** Lists Auto VPN Groups for a company. */
  autoVPNGroupsForCompany: Array<AutoVpnGroup>;
  /** Lists Auto VPN Groups for a network. */
  autoVPNGroupsForNetwork: Array<AutoVpnGroup>;
  /** Gets an Auto VPN Member by its UUID. */
  autoVPNMember: AutoVpnMember;
  /**
   * Retrieves an existing bearer session by its UUID. A CLI session creates a one-time-use bearer session that must be
   * verified before issuing a token. Once it is verified, the session can no longer be used.
   */
  bearerSession: BearerSession;
  /** Return all of the BSSIDs associated with hardware devices linked to virtual devices in a given network. */
  bssidsForNetwork: Array<Bssid>;
  /** Get a Bulk NOS upgrade by UUID. */
  bulkNOSUpgrade: BulkNosUpgrade;
  /** List Bulk NOS upgrades with the given filter. */
  bulkNOSUpgrades: Array<BulkNosUpgrade>;
  /** Gets a captive portal. */
  captivePortal: CaptivePortal;
  /** Returns all clients that have seen or interacted with the captive portal in the last specified amount of hours. May contain duplicate clients. */
  captivePortalAllClients: Array<CaptivePortalClient>;
  /** Returns authorized clients to the captive portal in the last specified amount of hours. */
  captivePortalAuthorizedClients: Array<CaptivePortalClient>;
  /** Gets the captive portal for a network. */
  captivePortalForNetwork?: Maybe<CaptivePortal>;
  /** Gets the captive portal attached to a VLAN. */
  captivePortalForVLAN?: Maybe<CaptivePortal>;
  /** Gets a captive portal by its webhook key. */
  captivePortalForWebhook?: Maybe<CaptivePortal>;
  /**
   * Lists captive portals for a network.
   * @deprecated Use `captivePortalForNetwork` instead; there is only at-most-one per network.
   */
  captivePortalsForNetwork: Array<CaptivePortal>;
  /** Returns one timeseries per radio band for all of the radio bands broadcasted by the AP. */
  channelUtilizationByAP: Array<ChannelUtilizationResponseV2>;
  /** Returns a timeseries for channel utilization for the client, aggregated over the APs that the client has been connected to. */
  channelUtilizationByClient: Array<ClientChannelUtilizationTimeseriesValue>;
  /** Returns a time series for each radio band for the APs in the network. */
  channelUtilizationByNetwork: Array<ChannelUtilizationResponseV2>;
  /**
   * Deprecated. Gets client metrics for a device.
   * @deprecated Prefer wirelessClientMetricsByX queries instead.
   */
  clientMetrics: ClientMetricsResponse;
  /** Get a Client VPN client. */
  clientVPNClient: ClientVpnClient;
  /** Lists Client VPN clients for a Client VPN server. */
  clientVPNClients: Array<ClientVpnClient>;
  /** Get a Client VPN server. */
  clientVPNServer: ClientVpnServer;
  /** Lists Client VPN servers for a Network. */
  clientVPNServers: Array<ClientVpnServer>;
  /** Get a company user by UUID. */
  companyUser: CompanyUser;
  /** List all company users for the specified company. */
  companyUsers: Array<CompanyUser>;
  /** Get recent console login challenges for a device. */
  consoleLogins: Array<ConsoleLogin>;
  /** Timeseries data of DHCP server uptime metrics for a single controller. */
  controllerDHCPUptime: ControllerDhcpUptimeResponse;
  /** Timeseries data of requests/sec per DNS server for a single controller. */
  controllerDNSRequestRates: ControllerDnsRequestRatesResponse;
  /** Per port timeseries metrics data for a single controller. */
  controllerPortMetrics: ControllerPortMetricsResponse;
  /** Per port timeseries metrics data as a rate per second for a single controller. */
  controllerPortMetricsRate: ControllerPortMetricsRateResponse;
  /** Per port stats for a specified controller over a lookback period. Encompasses receive/transmission data counters and hardware operational status. */
  controllerPortStats: Array<ControllerPortStat>;
  /**
   * Get a device by its serial number.
   * @deprecated Use `hardwareDevice` query instead, accessing `virtualDevice` within the hardware device if necessary.
   */
  device: Device;
  /** Gets the cellular status last reported by a device. */
  deviceCellularStatus?: Maybe<CellularStatus>;
  /** Fetch a specific configuration for a device by its version number. */
  deviceConfigByVersion: DeviceConfig;
  /** Fetch the configuration override for a device; should rarely be used, if ever, in production. */
  deviceConfigOverride: DeviceConfigOverride;
  /** Fetch all configurations for a device over time ordered by version. */
  deviceConfigs?: Maybe<Array<DeviceConfig>>;
  /** Retrieves the device heartbeat configuration associated with a given device. */
  deviceHeartbeatForDevice: DeviceHeartbeat;
  /**
   * Retrieves the cellular usage of a controller aggregated over the last day.
   *
   * This is its own resolver so that it can be used for both config 1 and config 2.
   */
  deviceLastDayCellularUsage?: Maybe<CellularUsage>;
  /** Config 1: Retrieves the uptime of a device specified by its serial number. */
  deviceUptime: Scalars['Duration']['output'];
  /**
   * List devices for a network.
   * @deprecated Use `virtualDevicesForNetwork` instead, accessing `hardwareDevice` within the virtual devices if necessary.
   */
  devicesForNetwork: Array<Device>;
  /** Get a DHCP option by its UUID. */
  dhcpOption: DhcpOption;
  /** Get a DHCP reserved range by its UUID. */
  dhcpReservedRange: DhcpReservedRange;
  /** Get a DHCP rule by its UUID. */
  dhcpRule: DhcpRule;
  /** Get all DHCP rules for a specific network. */
  dhcpRulesForNetwork: Array<DhcpRule>;
  /** Get a DHCP static mapping by its UUID. */
  dhcpStaticMapping: DhcpStaticMapping;
  /** Returns a list of services discovered on the specified network within the last given number of minutes, filtered by the provided service types. */
  discoveredServicesForNetwork: Array<DiscoveredService>;
  /** Get a DNS host mapping by its UUID. */
  dnsHostMapping: DnsHostMapping;
  /** Generates a pre-signed URL for downloading a file from S3. */
  downloadUrl: FileDownloadPayload;
  /** Lists the 802.1X encryption configurations for a specific network. */
  encryption8021XsForNetwork: Array<Encryption8021X>;
  /** Return the Switch virtual device / phy interface that the given MAC address is associated with using LLDP stats. */
  findSwitchLLDPEntryForMAC?: Maybe<MacAddressSwitchEntry>;
  /** Return the Switch virtual device / phy interface that the given MAC address is associated with. */
  findSwitchesForClientMAC: Array<MacAddressSwitchEntry>;
  /** Get a firewall rule by UUID. */
  firewallRule: FirewallRule;
  /** Get all firewall rules for a network. */
  firewallRulesForNetwork: Array<FirewallRule>;
  /** Get a floor plan by its unique identifier. */
  floorPlan: FloorPlan;
  /** List all floor plans associated with a specific network. */
  floorPlansForNetwork: Array<FloorPlan>;
  /** Get a company by its slug. */
  getCompany?: Maybe<Company>;
  /** Return a hardware device by its serial number. */
  hardwareDevice: HardwareDevice;
  /** Return a hardware device by its MAC address. */
  hardwareDeviceByMACAddress: HardwareDevice;
  /** Return a list of hardware devices that match the filter. */
  hardwareDevices: Array<HardwareDevice>;
  /** View host monitoring metrics for a network. */
  hostMonitoringForNetwork: Array<HostMonitoringResponse>;
  /** Get a host monitoring target by its UUID. */
  hostMonitoringTarget: HostMonitoringTarget;
  /** List all host monitoring targets for a specific network. */
  hostMonitoringTargetsForNetwork: Array<HostMonitoringTarget>;
  /** Get the valid types for Hotspot 2.0 (Passpoint) configuration. */
  hs20ValidTypes: Hs20ValidTypesResponse;
  /**
   * Get an incident by its UUID.
   * @deprecated Incidents are no longer supported in Config 2.
   */
  incident: Incident;
  /** List all links between VLANs that are allowed to communicate with each other. */
  interVLANCommunicationPermittedPairs: Array<InterVlanCommunicationPermittedPair>;
  /** Get an internal note by UUID. */
  internalNote: InternalNote;
  /** List all internal notes for a network. */
  internalNotesForNetwork: Array<InternalNote>;
  /** Internet service plan for a given UUID. */
  internetServicePlan?: Maybe<InternetServicePlan>;
  /** Retrieves all providers applicable to the given company. At the moment, this returns ALL known providers. */
  internetServicePlanProviders: Array<InternetServicePlanProvider>;
  /** Internet service plans for a given company, and optionally scoped by a network. */
  internetServicePlans: Array<InternetServicePlan>;
  /** Get an IPSec tunnel by UUID. */
  ipSecTunnel: IpSecTunnel;
  /** List all IPSec tunnels for a network. */
  ipSecTunnelsForNetwork: Array<IpSecTunnel>;
  /**
   * Check if a specific NOS feature is enabled for any network in a company.
   * In development environments, this always returns true if the key is not recognized on the backend.
   * This checks if *any* network for the specified company supports the NOS feature.
   */
  isNosFeatureEnabledForCompany: Scalars['Boolean']['output'];
  /**
   * Check if a specific NOS feature is enabled for a network.
   * In development environments, this always returns true if the key is not recognized on the backend.
   */
  isNosFeatureEnabledForNetwork: Scalars['Boolean']['output'];
  /** Lists network onboarding job trackers retrieved from Airtable. */
  jobTrackers: Array<NetworkOnboardingJobData>;
  /** Fetch the latest configuration that a device has acknowledged. */
  latestAcknowledgedDeviceConfig: DeviceConfig;
  /** Config 1: Retrieves the latest CPU load information for a device specified by its serial number. */
  latestDeviceCPULoad: LatestDeviceCpuLoad;
  /**
   * Fetches the latest configuration rendered for a device. Does not re-render the configuration;
   * use the `renderDeviceConfig` mutation for that.
   */
  latestDeviceConfig: DeviceConfig;
  /** Config 1: Retrieves the latest memory usage information for a device specified by its serial number. */
  latestDeviceMemoryUsage: LatestDeviceMemoryUsage;
  /** Returns all of the companies, excluding companies that only have Connect quotes. */
  listCompanies: Array<Company>;
  /** Retrieves the maintenance window for the specified network. */
  maintenanceWindowForNetwork: MaintenanceWindow;
  /** Retrieve the list of mutations that a network has undergone. */
  mutationAuditLogEntriesForNetwork: Array<MutationAuditLogEntry>;
  /** Get a network. */
  network: Network;
  /** Get a network by its slug. */
  networkBySlug: Network;
  /**
   * Get the latest connected clients for a network.
   * The values in filter can alter this behavior.
   */
  networkClients: Array<NetworkClient>;
  /** Get a report of the total number of bytes transmitted and received by each client connected to a network, on a per day basis. */
  networkClientsTxRxPerDay: Array<NetworkClientTxRxPerDay>;
  /**
   * Returns the network routes of a virtual device for the most recently acknowledged configuration.
   * This reads from the rendered configuration so includes all implied routes from other features.
   */
  networkRoutesForVirtualDevice: Array<NetworkRoute>;
  /**
   * Get metrics about a network's uplink quality (i.e., the ISP quality).
   * Quality is the percentage of successful pings to common web servers over the ISP connection.
   */
  networkUplinkQuality: NetworkUplinkQualityResponse;
  /**
   * Get metrics about a network's uplink throughput (i.e., the ISP throughput).
   * Throughput is the amount of data transferred over the ISP connection, measured in bits per second.
   */
  networkUplinkThroughput: NetworkUplinkThroughputMetricsResponse;
  /** Get all networks that match the given filter. */
  networks: Array<Network>;
  /** Get all of a company's networks. */
  networksForCompany: Array<Network>;
  /** Get uplink quality metrics for multiple networks. */
  networksUplinkQualities: Array<NetworkUplinkQualityResponse>;
  /** Always returns true and does nothing. */
  noop: Scalars['Boolean']['output'];
  /** Get a NOS feature by its UUID. */
  nosFeature: NosFeature;
  /** List all NOS features. */
  nosFeatures: Array<NosFeature>;
  /** Retrieves all NOS versions that were scheduled for a virtual device. */
  nosHistoryForVirtualDevice?: Maybe<Array<VirtualDeviceNosHistory>>;
  /** List all NOS versions with optional filters. */
  nosVersions: Array<NosVersion>;
  /**
   * Get a notification preference by UUID.
   * @deprecated No longer supported.
   */
  notificationPreference: NotificationPreferences;
  /**
   * Get notification preferences for a company and controller.
   * @deprecated No longer supported.
   */
  notificationPreferences: NotificationPreferencesResponse;
  /** Get a notification setting by UUID. */
  notificationSetting: NotificationSetting;
  /** List all notification settings for a network. */
  notificationSettingsForNetwork: Array<NotificationSetting>;
  /** Get a 1:1 NAT rule by its UUID. */
  oneToOneNATRule: OneToOneNatRule;
  /** List all 1:1 NAT rules for a specific network. */
  oneToOneNATRulesForNetwork: Array<OneToOneNatRule>;
  /** Get all outlet interfaces for a Power Distribution Unit (PDU) virtual device. */
  outletInterfacesForVirtualDevice: Array<OutletInterface>;
  /** Retrieves the permissions that are assigned to the current user, in context of the provided parameters. */
  permissions: Array<PermissionAssignment>;
  /** Get the physical interfaces associated with a virtual device. */
  phyInterfacesForVirtualDevice: Array<PhyInterface>;
  /** Get a policy routing rule. */
  policyRoutingRule: PolicyRoutingRule;
  /** List policy routing rules for a network. */
  policyRoutingRulesForNetwork: Array<PolicyRoutingRule>;
  /** Get a port forwarding rule by UUID. */
  portForwardingRule: PortForwardingRule;
  /** Get all port forwarding rules for a network. */
  portForwardingRulesForNetwork: Array<PortForwardingRule>;
  /** Get a rack elevation by UUID. */
  rackElevation: RackElevation;
  /** Get all rack elevations for a network. */
  rackElevations: Array<RackElevation>;
  /** Get the radio profiles for a network. */
  radioProfilesForNetwork: Array<RadioProfile>;
  /** Get a rate limit rule by UUID. */
  rateLimitRule: RateLimitRule;
  /** Get all rate limit rules for a network. */
  rateLimitRulesForNetwork: Array<RateLimitRule>;
  /** Events in the event log represent notable state changes in the state of the network. */
  recentEventLogEvents?: Maybe<Array<EventLogEvent>>;
  /** Retrieves the roles that are assigned to the current user, in context of the provided parameters. */
  roles: Array<RoleAssignment>;
  /** Return the hardware devices that are not associated with a virtual device; they're not in use but affiliated with the network for potential future use. */
  spareHardwareDevicesForNetwork: Array<HardwareDevice>;
  /** Get all speedtest servers for a network. */
  speedtestServersForNetwork: Array<SpeedtestServer>;
  /** List all SSIDs for a network. */
  ssidsForNetwork: Array<Ssid>;
  /** Retrieves the SSO options that can be configured for a company. */
  ssoOptions: SsoOptions;
  /** Get a static route by UUID. */
  staticRoute: StaticRoute;
  /** List all static routes for a network. */
  staticRoutesForNetwork: Array<StaticRoute>;
  /**
   * Fetch stats (device-generated metrics) for a virtual device.
   * @deprecated Use statsForVirtualDevices instead.
   */
  statsForVirtualDevice: Array<Stat>;
  /** Fetch stats (device-generated metrics) for multiple virtual devices. */
  statsForVirtualDevices: Array<Stat>;
  /** Fetch all MAC address or OUI to VLAN bindings for a network. */
  switchAutoConfigMACAddressVLANBindingsForNetwork: Array<SwitchAutoConfigMacAddressVlanBinding>;
  /**
   * Retrieve the packet discard rates per port for a specified switch within a given time filter.
   * Discard rates represent the number of packets dropped.
   */
  switchDiscardRatesPerPort: SwitchMetricsResponse;
  /** Retrieve the error rates per port for a specified switch within a given time filter. */
  switchErrorRatesPerPort: SwitchMetricsResponse;
  /**
   * Retrieve the MAC address table for a specified switch.
   * The MAC table contains all MAC addresses observed on the switch's ports.
   */
  switchMACTable: Array<SwitchMacTableEntry>;
  /**
   * Retrieve per-port timeseries metrics data for a specified switch.
   * This endpoint is deprecated; use switchPortMetricsRate instead.
   * @deprecated Use switchPortMetricsRate instead.
   */
  switchPortMetrics: SwitchPortMetricsResponse;
  /** Retrieve per-port timeseries metrics data expressed as rates (per second) for a specified switch. */
  switchPortMetricsRate: SwitchPortMetricsRateResponse;
  /** Retrieve aggregated per-port statistics for a specified switch over a given lookback period. */
  switchPortStats: Array<SwitchPortStat>;
  /**
   * Retrieve the per-port throughput for a specified switch within a given time filter.
   * Throughput is measured in bytes per second.
   */
  switchThroughputPerPort: SwitchMetricsResponse;
  /** Fetch system performance stats for a virtual device (as opposed to network-related data plan stats). */
  systemInfoStatForVirtualDevice: Array<SystemInfoStat>;
  /** A list of hardware devices that are detected as connected to virtual devices in the network without an associated virtual device of their own. */
  unassignedHardwareDevicesForNetwork: Array<UnassignedHardwareDeviceForNetworkResponse>;
  /** Returns network uplink (WAN) physical interfaces for the network (controller interfaces only). */
  uplinkPhyInterfacesForNetwork: Array<PhyInterface>;
  /** Checks if the calling user has access to a network. Responds with the expiration of the access if found. */
  userNetworkAccessExpiry?: Maybe<Scalars['DateTime']['output']>;
  /**
   * Return a list of valid channels for a radio, by band and network.
   * The list of valid channels is based on the regulatory domain of the network.
   */
  validChannels: Array<ValidChannelsResponse>;
  /** Return a virtual device by its UUID. */
  virtualDevice: VirtualDevice;
  /** Get all virtual device tags for a network. */
  virtualDeviceTagsByNetwork?: Maybe<Array<VirtualDeviceTag>>;
  /** Get all virtual devices with a specific tag. */
  virtualDevicesByTag?: Maybe<Array<VirtualDevice>>;
  /** Return a list of virtual devices for a network that match the given filter. */
  virtualDevicesForNetwork: Array<VirtualDevice>;
  /** Get a VLAN by UUID. */
  vlan: Vlan;
  /** Get all VLANs for a network. */
  vlans: Array<Vlan>;
  /** Retrieve the results or check the status of a specific WAN speed test job. */
  wanSpeedtestJob: WanSpeedtestJob;
  /** Retrieve the results for all WAN speed test jobs on a particular network. */
  wanSpeedtestJobsForNetwork: Array<WanSpeedtestJob>;
  /** Get all Wifi Observer Settings for a virtual device. */
  wifiObserverSettings?: Maybe<Array<Maybe<WifiObserverSetting>>>;
  /** A list of events that indicate when clients connected to an AP, disassociated from an AP, or failed to connect to an AP, for all clients interacting with an AP. */
  wirelessClientConnectionEventsByAP: Array<WirelessClientConnectionEvent>;
  /** A list of events that indicate when a client connected to an AP, disassociated from an AP, or failed to connect to an AP, for a single client. */
  wirelessClientConnectionEventsByClient: Array<WirelessClientConnectionEvent>;
  /**
   * Timeseries data of wireless connectivity metrics for all clients connected to an AP.
   * Returns a single aggregated timeseries, rather than one per client.
   */
  wirelessClientMetricsByAP: ClientMetricsTimeseriesResponse;
  /** Timeseries data of wireless connectivity metrics for a single client. */
  wirelessClientMetricsByClient: ClientMetricsTimeseriesResponse;
};

export type QueryActiveClientsArgs = {
  filter: MetricsFilterInput;
  input?: InputMaybe<ActiveClientsInput>;
  networkUUID: Scalars['UUID']['input'];
};

export type QueryAlertReceiverArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryAlertReceiversForCompanyArgs = {
  companyUUID: Scalars['UUID']['input'];
};

export type QueryAlertTargetWebhookArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryAlertTargetsArgs = {
  companyUUID: Scalars['UUID']['input'];
};

export type QueryAlertTargetsWebhookArgs = {
  companyUUID: Scalars['UUID']['input'];
};

export type QueryApChannelUtilizationArgs = {
  band?: InputMaybe<RadioBand>;
  filter: MetricsFilterInput;
  networkUUID: Scalars['UUID']['input'];
};

export type QueryApUptimeArgs = {
  filter: MetricsFilterInput;
  networkUUID: Scalars['UUID']['input'];
};

export type QueryApplicationDnsFirewallRuleArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryApplicationDnsFirewallRuleApplicationForHostnameArgs = {
  hostname: Scalars['String']['input'];
};

export type QueryApplicationDnsFirewallRuleCategoryForHostnameArgs = {
  hostname: Scalars['String']['input'];
};

export type QueryApplicationDnsFirewallRulesForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryAreNosFeaturesEnabledForCompanyArgs = {
  companySlug: Scalars['String']['input'];
  nosFeatureKeys: Array<Scalars['String']['input']>;
};

export type QueryAreNosFeaturesEnabledForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
  nosFeatureKeys: Array<Scalars['String']['input']>;
};

export type QueryAuthenticationTokenArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryAutoChannelSelectionJobArgs = {
  jobUUID: Scalars['UUID']['input'];
};

export type QueryAutoChannelSelectionJobsForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryAutoSwitchConfigurationJobArgs = {
  jobUUID: Scalars['UUID']['input'];
};

export type QueryAutoSwitchConfigurationJobsForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryAutoVpnGroupArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryAutoVpnGroupsForCompanyArgs = {
  companySlug: Scalars['String']['input'];
};

export type QueryAutoVpnGroupsForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryAutoVpnMemberArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryBearerSessionArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryBssidsForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryBulkNosUpgradeArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryBulkNosUpgradesArgs = {
  filter: BulkNosUpgradesFilterInput;
};

export type QueryCaptivePortalArgs = {
  input?: InputMaybe<GetCaptivePortalInput>;
  uuid: Scalars['UUID']['input'];
};

export type QueryCaptivePortalAllClientsArgs = {
  networkUUID: Scalars['UUID']['input'];
  numHoursLookback: Scalars['Int']['input'];
};

export type QueryCaptivePortalAuthorizedClientsArgs = {
  networkUUID: Scalars['UUID']['input'];
  numHoursLookback: Scalars['Int']['input'];
};

export type QueryCaptivePortalForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryCaptivePortalForVlanArgs = {
  vlanUUID: Scalars['UUID']['input'];
};

export type QueryCaptivePortalForWebhookArgs = {
  webhookKey: Scalars['UUID']['input'];
};

export type QueryCaptivePortalsForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryChannelUtilizationByApArgs = {
  apVirtualDeviceUUID: Scalars['UUID']['input'];
  filter: ChannelUtilizationTimeseriesFilterInput;
  networkUUID: Scalars['UUID']['input'];
};

export type QueryChannelUtilizationByClientArgs = {
  filter: ChannelUtilizationTimeseriesFilterInput;
  macAddress: Scalars['MacAddress']['input'];
  networkUUID: Scalars['UUID']['input'];
};

export type QueryChannelUtilizationByNetworkArgs = {
  filter: ChannelUtilizationTimeseriesFilterInput;
  networkUUID: Scalars['UUID']['input'];
};

export type QueryClientMetricsArgs = {
  filter: MetricsFilterInput;
  serialNumber: Scalars['String']['input'];
};

export type QueryClientVpnClientArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryClientVpnClientsArgs = {
  serverUUID: Scalars['UUID']['input'];
};

export type QueryClientVpnServerArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryClientVpnServersArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryCompanyUserArgs = {
  companyUserUUID: Scalars['UUID']['input'];
  input?: InputMaybe<GetCompanyUserInput>;
};

export type QueryCompanyUsersArgs = {
  companySlug: Scalars['String']['input'];
  input?: InputMaybe<GetCompanyUserInput>;
};

export type QueryConsoleLoginsArgs = {
  limit?: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  serialNumber: Scalars['String']['input'];
};

export type QueryControllerDhcpUptimeArgs = {
  filter: MetricsFilterInput;
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type QueryControllerDnsRequestRatesArgs = {
  filter: MetricsFilterInput;
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type QueryControllerPortMetricsArgs = {
  filter: MetricsFilterInput;
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type QueryControllerPortMetricsRateArgs = {
  filter: MetricsFilterInput;
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type QueryControllerPortStatsArgs = {
  lookbackHours?: InputMaybe<Scalars['Int']['input']>;
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type QueryDeviceArgs = {
  serialNumber: Scalars['String']['input'];
};

export type QueryDeviceCellularStatusArgs = {
  serialNumber: Scalars['String']['input'];
};

export type QueryDeviceConfigByVersionArgs = {
  serialNumber: Scalars['String']['input'];
  version: Scalars['Int']['input'];
};

export type QueryDeviceConfigOverrideArgs = {
  serialNumber: Scalars['String']['input'];
};

export type QueryDeviceConfigsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  serialNumber: Scalars['String']['input'];
};

export type QueryDeviceHeartbeatForDeviceArgs = {
  serialNumber: Scalars['String']['input'];
};

export type QueryDeviceLastDayCellularUsageArgs = {
  serialNumber: Scalars['String']['input'];
};

export type QueryDeviceUptimeArgs = {
  serialNumber: Scalars['String']['input'];
};

export type QueryDevicesForNetworkArgs = {
  filter?: InputMaybe<DevicesForNetworkFilter>;
  networkUUID: Scalars['UUID']['input'];
};

export type QueryDhcpOptionArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryDhcpReservedRangeArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryDhcpRuleArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryDhcpRulesForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryDhcpStaticMappingArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryDiscoveredServicesForNetworkArgs = {
  input: DiscoveredServicesInput;
  networkUUID: Scalars['UUID']['input'];
};

export type QueryDnsHostMappingArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryDownloadUrlArgs = {
  input: DownloadUrlInput;
};

export type QueryEncryption8021XsForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryFindSwitchLldpEntryForMacArgs = {
  macAddress: Scalars['MacAddress']['input'];
  networkUUID: Scalars['UUID']['input'];
};

export type QueryFindSwitchesForClientMacArgs = {
  macAddress: Scalars['MacAddress']['input'];
  networkUUID: Scalars['UUID']['input'];
};

export type QueryFirewallRuleArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryFirewallRulesForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryFloorPlanArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryFloorPlansForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryGetCompanyArgs = {
  slug: Scalars['String']['input'];
};

export type QueryHardwareDeviceArgs = {
  serialNumber: Scalars['String']['input'];
};

export type QueryHardwareDeviceByMacAddressArgs = {
  macAddress: Scalars['MacAddress']['input'];
};

export type QueryHardwareDevicesArgs = {
  filter?: InputMaybe<HardwareDevicesFilter>;
};

export type QueryHostMonitoringForNetworkArgs = {
  filter: MetricsFilterInput;
  hostIPs: Array<Scalars['String']['input']>;
  networkUUID: Scalars['UUID']['input'];
};

export type QueryHostMonitoringTargetArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryHostMonitoringTargetsForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryIncidentArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryInterVlanCommunicationPermittedPairsArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryInternalNoteArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryInternalNotesForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryInternetServicePlanArgs = {
  uuid: Scalars['UUID']['input'];
};

export type QueryInternetServicePlanProvidersArgs = {
  companySlug: Scalars['String']['input'];
};

export type QueryInternetServicePlansArgs = {
  companySlug: Scalars['String']['input'];
  networkUUID?: InputMaybe<Scalars['UUID']['input']>;
};

export type QueryIpSecTunnelArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryIpSecTunnelsForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryIsNosFeatureEnabledForCompanyArgs = {
  companySlug: Scalars['String']['input'];
  nosFeatureKey: Scalars['String']['input'];
};

export type QueryIsNosFeatureEnabledForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
  nosFeatureKey: Scalars['String']['input'];
};

export type QueryLatestAcknowledgedDeviceConfigArgs = {
  serialNumber: Scalars['String']['input'];
};

export type QueryLatestDeviceCpuLoadArgs = {
  serialNumber: Scalars['String']['input'];
};

export type QueryLatestDeviceConfigArgs = {
  serialNumber: Scalars['String']['input'];
};

export type QueryLatestDeviceMemoryUsageArgs = {
  serialNumber: Scalars['String']['input'];
};

export type QueryListCompaniesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryMaintenanceWindowForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryMutationAuditLogEntriesForNetworkArgs = {
  filter?: InputMaybe<MutationAuditLogFilter>;
  networkUUID: Scalars['UUID']['input'];
};

export type QueryNetworkArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryNetworkBySlugArgs = {
  companySlug: Scalars['String']['input'];
  networkSlug: Scalars['String']['input'];
};

export type QueryNetworkClientsArgs = {
  filter?: InputMaybe<NetworkClientsFilter>;
  networkUUID: Scalars['UUID']['input'];
};

export type QueryNetworkClientsTxRxPerDayArgs = {
  networkUUID: Scalars['UUID']['input'];
  numDaysLookback: Scalars['Int']['input'];
};

export type QueryNetworkRoutesForVirtualDeviceArgs = {
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type QueryNetworkUplinkQualityArgs = {
  filter: MetricsFilterInput;
  networkUUID: Scalars['UUID']['input'];
};

export type QueryNetworkUplinkThroughputArgs = {
  filter: MetricsFilterInput;
  networkUUID: Scalars['UUID']['input'];
};

export type QueryNetworksArgs = {
  filter: NetworksFilter;
};

export type QueryNetworksForCompanyArgs = {
  companySlug: Scalars['String']['input'];
};

export type QueryNetworksUplinkQualitiesArgs = {
  filter: MetricsFilterInput;
  networkUUIDs: Array<Scalars['UUID']['input']>;
};

export type QueryNosFeatureArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryNosHistoryForVirtualDeviceArgs = {
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type QueryNosVersionsArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  isGA?: InputMaybe<Scalars['Boolean']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};

export type QueryNotificationPreferenceArgs = {
  uuid: Scalars['UUID']['input'];
};

export type QueryNotificationPreferencesArgs = {
  input: GetNotificationPreferencesInput;
};

export type QueryNotificationSettingArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryNotificationSettingsForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryOneToOneNatRuleArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryOneToOneNatRulesForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryOutletInterfacesForVirtualDeviceArgs = {
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type QueryPermissionsArgs = {
  input: PermissionsInput;
};

export type QueryPhyInterfacesForVirtualDeviceArgs = {
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type QueryPolicyRoutingRuleArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryPolicyRoutingRulesForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryPortForwardingRuleArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryPortForwardingRulesForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryRackElevationArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryRackElevationsArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryRadioProfilesForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryRateLimitRuleArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryRateLimitRulesForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryRecentEventLogEventsArgs = {
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  limit?: Scalars['Int']['input'];
  networkUUID: Scalars['UUID']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  startTime?: InputMaybe<Scalars['DateTime']['input']>;
};

export type QueryRolesArgs = {
  input?: InputMaybe<RolesInput>;
};

export type QuerySpareHardwareDevicesForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QuerySpeedtestServersForNetworkArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QuerySsidsForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QuerySsoOptionsArgs = {
  input: SsoOptionsInput;
};

export type QueryStaticRouteArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryStaticRoutesForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryStatsForVirtualDeviceArgs = {
  filter: StatsFilter;
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type QueryStatsForVirtualDevicesArgs = {
  filter: NetworkStatsFilter;
  networkUUID: Scalars['UUID']['input'];
};

export type QuerySwitchAutoConfigMacAddressVlanBindingsForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QuerySwitchDiscardRatesPerPortArgs = {
  filter: MetricsFilterInput;
  input: MetricsSerialNumberDirectionInput;
};

export type QuerySwitchErrorRatesPerPortArgs = {
  filter: MetricsFilterInput;
  input: MetricsSerialNumberDirectionInput;
};

export type QuerySwitchMacTableArgs = {
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type QuerySwitchPortMetricsArgs = {
  filter: MetricsFilterInput;
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type QuerySwitchPortMetricsRateArgs = {
  filter: MetricsFilterInput;
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type QuerySwitchPortStatsArgs = {
  lookbackHours?: InputMaybe<Scalars['Int']['input']>;
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type QuerySwitchThroughputPerPortArgs = {
  filter: MetricsFilterInput;
  input: MetricsSerialNumberDirectionInput;
};

export type QuerySystemInfoStatForVirtualDeviceArgs = {
  filter: SystemInfoStatsFilter;
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type QueryUnassignedHardwareDevicesForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryUplinkPhyInterfacesForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryUserNetworkAccessExpiryArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryValidChannelsArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryVirtualDeviceArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryVirtualDeviceTagsByNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryVirtualDevicesByTagArgs = {
  tagUUID: Scalars['UUID']['input'];
};

export type QueryVirtualDevicesForNetworkArgs = {
  filter?: InputMaybe<DevicesForNetworkFilter>;
  networkUUID: Scalars['UUID']['input'];
};

export type QueryVlanArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryVlansArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryWanSpeedtestJobArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryWanSpeedtestJobsForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryWifiObserverSettingsArgs = {
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type QueryWirelessClientConnectionEventsByApArgs = {
  apVirtualDeviceUUID: Scalars['UUID']['input'];
  filter: ClientMetricsTimeseriesFilterInput;
  limit?: InputMaybe<Scalars['Int']['input']>;
  networkUUID: Scalars['UUID']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryWirelessClientConnectionEventsByClientArgs = {
  filter: ClientMetricsTimeseriesFilterInput;
  limit?: InputMaybe<Scalars['Int']['input']>;
  macAddress: Scalars['MacAddress']['input'];
  networkUUID: Scalars['UUID']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryWirelessClientMetricsByApArgs = {
  apVirtualDeviceUUID: Scalars['UUID']['input'];
  filter: ClientMetricsTimeseriesFilterInput;
  networkUUID: Scalars['UUID']['input'];
};

export type QueryWirelessClientMetricsByClientArgs = {
  filter: ClientMetricsTimeseriesFilterInput;
  macAddress: Scalars['MacAddress']['input'];
  networkUUID: Scalars['UUID']['input'];
};

/** A response containing the results of a cable test. */
export type RpcCableTestResponse = {
  __typename?: 'RPCCableTestResponse';
  /** The result for twisted pair A. */
  pairA: RpcCableTestTwistedPairResult;
  /** The result for twisted pair B. */
  pairB: RpcCableTestTwistedPairResult;
  /** The result for twisted pair C. */
  pairC: RpcCableTestTwistedPairResult;
  /** The result for twisted pair D. */
  pairD: RpcCableTestTwistedPairResult;
};

/** Result of the cable test for a single twisted pair. */
export type RpcCableTestTwistedPairResult = {
  __typename?: 'RPCCableTestTwistedPairResult';
  /** The estimated length of the twisted pair in meters. */
  estimatedLengthInMeters: Scalars['Int']['output'];
  /** The status of the twisted pair in the cable test. */
  status: RpcCableTestTwistedPairStatus;
};

/**
 * Cable test checks each pair of wires (Ethernet cables have 4 pairs) to find if they are crimped correctly.
 * The wires can be good, open (not connected), short, or have crosstalk (bad cabling).
 * Emplus's implementation can also estimate the length of the Ethernet cable (in meters).
 * Cable test can only be run on RJ45 Ethernet ports and not on SFP ports.
 */
export enum RpcCableTestTwistedPairStatus {
  /** The twisted pair has crosstalk (bad cabling causing interference between pairs). */
  Crosstalk = 'CROSSTALK',
  /** The twisted pair is functioning correctly. */
  Good = 'GOOD',
  /** The twisted pair is open (not connected at one end). */
  Open = 'OPEN',
  /** The twisted pair has both an open and a short circuit. */
  OpenShort = 'OPEN_SHORT',
  /** The twisted pair has a short circuit. */
  Short = 'SHORT',
  /** The status of the twisted pair is unknown. */
  Unknown = 'UNKNOWN',
}

/** A request to run an EAPOL test on a WOS access point. */
export type RpcEapolTestForSerialInput = {
  /** The UUID of the RADIUS server profile (either primary or secondary encryption 802.1X) to use for the EAPOL test. */
  Encryption8021XUUID: Scalars['UUID']['input'];
  /** The password to use for the EAPOL test. */
  password: Scalars['String']['input'];
  /** The serial number of the device to run the EAPOL test on. */
  serialNumber: Scalars['String']['input'];
  /** The UUID of the SSID to use for the EAPOL test. An EAPOL test without `ssidUuid` will omit sending this field to the authentication server. */
  ssidUuid?: InputMaybe<Scalars['UUID']['input']>;
  /** The username to use for the EAPOL test. */
  user: Scalars['String']['input'];
};

/** A response containing the results of an EAPOL test. */
export type RpcEapolTestForSerialResponse = {
  __typename?: 'RPCEapolTestForSerialResponse';
  /** A human-readable error message if the EAPOL test failed with an unknown error. */
  error_reason?: Maybe<Scalars['String']['output']>;
  /** The serial number of the device this test was run on. */
  serialNumber: Scalars['String']['output'];
  /** The status of the EAPOL server response. */
  status: RpcEapolTestForSerialStatus;
};

/**
 * EAPOL (Extensible Authentication Protocol over LAN) is a network authentication protocol used in IEEE 802.1X to
 * authenticate users and computers in a network through a RADIUS server. It is used in wireless networks (WPA-Enterprise)
 * to secure access. The EAPOL test RPC runs directly on a WOS access point and simulates a client connecting wirelessly to
 * an AP performing RADIUS authentication. A successful EAPOL test means that the AP can authenticate a client with the
 * RADIUS server using the provided credentials and validates that a client can connect to the network.
 */
export enum RpcEapolTestForSerialStatus {
  /** The EAPOL test failed due to bad user/password credentials. */
  FailureBadCredentials = 'FAILURE_BAD_CREDENTIALS',
  /** The EAPOL test failed due to an incorrect RADIUS secret. */
  FailureIncorrectRadiusSecret = 'FAILURE_INCORRECT_RADIUS_SECRET',
  /** The EAPOL test failed due to the server not responding in time. */
  FailureRadiusServerTimeout = 'FAILURE_RADIUS_SERVER_TIMEOUT',
  /** The EAPOL test failed due to an unknown error. See `error_reason` for more details. */
  FailureUnknown = 'FAILURE_UNKNOWN',
  /** The EAPOL test was successful. */
  Success = 'SUCCESS',
}

/** A request to run EAPOL tests on multiple WOS access points. */
export type RpcEapolTestForSerialsInput = {
  /** The UUID of the RADIUS server profile (either primary or secondary encryption 802.1X) to use for the EAPOL tests. */
  Encryption8021XUUID: Scalars['UUID']['input'];
  /** The password to use for the EAPOL tests. */
  password: Scalars['String']['input'];
  /** The serial numbers of the devices to run the EAPOL tests on. */
  serialNumbers: Array<Scalars['String']['input']>;
  /** The UUID of the SSID to use for the EAPOL tests. An EAPOL test without `ssidUuid` will omit sending this field to the authentication server. */
  ssidUuid?: InputMaybe<Scalars['UUID']['input']>;
  /** The username to use for the EAPOL tests. */
  user: Scalars['String']['input'];
};

/** A request to power-cycle an outlet on a Power Distribution Unit (PDU). */
export type RpcpduOutletCycleInput = {
  /** A list of UUIDs identifying the outlets to cycle. */
  outletUUIDs: Array<Scalars['UUID']['input']>;
};

/** A request to ping from a device to a specified host. */
export type RpcPingInput = {
  /** The number of ping packets the device sends at once. Defaults to 5. */
  burstSize?: Scalars['Int']['input'];
  /** The host IP address or domain name to ping. */
  host: Scalars['String']['input'];
  /** The interval between bursts of pings, in seconds. Defaults to 1 second. */
  intervalSeconds?: Scalars['Int']['input'];
  /** The number of bursts to send. Defaults to 1. */
  numberOfBursts?: Scalars['Int']['input'];
  /** The UUID of the physical interface if the user selects to ping out of a specific WAN port. */
  phyInterfaceUUID?: InputMaybe<Scalars['UUID']['input']>;
  /** The serial number of the device issuing the ping. */
  serialNumber: Scalars['String']['input'];
  /** Maximum number of seconds before the ping command exits, regardless of how many packets have been sent or received. Defaults to 5 seconds. */
  timeoutSeconds?: Scalars['Int']['input'];
  /** The UUID of the VLAN if the user selects to ping out of a VLAN interface (for both client ping and WAN ping). */
  vlanUUID?: InputMaybe<Scalars['UUID']['input']>;
  /** The WAN interface to use for the ping (`wan0` or `wan1`). Defaults to `wan0`. Config-1 only. */
  wanInterface?: InputMaybe<Scalars['String']['input']>;
};

/** A response containing the results of a ping operation. */
export type RpcPingResponse = {
  __typename?: 'RPCPingResponse';
  /** The mean round-trip time in milliseconds, if any pings were successful. */
  roundTripTimeMs?: Maybe<Scalars['Int']['output']>;
  /** The number of successful pings divided by the total number of pings, expressed as a float between 0 and 1. */
  successRate: Scalars['Float']['output'];
};

/** A request to power-cycle a port on a Meter switch. */
export type RpcSwitchPortCycleInput = {
  /** The number of seconds between disabling and re-enabling the port (range 1-30 seconds). Defaults to 3 seconds. */
  cycleDownSec?: InputMaybe<Scalars['Int']['input']>;
  /** If true, cycle the Power over Ethernet (PoE) on the port by disabling and re-enabling it. */
  poeCycle?: InputMaybe<Scalars['Boolean']['input']>;
  /** If true, cycle the port by disabling and re-enabling it. */
  portCycle?: InputMaybe<Scalars['Boolean']['input']>;
  /** The port number to cycle. */
  portNumber: Scalars['Int']['input'];
  /** The serial number of the switch whose port needs cycling. */
  serialNumber: Scalars['String']['input'];
};

/** A request to run a WAN speed test on a Meter device. */
export type RpcwanSpeedtestInput = {
  /** Config 2 only. The UUID of the physical interface. If omitted, will select the currently active WAN. */
  phyInterfaceUUID?: InputMaybe<Scalars['UUID']['input']>;
  /** The serial number of the device to run the speed test on. */
  serialNumber: Scalars['String']['input'];
  /** Config 2 only. The server ID retrieved with a call to `speedtestServersForNetwork`. If omitted, the controller will auto-select a server. */
  serverID?: InputMaybe<Scalars['String']['input']>;
  /** The WAN interface to run the speed test on (e.g., `WAN1`, `WAN2`). */
  wan?: InputMaybe<Wan>;
};

/** A response containing the results of a WAN speed test. */
export type RpcwanSpeedtestResponse = {
  __typename?: 'RPCWANSpeedtestResponse';
  /** Config 2 only. The distance to the target server, in kilometers. */
  distance?: Maybe<Scalars['Float']['output']>;
  /** The measured download speed in megabits per second. */
  downloadSpeedMbps: Scalars['Float']['output'];
  /** Config 2 only. The measured jitter in nanoseconds. */
  jitterNs?: Maybe<Scalars['Int']['output']>;
  /** Config 2 only. The measured latency in nanoseconds. */
  latencyNs?: Maybe<Scalars['Int']['output']>;
  /** Config 2 only. The host of the target server the test was run against. */
  targetHost?: Maybe<Scalars['String']['output']>;
  /** Config 2 only. The name of the target server the test was run against. */
  targetName?: Maybe<Scalars['String']['output']>;
  /** The measured upload speed in megabits per second. */
  uploadSpeedMbps: Scalars['Float']['output'];
};

/** Results of a command executed during the Observer test. */
export type RpcWosObserverCommandResults = {
  __typename?: 'RPCWosObserverCommandResults';
  /** Average duration of the command execution in msec. */
  avgDurationMsec: Scalars['Int']['output'];
  /** Average number of bytes transferred per command execution. */
  avgNumBytes: Scalars['Int']['output'];
  /** Index of the test command, matching the request. */
  index: Scalars['Int']['output'];
  /** Jitter (variability) in the command execution duration in msec. */
  jitterMsec: Scalars['Int']['output'];
  /** Maximum duration of the command execution in msec. */
  maxDurationMsec: Scalars['Int']['output'];
  /** Minimum duration of the command execution in msec. */
  minDurationMsec: Scalars['Int']['output'];
  /** Number of attempts made to run the command. */
  numAttempts: Scalars['Int']['output'];
  /** Ratio of successful attempts to total attempts, expressed as a float between 0 and 1. */
  successRatio: Scalars['Float']['output'];
};

/** A request to run Observer tests on a Meter Observer device. */
export type RpcWosObserverInput = {
  /** The UUID of the SSID to use for the Observer test. If empty with `triggerTest` set to true, will simply cancel the previous test. */
  SSIDUUID?: InputMaybe<Scalars['UUID']['input']>;
  /** The Wi-Fi band on which the test should be run (e.g., 2G, 5G, 6G). */
  band?: InputMaybe<RadioBand>;
  /** A list of commands to run during the Observer test. */
  commands?: InputMaybe<Array<WosObserverCommandInput>>;
  /** Indicates whether to run the Observer test in debug mode. */
  debugMode?: InputMaybe<Scalars['Boolean']['input']>;
  /** The duration of the test in msec. */
  durationMsec?: InputMaybe<Scalars['Int']['input']>;
  /** If true, retrieves the results from a previously triggered Observer test. */
  getTestResults?: InputMaybe<Scalars['Boolean']['input']>;
  /** Hostname to use with DHCP; if unset, the firmware generates it. */
  hostname?: InputMaybe<Scalars['String']['input']>;
  /** If set, limits the number of spatial streams for the test. */
  numSpatialStreams?: InputMaybe<Scalars['Int']['input']>;
  /** The 802.1X password to use for authentication in the Observer test. */
  password8021X?: InputMaybe<Scalars['String']['input']>;
  /** The serial number of the Observer device to run the test on. */
  serialNumber: Scalars['String']['input'];
  /** The serial number of the target device the Observer will focus on or get results for. */
  targetSerialNumber?: InputMaybe<Scalars['String']['input']>;
  /** The timeout for the connection attempt in msec. */
  timeoutMsec?: InputMaybe<Scalars['Int']['input']>;
  /** When WPA3 transition is enabled, specifies whether to use WPA2 (true) or WPA3 (false). */
  transitionUseWpa2?: InputMaybe<Scalars['Boolean']['input']>;
  /** If true, triggers the Observer test on the device. */
  triggerTest?: InputMaybe<Scalars['Boolean']['input']>;
  /** Indicates whether to use Protected Management Frames (802.11w). With WPA3, 802.11w is used anyway. */
  use11w?: InputMaybe<Scalars['Boolean']['input']>;
  /** Indicates whether to use the scanning radio for this test. */
  useScanningRadio?: InputMaybe<Scalars['Boolean']['input']>;
  /** The 802.1X username to use for authentication in the Observer test. */
  username8021X?: InputMaybe<Scalars['String']['input']>;
};

/** A response containing the status and results of an Observer test. */
export type RpcWosObserverResponse = {
  __typename?: 'RPCWosObserverResponse';
  /** A reason for a failure, if any. */
  failureReason?: Maybe<Scalars['String']['output']>;
  /** The results of the Observer tests. */
  testResults?: Maybe<Array<RpcWosObserverTestResults>>;
  /** The status of a trigger request for the Observer test. */
  triggerStatus: RpcWosObserverResponseStatus;
};

/** Status of the Observer test trigger response. */
export enum RpcWosObserverResponseStatus {
  /** The Observer test failed to trigger. */
  TriggerFail = 'TRIGGER_FAIL',
  /** The Observer test was successfully triggered. */
  TriggerOk = 'TRIGGER_OK',
  /** Unspecified status. */
  Unspecified = 'UNSPECIFIED',
}

/** Results of an Observer test. */
export type RpcWosObserverTestResults = {
  __typename?: 'RPCWosObserverTestResults';
  /** Duration of the DHCP phase in milliseconds. */
  DHCPDurationMsec: Scalars['Int']['output'];
  /** Duration of the association phase in milliseconds. */
  assocDurationMsec: Scalars['Int']['output'];
  /** Duration of the authentication phase in milliseconds. */
  authDurationMsec: Scalars['Int']['output'];
  /** The Wi-Fi band on which the test was run. */
  band: RadioBand;
  /** Results of all commands executed during the test. */
  commandResults?: Maybe<Array<RpcWosObserverCommandResults>>;
  /** Duration of the scan phase in milliseconds. */
  scanDurationMsec: Scalars['Int']['output'];
  /** The UUID of the SSID used for the Observer test. */
  ssidUuid?: Maybe<Scalars['UUID']['output']>;
  /** Start time of the test. */
  startedAt: Scalars['DateTime']['output'];
  /** The serial number of the target device for which the Observer test was run. */
  targetSerialNumber: Scalars['String']['output'];
};

/** A response containing the status of a packet capture request. */
export type RpcWosPacketCaptureResponse = {
  __typename?: 'RPCWosPacketCaptureResponse';
  /** The job ID of the packet capture. */
  jobId: Scalars['String']['output'];
  /** Status of the packet capture request. */
  status: RpcWosPacketCaptureResponseStatus;
};

/** Status of the packet capture response. */
export enum RpcWosPacketCaptureResponseStatus {
  /** The packet capture request failed. */
  Fail = 'FAIL',
  /** The packet capture request was successful. */
  Ok = 'OK',
  /** Unspecified status. */
  Unspecified = 'UNSPECIFIED',
}

/** Run a packet capture on a device. */
export type RpcWosPacketCaptureStartInput = {
  /** The filter ID to be applied to the packet capture. Not yet implemented in firmware. */
  filterID: Scalars['Int']['input'];
  /** The job ID of the packet capture. */
  jobId: Scalars['String']['input'];
  /** The monitor interface (radio) on which to conduct the packet capture. */
  radios: WosPacketCaptureCommandMonitor;
  /** The presigned public S3 URL where the packet capture file needs to be uploaded. */
  resultUploadURL: Scalars['String']['input'];
  /** The maximum size limit of the packet capture file in megabytes. */
  sizeLimitMb: Scalars['Int']['input'];
  /** The maximum duration of the packet capture in seconds. */
  timeLimitSec: Scalars['Int']['input'];
};

/** Stop a packet capture on a device. */
export type RpcWosPacketCaptureStopInput = {
  /** The job ID of the packet capture to stop. */
  jobId: Scalars['String']['input'];
};

/** A rack elevation is a 2D representation of racks of hardware in a datacenter or supply closet. */
export type RackElevation = {
  __typename?: 'RackElevation';
  /** The unique identifier for the rack elevation. */
  UUID: Scalars['UUID']['output'];
  /** The list of devices that are attached to this rack elevation. */
  devices: Array<RackElevationDevice>;
  /** The left-to-right position of the rack relative to others. */
  horizontalIndex?: Maybe<Scalars['Int']['output']>;
  /** Human-readable name for the rack elevation. */
  label: Scalars['String']['output'];
  /** The unique identifier of the network to which this rack elevation belongs. */
  networkUUID: Scalars['UUID']['output'];
  /** Notes associated with this rack elevation. */
  notes?: Maybe<Array<RackElevationNote>>;
  /** The number of rack units (like slots) for the rack elevation. */
  rackMountUnitCount: Scalars['Int']['output'];
};

/** A device attached to a rack elevation. */
export type RackElevationDevice = {
  __typename?: 'RackElevationDevice';
  /** The unique identifier for the rack elevation device. */
  UUID: Scalars['UUID']['output'];
  /** Whether the device is front-facing or rear-facing. */
  isFrontFacing: Scalars['Boolean']['output'];
  /** If the device is a switch, whether it supports PoE. */
  isPoESupported?: Maybe<Scalars['Boolean']['output']>;
  /** A friendly name for display purposes. */
  label?: Maybe<Scalars['String']['output']>;
  /** The hardware device's model. */
  model?: Maybe<Scalars['String']['output']>;
  /** Additional context or comments about the device. */
  notes?: Maybe<Scalars['String']['output']>;
  /** The number of ports on the device. */
  portCount?: Maybe<Scalars['Int']['output']>;
  /** The unique identifier of the rack elevation to which this device is attached. */
  rackElevationUUID: Scalars['UUID']['output'];
  /** The indexes of the rack units that the device occupies. */
  rackMountUnitIndexes: Array<Scalars['Int']['output']>;
  /** The type of the device (e.g., switch, patch panel, etc.). */
  type: RackElevationDeviceType;
  /** The virtual device associated with this rack elevation device. */
  virtualDevice?: Maybe<VirtualDevice>;
  /** If the device is associated with a virtual device, this is the UUID of that virtual device. */
  virtualDeviceUUID?: Maybe<Scalars['UUID']['output']>;
};

/** An enumeration of possible device types that can be attached to a rack elevation. */
export enum RackElevationDeviceType {
  /** A cable management device. */
  CableManagement = 'CABLE_MANAGEMENT',
  /** [Deprecated] Existing equipment. */
  ExistingEquipment = 'EXISTING_EQUIPMENT',
  /** A fiber optic device. */
  Fiber = 'FIBER',
  /** An Internet Service Provider (ISP) device. */
  Isp = 'ISP',
  /** A Meter access point device. */
  MeterAccessPoint = 'METER_ACCESS_POINT',
  /** A Meter controller device. */
  MeterController = 'METER_CONTROLLER',
  /** A Meter switch device. */
  MeterSwitch = 'METER_SWITCH',
  /** A non-Meter switch device. */
  NonMeterSwitch = 'NON_METER_SWITCH',
  /** Other type of device. */
  Other = 'OTHER',
  /** [Deprecated] A patch box device. */
  PatchBox = 'PATCH_BOX',
  /** A patch panel. */
  PatchPanel = 'PATCH_PANEL',
  /** A universal power supply (UPS) device. */
  UniversalPowerSupply = 'UNIVERSAL_POWER_SUPPLY',
  /** [Deprecated] Unknown device type. */
  Unknown = 'UNKNOWN',
}

/** A note associated with a rack elevation, applicable to a range of rack units. */
export type RackElevationNote = {
  __typename?: 'RackElevationNote';
  /** The content of the note. */
  note: Scalars['String']['output'];
  /** The ending rack mount unit index where the note applies. */
  rackMountUnitIndexEnd: Scalars['Int']['output'];
  /** The starting rack mount unit index where the note applies. */
  rackMountUnitIndexStart: Scalars['Int']['output'];
};

/** Input type for creating or updating a rack elevation note. */
export type RackElevationNoteInput = {
  /** The content of the note. */
  note: Scalars['String']['input'];
  /** The ending rack mount unit index for the note. */
  rackMountUnitIndexEnd: Scalars['Int']['input'];
  /** The starting rack mount unit index for the note. */
  rackMountUnitIndexStart: Scalars['Int']['input'];
};

/** An enumeration of radio bands. */
export enum RadioBand {
  /** Represents the 2.4 GHz radio band. */
  Band_2_4G = 'BAND_2_4G',
  /** Represents the 5 GHz radio band. */
  Band_5G = 'BAND_5G',
}

/** Contains configuration for wireless radios. A profile is a way to apply the same configuration to multiple APs. */
export type RadioProfile = {
  __typename?: 'RadioProfile';
  /** The unique identifier for the radio profile. */
  UUID: Scalars['UUID']['output'];
  /**
   * Only set if band2_4GAutoChannelIsEnabled is true.
   * This is the list of channels that are permitted for ACS (auto channel selection) to
   * select from and is user-configurable.
   */
  band2_4GAllowedChannels?: Maybe<Array<Scalars['Int']['output']>>;
  /** Indicates whether auto channel selection is enabled for the 2.4 GHz band. */
  band2_4GAutoChannelIsEnabled: Scalars['Boolean']['output'];
  /** Indicates whether auto channel width selection is enabled for the 2.4 GHz band. */
  band2_4GAutoChannelWidthIsEnabled: Scalars['Boolean']['output'];
  /** Indicates whether automatic transmit power adjustment is enabled for the 2.4 GHz band. */
  band2_4GAutoTxPowerIsEnabled: Scalars['Boolean']['output'];
  /**
   * Only set if band2_4GAutoTxPowerIsEnabled is true.
   * The maximum transmit power in dBm for the 2.4 GHz band when auto transmit power is enabled.
   */
  band2_4GAutoTxPowerMaxdBm?: Maybe<Scalars['Int']['output']>;
  /**
   * Only set if band2_4GAutoTxPowerIsEnabled is true.
   * The minimum transmit power in dBm for the 2.4 GHz band when auto transmit power is enabled.
   */
  band2_4GAutoTxPowerMindBm?: Maybe<Scalars['Int']['output']>;
  /**
   * Only set if band2_4GAutoChannelWidthIsEnabled is false.
   * The channel width in MHz for the 2.4 GHz band when auto channel width is disabled.
   */
  band2_4GChannelWidthMHz?: Maybe<Scalars['Int']['output']>;
  /** Indicates whether the 2.4 GHz band is enabled. */
  band2_4GIsEnabled: Scalars['Boolean']['output'];
  /**
   * Only set if band5GAutoChannelIsEnabled is true.
   * This is the list of channels that are permitted for ACS (auto channel selection) to
   * select from and is user-configurable.
   */
  band5GAllowedChannels?: Maybe<Array<Scalars['Int']['output']>>;
  /** Indicates whether auto channel selection is enabled for the 5 GHz band. */
  band5GAutoChannelIsEnabled: Scalars['Boolean']['output'];
  /** Indicates whether auto channel width selection is enabled for the 5 GHz band. */
  band5GAutoChannelWidthIsEnabled: Scalars['Boolean']['output'];
  /** Indicates whether automatic transmit power adjustment is enabled for the 5 GHz band. */
  band5GAutoTxPowerIsEnabled: Scalars['Boolean']['output'];
  /**
   * Only set if band5GAutoTxPowerIsEnabled is true.
   * The maximum transmit power in dBm for the 5 GHz band when auto transmit power is enabled.
   */
  band5GAutoTxPowerMaxdBm?: Maybe<Scalars['Int']['output']>;
  /**
   * Only set if band5GAutoTxPowerIsEnabled is true.
   * The minimum transmit power in dBm for the 5 GHz band when auto transmit power is enabled.
   */
  band5GAutoTxPowerMindBm?: Maybe<Scalars['Int']['output']>;
  /**
   * Only set if band5GAutoChannelWidthIsEnabled is false.
   * The channel width in MHz for the 5 GHz band when auto channel width is disabled.
   */
  band5GChannelWidthMHz?: Maybe<Scalars['Int']['output']>;
  /** Indicates whether the 5 GHz band is enabled. */
  band5GIsEnabled: Scalars['Boolean']['output'];
  /** The timestamp when the radio profile was created. */
  createdAt: Scalars['DateTime']['output'];
  /** Indicates whether this profile is the default profile for the network. */
  isDefault: Scalars['Boolean']['output'];
  /** The name of the radio profile. */
  name: Scalars['String']['output'];
  /** The unique identifier of the network to which this radio profile belongs. */
  networkUUID: Scalars['UUID']['output'];
  /** The ISO2 country code where the device is located. */
  regulatoryDomain: Scalars['String']['output'];
  /** The timestamp when the radio profile was last updated. */
  updatedAt: Scalars['DateTime']['output'];
  /** The list of virtual devices (access points) attached to this radio profile. */
  virtualDevices: Array<VirtualDevice>;
};

/** An individual Access Point's radio settings (channel, power). Other radio settings are configured in the radio profile and shared across multiple APs. */
export type RadioSettings = {
  __typename?: 'RadioSettings';
  /** The primary channel for the 2.4 GHz radio band. */
  band2_4GPrimaryChannel: Scalars['Int']['output'];
  /** The secondary channel for the 2.4 GHz radio band, if applicable. */
  band2_4GSecondaryChannel?: Maybe<Scalars['Int']['output']>;
  /** The transmit power in dBm for the 2.4 GHz radio band. */
  band2_4GTransmitPowerdBm: Scalars['Int']['output'];
  /** The primary channel for the 5 GHz radio band. */
  band5GPrimaryChannel: Scalars['Int']['output'];
  /** The secondary channel for the 5 GHz radio band, if applicable. */
  band5GSecondaryChannel?: Maybe<Scalars['Int']['output']>;
  /** The transmit power in dBm for the 5 GHz radio band. */
  band5GTransmitPowerdBm: Scalars['Int']['output'];
};

export type RateLimitPhyInterfaceBindingInput = {
  /** The UUID of the physical interface to bind or unbind the rate limit rule to/from. */
  phyInterfaceUUID: Scalars['UUID']['input'];
  /** The UUID of the rate limit rule. */
  rateLimitRuleUUID: Scalars['UUID']['input'];
};

/** Defines L2 and L3 rate limit rules for a network. A rate limit rule can be applied to multiple interfaces. */
export type RateLimitRule = {
  __typename?: 'RateLimitRule';
  /** Unique identifier for the rate limit rule. */
  UUID: Scalars['UUID']['output'];
  /** The timestamp when the rate limit rule was created. */
  createdAt: Scalars['DateTime']['output'];
  /** A description of the rate limit rule. */
  description?: Maybe<Scalars['String']['output']>;
  /** Destination MAC address to match for the rate limit rule. */
  dstMac?: Maybe<Scalars['MacAddress']['output']>;
  /** Destination port range to match for the rate limit rule. */
  dstPortRange?: Maybe<PortRange>;
  /** Destination IP address prefix to match for the rate limit rule. */
  dstPrefix?: Maybe<Scalars['IPPrefix']['output']>;
  /** Indicates whether the rate limit rule is currently enabled. */
  isEnabled: Scalars['Boolean']['output'];
  /** Specifies if the rate limit is applied per source IP/MAC address. */
  isPerSource: Scalars['Boolean']['output'];
  /** The name of the rate limit rule. */
  name: Scalars['String']['output'];
  /** The UUID of the network this rate limit rule belongs to. */
  networkUUID: Scalars['UUID']['output'];
  /** The physical interfaces that this rate limit rule is applied on. The same rate limit rule can be applied to multiple physical interfaces. */
  phyInterfaceBindings?: Maybe<Array<PhyInterface>>;
  /** The IANA IP protocol to apply this rule on. */
  protocol?: Maybe<IpProtocol>;
  /** The maximum download rate limit in kilobits per second. */
  rateLimitKbpsDownload?: Maybe<Scalars['Int']['output']>;
  /** The maximum upload rate limit in kilobits per second. */
  rateLimitKbpsUpload?: Maybe<Scalars['Int']['output']>;
  /** Source MAC address to match for the rate limit rule. */
  srcMac?: Maybe<Scalars['MacAddress']['output']>;
  /** Source port range to match for the rate limit rule. */
  srcPortRange?: Maybe<PortRange>;
  /** Source IP address prefix to match for the rate limit rule. */
  srcPrefix?: Maybe<Scalars['IPPrefix']['output']>;
  /** The timestamp when the rate limit rule was last updated. */
  updatedAt: Scalars['DateTime']['output'];
  /** The VLAN interfaces that this rate limit rule is applied on. The same rate limit rule can be applied to multiple VLANs. */
  vlanBindings?: Maybe<Array<Vlan>>;
};

export type RateLimitVlanBindingInput = {
  /** The UUID of the rate limit rule. */
  rateLimitRuleUUID: Scalars['UUID']['input'];
  /** The UUID of the VLAN to bind or unbind the rate limit rule to/from. */
  vlanUUID: Scalars['UUID']['input'];
};

/** Enumeration of user roles. */
export enum Role {
  /** Operator role with elevated permissions. */
  Operator = 'OPERATOR',
}

/**
 * A RoleAssignment denotes the role of a user in the context of a company or company & network.
 *
 * In the case of an Operator role, the companySlug and networkUUID fields will be null.
 */
export type RoleAssignment = {
  __typename?: 'RoleAssignment';
  /**
   * The slug of the company associated with the role assignment.
   *
   * Omitted if the role is global or operator-level.
   */
  companySlug?: Maybe<Scalars['String']['output']>;
  /** The name of the role assigned to the user. */
  name: RoleName;
  /**
   * The UUID of the network associated with the role assignment.
   *
   * Omitted if the role is not network-specific.
   */
  networkUUID?: Maybe<Scalars['UUID']['output']>;
};

/** Result type indicating whether the user has the specified role. */
export type RoleCheckResult = {
  __typename?: 'RoleCheckResult';
  /** True if the user has the specified role, false otherwise. */
  hasRole: Scalars['Boolean']['output'];
};

/** RoleName represents the various roles that can be assigned to users within the system. */
export enum RoleName {
  /** Company Global Admin role, grants administrative privileges across the entire company. */
  CompanyGlobalAdmin = 'COMPANY_GLOBAL_ADMIN',
  /** Company Guest role, limited access. */
  CompanyGuest = 'COMPANY_GUEST',
  /** Company Network Admin role, grants administrative privileges within a specific network. */
  CompanyNetworkAdmin = 'COMPANY_NETWORK_ADMIN',
  /** Company Standard User role, typical user role with standard access rights. */
  CompanyStandardUser = 'COMPANY_STANDARD_USER',
  /** Operator role, has every permission. */
  Operator = 'OPERATOR',
}

/** Input type specifying the context for which to fetch the roles for a given user. */
export type RolesInput = {
  /**
   * The slug of the company to fetch roles for.
   *
   * If omitted, roles without a company context are retrieved.
   */
  companySlug?: InputMaybe<Scalars['String']['input']>;
};

export type SfpModuleInfo = {
  __typename?: 'SFPModuleInfo';
  /** The speed capability of the SFP module. */
  moduleSpeed: Scalars['String']['output'];
  /** The type of the SFP module. */
  moduleType: Scalars['String']['output'];
  /** The part name of the SFP module. */
  partName: Scalars['String']['output'];
  /** The serial number of the SFP module. */
  serialNumber: Scalars['String']['output'];
  /** The vendor of the SFP module. */
  vendor: Scalars['String']['output'];
};

/** Service set identifiers (SSIDs) for a network. */
export type Ssid = {
  __typename?: 'SSID';
  /** BSSIDs are MAC addresses that identify a radio band / SSID pair for an access point. */
  BSSIDs: Array<Bssid>;
  /** Unique identifier for the SSID. */
  UUID: Scalars['UUID']['output'];
  /** The lowest rate at which a client can connect to the 2.4 GHz radio, in kilobits per second. */
  band2GMinimumBasicRateKbps?: Maybe<Scalars['Int']['output']>;
  /** The lowest rate at which a client can connect to the 5 GHz radio, in kilobits per second. */
  band5GMinimumBasicRateKbps?: Maybe<Scalars['Int']['output']>;
  /** Client steering allows devices to select an optimal access point for better connectivity and performance. */
  clientSteeringAssociationSteeringIsEnabled: Scalars['Boolean']['output'];
  /** The minimum number of clients on an SSID before client steering is attempted. */
  clientSteeringLoadBalancingClientMinThreshold?: Maybe<Scalars['Int']['output']>;
  /** Post association steering allows devices to move to a better access point after they have already connected. */
  clientSteeringPostassociationSteeringIsEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** The minimum RSSI signal strength a client must have before pre-association client steering is attempted. */
  clientSteeringPreassociationRSSIMinThreshold?: Maybe<Scalars['Int']['output']>;
  /** Timestamp when the SSID was created. */
  createdAt: Scalars['DateTime']['output'];
  /** Description of the SSID. */
  description?: Maybe<Scalars['String']['output']>;
  /** The access points that will not broadcast this SSID, if isEnabledForAllAccessPoints is true. */
  disabledAccessPointVirtualDevices?: Maybe<Array<AccessPointVirtualDevice>>;
  /** The DTIM (Delivery Traffic Indication Message) period is a value in beacon frames that indicates how often clients should wake up to receive multicast and broadcast traffic. */
  dtimPeriod?: Maybe<Scalars['Int']['output']>;
  /** Dynamic VLAN is a setting to use the VLAN specified by an external RADIUS server's response after authentication. If null, then the VLAN specified by `vlan` is used. */
  dynamicVLANMode?: Maybe<SsidDynamicVlanMode>;
  /** The access points that will broadcast this SSID, if isEnabledForAllAccessPoints is false. */
  enabledAccessPointVirtualDevices?: Maybe<Array<AccessPointVirtualDevice>>;
  /**
   * 802.11w is a security standard that protects management frames from being forged by an attacker.
   * For WPA3 PSK, this field is required and must be REQUIRED.
   * For WPA3 Transition, this field is required but can be REQUIRED or OPTIONAL.
   * For WPA2, this field is optional.
   */
  encryption80211wProtectedManagementFramesMode?: Maybe<SsidEncryption80211wProtectedManagementFramesMode>;
  /** Encryption protocol used by the SSID. If null, this is an open network. */
  encryptionProtocol?: Maybe<SsidEncryptionProtocol>;
  /** An optional value for the Hotspot 2.0 Access network type, as defined in Table 9-214 of 802.11-2016. */
  hs20AccessNetworkType?: Maybe<Scalars['Int']['output']>;
  /** A list of Hotspot 2.0 domain names. */
  hs20DomainNames?: Maybe<Array<Scalars['String']['output']>>;
  /** An option to enable/disable Hotspot 2.0, aka Passpoint. */
  hs20Enabled: Scalars['Boolean']['output'];
  /** A boolean to represent whether a Hotspot 2.0 profile is attached to the SSID (has any fields set). */
  hs20HasProfile: Scalars['Boolean']['output'];
  /** A list of MCC/MNC (Mobile Country Codes and Mobile Network Codes) pairs, formatted as "MCC,MNC". */
  hs20MccMncPairs?: Maybe<Array<Scalars['String']['output']>>;
  /** A list of Hotspot 2.0 NAI (Network Access Identifier) realms; format follows hostapd format for nai_realm option (see hostapd documentation). */
  hs20NaiRealms?: Maybe<Array<Scalars['String']['output']>>;
  /** A list of Operator names for Hotspot 2.0 (one per language). */
  hs20OperatorNames?: Maybe<Array<Scalars['String']['output']>>;
  /** A list of Hotspot 2.0 Consortium OIs (Organization Identifiers). */
  hs20RoamingConsortiumOIs?: Maybe<Array<Scalars['String']['output']>>;
  /** An optional value for the Hotspot 2.0 Venue group, as defined in Table 9-62 of 802.11-2016. */
  hs20VenueGroup?: Maybe<Scalars['Int']['output']>;
  /**
   * A list of Venue names for Hotspot 2.0 (one per language); must be formatted with a two or three character language code separated by a colon from the venue name.
   * If at least one is set, hs20VenueGroup and hs20VenueType must be set.
   */
  hs20VenueNames?: Maybe<Array<Scalars['String']['output']>>;
  /** An optional value for the Hotspot 2.0 Venue type, as defined in Table 9-62 of 802.11-2016. */
  hs20VenueType?: Maybe<Scalars['Int']['output']>;
  /** Whether or not the SSID is enabled for 802.11ax (Wi-Fi 6) clients. */
  is80211axEnabled: Scalars['Boolean']['output'];
  /** Whether or not AP debug logging is enabled for APs broadcasting the SSID. */
  isAPDebugLogEnabled: Scalars['Boolean']['output'];
  /** Whether or not the SSID is allowed to be broadcasted on the 2.4 GHz radio. */
  isBand2GAllowed: Scalars['Boolean']['output'];
  /** Whether or not the SSID is allowed to be broadcasted on the 5 GHz radio. */
  isBand5GAllowed: Scalars['Boolean']['output'];
  /** Indicates whether the SSID is enabled. */
  isEnabled: Scalars['Boolean']['output'];
  /** If true, the SSID is enabled for all access points in the network, except for any access points specified in the disabledAccessPointsVirtualDevices field. If false, the SSID is enabled for only the access points specified in the enabledAccessPointVirtualDevices field. */
  isEnabledForAllAccessPoints: Scalars['Boolean']['output'];
  /** If true, the SSID is considered as guest and can be displayed on site for guest access, for example on a kiosk. */
  isGuest: Scalars['Boolean']['output'];
  /** Whether or not the broadcasted SSID is visible to clients; clients have to manually input the SSID to join the network. */
  isHidden: Scalars['Boolean']['output'];
  /** If true, clients on the same SSID cannot communicate with each other. */
  isIsolateClientsEnabled: Scalars['Boolean']['output'];
  /** True if multicast to unicast translation is enabled on the SSID. */
  isMulticastToUnicastEnabled: Scalars['Boolean']['output'];
  /** Threshold (number of clients in the multicast group) above which multicast to unicast is disabled (multicast frame is sent). If 0, no limit. */
  multicastToUnicastClientThreshold?: Maybe<Scalars['Int']['output']>;
  /** UUID of the network this SSID belongs to. */
  networkUUID: Scalars['UUID']['output'];
  /** The PSK for the SSID. If null, it is an open passwordless network or the encryption protocol does not use a PSK. */
  password?: Maybe<Scalars['String']['output']>;
  /** The last time the password was rotated, if password rotation is enabled. */
  passwordLastRotatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The next time the password will be rotated, if password rotation is enabled. */
  passwordNextRotationAt?: Maybe<Scalars['DateTime']['output']>;
  /** The period upon which the password is automatically rotated; a non-null value indicates that password rotation is enabled. */
  passwordRotationCadence?: Maybe<SsidPasswordRotationCadence>;
  /** The day of the month, in the network's local time zone, at which the password is automatically rotated. */
  passwordRotationDayOfMonthLocal?: Maybe<Scalars['Int']['output']>;
  /** The day of the week, in the network's local time zone, at which the password is automatically rotated (0 = Sunday, 1 = Monday, etc.) */
  passwordRotationDayOfWeekLocal?: Maybe<Scalars['Int']['output']>;
  /** The hour of the day, in the network's local time zone, at which the password is automatically rotated. */
  passwordRotationHourOfDayLocal?: Maybe<Scalars['Int']['output']>;
  /** Primary 802.1X encryption configuration for Enterprise authentication. */
  primaryEncryption8021X?: Maybe<Encryption8021X>;
  /** True if SSID supports RADIUS Change of Authorization (CoA). */
  radiusCoaEnabled: Scalars['Boolean']['output'];
  /** A port where RADIUS servers can send CoA requests. */
  radiusCoaPort?: Maybe<Scalars['Int']['output']>;
  /** 802.11k is a standard that helps wireless devices quickly find and switch to the best available access point. */
  roaming80211kIsEnabled: Scalars['Boolean']['output'];
  /** 802.11r is a standard that allows clients to roam more effectively between access points. */
  roaming80211rIsEnabled: Scalars['Boolean']['output'];
  /**
   * Used to indicate a group of APs sharing the same SSID between which a client can use 802.11r to roam. A 2 byte hex string.
   * Currently we do not allow callers to set this field.
   */
  roaming80211rMobilityDomain?: Maybe<Scalars['String']['output']>;
  /**
   * A 32 byte hex string key used for encryption in 802.11r for r0 and r1 key holders.
   * Currently we do not allow callers to set this field.
   */
  roaming80211rR0khR1khKey?: Maybe<Scalars['String']['output']>;
  /** 802.11v is a standard that allows devices to communicate with the network for better connection performance, such as optimizing roaming and power efficiency. */
  roaming80211vIsEnabled: Scalars['Boolean']['output'];
  /** A value to configure RTS/CTS (see QCA doc "Wireless LAN Access Point Command Reference" for more details). */
  rtsCtsEnabledCode?: Maybe<Scalars['Int']['output']>;
  /** Size of the PPDU on which RTS would be sent out. */
  rtsThreshold?: Maybe<Scalars['Int']['output']>;
  /** Secondary 802.1X encryption configuration for Enterprise authentication. */
  secondaryEncryption8021X?: Maybe<Encryption8021X>;
  /** Name of the SSID (network name). */
  ssid: Scalars['String']['output'];
  /** A number to uniquely identify the SSID within the network. Starts from zero. */
  ssidIndex: Scalars['Int']['output'];
  /** Timestamp when the SSID was last updated. */
  updatedAt: Scalars['DateTime']['output'];
  /** VLAN associated with the SSID. This field must be non-null if the SSID is enabled. */
  vlan?: Maybe<Vlan>;
};

export enum SsidDynamicVlanMode {
  /**
   * The VLAN specified by an external RADIUS server's response after authentication is used.
   * The VLAN specified by `vlan` is used as a fallback if the RADIUS server does not specify a VLAN.
   */
  Enabled = 'ENABLED',
  /**
   * The VLAN specified by an external RADIUS server's response after authentication is used.
   * The VLAN specified by `vlan` is ignored.
   */
  Required = 'REQUIRED',
}

export enum SsidEncryption80211wProtectedManagementFramesMode {
  /** Can only be set as optional for WPA2 / WPA2 Enterprise / WPA3 Transition. */
  Optional = 'OPTIONAL',
  /** Must be set to this for WPA3; for other encryption protocols, it is also permitted. */
  Required = 'REQUIRED',
}

export enum SsidEncryptionProtocol {
  /** Open network with MAC address authentication via RADIUS. */
  OpenMacAuthRadius = 'OPEN_MAC_AUTH_RADIUS',
  /** Wi-Fi Protected Access II (WPA2) Personal. */
  Wpa2 = 'WPA2',
  /** WPA2 Enterprise with 802.1X authentication. */
  Wpa2Enterprise = 'WPA2_ENTERPRISE',
  /** WPA3 Personal. */
  Wpa3 = 'WPA3',
  /** WPA3 Enterprise with 802.1X authentication. */
  Wpa3Enterprise = 'WPA3_ENTERPRISE',
  /** Supports clients that use WPA2 or WPA3. */
  Wpa3Transition = 'WPA3_TRANSITION',
}

export enum SsidPasswordRotationCadence {
  /** Rotate the password daily. */
  Daily = 'DAILY',
  /** Rotate the password monthly. */
  Monthly = 'MONTHLY',
  /** Rotate the password weekly. */
  Weekly = 'WEEKLY',
}

/** Specifies the type of admin portal link to generate for SSO configuration. */
export enum SsoAdminPortalType {
  /** WorkOS admin dashboard for viewing WorkOS Audit Logs. */
  AuditLogs = 'AUDIT_LOGS',
  /** WorkOS admin dashboard for configuring Directory Sync. */
  Dsync = 'DSYNC',
  /** WorkOS admin dashboard for configuring Log Streams. */
  LogStreams = 'LOG_STREAMS',
  /** WorkOS admin dashboard for configuring an SSO Provider. */
  SsoProvider = 'SSO_PROVIDER',
}

/** Represents a directory that is connected to the company via WorkOS. */
export type SsoConnectedDirectory = {
  __typename?: 'SSOConnectedDirectory';
  /** The date and time when the directory was created. */
  createdAt: Scalars['DateTime']['output'];
  /** The state of the connected directory. */
  state: SsoState;
  /** The type of the connected directory. */
  type: SsoDirectoryTypes;
};

/** Represents an SSO connection for a company. */
export type SsoConnection = {
  __typename?: 'SSOConnection';
  /** The date and time when the SSO connection was created. */
  connectedAt: Scalars['DateTime']['output'];
  /** The current state of the SSO connection. */
  state: SsoConnectionState;
  /** The type of the SSO connection. */
  type: SsoConnectionType;
};

/** Represents the state of an SSO connection. */
export enum SsoConnectionState {
  /** The SSO connection is active. */
  Active = 'Active',
  /** The SSO connection is in draft state and not yet active. */
  Draft = 'Draft',
  /** The SSO connection is inactive. */
  Inactive = 'Inactive',
  /** No SSO connection exists. */
  None = 'None',
  /** The SSO connection is validating credentials or configuration. */
  Validating = 'Validating',
}

/** Represents the type of an SSO connection. */
export enum SsoConnectionType {
  Adfssaml = 'ADFSSAML',
  AdpOidc = 'AdpOidc',
  Auth0Saml = 'Auth0SAML',
  AzureSaml = 'AzureSAML',
  CasSaml = 'CasSAML',
  ClassLinkSaml = 'ClassLinkSAML',
  CloudflareSaml = 'CloudflareSAML',
  CyberArkSaml = 'CyberArkSAML',
  DuoSaml = 'DuoSAML',
  GenericOidc = 'GenericOIDC',
  GenericSaml = 'GenericSAML',
  GoogleOAuth = 'GoogleOAuth',
  GoogleSaml = 'GoogleSAML',
  JumpCloudSaml = 'JumpCloudSAML',
  KeycloakSaml = 'KeycloakSAML',
  LastPassSaml = 'LastPassSAML',
  LoginGovOidc = 'LoginGovOidc',
  MagicLink = 'MagicLink',
  MicrosoftOAuth = 'MicrosoftOAuth',
  MiniOrangeSaml = 'MiniOrangeSAML',
  NetIqSaml = 'NetIqSAML',
  None = 'None',
  OktaSaml = 'OktaSAML',
  OneLoginSaml = 'OneLoginSAML',
  OracleSaml = 'OracleSAML',
  PingFederateSaml = 'PingFederateSAML',
  PingOneSaml = 'PingOneSAML',
  RipplingSaml = 'RipplingSAML',
  SalesforceSaml = 'SalesforceSAML',
  ShibbolethGenericSaml = 'ShibbolethGenericSAML',
  ShibbolethSaml = 'ShibbolethSAML',
  SimpleSamlPhpSaml = 'SimpleSamlPhpSAML',
  VMwareSaml = 'VMwareSAML',
}

/** Represents a directory available via WorkOS for SSO. */
export type SsoDirectory = {
  __typename?: 'SSODirectory';
  /** The domain associated with the directory. */
  domain: Scalars['String']['output'];
  /** The name of the directory. */
  name: Scalars['String']['output'];
  /** The state of the directory connection. */
  state: SsoState;
  /** The type of the directory. */
  type: SsoDirectoryTypes;
};

/** The type of directory that is available via WorkOS. 'None' doesn't exist in WorkOS, but is used internally to represent no connection. */
export enum SsoDirectoryTypes {
  AzureScimv2_0 = 'AzureSCIMV2_0',
  BambooHr = 'BambooHr',
  BreatheHr = 'BreatheHr',
  CezanneHr = 'CezanneHr',
  CyberArk = 'CyberArk',
  FourthHr = 'FourthHr',
  GSuiteDirectory = 'GSuiteDirectory',
  GenericScimv2_0 = 'GenericSCIMV2_0',
  Hibob = 'Hibob',
  JumpCloud = 'JumpCloud',
  None = 'None',
  OktaScimv2_0 = 'OktaSCIMV2_0',
  OneLogin = 'OneLogin',
  PeopleHr = 'PeopleHr',
  Personio = 'Personio',
  PingFederate = 'PingFederate',
  Rippling = 'Rippling',
  Sftp = 'SFTP',
  SftpWorkday = 'SFTPWorkday',
  Workday = 'Workday',
}

/** Represents the SSO options that can be configured for a company. */
export type SsoOptions = {
  __typename?: 'SSOOptions';
  /** The directory currently connected to the company. */
  connectedDirectory?: Maybe<SsoConnectedDirectory>;
  /** A list of SSO connections for the company. */
  connections: Array<SsoConnection>;
  /** A list of directories available via WorkOS. */
  directories: Array<SsoDirectory>;
};

/** Input for retrieving SSO options for a company. */
export type SsoOptionsInput = {
  /** The slug (unique identifier) of the company. */
  companySlug: Scalars['String']['input'];
};

/** Represents the state of the directory connection for SSO. */
export enum SsoState {
  /** The directory is connected, but the credentials are invalid. */
  InvalidCredentials = 'InvalidCredentials',
  /** The directory is connected and ready to use. */
  Linked = 'Linked',
  /** The directory is not connected and cannot be used. */
  Unlinked = 'Unlinked',
}

/** The source from which the SSO verification request originated. */
export enum SsoVerificationSource {
  /** The verification request originated from Auth Kit. */
  AuthKit = 'AUTH_KIT',
  /** The verification request originated from an SSO provider. */
  Sso = 'SSO',
}

/** STP (Spanning Tree Protocol) configuration information for switches to ensure loop-free topologies. */
export type StpInfo = {
  __typename?: 'STPInfo';
  /** The root bridge is the switch in a network from which all paths are derived to prevent loops. */
  isRootBridge: Scalars['Boolean']['output'];
  /** The MAC address of the root bridge. */
  rootBridgeMACAddress: Scalars['MacAddress']['output'];
  /** The port number on which the root bridge is connected. */
  rootBridgePortNumber: Scalars['Int']['output'];
};

/** Possible roles of a port in Spanning Tree Protocol (STP). */
export enum StpPortRole {
  /** An alternate port provides an alternate path to the root bridge in case the designated port fails. */
  Alternate = 'ALTERNATE',
  /** A backup port provides a backup path to a segment where another port already connects. */
  Backup = 'BACKUP',
  /** A designated port is a port that is designated for forwarding on a LAN segment. */
  Designated = 'DESIGNATED',
  /** A disabled port is not participating in STP. */
  Disabled = 'DISABLED',
  /** A root port is the port with the lowest cost path to the root bridge. */
  Root = 'ROOT',
}

export enum ScopeKind {
  AlertReceiverUuid = 'ALERT_RECEIVER_UUID',
  AlertTargetWebhookUuid = 'ALERT_TARGET_WEBHOOK_UUID',
  ApplicationDnsFirewallRuleUuid = 'APPLICATION_DNS_FIREWALL_RULE_UUID',
  AutoSelectionJobUuid = 'AUTO_SELECTION_JOB_UUID',
  AutoVpnGroupUuid = 'AUTO_VPN_GROUP_UUID',
  AutoVpnMemberUuid = 'AUTO_VPN_MEMBER_UUID',
  AutoVpnRouteUuid = 'AUTO_VPN_ROUTE_UUID',
  CaptivePortalUuid = 'CAPTIVE_PORTAL_UUID',
  CaptivePortalWebhookUuid = 'CAPTIVE_PORTAL_WEBHOOK_UUID',
  ClientVpnClientUuid = 'CLIENT_VPN_CLIENT_UUID',
  ClientVpnServerUuid = 'CLIENT_VPN_SERVER_UUID',
  CompanySlug = 'COMPANY_SLUG',
  CompanyUserUuid = 'COMPANY_USER_UUID',
  CompanyUuid = 'COMPANY_UUID',
  DhcpOptionUuid = 'DHCP_OPTION_UUID',
  DhcpReservedRangeUuid = 'DHCP_RESERVED_RANGE_UUID',
  DhcpRuleUuid = 'DHCP_RULE_UUID',
  DhcpStaticMappingUuid = 'DHCP_STATIC_MAPPING_UUID',
  DnsHostMappingsUuid = 'DNS_HOST_MAPPINGS_UUID',
  Encryption_8021XUuid = 'ENCRYPTION_8021X_UUID',
  FirewallRuleUuid = 'FIREWALL_RULE_UUID',
  FloorPlanUuid = 'FLOOR_PLAN_UUID',
  HostMonitoringTargetUuid = 'HOST_MONITORING_TARGET_UUID',
  InternalNoteUuid = 'INTERNAL_NOTE_UUID',
  InternetServicePlanUuid = 'INTERNET_SERVICE_PLAN_UUID',
  IpsecTunnelUuid = 'IPSEC_TUNNEL_UUID',
  NetworkJobUuid = 'NETWORK_JOB_UUID',
  NetworkOnboardingDocumentUuid = 'NETWORK_ONBOARDING_DOCUMENT_UUID',
  NetworkOnboardingFieldDelegationUuid = 'NETWORK_ONBOARDING_FIELD_DELEGATION_UUID',
  NetworkSlug = 'NETWORK_SLUG',
  NetworkUuid = 'NETWORK_UUID',
  NotNetworkScoped = 'NOT_NETWORK_SCOPED',
  OneToOneNatRuleUuid = 'ONE_TO_ONE_NAT_RULE_UUID',
  OutletInterfaceUuid = 'OUTLET_INTERFACE_UUID',
  PhyInterfaceUuid = 'PHY_INTERFACE_UUID',
  PolicyRoutingRuleUuid = 'POLICY_ROUTING_RULE_UUID',
  PortForwardingRuleUuid = 'PORT_FORWARDING_RULE_UUID',
  RackElevationDeviceUuid = 'RACK_ELEVATION_DEVICE_UUID',
  RackElevationUuid = 'RACK_ELEVATION_UUID',
  RadioProfileUuid = 'RADIO_PROFILE_UUID',
  RateLimitRuleUuid = 'RATE_LIMIT_RULE_UUID',
  SerialNumber = 'SERIAL_NUMBER',
  SsidUuid = 'SSID_UUID',
  StaticRouteUuid = 'STATIC_ROUTE_UUID',
  SwitchAutoConfigMacAddressVlanBindingUuid = 'SWITCH_AUTO_CONFIG_MAC_ADDRESS_VLAN_BINDING_UUID',
  SwitchProfileUuid = 'SWITCH_PROFILE_UUID',
  Unknown = 'UNKNOWN',
  VirtualDeviceTagUuid = 'VIRTUAL_DEVICE_TAG_UUID',
  VirtualDeviceUuid = 'VIRTUAL_DEVICE_UUID',
  VlanUuid = 'VLAN_UUID',
}

/** A security appliance profile is a bundle of settings that applies to a security appliance device. */
export type SecurityApplianceProfile = {
  __typename?: 'SecurityApplianceProfile';
  /** Unique identifier of the security appliance profile. */
  UUID: Scalars['UUID']['output'];
  /** Timestamp when the profile was created. */
  createdAt: Scalars['DateTime']['output'];
  /** The algorithm used to manage multiple WAN connections, such as load balancing or failover strategies. */
  multiWANAlgorithm: MultiWanAlgorithm;
  /** The number of seconds to wait before another failover can occur after a recent one. */
  multiWANUpdateCooldownSec?: Maybe<Scalars['Int']['output']>;
  /** Number of previous ping results to consider for the failover calculation. */
  multiWANUpdateLookback: Scalars['Int']['output'];
  /**
   * The minimum success ratio to consider a WAN link as good.
   * If it drops below the threshold, a failover occurs.
   * Stored as an integer representing the percentage.
   * For example, a value of 65 represents 65%, or a success ratio of 0.65.
   */
  multiWANUpdateThreshold: Scalars['Int']['output'];
  /** Timestamp when the profile was last updated. */
  updatedAt: Scalars['DateTime']['output'];
};

/** Enumeration of service types that can be discovered on the network. */
export enum ServiceType {
  /** AirPlay service (e.g., Apple devices that support media streaming). */
  Airplay = 'AIRPLAY',
  /** Printer service that is discoverable on the network. */
  Printer = 'PRINTER',
  /** Speaker service (e.g., network-connected audio devices). */
  Speaker = 'SPEAKER',
  /** Service type is unknown or not specified. */
  Unknown = 'UNKNOWN',
}

/** Input for setting the NOS version for a network, which will apply the specified NOS version to all devices in the network. */
export type SetNetworkNosVersionInput = {
  /** If true, forces the NOS version change even if there are potential conflicts or constraints. */
  force?: Scalars['Boolean']['input'];
  /** The unique identifier of the NOS version to apply to the network. */
  nosId: Scalars['Int']['input'];
  /** The interval in minutes to stagger the upgrades across devices within the network to prevent simultaneous upgrades. */
  staggeredUpgradeIntervalMin?: Scalars['Int']['input'];
};

export type SetPolicyRoutingRuleBindingsInput = {
  /** The list of bindings to associate with the policy routing rule. */
  bindings: Array<PolicyRoutingRuleBindingInput>;
};

export type SignedConsoleLoginChallenge = {
  __typename?: 'SignedConsoleLoginChallenge';
  /** Algorithm used to encode the payload. */
  algorithm: Scalars['String']['output'];
  /**
   * Key ID of the KMS key used to sign the challenge. This can be used to ensure
   * that the challenge was signed by the correct key.
   */
  keyID: Scalars['String']['output'];
  /**
   * The signature over the plain text response.
   * This is a base64 encoded string with the following JSON Object:
   * - a PSS SHA 256 signed JSON payload.
   * - A JSON encoded string with the following keys:
   *     - challenge: the original challenge
   *     - serialNumber: the serial number of the device to sign the challenge for
   *     - user: the user that signed the challenge
   * - To decode and verify the signature, use the following algorithms:
   *     - Base64 decode the response
   *     - Decode the JSON payload
   *     - Verify the PSS SHA 256 signature over the decoded JSON payload
   */
  response: Scalars['String']['output'];
};

/** Represents a speedtest server. */
export type SpeedtestServer = {
  __typename?: 'SpeedtestServer';
  /** The distance to the server from the client. */
  distance: Scalars['Int']['output'];
  /** The hostname of the server. */
  host: Scalars['String']['output'];
  /** The identifier of the server. */
  id: Scalars['String']['output'];
  /** The latitude of the server location. */
  lat: Scalars['String']['output'];
  /** The longitude of the server location. */
  lon: Scalars['String']['output'];
  /** The name of the server. */
  name: Scalars['String']['output'];
};

/** Input parameters for starting a WAN speed test job. */
export type StartWanSpeedtestJobInput = {
  /** The base URL used for downloading/uploading during the speed test. Defaults to 'https://speed.cloudflare.com'. */
  baseURL?: Scalars['String']['input'];
  /** The maximum number of bytes to transfer during the speed test. Must be greater than or equal to 100,000. */
  maxTransferBytes?: InputMaybe<Scalars['Int']['input']>;
  /** The UUID of the physical interface on which to perform the speed test. */
  phyInterfaceUUID: Scalars['UUID']['input'];
};

export type Stat = {
  __typename?: 'Stat';
  /** The timestamp when the stat was inserted into the database. */
  insertedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The unique identifier of the network the device belongs to. */
  networkUUID: Scalars['UUID']['output'];
  /** The timestamp when the stat was observed by the device. */
  observedAt: Scalars['DateTime']['output'];
  /** The raw JSON data of the stat, contents depend on the type. */
  rawData: Scalars['JSONObject']['output'];
  /** The type of the stat, indicating what data is contained in rawData. */
  type: StatType;
  /** The unique identifier of the virtual device that reported the stat. */
  virtualDeviceUUID: Scalars['UUID']['output'];
};

export type StatInjectionDuration = {
  /** The end time for fake stats injection. */
  endTime: Scalars['DateTime']['input'];
  /** The start time for fake stats injection. */
  startTime: Scalars['DateTime']['input'];
};

export enum StatType {
  Anomaly = 'ANOMALY',
  ArpTable = 'ARP_TABLE',
  CellularBootInfo = 'CELLULAR_BOOT_INFO',
  CellularStatus = 'CELLULAR_STATUS',
  ClientLatency = 'CLIENT_LATENCY',
  ConfigVersionInfo = 'CONFIG_VERSION_INFO',
  CosCapoAuthEvent = 'COS_CAPO_AUTH_EVENT',
  CosCapoRedirectEvent = 'COS_CAPO_REDIRECT_EVENT',
  CosClients = 'COS_CLIENTS',
  CosDhcpServerInfo = 'COS_DHCP_SERVER_INFO',
  CosDnsInfo = 'COS_DNS_INFO',
  CosFirewallCounters = 'COS_FIREWALL_COUNTERS',
  CosHaEvent = 'COS_HA_EVENT',
  CosIpsecTunnels = 'COS_IPSEC_TUNNELS',
  CosLteFailoverEvent = 'COS_LTE_FAILOVER_EVENT',
  CosMacTableEvent = 'COS_MAC_TABLE_EVENT',
  CosMultiwanEvent = 'COS_MULTIWAN_EVENT',
  CosNetworks = 'COS_NETWORKS',
  CosUsbPortEvent = 'COS_USB_PORT_EVENT',
  CosWanSpeedTestResult = 'COS_WAN_SPEED_TEST_RESULT',
  Heartbeat = 'HEARTBEAT',
  HostPing = 'HOST_PING',
  HostTraceroute = 'HOST_TRACEROUTE',
  LldpTable = 'LLDP_TABLE',
  MacTable = 'MAC_TABLE',
  MdnsServiceDiscoveryEvent = 'MDNS_SERVICE_DISCOVERY_EVENT',
  PacketCapture = 'PACKET_CAPTURE',
  PduOutletAutoCycleEvent = 'PDU_OUTLET_AUTO_CYCLE_EVENT',
  PduOutletEvent = 'PDU_OUTLET_EVENT',
  PduStatusInfo = 'PDU_STATUS_INFO',
  PortEvent = 'PORT_EVENT',
  PortInfo = 'PORT_INFO',
  SecureAddressingAndMessagingSystemInfo = 'SECURE_ADDRESSING_AND_MESSAGING_SYSTEM_INFO',
  SfpPortsInfo = 'SFP_PORTS_INFO',
  SfpPortLoopbackInfo = 'SFP_PORT_LOOPBACK_INFO',
  Sos_8021XAuthenticatedHosts = 'SOS_8021X_AUTHENTICATED_HOSTS',
  Sos_8021XPortStatus = 'SOS_8021X_PORT_STATUS',
  SosFlapLinkStatus = 'SOS_FLAP_LINK_STATUS',
  SosIgmpSnoopingStatus = 'SOS_IGMP_SNOOPING_STATUS',
  /** @deprecated Use `MAC_TABLE` instead. */
  SosMacTable = 'SOS_MAC_TABLE',
  SosMldSnoopingStatus = 'SOS_MLD_SNOOPING_STATUS',
  SosPoeInfo = 'SOS_POE_INFO',
  SosPortCounters = 'SOS_PORT_COUNTERS',
  SosPortEvent = 'SOS_PORT_EVENT',
  SosPortLoopbackInfo = 'SOS_PORT_LOOPBACK_INFO',
  SosStpInfo = 'SOS_STP_INFO',
  SystemInfo = 'SYSTEM_INFO',
  Unspecified = 'UNSPECIFIED',
  UplinkInfo = 'UPLINK_INFO',
  WireguardPeerEvent = 'WIREGUARD_PEER_EVENT',
  WireguardTunnelInfo = 'WIREGUARD_TUNNEL_INFO',
  WosAcsResult = 'WOS_ACS_RESULT',
  WosAutoTransmitPowerSelectJobResults = 'WOS_AUTO_TRANSMIT_POWER_SELECT_JOB_RESULTS',
  WosClientTable = 'WOS_CLIENT_TABLE',
  WosConnectionEvent = 'WOS_CONNECTION_EVENT',
  WosEthStats = 'WOS_ETH_STATS',
  WosEvent = 'WOS_EVENT',
  WosObserverResult = 'WOS_OBSERVER_RESULT',
  WosObssStats = 'WOS_OBSS_STATS',
  WosPingTable = 'WOS_PING_TABLE',
  WosRfStats = 'WOS_RF_STATS',
  WosScanTable = 'WOS_SCAN_TABLE',
}

export type StaticRoute = {
  __typename?: 'StaticRoute';
  /** Unique identifier of the static route. */
  UUID: Scalars['UUID']['output'];
  /** Timestamp when the static route was created. */
  createdAt: Scalars['DateTime']['output'];
  /** Destination IP prefix for the static route. */
  dstPrefix: Scalars['IPPrefix']['output'];
  /** DHCP Static Mapping used as gateway for the static route. */
  gatewayDHCPStaticMapping?: Maybe<DhcpStaticMapping>;
  /** Gateway IP address for the static route. */
  gatewayIP?: Maybe<Scalars['IPV4']['output']>;
  /** Indicates whether the static route is enabled. */
  isEnabled: Scalars['Boolean']['output'];
  /** Name of the static route. */
  name: Scalars['String']['output'];
  /** UUID of the network to which the static route belongs. */
  networkUUID: Scalars['UUID']['output'];
  /** Timestamp when the static route was last updated. */
  updatedAt: Scalars['DateTime']['output'];
};

export type StatsFilter = {
  /** The lookback time to consider stats for, starting at `endTime`. Maximum allowed value is 86400, one day. */
  durationSeconds: Scalars['Int']['input'];
  /** The latest observation time to consider stats for, inclusive. Defaults to the current time if not set. */
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** The maximum number of results to return, between 1 and 1000. Defaults to 100 if not set. */
  limit?: Scalars['Int']['input'];
  /** The number of results to skip before returning results. Defaults to 0 if not set. */
  offset?: Scalars['Int']['input'];
  /** The list of stat types to return. */
  types: Array<StatType>;
};

/** Allows users to specify which native VLAN to assign to the physical interface where a given MAC address or OUI is detected during a Switch Auto Config job. */
export type SwitchAutoConfigMacAddressVlanBinding = {
  __typename?: 'SwitchAutoConfigMACAddressVLANBinding';
  /** The Organizationally Unique Identifier (OUI) to bind to a VLAN. Optional if macAddress is specified. */
  OUI?: Maybe<Scalars['String']['output']>;
  /** Unique identifier for this MAC address to VLAN binding. */
  UUID: Scalars['UUID']['output'];
  /** The VLAN assigned to the specified MAC address or OUI. */
  VLAN: Vlan;
  /** The specific MAC address to bind to a VLAN. Optional if OUI is specified. */
  macAddress?: Maybe<Scalars['MacAddress']['output']>;
  /** Unique identifier of the network where this binding applies. */
  networkUUID: Scalars['UUID']['output'];
};

/** Switch Auto Configuration Jobs automatically configure switch ports based on the connected clients and Meter devices. */
export type SwitchAutoConfigurationJob = AutoSelectionJob & {
  __typename?: 'SwitchAutoConfigurationJob';
  /** The unique identifier of the job. */
  UUID: Scalars['UUID']['output'];
  /** The date and time when the job was completed. Null if not completed yet. */
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Whether or not the job is a dry run. If true, no configuration changes are made. */
  isDryRun: Scalars['Boolean']['output'];
  /** The UUID of the network where the job is executed. */
  networkUUID: Scalars['UUID']['output'];
  /**
   * The results of the Switch Auto Configuration job.
   * Only ports that had their configuration changed or encountered warnings will appear in the results.
   */
  results?: Maybe<Array<SwitchAutoConfigurationResult>>;
  /** The date and time when the job is scheduled to start. */
  scheduledAt: Scalars['DateTime']['output'];
  /** The date and time when the job will time out if not completed. */
  timeoutAt: Scalars['DateTime']['output'];
  /** List of UUIDs of virtual devices (switches) involved in the job. */
  virtualDeviceUUIDs: Array<Scalars['UUID']['output']>;
};

/** Input parameters for initializing a Switch Auto Configuration Job. */
export type SwitchAutoConfigurationJobInput = {
  /**
   * If true, the job will not make any configuration changes.
   * It will only return the changes it would have made (dry run).
   */
  isDryRun?: Scalars['Boolean']['input'];
  /**
   * The list of Switch virtual device UUIDs to run Auto Configuration on.
   * If null, all switches in the network will be auto-configured.
   */
  switchVirtualDeviceUUIDs?: InputMaybe<Array<Scalars['UUID']['input']>>;
  /** The Native VLAN UUID to assign to the switch port if the clients on the port are not classified in the client MAC to VLAN mappings for the network. */
  uncategorizedClientNativeVLANUUID: Scalars['UUID']['input'];
};

/** The result of auto-configuring a single switch port. */
export type SwitchAutoConfigurationResult = {
  __typename?: 'SwitchAutoConfigurationResult';
  /** The new Native VLAN assigned to the port. Null if the VLAN has since been deleted. */
  newNativeVLAN?: Maybe<Vlan>;
  /** The physical interface (port) that was configured. */
  phyInterface: PhyInterface;
  /** The previous Native VLAN assigned to the port. Null if the VLAN has since been deleted. */
  previousNativeVLAN?: Maybe<Vlan>;
  /** The direction of the toggle between access and trunk modes (or no change). */
  toggleDirection: SwitchAutoConfigurationResultToggleDirection;
  /** The switch virtual device that was configured. */
  virtualDevice: SwitchVirtualDevice;
  /** Any warnings that occurred during the port configuration. */
  warnings?: Maybe<Array<SwitchAutoConfigurationResultWarning>>;
};

/** Enumeration of possible toggles between trunk and access modes for switch ports during auto configuration. */
export enum SwitchAutoConfigurationResultToggleDirection {
  /** The port's mode was changed from access to trunk. */
  AccessToTrunk = 'ACCESS_TO_TRUNK',
  /** No change was made to the port's mode. */
  NoChange = 'NO_CHANGE',
  /** The port's mode was changed from trunk to access. */
  TrunkToAccess = 'TRUNK_TO_ACCESS',
}

/** Enumeration of possible warnings encountered during Switch Auto Configuration. */
export enum SwitchAutoConfigurationResultWarning {
  /**
   * Clients on the port had multiple mapped native VLANs based on their MAC addresses or OUIs.
   * The `uncategorizedClientNativeVLANUUID` was selected as the native VLAN.
   */
  MultipleClientMacVlanMappings = 'MULTIPLE_CLIENT_MAC_VLAN_MAPPINGS',
  /** The switch did not report any statistics, so no configuration could take place. */
  NoStatsReported = 'NO_STATS_REPORTED',
}

/** A switch hardware device represents a physical switch. */
export type SwitchHardwareDevice = HardwareDevice & {
  __typename?: 'SwitchHardwareDevice';
  /** Shows the most recent boot history of a device (buildName, bootCount). */
  bootHistory?: Maybe<Array<BootInfo>>;
  /** The timestamp when the hardware device was first created in the system. */
  createdAt: Scalars['DateTime']['output'];
  /** The model name of the hardware device. */
  deviceModel: DeviceModel;
  /** The type of the hardware device. */
  deviceType: DeviceType;
  /** If the device is no longer connected to the backend, this is the time at which it was last connected. */
  disconnectedFromBackendAt?: Maybe<Scalars['DateTime']['output']>;
  /** The IP address of the switch hardware device. */
  ipAddress?: Maybe<Scalars['String']['output']>;
  /**
   * True if the device has an active websocket connection to the backend. Note that this only works if the device has registered via the /v2/register endpoint (i.e., is a Config 2 device) - otherwise is always false.
   * @deprecated Refer to `isSpare` instead.
   */
  isActive: Scalars['Boolean']['output'];
  /** Indicates whether the hardware device is connected to the backend. */
  isConnectedToBackend: Scalars['Boolean']['output'];
  /** Indicates whether the hardware device's console port is enabled. */
  isConsoleEnabled: Scalars['Boolean']['output'];
  /** Indicates whether the hardware device is marked as a development (dev) device, moving it into the dev (staging) realm for testing purposes. */
  isDev: Scalars['Boolean']['output'];
  /** Indicates whether the hardware device is in factory mode. */
  isFactoryMode: Scalars['Boolean']['output'];
  /** True if the Switch's MAC address exists in the most recent controller MAC table for the network. */
  isInCurrentControllerMACTable: Scalars['Boolean']['output'];
  /** Indicates whether the hardware device is an RMA (Return Merchandise Authorization) replacement device. */
  isRMA: Scalars['Boolean']['output'];
  /** Indicates whether the hardware device has been retired. */
  isRetired: Scalars['Boolean']['output'];
  /** A spare hardware device belongs to a network but not a virtual device. Used most often to keep track of spare hardware we ship to a customer but don't install immediately. */
  isSpare: Scalars['Boolean']['output'];
  /** The MAC address of the hardware device. */
  macAddress?: Maybe<Scalars['MacAddress']['output']>;
  /** The UUID of the network that the spare hardware device belongs to. Spare hardware devices that are not affiliated with a virtual device will have this field populated. */
  networkUUID?: Maybe<Scalars['UUID']['output']>;
  /** Information about the switch's Power over Ethernet (PoE) usage. */
  poeInfo?: Maybe<PoEInfo>;
  /** The public key used to add the device to the jumphost's WireGuard interface. */
  publicKey?: Maybe<Scalars['String']['output']>;
  /** The unique serial number of the hardware device. */
  serialNumber: Scalars['String']['output'];
  /** The Spanning Tree Protocol (STP) information for the switch, providing data to ensure a loop-free topology. */
  stpInfo?: Maybe<StpInfo>;
  /** IPv6 address on the jumphost subnet. Used to send requests to the device. */
  tunnelIPAddress?: Maybe<Scalars['String']['output']>;
  /** The timestamp when the hardware device was last updated in the system. */
  updatedAt: Scalars['DateTime']['output'];
  /** The uptime from our time series database for the device. */
  uptime?: Maybe<Scalars['Duration']['output']>;
  /** The virtual device associated with this hardware device, if any. */
  virtualDevice?: Maybe<VirtualDevice>;
  /** The UUID of the virtual device this hardware device is assigned to, if any. */
  virtualDeviceUUID?: Maybe<Scalars['UUID']['output']>;
};

/** A switch hardware device represents a physical switch. */
export type SwitchHardwareDeviceBootHistoryArgs = {
  count?: InputMaybe<Scalars['Int']['input']>;
};

/** Represents an entry in the switch's MAC address table, mapping MAC addresses to ports and VLANs. */
export type SwitchMacTableEntry = {
  __typename?: 'SwitchMACTableEntry';
  /** The MAC address observed on the switch. */
  macAddress: Scalars['MacAddress']['output'];
  /** The port number where the MAC address was observed. */
  port: Scalars['Int']['output'];
  /** The timestamp when the entry was last updated. */
  updatedAt: Scalars['DateTime']['output'];
  /** The VLAN associated with the VLAN ID. */
  vlan?: Maybe<Vlan>;
  /** The VLAN ID associated with the MAC address. */
  vlanID: Scalars['Int']['output'];
};

/** Response type containing metadata and an array of switch port metric values. */
export type SwitchMetricsResponse = MetricsResponse & {
  __typename?: 'SwitchMetricsResponse';
  /** Metadata about the metrics, such as units and aggregation methods. */
  metadata?: Maybe<MetricsMetadata>;
  /** An array of switch port metric values. */
  values: Array<SwitchPortMetricsValue>;
};

/** Response type containing an array of per-port rate metrics at specific timestamps. */
export type SwitchPortMetricsRateResponse = {
  __typename?: 'SwitchPortMetricsRateResponse';
  /** An array of per-port metrics expressed as rates at specific timestamps. */
  values: Array<SwitchPortMetricsRateValue>;
};

/** Per-port metrics data expressed as rates (per second) at specific timestamps. */
export type SwitchPortMetricsRateValue = {
  __typename?: 'SwitchPortMetricsRateValue';
  /** Total number of broadcast packets per second received in the given time period; a subset of totalRxPackets. */
  broadcastRxPacketsPerSecond: Scalars['Float']['output'];
  /** Total number of broadcast packets per second received in the given time period; a subset of totalRxPackets. */
  broadcastTxPacketsPerSecond: Scalars['Float']['output'];
  /** The rate of packets per second that were dropped during transmission or reception on this port. */
  dropsPerSecond: Scalars['Float']['output'];
  /** Total number of multicast packets per second received in the given time period; a subset of totalRxPackets. */
  multicastRxPacketsPerSecond: Scalars['Float']['output'];
  /** Total number of multicast packets per second transmitted in the given time period; a subset of totalTxPackets. */
  multicastTxPacketsPerSecond: Scalars['Float']['output'];
  /** The physical port number on the switch. */
  portNumber: Scalars['Int']['output'];
  /** The rate of reception errors per second on this port. */
  rxErrPerSecond: Scalars['Float']['output'];
  /** The timestamp for when the rate values were calculated. */
  timestamp: Scalars['DateTime']['output'];
  /** The rate of bytes per second received on this port. */
  totalRxBytesPerSecond: Scalars['Float']['output'];
  /** The rate of bytes per second transmitted from this port. */
  totalTxBytesPerSecond: Scalars['Float']['output'];
  /** The rate of transmission errors per second on this port. */
  txErrPerSecond: Scalars['Float']['output'];
};

/** Response type containing an array of per-port counter statistics captured at specific timestamps. */
export type SwitchPortMetricsResponse = {
  __typename?: 'SwitchPortMetricsResponse';
  /** An array of per-port counter statistics values. */
  values: Array<SwitchPortMetricsStatValue>;
};

/** Counter stats per switch port over a lookback period. */
export type SwitchPortMetricsStatValue = {
  __typename?: 'SwitchPortMetricsStatValue';
  /** Total number of broadcast packets received in the given time period; a subset of totalRxPackets. */
  broadcastRxPackets: Scalars['Float']['output'];
  /** Total number of broadcast packets transmitted in the given time period; a subset of totalTxPackets. */
  broadcastTxPackets: Scalars['Float']['output'];
  /** Total number of multicast packets received in the given time period; a subset of totalRxPackets. */
  multicastRxPackets: Scalars['Float']['output'];
  /** Total number of multicast packets transmitted in the given time period; a subset of totalTxPackets. */
  multicastTxPackets: Scalars['Float']['output'];
  /** The physical port number on the switch. */
  portNumber: Scalars['Int']['output'];
  /** The total cumulative number of packets discarded during reception on this port up to this timestamp. */
  rxDiscards: Scalars['Float']['output'];
  /** The total cumulative number of reception errors on this port up to this timestamp. */
  rxErr: Scalars['Float']['output'];
  /** The timestamp when the counters were recorded. */
  timestamp: Scalars['DateTime']['output'];
  /** The total cumulative number of bytes received on this port up to this timestamp. */
  totalRxBytes: Scalars['Float']['output'];
  /** The total cumulative number of bytes transmitted from this port up to this timestamp. */
  totalTxBytes: Scalars['Float']['output'];
  /** The total cumulative number of packets discarded during transmission on this port up to this timestamp. */
  txDiscards: Scalars['Float']['output'];
  /** The total cumulative number of transmission errors on this port up to this timestamp. */
  txErr: Scalars['Float']['output'];
};

/** Represents the value of switch port metrics at a specific timestamp. */
export type SwitchPortMetricsValue = MetricsValue & {
  __typename?: 'SwitchPortMetricsValue';
  /** The direction of traffic (e.g., inbound or outbound). */
  direction: TrafficDirection;
  /** The port identifier. */
  port: Scalars['String']['output'];
  /** The timestamp for the metric value. */
  timestamp: Scalars['DateTime']['output'];
  /** The metric value. */
  value: Scalars['Float']['output'];
};

/** Aggregated statistics per switch port over a specified lookback period. */
export type SwitchPortStat = {
  __typename?: 'SwitchPortStat';
  /** Total number of broadcast packets received on this port during the lookback period; a subset of totalRxPackets. */
  broadcastRxPackets: Scalars['Int']['output'];
  /** Total number of broadcast packets transmitted from this port during the lookback period; a subset of totalTxPackets. */
  broadcastTxPackets: Scalars['Int']['output'];
  /** Total number of packets received on this port but discarded due to errors or resource limitations during the lookback period; a subset of totalRxPackets. */
  discardRxPackets: Scalars['Int']['output'];
  /** Total number of packets attempted to be transmitted from this port but discarded due to errors or resource limitations during the lookback period; a subset of totalTxPackets. */
  discardTxPackets: Scalars['Int']['output'];
  /** Total number of packets received on this port that contained errors (e.g., CRC errors) during the lookback period; a subset of totalRxPackets. */
  errorRxPackets: Scalars['Int']['output'];
  /** Total number of packets transmitted from this port that encountered errors during the lookback period; a subset of totalTxPackets. */
  errorTxPackets: Scalars['Int']['output'];
  /** Total number of multicast packets received on this port during the lookback period; a subset of totalRxPackets. */
  multicastRxPackets: Scalars['Int']['output'];
  /** Total number of multicast packets transmitted from this port during the lookback period; a subset of totalTxPackets. */
  multicastTxPackets: Scalars['Int']['output'];
  /** Total number of non-unicast (i.e., multicast and broadcast) packets received on this port during the lookback period; a subset of totalRxPackets. */
  nonUnicastRxPackets: Scalars['Int']['output'];
  /** Total number of non-unicast (i.e., multicast and broadcast) packets transmitted from this port during the lookback period; a subset of totalTxPackets. */
  nonUnicastTxPackets: Scalars['Int']['output'];
  /** The physical number of the port on the switch. */
  portNumber: Scalars['Int']['output'];
  /** Total number of bytes received on this port during the lookback period. */
  totalRxBytes: Scalars['Int']['output'];
  /** Total number of packets received on this port during the lookback period. */
  totalRxPackets: Scalars['Int']['output'];
  /** Total number of bytes transmitted from this port during the lookback period. */
  totalTxBytes: Scalars['Int']['output'];
  /** Total number of packets transmitted from this port during the lookback period. */
  totalTxPackets: Scalars['Int']['output'];
  /** Total number of unicast packets received on this port during the lookback period; a subset of totalRxPackets. */
  unicastRxPackets: Scalars['Int']['output'];
  /** Total number of unicast packets transmitted from this port during the lookback period; a subset of totalTxPackets. */
  unicastTxPackets: Scalars['Int']['output'];
};

/** A switch profile is a bundle of settings that applies to a switch device. */
export type SwitchProfile = {
  __typename?: 'SwitchProfile';
  /** The unique identifier of the switch profile. */
  UUID: Scalars['UUID']['output'];
  /** The date and time when the switch profile was created. */
  createdAt: Scalars['DateTime']['output'];
  /** The time in seconds after which all flap state for a port is cleared. */
  flipClearFlapStateAfterSec?: Maybe<Scalars['Int']['output']>;
  /** The maximum number of times to toggle the port before requiring manual toggle of port in dashboard. */
  flipMaxRetryCount?: Maybe<Scalars['Int']['output']>;
  /** The number of seconds to wait before re-enabling the port. */
  flipRetryPeriodSec?: Maybe<Scalars['Int']['output']>;
  /** Time window in seconds to count the number of flaps. */
  flipWindowSec?: Maybe<Scalars['Int']['output']>;
  /** The number of flaps per time window that will result in a port being shut down. */
  flipWindowThreshold?: Maybe<Scalars['Int']['output']>;
  /** If true, flap link intervention protocol is enabled for this switch profile. Both this flag and isFlipEnabled in PhyInterface must be true for FLIP to be enabled on a port. */
  isFlipEnabled: Scalars['Boolean']['output'];
  /** The name of the switch profile. */
  name: Scalars['String']['output'];
  /** The unique identifier of the network the switch profile belongs to. */
  networkUUID: Scalars['UUID']['output'];
  /**
   * The STP (Spanning Tree Protocol) bridge priority for the switch.
   *
   * The lower the value, the more likely the switch is to be elected as the root bridge.
   * The range is 0 to 61440. Valid values are multiples of 4096.
   */
  stpBridgePriority: Scalars['Int']['output'];
  /**
   * STP forwarding delay in seconds.
   *
   * The time that the switch will wait before transitioning a port from the listening to the learning state, and from the learning to the forwarding state.
   * Valid values are between 4 and 30 seconds.
   */
  stpForwardingDelaySeconds: Scalars['Int']['output'];
  /**
   * STP Hello interval in seconds.
   *
   * The time between configuration messages sent by the root bridge to maintain the spanning tree topology.
   * Valid values are between 1 and 10 seconds.
   */
  stpHelloIntervalSeconds: Scalars['Int']['output'];
  /**
   * STP Max BPDU (Bridge Protocol Data Unit) age in seconds.
   *
   * The maximum age of received BPDU information before it is discarded.
   * Valid values are between 6 and 40 seconds.
   */
  stpMaxAgeSeconds: Scalars['Int']['output'];
  /**
   * STP BPDU transmit hold count per second.
   *
   * The maximum number of BPDUs that can be sent per second.
   * Valid values are between 1 and 10.
   */
  stpTransmitHoldCount: Scalars['Int']['output'];
  /** The date and time when the switch profile was last updated. */
  updatedAt: Scalars['DateTime']['output'];
};

/** A switch virtual device is a logical representation of a physical switch. */
export type SwitchVirtualDevice = VirtualDevice & {
  __typename?: 'SwitchVirtualDevice';
  /** The unique identifier (UUID) of the virtual device. */
  UUID: Scalars['UUID']['output'];
  /** The timestamp when the virtual device was first created in the system. */
  createdAt: Scalars['DateTime']['output'];
  /** An optional description for the virtual device. */
  description?: Maybe<Scalars['String']['output']>;
  /** The model name of the virtual device. */
  deviceModel: DeviceModel;
  /** The type of the virtual device. */
  deviceType: VirtualDeviceType;
  /** The hardware device associated with this virtual device, if any. */
  hardwareDevice?: Maybe<HardwareDevice>;
  /** Indicates whether the virtual device's console port is enabled. */
  isConsoleEnabled: Scalars['Boolean']['output'];
  /** Indicates whether debug logging is enabled on the virtual device. */
  isDebugLogEnabled: Scalars['Boolean']['output'];
  /** A human-friendly name for the device. */
  label: Scalars['String']['output'];
  /** The network that the virtual device belongs to. */
  network: Network;
  /** The unique identifier (UUID) of the network the virtual device belongs to. */
  networkUUID: Scalars['UUID']['output'];
  /** The current NOS firmware version that the virtual device is configured to run. */
  nosVersion?: Maybe<ConfiguredNosVersion>;
  /** The identifier of the NOS firmware version that the virtual device is configured to run. */
  nosVersionID: Scalars['Int']['output'];
  /** The NOS firmware version that the virtual device is scheduled to upgrade to. */
  pendingNosVersion?: Maybe<PendingNosVersion>;
  /** The physical interfaces associated with the switch. */
  phyInterfaces: Array<PhyInterface>;
  /** Information about the switch's Power over Ethernet (PoE) usage. */
  poeInfo?: Maybe<PoEInfo>;
  /** The switch profile associated with this switch that defines port configurations and other settings. */
  switchProfile: SwitchProfile;
  /** The UUID of the switch profile associated with this switch. */
  switchProfileUUID: Scalars['UUID']['output'];
  /** The timestamp when the virtual device was last updated in the system. */
  updatedAt: Scalars['DateTime']['output'];
  /** The uptime from our time series database for the device. */
  uptime?: Maybe<Scalars['Duration']['output']>;
};

export type SystemInfoStat = {
  __typename?: 'SystemInfoStat';
  /** The used CPU percentage represented as a value [0, 1]. */
  cpuUsedPercentage: Scalars['Float']['output'];
  /** Amount of available memory in bytes. */
  memoryBytesAvailable: Scalars['Int']['output'];
  /** Total memory in bytes. */
  memoryBytesTotal: Scalars['Int']['output'];
  /** Amount of used memory in bytes. */
  memoryBytesUsed: Scalars['Int']['output'];
  /** The uptime of the device since last reboot, in seconnds. */
  uptime: Scalars['Duration']['output'];
};

export type SystemInfoStatsFilter = {
  /** The lookback time to consider stats for, starting at `endTime`. Maximum allowed value is 86400, one day. */
  durationSeconds: Scalars['Int']['input'];
  /** The latest observation time to consider stats for, inclusive. Defaults to the current time if not set. */
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** The maximum number of results to return, between 1 and 1000. Defaults to 100 if not set. */
  limit?: Scalars['Int']['input'];
};

/** Input type for testing a webhook alert target by triggering a simulated event. */
export type TestTriggerAlertTargetWebhookInput = {
  /** The name of the event to simulate triggering. */
  eventName: Scalars['String']['input'];
  /** The payload data to send with the simulated event. */
  payload: Scalars['String']['input'];
};

export type ThroughputValueWithDirection = {
  __typename?: 'ThroughputValueWithDirection';
  /** The direction of traffic for the throughput value. */
  direction: TrafficDirection;
  /** The throughput value in bits per second. */
  value: Scalars['Int']['output'];
};

/** Represents the principal (entity) associated with a token. */
export type TokenPrincipal = {
  __typename?: 'TokenPrincipal';
  /** The name of the principal. For devices, the serial number; for machines, the machine name; for users, the user name. */
  name: Scalars['String']['output'];
  /** The type of the principal (e.g., DEVICE, MACHINE, USER). */
  type: PrincipalType;
};

/** An enumeration of possible token types. */
export enum TokenType {
  /** A bearer token used for authentication. */
  Bearer = 'BEARER',
}

/** Represents the direction of network traffic. */
export enum TrafficDirection {
  /** Incoming traffic direction (Received traffic). */
  Rx = 'RX',
  /** Outgoing traffic direction (Transmitted traffic). */
  Tx = 'TX',
}

/** Input type for activating a Twilio cellular SIM card. */
export type TwilioCellularActivationInput = {
  /** The country where the SIM card will be used. */
  country: TwilioCellularCountry;
  /** The ICCID (Integrated Circuit Card Identifier) of the SIM card to activate. */
  iccid: Scalars['String']['input'];
};

/** Represents the response from Twilio when activating, reactivating, or deactivating a cellular SIM card. */
export type TwilioCellularActivationResponse = {
  __typename?: 'TwilioCellularActivationResponse';
  /** The account SID associated with the Super SIM. */
  accountSID?: Maybe<Scalars['String']['output']>;
  /** The date and time when the SIM was created. */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** The name of the fleet to which the SIM belongs. */
  fleetName?: Maybe<Scalars['String']['output']>;
  /** The URL of the fleet resource in Twilio. */
  fleetURL?: Maybe<Scalars['String']['output']>;
  /** The Integrated Circuit Card Identifier (ICCID) of the SIM. */
  iccid?: Maybe<Scalars['String']['output']>;
  /** The unique SID (identifier) for the Super SIM resource. */
  sid?: Maybe<Scalars['String']['output']>;
  /** The current activation status of the SIM. */
  status?: Maybe<TwilioCellularActivationStatus>;
  /** A unique name assigned to the Super SIM. */
  uniqueName?: Maybe<Scalars['String']['output']>;
  /** The date and time when the SIM was last updated. */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The URL of the SIM resource in Twilio. */
  url?: Maybe<Scalars['String']['output']>;
};

/** Enumeration of possible activation statuses for a Twilio SIM card. */
export enum TwilioCellularActivationStatus {
  /** The SIM is active and can be used for cellular connectivity. */
  Active = 'ACTIVE',
  /** The SIM is inactive. */
  Inactive = 'INACTIVE',
  /** The SIM is newly created and not yet ready for use. */
  New = 'NEW',
  /** The SIM is ready for activation. */
  Ready = 'READY',
  /** The SIM activation has been scheduled. */
  Scheduled = 'SCHEDULED',
}

/** Enumeration of supported countries for activating Twilio cellular SIM cards. */
export enum TwilioCellularCountry {
  /** Canada */
  Ca = 'CA',
  /** Used for all other countries. */
  Other = 'OTHER',
  /** United States of America */
  Us = 'US',
}

export type UnassignedHardwareAndConnectedPhyInterface = {
  __typename?: 'UnassignedHardwareAndConnectedPhyInterface';
  /** The physical interface of the switch that the unassigned hardware device is connected to. */
  phyInterface: PhyInterface;
  /** The physically connected hardware device that is not associated with any virtual device in this network. */
  unassignedHardwareDevice: AccessPointHardwareDevice;
};

/** Represents a response containing unassigned hardware devices for a network. */
export type UnassignedHardwareDeviceForNetworkResponse = {
  __typename?: 'UnassignedHardwareDeviceForNetworkResponse';
  /** Tuples of unassigned hardware devices and the physical interfaces they are connected to. */
  unassignedHardwareConnections: Array<UnassignedHardwareAndConnectedPhyInterface>;
  /** The switch virtual device that the unassigned hardware device is connected to. */
  virtualDevice: SwitchVirtualDevice;
};

/** Specifies the referer types allowed for unauthenticated access. */
export enum UnauthenticatedReferer {
  /** Access from any referer. */
  Any = 'ANY',
  /** Access from a captive portal. */
  Captive = 'CAPTIVE',
  /** Access during installation. */
  Install = 'INSTALL',
}

/** Input type for unbinding a firewall rule from a physical interface. */
export type UnbindFirewallRuleFromPhyInterface = {
  /** UUID of the firewall rule to unbind. */
  firewallRuleUUID: Scalars['UUID']['input'];
  /** UUID of the physical interface to unbind the firewall rule from. */
  phyInterfaceUUID: Scalars['UUID']['input'];
};

/** Input type for unbinding a firewall rule from a VLAN. */
export type UnbindFirewallRuleFromVlan = {
  /** UUID of the firewall rule to unbind. */
  firewallRuleUUID: Scalars['UUID']['input'];
  /** UUID of the VLAN to unbind the firewall rule from. */
  vlanUUID: Scalars['UUID']['input'];
};

export type UnbindPolicyRoutingRuleInput = {
  /** The binding information to unbind from the policy routing rule. */
  binding: PolicyRoutingRuleBindingInput;
};

/** The provided list of targets replaces the existing targets entirely. Any targets not included in this list will be removed. */
export type UpdateAlertReceiverWithTargetsInput = {
  /** Updated label for the alert receiver. */
  label?: InputMaybe<Scalars['String']['input']>;
  /** List of alert targets to associate with the alert receiver. This list will replace any existing targets. */
  targets?: InputMaybe<Array<AlertTargetInput>>;
};

/** Input type for updating an existing webhook alert target. */
export type UpdateAlertTargetWebhookInput = {
  /** An updated label for the webhook alert target. */
  label?: InputMaybe<Scalars['String']['input']>;
  /** An updated secret used to sign webhook requests for verification. */
  signingSecret?: InputMaybe<Scalars['String']['input']>;
  /** An updated URL endpoint for the webhook. */
  url?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateApplicationDnsFirewallRuleApplicationVisibilities = {
  /** The ID of the application to update visibility for. */
  id: Scalars['Int']['input'];
  /** Indicates whether the application is visible to the customer. */
  isVisible: Scalars['Boolean']['input'];
};

export type UpdateApplicationDnsFirewallRuleInput = {
  /** The action to take when the rule is matched. */
  action?: InputMaybe<FirewallRuleAction>;
  /** The ID of the Netstar application to associate with the rule. */
  applicationID?: InputMaybe<Scalars['Int']['input']>;
  /** The ID of the Netstar category to associate with the rule. */
  categoryID?: InputMaybe<Scalars['Int']['input']>;
  /** Updates the DHCP rule the DNS firewall rule is associated with. If priority is not specified, will default to 1 greater than the highest existing priority. */
  dhcpRuleUUID?: InputMaybe<Scalars['UUID']['input']>;
  /** The domain or FQDN that is matched by the rule, allowing for wildcards denoted by '*'. */
  domain?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the Netstar group to associate with the rule. */
  groupID?: InputMaybe<Scalars['Int']['input']>;
  /** Indicates whether the rule is enabled. */
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** The name of the DNS firewall rule. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The priority of the rule; if not specified, will default to 1 greater than the highest existing priority. */
  priority?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateApplicationDnsFirewallRulesPrioritiesInput = {
  /** The UUID of the DNS firewall rule to update. */
  applicationDNSFirewallRuleUUID: Scalars['UUID']['input'];
  /** The new priority to assign to the rule. */
  priority: Scalars['Int']['input'];
};

export type UpdateAutoVpnGroupInput = {
  /** Indicates if hub failover is enabled. */
  isHubFailoverEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** New name for the AutoVPN Group. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Updated configuration of the hub's uplink. */
  uplink?: InputMaybe<CreateAutoVpnGroupHubUplinkInput>;
};

/** Acts as a PUT */
export type UpdateAutoVpnMemberInput = {
  /** Indicates if failover is enabled for the member's uplink. */
  isFailoverEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** List of permitted VLAN unique identifiers. */
  permittedVLANUUIDs?: InputMaybe<Array<Scalars['UUID']['input']>>;
  /** Unique identifier of the physical interface used by the member's uplink. */
  phyInterfaceUUID?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdateAutoVpnRouteInput = {
  /** Destination gateway IP address for the route. */
  dstGateway?: InputMaybe<Scalars['IPV4']['input']>;
  /** Prefix length for the destination IP of the route. */
  dstPrefixLength?: InputMaybe<Scalars['Int']['input']>;
  /** Indicates if the route is enabled. */
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** New name for the route. */
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Input parameters for updating a Client VPN server. */
export type UpdateClientVpnServerInput = {
  /** IPv4 address to assign to the WireGuard interface. */
  address?: InputMaybe<Scalars['IPV4']['input']>;
  /** Subnet mask length (CIDR notation) for the WireGuard interface address. */
  addressMask?: InputMaybe<Scalars['Int']['input']>;
  /** Default list of IP prefixes that clients are allowed to access. */
  defaultClientAllowedIPs?: InputMaybe<Array<Scalars['IPPrefix']['input']>>;
  /** An additional listen address other than `address` this server serves DNS traffic on. It must be a private IP. */
  dnsListenAddress?: InputMaybe<Scalars['IPV4']['input']>;
  /** Set to true to enable failover between uplink physical interfaces (WANs). */
  isFailoverEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Port number on which the WireGuard server listens for incoming connections. */
  listenPort?: InputMaybe<Scalars['Int']['input']>;
  /** UUID of the uplink physical interface that the WireGuard server will listen on. */
  phyInterfaceUUID?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdateCompanyInput = {
  /** Indicates if the company is prospective (used for sales purposes). */
  isProspective?: InputMaybe<Scalars['Boolean']['input']>;
  /** New name for the company. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** New slug for the company. */
  slug?: InputMaybe<Scalars['String']['input']>;
};

/** Fields that can be updated for a company user. */
export type UpdateCompanyUserInput = {
  /** The new membership role for the company user within the company. */
  companyMembershipRole?: InputMaybe<CompanyMembershipRole>;
  /** The new first name for the company user. */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** The new last name for the company user. */
  lastName?: InputMaybe<Scalars['String']['input']>;
};

/** Input for updating an existing DHCP option. */
export type UpdateDhcpOptionInput = {
  /** The data value for the DHCP option. */
  data?: InputMaybe<Scalars['String']['input']>;
  /** An optional description for the DHCP option. */
  description?: InputMaybe<Scalars['String']['input']>;
};

/** Input for updating an existing DHCP reserved range. */
export type UpdateDhcpReservedRangeInput = {
  /** The last reserved IP address in the range. */
  endIPAddress?: InputMaybe<Scalars['IP']['input']>;
  /** The first reserved IP address in the range. */
  startIPAddress?: InputMaybe<Scalars['IP']['input']>;
};

/** Input for updating a specific DHCP reserved range identified by UUID. */
export type UpdateDhcpReservedRangeUuidInput = {
  /** The unique identifier of the DHCP reserved range to update. */
  UUID: Scalars['UUID']['input'];
  /** The input data for updating the DHCP reserved range. */
  input: UpdateDhcpReservedRangeInput;
};

/** Input for updating an existing DHCP rule. */
export type UpdateDhcpRuleInput = {
  /** Enables or disables DNS caching on the DHCP server. */
  dnsCacheIsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Specifies the maximum Time-To-Live (TTL) in seconds for cached DNS entries. */
  dnsCacheMaxTTL?: InputMaybe<Scalars['Int']['input']>;
  /** Specifies the maximum number of DNS records to cache. */
  dnsCacheSize?: InputMaybe<Scalars['Int']['input']>;
  /** A list of DNS search domains provided to DHCP clients. */
  dnsSearchDomains?: InputMaybe<Array<Scalars['String']['input']>>;
  /** List of upstream DNS servers to use. */
  dnsUpstreamServers?: InputMaybe<Array<Scalars['IP']['input']>>;
  /** Determines if the gateway acts as a DNS proxy for clients. */
  dnsUseGatewayProxy?: InputMaybe<Scalars['Boolean']['input']>;
  /** The last available IP address in the DHCP range, ascending and inclusive. */
  endIPAddress?: InputMaybe<Scalars['IP']['input']>;
  /** The gateway IP address provided to DHCP clients. */
  gatewayIPAddress?: InputMaybe<Scalars['IP']['input']>;
  /** The prefix length of the gateway IP address. */
  gatewayPrefixLength?: InputMaybe<Scalars['Int']['input']>;
  /** Indicates whether this DHCP rule is for IPv6 (`true`) or IPv4 (`false`). */
  isIPv6?: InputMaybe<Scalars['Boolean']['input']>;
  /** The duration (in seconds) of the DHCP lease assigned to clients. */
  leaseDurationSeconds?: InputMaybe<Scalars['Int']['input']>;
  /** List of reserved IP ranges within the DHCP range to update. */
  reservedRanges?: InputMaybe<Array<UpdateDhcpReservedRangeUuidInput>>;
  /** The first available IP address in the DHCP range, ascending and inclusive. */
  startIPAddress?: InputMaybe<Scalars['IP']['input']>;
  /** List of static mappings between MAC addresses and IP addresses to update. */
  staticMappings?: InputMaybe<Array<UpdateDhcpStaticMappingUuidInput>>;
};

/** Input for updating an existing DHCP static mapping. */
export type UpdateDhcpStaticMappingInput = {
  /** The hostname to assign to the client device. */
  hostname?: InputMaybe<Scalars['String']['input']>;
  /** The fixed IP address to assign to the client device. */
  ipAddress?: InputMaybe<Scalars['IP']['input']>;
  /** The MAC address of the client device. */
  macAddress?: InputMaybe<Scalars['MacAddress']['input']>;
  /** A name or label for this static mapping. */
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Input for updating a specific DHCP static mapping identified by UUID. */
export type UpdateDhcpStaticMappingUuidInput = {
  /** The unique identifier of the DHCP static mapping to update. */
  UUID: Scalars['UUID']['input'];
  /** The input data for updating the DHCP static mapping. */
  input: UpdateDhcpStaticMappingInput;
};

/** Input for updating an existing DNS host mapping. */
export type UpdateDnsHostMappingInput = {
  /** The domain to which `overrideDomain` should resolve. */
  destinationDomain?: InputMaybe<Scalars['String']['input']>;
  /** The IP address to which `overrideDomain` should resolve. */
  destinationIPAddress?: InputMaybe<Scalars['IP']['input']>;
  /** Indicates whether this DNS host mapping is enabled. */
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** The domain name to override. This can be a fully qualified domain name (FQDN) or a domain name. */
  overrideDomain?: InputMaybe<Scalars['String']['input']>;
};

/** Input type for updating an existing 802.1X encryption configuration. All fields are optional; only provided fields will be updated. */
export type UpdateEncryption8021XInput = {
  /** The new IP address of the accounting server. If provided, accountingServerPort and accountingServerSecret must also be provided. */
  accountingServerIPAddress?: InputMaybe<Scalars['IP']['input']>;
  /** The new port of the accounting server (1-65535). */
  accountingServerPort?: InputMaybe<Scalars['Int']['input']>;
  /** The new shared secret used to authenticate with the accounting server. */
  accountingServerSecret?: InputMaybe<Scalars['String']['input']>;
  /** The new IP address of the authentication server.. */
  authServerIPAddress?: InputMaybe<Scalars['IP']['input']>;
  /** The new port of the authentication server. */
  authServerPort?: InputMaybe<Scalars['Int']['input']>;
  /** The new shared secret used to authenticate with the authentication server. */
  authServerSecret?: InputMaybe<Scalars['String']['input']>;
  /** A new label for the encryption configuration. */
  label?: InputMaybe<Scalars['String']['input']>;
};

/** Input type for updating an existing firewall rule. */
export type UpdateFirewallRuleInput = {
  /** The action to take when the firewall rule matches (e.g., DENY or PERMIT). */
  action?: InputMaybe<FirewallRuleAction>;
  /**
   * List of UUIDs of physical interfaces to which the firewall rule is bound.
   * The physical interfaces must be uplinks.
   */
  boundPhyInterfaceUUIDs?: InputMaybe<Array<Scalars['UUID']['input']>>;
  /** List of UUIDs of VLANs to which the firewall rule is bound. */
  boundVLANUUIDs?: InputMaybe<Array<Scalars['UUID']['input']>>;
  /** A description of the firewall rule. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** UUID of the destination client VPN server to match traffic to. */
  dstClientVPNServerUUID?: InputMaybe<Scalars['UUID']['input']>;
  /** UUID of the destination IPSec tunnel to match traffic to. */
  dstIPSecTunnelUUID?: InputMaybe<Scalars['UUID']['input']>;
  /**
   * Deprecated. Use `dstPortRanges` instead. Single destination port range to match.
   * @deprecated Use `dstPortRanges` instead.
   */
  dstPortRange?: InputMaybe<PortRangeInput>;
  /** List of destination port ranges to match traffic to. */
  dstPortRanges?: InputMaybe<Array<PortRangeInput>>;
  /** Destination IP prefix to match traffic to. */
  dstPrefix?: InputMaybe<Scalars['IPPrefix']['input']>;
  /** UUID of the destination VLAN to match traffic to. */
  dstVLANUUID?: InputMaybe<Scalars['UUID']['input']>;
  /**
   * Indicates if the firewall rule should be applied bidirectionally.
   * @deprecated Bidirectional rules are now implicit, enabled if the rule is between two VLANs.
   */
  isBidirectional?: InputMaybe<Scalars['Boolean']['input']>;
  /** Indicates if the firewall rule is currently enabled. */
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Indicates if the firewall rule is internal to Meter. */
  isMeterInternal?: InputMaybe<Scalars['Boolean']['input']>;
  /** The name of the firewall rule. */
  name?: InputMaybe<Scalars['String']['input']>;
  /**
   * Deprecated. Use `protocols` instead. Single IP protocol to match.
   * @deprecated Use `protocols` instead.
   */
  protocol?: InputMaybe<IpProtocol>;
  /** List of IP protocols to match. */
  protocols?: InputMaybe<Array<IpProtocol>>;
  /** UUID of the source client VPN server to match traffic from. */
  srcClientVPNServerUUID?: InputMaybe<Scalars['UUID']['input']>;
  /** UUID of the source IPSec tunnel to match traffic from. */
  srcIPSecTunnelUUID?: InputMaybe<Scalars['UUID']['input']>;
  /**
   * Deprecated. Use `srcPortRanges` instead. Single source port range to match.
   * @deprecated Use `srcPortRanges` instead.
   */
  srcPortRange?: InputMaybe<PortRangeInput>;
  /** List of source port ranges to match traffic from. */
  srcPortRanges?: InputMaybe<Array<PortRangeInput>>;
  /** Source IP prefix to match traffic from. */
  srcPrefix?: InputMaybe<Scalars['IPPrefix']['input']>;
  /**
   * UUID of the source VLAN to match traffic from.
   * @deprecated Source VLAN is now implied from `boundVLANUUIDs`.
   */
  srcVLANUUID?: InputMaybe<Scalars['UUID']['input']>;
  /** List of tags associated with the firewall rule. */
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateFloorPlanInput = {
  /** An updated description of the floor plan. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** An updated S3 key (path) of the image file representing the floor plan. */
  imageS3Key?: InputMaybe<Scalars['String']['input']>;
  /** An updated human-readable name or label for the floor plan. */
  label?: InputMaybe<Scalars['String']['input']>;
};

/** Input type containing details for updating the cellular failover event. */
export type UpdateForCellularFailoverEventInput = {
  /** Specifies the route that the cellular failover event should follow. It can be either CELLULAR or WAN. */
  route: CellularFailoverEventRoute;
};

/** Input type for updating high availability event status. */
export type UpdateForHighAvailabilityEventInput = {
  /** The new status of the high availability event. */
  status: HighAvailabilityStatus;
};

/** Specifies the metadata that can be updated for a hardware device. */
export type UpdateHardwareDeviceInput = {
  /** [Deprecated] Use 'isSpare' instead. Indicates whether the hardware device is active. */
  active?: InputMaybe<Scalars['Boolean']['input']>;
  /** Enable or disable the hardware device's console port. */
  consoleEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Set the hardware device's factory mode status. */
  factoryMode?: InputMaybe<Scalars['Boolean']['input']>;
  /** Mark the hardware device as retired. */
  retired?: InputMaybe<Scalars['Boolean']['input']>;
  /** Mark the hardware device as an RMA (Return Merchandise Authorization) replacement device. */
  rma?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Input type for updating an existing host monitoring target. */
export type UpdateHostMonitoringTargetInput = {
  /** A new description for the host monitoring target. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Indicates whether the host monitoring target is enabled. */
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Input type for updating an existing IPSec tunnel. */
export type UpdateIpSecTunnelInput = {
  /** The authentication algorithm to use for the IPSec connection. */
  authenticationAlgorithm?: InputMaybe<IpSecAuthenticationAlgorithm>;
  /** The encryption algorithm to use for the IPSec connection. */
  encryptionAlgorithm?: InputMaybe<IpSecEncryptionAlgorithm>;
  /** Indicates whether the IPSec tunnel should be enabled. */
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Indicates whether this tunnel should initiate the connection to the right participant. */
  isInitiator?: InputMaybe<Scalars['Boolean']['input']>;
  /** The Diffie-Hellman group to use for key exchange in the IPSec connection. */
  keyExchangeDHGroup?: InputMaybe<IpSecDhGroup>;
  /** Identifier for the left participant (this side) for authentication purposes. Must be a FQDN or IPv4 address. */
  leftID?: InputMaybe<Scalars['String']['input']>;
  /** The name of the IPSec tunnel. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** UUID of the physical interface to bind the IPSec tunnel to; must be an uplink interface. */
  phyInterfaceUUID?: InputMaybe<Scalars['UUID']['input']>;
  /** The pre-shared key for authentication; can be base64 encoded or plaintext. */
  presharedKey?: InputMaybe<Scalars['String']['input']>;
  /** The public IP address or hostname of the right participant's gateway. */
  right?: InputMaybe<Scalars['String']['input']>;
  /** Identifier for the right participant (remote side) for authentication purposes. Must be a FQDN or IPv4 address. */
  rightID?: InputMaybe<Scalars['String']['input']>;
  /** List of IP prefixes representing the private subnets behind the right participant. */
  rightPrefixes?: InputMaybe<Array<Scalars['IPPrefix']['input']>>;
  /** List of VLAN UUIDs to bind the IPSec tunnel to. */
  vlanUUIDs?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type UpdateInternalNoteInput = {
  /** Indicates whether the note is marked as important. */
  isImportant?: InputMaybe<Scalars['Boolean']['input']>;
  /** Updated content of the internal note. */
  note?: InputMaybe<Scalars['String']['input']>;
  /** Use the `createPreSignedUploadUrl` mutation to generate S3 keys and presigned URLs for the photos, and specify them here. */
  photoS3Keys?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Input type for updating an existing Internet Service Plan. */
export type UpdateInternetServicePlanInput = {
  /** Connection details to update, including product and status. */
  connection?: InputMaybe<InternetServicePlanConnectionInput>;
  /** IP configuration details to update. */
  ipConfiguration?: InputMaybe<InternetServicePlanIpInput>;
  /** Unique identifier of the ISP provider to update. */
  providerUUID?: InputMaybe<Scalars['UUID']['input']>;
  /** Unique identifier of the sublocation to associate with the Internet Service Plan. */
  sublocationUUID?: InputMaybe<Scalars['UUID']['input']>;
};

/** Input type for updating a mailing address. */
export type UpdateMailingAddressInput = {
  /** The city of the mailing address. */
  city?: InputMaybe<Scalars['String']['input']>;
  /** The two-letter ISO code of the country. */
  countryISO2?: InputMaybe<Scalars['String']['input']>;
  /** The first line of the mailing address. */
  line1?: InputMaybe<Scalars['String']['input']>;
  /** The second line of the mailing address. */
  line2?: InputMaybe<Scalars['String']['input']>;
  /** The postal code or ZIP code of the mailing address. */
  postalCode?: InputMaybe<Scalars['String']['input']>;
  /** The subdivision code, such as the state or province code. */
  subdivisionCode?: InputMaybe<Scalars['String']['input']>;
};

/** Input object for updating a maintenance window. */
export type UpdateMaintenanceWindowInput = {
  /** The ending day of the week for the maintenance window, in local time (0-6, where 0 is Sunday). */
  endDayOfWeekLocal?: InputMaybe<Scalars['Int']['input']>;
  /** The ending hour of the maintenance window, in local time (0-23). */
  endHourOfDayLocal?: InputMaybe<Scalars['Int']['input']>;
  /** For DAILY recurrence windows only. Specifies days of the week (0-6, where 0 is Sunday) to exclude from the maintenance window. */
  excludedDaysOfWeek?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Indicates whether the maintenance window is opted out (true) or not (false). */
  isOptedOut?: InputMaybe<Scalars['Boolean']['input']>;
  /** The recurrence pattern of the maintenance window (daily or weekly). */
  recurrence?: InputMaybe<MaintenanceWindowRecurrence>;
  /** The starting day of the week for the maintenance window, in local time (0-6, where 0 is Sunday). */
  startDayOfWeekLocal?: InputMaybe<Scalars['Int']['input']>;
  /** The starting hour of the maintenance window, in local time (0-23). */
  startHourOfDayLocal?: InputMaybe<Scalars['Int']['input']>;
};

/** Input type for updating a network. */
export type UpdateNetworkInput = {
  /** A list of service types to enable for service discovery. */
  enabledServiceDiscoveryTypes?: InputMaybe<Array<ServiceType>>;
  /** Indicates whether the network is currently active. */
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  /** Enables controller to upgrade config-1 APs deployed to the network. */
  isConfig1WosUpgradesEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Indicates whether the network is a template for creating new networks. */
  isTemplate?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * Deprecated: Not a network property anymore.
   * @deprecated Not a network property anymore
   */
  isTest?: InputMaybe<Scalars['Boolean']['input']>;
  /** Indicates whether the network is sensitive to firmware updates. */
  isUpgradeSensitive?: InputMaybe<Scalars['Boolean']['input']>;
  /** A label to quickly identify the network. */
  label?: InputMaybe<Scalars['String']['input']>;
  /** The mailing address details to update. */
  mailingAddressInput?: InputMaybe<CreateMailingAddressInput>;
  /** The UUID of the mailing address associated with the network. */
  mailingAddressUUID?: InputMaybe<Scalars['UUID']['input']>;
  /** The algorithm used for multi-WAN load balancing. */
  multiWANAlgorithm?: InputMaybe<MultiWanAlgorithm>;
  /** The URL of the patch panel diagram for the network. */
  patchPanelDiagramURL?: InputMaybe<Scalars['String']['input']>;
  /** A human-readable identifier for the network. */
  slug?: InputMaybe<Scalars['String']['input']>;
  /**
   * Deprecated: Not a network property anymore.
   * @deprecated Not a network property anymore
   */
  squareFeet?: InputMaybe<Scalars['Int']['input']>;
  /** The S3 key for the network topology image. */
  topologyImageS3Key?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateNosFeatureInput = {
  /** A detailed description of the NOS feature. */
  description: Scalars['String']['input'];
  /** If the feature is also behind a feature flag, this is the name of the feature flag. */
  featureFlag?: InputMaybe<Scalars['String']['input']>;
  /** The maximum major version of NOS firmware that supports this feature. If null, there is no upper major version limit. */
  maxMajorVersion?: InputMaybe<Scalars['Int']['input']>;
  /** The maximum minor version of NOS firmware that supports this feature. If null, there is no upper minor version limit. */
  maxMinorVersion?: InputMaybe<Scalars['Int']['input']>;
  /** The maximum patch version of NOS firmware that supports this feature. If null, there is no upper patch version limit. */
  maxPatchVersion?: InputMaybe<Scalars['Int']['input']>;
  /** The minimum major version of NOS firmware required to support this feature. */
  minMajorVersion: Scalars['Int']['input'];
  /** The minimum minor version of NOS firmware required to support this feature. */
  minMinorVersion: Scalars['Int']['input'];
  /** The minimum patch version of NOS firmware required to support this feature. */
  minPatchVersion: Scalars['Int']['input'];
  /** The display name of the NOS feature. */
  name: Scalars['String']['input'];
};

/** Input type for updating an existing notification setting. */
export type UpdateNotificationSettingInput = {
  /** The UUID of the alert receiver who will receive the notifications. */
  alertReceiverUUID?: InputMaybe<Scalars['UUID']['input']>;
  /** A list of arguments providing specific parameters or context required by the notification definition. */
  arguments?: InputMaybe<Array<NotificationSettingArgumentInput>>;
  /** Indicates whether the notification setting is enabled. */
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** ID of the user who updated the notification setting. */
  updatedBy?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateOneToOneNatRuleInput = {
  /** New description for the 1:1 NAT rule. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** New external IP address for the NAT rule. */
  externalIPAddr?: InputMaybe<Scalars['IPV4']['input']>;
  /** UUID of the new physical interface associated with the external IP address. */
  externalPhyInterfaceUUID?: InputMaybe<Scalars['UUID']['input']>;
  /** New internal IP address for the NAT rule. */
  internalIPAddr?: InputMaybe<Scalars['IPV4']['input']>;
  /** Toggle to enable or disable the NAT rule. */
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** New name for the 1:1 NAT rule. */
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Input type for updating an outlet interface. */
export type UpdateOutletInterfaceInput = {
  /** UUID of the virtual device to connect to the power outlet. */
  connectedVirtualDeviceUUID?: InputMaybe<Scalars['UUID']['input']>;
  /** Description of the outlet interface. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Whether or not the outlet interface is enabled. */
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** User-defined label for the outlet interface. */
  label?: InputMaybe<Scalars['String']['input']>;
  /**
   * Number of seconds to wait when performing a power cycle.
   * The outlet turns off, waits for this duration, and then turns back on.
   */
  powerCycleDelaySec?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdatePhyInterfaceForMultiWanEventInput = {
  /** Port number of the active uplink interface. */
  activePortNumber: Scalars['Int']['input'];
  /** Timestamp when the active port number was updated. */
  activePortNumberUpdatedAt: Scalars['DateTime']['input'];
  /** Statically reported addresses of the physical interface. */
  uplinkExternalAddresses: Array<Scalars['UnmaskedIPPrefix']['input']>;
};

export type UpdatePhyInterfaceInput = {
  /** If true, flap link intervention protocol is enabled on the port. Both this flag and isFlipEnabled in SwitchProfile must be true for FLIP to be enabled on a port. */
  IsFLIPEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Maximum transmission unit (MTU) - max size in bytes for frames on the port. */
  MTU?: InputMaybe<Scalars['Int']['input']>;
  /** The UUIDs of the allowed (tagged) VLANs permitted on this interface. */
  allowedVLANUUIDs?: InputMaybe<Array<Scalars['UUID']['input']>>;
  /** A description of the physical interface. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The maximum amount of data that can be transmitted through the interface, in Mbps. */
  forcedPortSpeedMbps?: InputMaybe<Scalars['Int']['input']>;
  /** An optional filter to only accept certain frame types on this port. */
  frameAcceptTypeFilter?: InputMaybe<PhyInterfaceFrameAcceptTypeFilter>;
  /** IPv4 addresses to assign to the interface without netmasks when assigning as a client. */
  ipv4ClientAddresses?: InputMaybe<Array<Scalars['IPV4']['input']>>;
  /** Protocol to use for IPv4 addresses when assigning this interface as a client. */
  ipv4ClientAssignmentProtocol?: InputMaybe<ClientAssignmentProtocol>;
  /** Gateway IPv4 address without netmask when assigning as a client. */
  ipv4ClientGateway?: InputMaybe<Scalars['IPV4']['input']>;
  /** Subnet prefix length for the IPv4 addresses when assigning as a client. */
  ipv4ClientPrefixLength?: InputMaybe<Scalars['Int']['input']>;
  /** IPv6 addresses to assign to the interface without netmasks when assigning as a client. */
  ipv6ClientAddresses?: InputMaybe<Array<Scalars['IPV6']['input']>>;
  /** Protocol to use for IPv6 addresses when assigning this interface as a client. */
  ipv6ClientAssignmentProtocol?: InputMaybe<ClientAssignmentProtocol>;
  /** Gateway IPv6 address without netmask when assigning as a client. */
  ipv6ClientGateway?: InputMaybe<Scalars['IPV6']['input']>;
  /** Subnet prefix length for the IPv6 addresses when assigning as a client. */
  ipv6ClientPrefixLength?: InputMaybe<Scalars['Int']['input']>;
  /** Whether or not the interface is implicitly bound to all VLANs. */
  isBoundToAllVLANs?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether or not the interface is enabled. */
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** If true, tagged frames are discarded when the VLAN ID does not match. */
  isIngressFilteringEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether or not the interface is enabled to deliver Power over Ethernet. */
  isPOEEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** If true, configures the interface as an STP edge port. */
  isSTPEdgePortEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether or not the interface is enabled to participate in Spanning Tree Protocol. */
  isSTPEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether or not storm control is enabled on the port. */
  isStormControlEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether the port is a trunk port that carries multiple VLANs. */
  isTrunkPort?: InputMaybe<Scalars['Boolean']['input']>;
  /** Indicates if the interface is an uplink to other networks or devices. */
  isUplink?: InputMaybe<Scalars['Boolean']['input']>;
  /** A custom label for the physical interface. */
  label?: InputMaybe<Scalars['String']['input']>;
  /** Maximum number of MAC addresses allowed on the port for security. */
  maxMACAddressesAllowed?: InputMaybe<Scalars['Int']['input']>;
  /** The UUID of the native (untagged) VLAN assigned to this interface. */
  nativeVLANUUID?: InputMaybe<Scalars['UUID']['input']>;
  /** Receive rate limit for the port, in Kbps. */
  rxRateLimitKbps?: InputMaybe<Scalars['Int']['input']>;
  /** Percent of broadcast traffic to drop if storm control is enabled. */
  stormControlBroadcastTrafficPercent?: InputMaybe<Scalars['Int']['input']>;
  /** Percent of unknown multicast traffic to drop if storm control is enabled. */
  stormControlUnknownMulticastTrafficPercent?: InputMaybe<Scalars['Int']['input']>;
  /** Percent of unknown unicast traffic to drop if storm control is enabled. */
  stormControlUnknownUnicastTrafficPercent?: InputMaybe<Scalars['Int']['input']>;
  /** STP Path cost for the port (1-200000000). */
  stpPathCost?: InputMaybe<Scalars['Int']['input']>;
  /** STP Priority for the port (0-240 in multiples of 16, default 128). */
  stpPriority?: InputMaybe<Scalars['Int']['input']>;
  /** Transmit rate limit for the port, in Kbps. */
  txRateLimitKbps?: InputMaybe<Scalars['Int']['input']>;
  /** Priority order for using the uplink; lower values take precedence (if `isUplink` is true). */
  uplinkPriority?: InputMaybe<Scalars['Int']['input']>;
  /** VLAN ID used for the uplink (if `isUplink` is true). */
  uplinkVLANID?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdatePolicyRoutingRuleInput = {
  /** The list of bindings to update for this policy routing rule (optional). */
  bindings?: InputMaybe<Array<PolicyRoutingRuleBindingInput>>;
  /** A description for the policy routing rule (optional). */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The list of destination port ranges to update for the traffic matching this rule (optional). */
  dstPortRanges?: InputMaybe<Array<PortRangeInput>>;
  /** The destination IP prefix to update for the traffic matching this rule (optional). */
  dstPrefix?: InputMaybe<Scalars['IPPrefix']['input']>;
  /** Indicates whether the policy routing rule is enabled (optional). */
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** The new name of the policy routing rule (optional). */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The list of IP protocols to update for the traffic matching this rule (optional). */
  protocols?: InputMaybe<Array<IpProtocol>>;
  /** The list of source port ranges to update for the traffic matching this rule (optional). */
  srcPortRanges?: InputMaybe<Array<PortRangeInput>>;
  /** The source IP prefix to update for the traffic matching this rule (optional). */
  srcPrefix?: InputMaybe<Scalars['IPPrefix']['input']>;
  /** The UUID of the source VLAN to update for the traffic matching this rule (optional). */
  srcVLANUUID?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdatePortForwardingRuleInput = {
  /** Restricts the port forwarding rule to only allow traffic to be forwarded if it originates from any of these IP addresses. */
  allowedRemoteIPAddresses?: InputMaybe<Array<Scalars['IP']['input']>>;
  /** Description of the port forwarding rule. */
  description?: InputMaybe<Scalars['String']['input']>;
  /**
   * The external IP address to which this rule applies.
   * One of `externalPhyInterfaceUUID` or `externalIPAddress` must be provided.
   */
  externalIPAddress?: InputMaybe<Scalars['IP']['input']>;
  /**
   * UUID of the external uplink physical interface.
   * One of `externalPhyInterfaceUUID` or `externalIPAddress` must be provided.
   */
  externalPhyInterfaceUUID?: InputMaybe<Scalars['UUID']['input']>;
  /** The external port from which incoming traffic is forwarded. */
  externalPort?: InputMaybe<Scalars['Int']['input']>;
  /** Indicates whether the port forwarding rule is enabled. */
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** The local IP address to which incoming traffic is forwarded. */
  localIPAddress?: InputMaybe<Scalars['IP']['input']>;
  /** The local port to which the external port forwards traffic. */
  localPort?: InputMaybe<Scalars['Int']['input']>;
  /** Name of the port forwarding rule. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The IP protocol to apply this rule on. */
  protocol?: InputMaybe<PortIpProtocol>;
};

/** Input type for updating a device in a rack elevation. */
export type UpdateRackElevationDeviceInput = {
  /** Whether the device is front-facing or rear-facing. */
  isFrontFacing?: InputMaybe<Scalars['Boolean']['input']>;
  /** A friendly name for display purposes. */
  label?: InputMaybe<Scalars['String']['input']>;
  /** Additional context or comments about the device. */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** The number of ports on the device. Only for switches and patch panels; cannot be specified if it's a Meter device. */
  portCount?: InputMaybe<Scalars['Int']['input']>;
  /** The indexes of the rack units that the device occupies. */
  rackMountUnitIndexes?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** The type of the device. Cannot be specified if it's a Meter device. */
  type?: InputMaybe<RackElevationDeviceType>;
  /** If it's a Meter device, the virtual device's UUID. */
  virtualDeviceUUID?: InputMaybe<Scalars['UUID']['input']>;
};

/** Input type for updating an existing rack elevation. */
export type UpdateRackElevationInput = {
  /** The left-to-right position of the rack relative to others. */
  horizontalIndex?: InputMaybe<Scalars['Int']['input']>;
  /** Human-readable name for the rack elevation. */
  label?: InputMaybe<Scalars['String']['input']>;
  /** Notes associated with the rack elevation. */
  notes?: InputMaybe<Array<RackElevationNoteInput>>;
  /** The number of rack units (like slots) for the rack elevation. */
  rackMountUnitCount?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateRadioProfileInput = {
  /**
   * Required if band2_4GAutoChannelIsEnabled is true.
   * The list of allowed channels for auto channel selection on the 2.4 GHz band.
   */
  band2_4GAllowedChannels?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Indicates whether auto channel selection is enabled for the 2.4 GHz band. */
  band2_4GAutoChannelIsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Indicates whether auto channel width selection is enabled for the 2.4 GHz band. */
  band2_4GAutoChannelWidthIsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Indicates whether automatic transmit power adjustment is enabled for the 2.4 GHz band. */
  band2_4GAutoTxPowerIsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * Required if band2_4GAutoTxPowerIsEnabled is true.
   * The maximum transmit power in dBm for the 2.4 GHz band when auto transmit power is enabled.
   */
  band2_4GAutoTxPowerMaxdBm?: InputMaybe<Scalars['Int']['input']>;
  /**
   * Required if band2_4GAutoTxPowerIsEnabled is true.
   * The minimum transmit power in dBm for the 2.4 GHz band when auto transmit power is enabled.
   */
  band2_4GAutoTxPowerMindBm?: InputMaybe<Scalars['Int']['input']>;
  /**
   * Required if band2_4GAutoChannelWidthIsEnabled is false.
   * The channel width in MHz for the 2.4 GHz band when auto channel width is disabled.
   */
  band2_4GChannelWidthMHz?: InputMaybe<Scalars['Int']['input']>;
  /** Indicates whether the 2.4 GHz band is enabled. */
  band2_4GIsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * Required if band5GAutoChannelIsEnabled is true.
   * The list of allowed channels for auto channel selection on the 5 GHz band.
   */
  band5GAllowedChannels?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Indicates whether auto channel selection is enabled for the 5 GHz band. */
  band5GAutoChannelIsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Indicates whether auto channel width selection is enabled for the 5 GHz band. */
  band5GAutoChannelWidthIsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Indicates whether automatic transmit power adjustment is enabled for the 5 GHz band. */
  band5GAutoTxPowerIsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * Required if band5GAutoTxPowerIsEnabled is true.
   * The maximum transmit power in dBm for the 5 GHz band when auto transmit power is enabled.
   */
  band5GAutoTxPowerMaxdBm?: InputMaybe<Scalars['Int']['input']>;
  /**
   * Required if band5GAutoTxPowerIsEnabled is true.
   * The minimum transmit power in dBm for the 5 GHz band when auto transmit power is enabled.
   */
  band5GAutoTxPowerMindBm?: InputMaybe<Scalars['Int']['input']>;
  /**
   * Required if band5GAutoChannelWidthIsEnabled is false.
   * The channel width in MHz for the 5 GHz band when auto channel width is disabled.
   */
  band5GChannelWidthMHz?: InputMaybe<Scalars['Int']['input']>;
  /** Indicates whether the 5 GHz band is enabled. */
  band5GIsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * Setting this to true will set all other profiles to false.
   * Indicates whether this profile should be set as the default for the network.
   */
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  /** The new name for the radio profile. */
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Specifies the metadata that can be updated for radio settings for an Access Point. */
export type UpdateRadioSettingsInput = {
  /** The primary channel for the 2.4 GHz radio band. */
  band2_4GPrimaryChannel?: InputMaybe<Scalars['Int']['input']>;
  /** The secondary channel for the 2.4 GHz radio band, if applicable. */
  band2_4GSecondaryChannel?: InputMaybe<Scalars['Int']['input']>;
  /** The transmit power in dBm for the 2.4 GHz radio band. */
  band2_4GTransmitPowerdBm?: InputMaybe<Scalars['Int']['input']>;
  /** The primary channel for the 5 GHz radio band. */
  band5GPrimaryChannel?: InputMaybe<Scalars['Int']['input']>;
  /** The secondary channel for the 5 GHz radio band, if applicable. */
  band5GSecondaryChannel?: InputMaybe<Scalars['Int']['input']>;
  /** The transmit power in dBm for the 5 GHz radio band. */
  band5GTransmitPowerdBm?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateRateLimitRuleInput = {
  /** A list of UUIDs of physical interfaces to which this rate limit rule is bound. */
  boundPhyInterfaceUUIDs?: InputMaybe<Array<Scalars['UUID']['input']>>;
  /** A list of UUIDs of VLANs to which this rate limit rule is bound. */
  boundVLANUUIDs?: InputMaybe<Array<Scalars['UUID']['input']>>;
  /** A description of the rate limit rule. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Destination MAC address to match. */
  dstMac?: InputMaybe<Scalars['MacAddress']['input']>;
  /** Destination port range to match. */
  dstPortRange?: InputMaybe<PortRangeInput>;
  /** Destination IP address prefix to match. */
  dstPrefix?: InputMaybe<Scalars['IPPrefix']['input']>;
  /** Indicates whether the rate limit rule is enabled. */
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Specifies if the rate limit is applied per source IP/MAC address. */
  isPerSource?: InputMaybe<Scalars['Boolean']['input']>;
  /** The name of the rate limit rule. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** IANA protocol number to match (e.g., 1 = ICMP, 6 = TCP, 17 = UDP). A value of 256 denotes all protocols. */
  protocol?: InputMaybe<IpProtocol>;
  /** Maximum download rate limit in kilobits per second. */
  rateLimitKbpsDownload?: InputMaybe<Scalars['Int']['input']>;
  /** Maximum upload rate limit in kilobits per second. */
  rateLimitKbpsUpload?: InputMaybe<Scalars['Int']['input']>;
  /** Source MAC address to match. */
  srcMac?: InputMaybe<Scalars['MacAddress']['input']>;
  /** Source port range to match. */
  srcPortRange?: InputMaybe<PortRangeInput>;
  /** Source IP address prefix to match. */
  srcPrefix?: InputMaybe<Scalars['IPPrefix']['input']>;
};

export type UpdateSsidInput = {
  /** The lowest rate at which a client can connect to the 2.4 GHz radio, in kilobits per second. */
  band2GMinimumBasicRateKbps?: InputMaybe<Scalars['Int']['input']>;
  /** The lowest rate at which a client can connect to the 5 GHz radio, in kilobits per second. */
  band5GMinimumBasicRateKbps?: InputMaybe<Scalars['Int']['input']>;
  /** Enable or disable client steering for association. */
  clientSteeringAssociationSteeringIsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** The minimum number of clients on an SSID before client steering is attempted. */
  clientSteeringLoadBalancingClientMinThreshold?: InputMaybe<Scalars['Int']['input']>;
  /** Enable or disable post-association client steering. */
  clientSteeringPostassociationSteeringIsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** The minimum RSSI signal strength (in dBm) before pre-association client steering is attempted. */
  clientSteeringPreassociationRSSIMinThreshold?: InputMaybe<Scalars['Int']['input']>;
  /** Description of the SSID. */
  description?: InputMaybe<Scalars['String']['input']>;
  disabledAccessPointVirtualDeviceUUIDs?: InputMaybe<Array<Scalars['UUID']['input']>>;
  /** The DTIM period for the SSID. */
  dtimPeriod?: InputMaybe<Scalars['Int']['input']>;
  /** Dynamic VLAN mode setting. */
  dynamicVLANMode?: InputMaybe<SsidDynamicVlanMode>;
  /**
   * Required if isEnabledForAllAccessPoints is false.
   * List of access point virtual device UUIDs on which the SSID is enabled.
   */
  enabledAccessPointVirtualDeviceUUIDs?: InputMaybe<Array<Scalars['UUID']['input']>>;
  /** 802.11w Protected Management Frames mode. */
  encryption80211wProtectedManagementFramesMode?: InputMaybe<SsidEncryption80211wProtectedManagementFramesMode>;
  /** Encryption protocol used by the SSID. */
  encryptionProtocol?: InputMaybe<SsidEncryptionProtocol>;
  /** An optional value for the Hotspot 2.0 Access network type. */
  hs20AccessNetworkType?: InputMaybe<Scalars['Int']['input']>;
  /** When set to true, clears all Hotspot 2.0 data. */
  hs20ClearAll?: InputMaybe<Scalars['Boolean']['input']>;
  /** A list of Hotspot 2.0 domain names. */
  hs20DomainNames?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Enable or disable Hotspot 2.0 (Passpoint). */
  hs20Enabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** A list of MCC/MNC pairs, formatted as "MCC,MNC". */
  hs20MccMncPairs?: InputMaybe<Array<Scalars['String']['input']>>;
  /** A list of Hotspot 2.0 NAI realms. */
  hs20NaiRealms?: InputMaybe<Array<Scalars['String']['input']>>;
  /** A list of Operator names for Hotspot 2.0 (one per language). */
  hs20OperatorNames?: InputMaybe<Array<Scalars['String']['input']>>;
  /** A list of Hotspot 2.0 Consortium OIs (Organization Identifiers). */
  hs20RoamingConsortiumOIs?: InputMaybe<Array<Scalars['String']['input']>>;
  /** An optional value for the Hotspot 2.0 Venue group. */
  hs20VenueGroup?: InputMaybe<Scalars['Int']['input']>;
  /**
   * A list of Venue names for Hotspot 2.0 (one per language); must be formatted with a two or three character language code separated by a colon from the venue name.
   * If at least one is set, hs20VenueGroup and hs20VenueType must be set.
   */
  hs20VenueNames?: InputMaybe<Array<Scalars['String']['input']>>;
  /** An optional value for the Hotspot 2.0 Venue type. */
  hs20VenueType?: InputMaybe<Scalars['Int']['input']>;
  /** Enable or disable 802.11ax (Wi-Fi 6) for the SSID. */
  is80211axEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Enable or disable AP debug logging for APs broadcasting the SSID. */
  isAPDebugLogEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Enable or disable broadcasting the SSID on the 2.4 GHz band. */
  isBand2GAllowed?: InputMaybe<Scalars['Boolean']['input']>;
  /** Enable or disable broadcasting the SSID on the 5 GHz band. */
  isBand5GAllowed?: InputMaybe<Scalars['Boolean']['input']>;
  /** Enable or disable the SSID. */
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Enable the SSID for all access points or only specified ones. */
  isEnabledForAllAccessPoints?: InputMaybe<Scalars['Boolean']['input']>;
  /** Mark the SSID as a guest network. */
  isGuest?: InputMaybe<Scalars['Boolean']['input']>;
  /** Hide the SSID from being broadcasted. */
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  /** Enable client isolation on the SSID. */
  isIsolateClientsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Enable or disable multicast to unicast translation on the SSID. */
  isMulticastToUnicastEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Enable or disable 802.11k roaming. */
  isRoaming80211kEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Enable or disable 802.11r fast roaming. */
  isRoaming80211rEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Enable or disable 802.11v roaming. */
  isRoaming80211vEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Threshold above which multicast to unicast is disabled. If 0, no limit. */
  multicastToUnicastClientThreshold?: InputMaybe<Scalars['Int']['input']>;
  /** Pre-shared key (PSK) for the SSID. */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Cadence for automatic password rotation. */
  passwordRotationCadence?: InputMaybe<SsidPasswordRotationCadence>;
  /** The day of the month for password rotation in the network's local time zone. */
  passwordRotationDayOfMonthLocal?: InputMaybe<Scalars['Int']['input']>;
  /** The day of the week for password rotation in the network's local time zone (0 = Sunday). */
  passwordRotationDayOfWeekLocal?: InputMaybe<Scalars['Int']['input']>;
  /** The hour of the day for password rotation in the network's local time zone. */
  passwordRotationHourOfDayLocal?: InputMaybe<Scalars['Int']['input']>;
  /**
   * Set the primary 802.1X encryption configuration.
   * Required if encryptionProtocol is WPA2_ENTERPRISE.
   */
  primaryEncryption8021XUUID?: InputMaybe<Scalars['UUID']['input']>;
  /** Enable or disable RADIUS Change of Authorization (CoA). */
  radiusCoaEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** A port where RADIUS servers can send CoA requests. */
  radiusCoaPort?: InputMaybe<Scalars['Int']['input']>;
  /** Value to configure RTS/CTS. */
  rtsCtsEnabledCode?: InputMaybe<Scalars['Int']['input']>;
  /** Size of the PPDU on which RTS would be sent out. */
  rtsThreshold?: InputMaybe<Scalars['Int']['input']>;
  /**
   * Set the secondary 802.1X encryption configuration.
   * Required if encryptionProtocol is WPA2_ENTERPRISE.
   */
  secondaryEncryption8021XUUID?: InputMaybe<Scalars['UUID']['input']>;
  /** Name of the SSID. */
  ssid?: InputMaybe<Scalars['String']['input']>;
  /** UUID of the VLAN to associate with the SSID. */
  vlanUUID?: InputMaybe<Scalars['UUID']['input']>;
};

/** Input type for updating a security appliance profile. */
export type UpdateSecurityApplianceProfileInput = {
  /** The algorithm used to manage multiple WAN connections, such as load balancing or failover strategies. */
  multiWANAlgorithm?: InputMaybe<MultiWanAlgorithm>;
  /** The number of seconds to wait before another failover can occur after a recent one */
  multiWANUpdateCooldownSec?: InputMaybe<Scalars['Int']['input']>;
  /** Number of previous ping results to consider for the failover calculation. */
  multiWANUpdateLookback?: InputMaybe<Scalars['Int']['input']>;
  /**
   * The minimum success ratio to consider a WAN link as good.
   * If it drops below the threshold, a failover occurs.
   * Stored as an integer representing the percentage.
   * For example, a value of 65 represents 65%, or a success ratio of 0.65.
   */
  multiWANUpdateThreshold?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateStaticRouteInput = {
  /** New enabled state for the static route. */
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** New name for the static route. */
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Input type for updating a switch profile. */
export type UpdateSwitchProfileInput = {
  /** The time in seconds after which all flap state for a port is cleared. */
  flipClearFlapStateAfterSec?: InputMaybe<Scalars['Int']['input']>;
  /** The maximum number of times to toggle the port before requiring manual toggle of port in dashboard. */
  flipMaxRetryCount?: InputMaybe<Scalars['Int']['input']>;
  /** The number of seconds to wait before re-enabling the port. */
  flipRetryPeriodSec?: InputMaybe<Scalars['Int']['input']>;
  /** Time window in seconds to count the number of flaps. */
  flipWindowSec?: InputMaybe<Scalars['Int']['input']>;
  /** The number of flaps per time window that will result in a port being shut down. */
  flipWindowThreshold?: InputMaybe<Scalars['Int']['input']>;
  /** If true, flap link intervention protocol is enabled for this switch profile. Both this flag and flipIsEnabled in PhyInterface must be true for FLIP to be enabled on a port. */
  isFlipEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** The new name of the switch profile. */
  name?: InputMaybe<Scalars['String']['input']>;
  /**
   * The STP bridge priority for the switch.
   *
   * The lower the value, the more likely the switch is to be elected as the root bridge.
   * The range is 0 to 61440. Valid values are multiples of 4096.
   */
  stpBridgePriority?: InputMaybe<Scalars['Int']['input']>;
  /**
   * STP forwarding delay in seconds (4-30).
   *
   * The delay between port state changes in the spanning tree.
   */
  stpForwardingDelaySeconds?: InputMaybe<Scalars['Int']['input']>;
  /**
   * STP Hello interval in seconds (1-10).
   *
   * The time between configuration messages sent by the root bridge.
   */
  stpHelloIntervalSeconds?: InputMaybe<Scalars['Int']['input']>;
  /**
   * STP Max BPDU age in seconds (6-40).
   *
   * The maximum age of received BPDU information before discarding.
   */
  stpMaxAgeSeconds?: InputMaybe<Scalars['Int']['input']>;
  /**
   * STP BPDU transmit hold count per second (1-10).
   *
   * The maximum number of BPDUs that can be sent per second.
   */
  stpTransmitHoldCount?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateUplinkPhyInterfaceExternalAddressesInput = {
  /** Physical port number on the device where the interface is located. */
  portNumber: Scalars['Int']['input'];
  /** Statically reported addresses of the physical interface. */
  uplinkExternalAddresses: Array<Scalars['UnmaskedIPPrefix']['input']>;
  /** Timestamp when the uplink external addresses were updated. */
  uplinkExternalAddressesUpdatedAt: Scalars['DateTime']['input'];
};

export type UpdateVlandhcpRuleInput = {
  /** The UUID of the DHCP rule to update. */
  dhcpRuleUUID: Scalars['UUID']['input'];
  /** The input containing updated values for the DHCP rule. */
  input: UpdateDhcpRuleInput;
};

export type UpdateVlanInput = {
  /**
   * [Deprecated] The UUID of the VLAN to update. Use `UUID` in `updateVLAN` instead.
   * @deprecated Use `UUID` in `updateVLAN` instead.
   */
  UUID?: InputMaybe<Scalars['UUID']['input']>;
  /** A list of DHCP rules to create for this VLAN. */
  createDHCPRules?: InputMaybe<Array<CreateDhcpRuleInput>>;
  /** A list of UUIDs of DHCP rules to delete from this VLAN. */
  deleteDHCPRules?: InputMaybe<Array<Scalars['UUID']['input']>>;
  /** A new description for the VLAN. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Updates the protocol for assigning IPv4 addresses to clients on this VLAN. */
  ipV4ClientAssignmentProtocol?: InputMaybe<ClientAssignmentProtocol>;
  /** Updates the IPv4 gateway address for this VLAN. */
  ipV4ClientGateway?: InputMaybe<Scalars['IPV4']['input']>;
  /** Updates the IPv4 prefix length (subnet mask) for this VLAN. */
  ipV4ClientPrefixLength?: InputMaybe<Scalars['Int']['input']>;
  /** Updates the protocol for assigning IPv6 addresses to clients on this VLAN. */
  ipV6ClientAssignmentProtocol?: InputMaybe<ClientAssignmentProtocol>;
  /** Updates the IPv6 gateway address for this VLAN. */
  ipV6ClientGateway?: InputMaybe<Scalars['IPV6']['input']>;
  /** Updates the IPv6 prefix length (subnet mask) for this VLAN. */
  ipV6ClientPrefixLength?: InputMaybe<Scalars['Int']['input']>;
  /** Enable or disable the VLAN. */
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** If true, marks the VLAN as internal to the network. */
  isInternal?: InputMaybe<Scalars['Boolean']['input']>;
  /** If true, enables or disables multicast/mDNS reflection for the VLAN. */
  isMulticastReflectionEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** A new name for the VLAN. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** A list of DHCP rules to update for this VLAN. */
  updateDHCPRules?: InputMaybe<Array<UpdateVlandhcpRuleInput>>;
  /** The new VLAN ID. Must be between 1 and 4094 and unique per network. */
  vlanID?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateVlaNsIndependentlyInput = {
  /** The UUID of the VLAN to update. */
  UUID: Scalars['UUID']['input'];
  /** The input containing the settings to update for the VLAN. */
  input: UpdateVlaNsInput;
};

/** Settings to update for multiple VLANs. */
export type UpdateVlaNsInput = {
  /** If true, enables multicast/mDNS reflection on the VLANs. */
  isMulticastReflectionEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Specifies the metadata that can be updated for a virtual device, used to update many virtual devices at once with different values. */
export type UpdateVirtualDeviceIndependentlyInput = {
  /** The update input containing the fields to update for the virtual device. */
  input: UpdateVirtualDeviceInput;
  /** The UUID of the virtual device to update. */
  virtualDeviceUUID: Scalars['UUID']['input'];
};

/** Specifies the metadata that can be updated for a virtual device. */
export type UpdateVirtualDeviceInput = {
  /** An optional description for the virtual device. */
  description?: InputMaybe<Scalars['String']['input']>;
  /**
   * For some devices, we will allow the user to set the device model.
   * This will be useful for hardware swaps where we want to retain the same configuration for the virtual device.
   * There will be application validation, since sometimes changing the model is not valid (e.g., if the number of ports on the device differs,
   * or if the new model doesn't support features that the old model did).
   */
  deviceModel?: InputMaybe<DeviceModel>;
  /** Enable or disable the console port on the virtual device. */
  enableConsolePort?: InputMaybe<Scalars['Boolean']['input']>;
  /** Enable or disable LED dark mode on the access point. */
  enableLEDDarkMode?: InputMaybe<Scalars['Boolean']['input']>;
  /** Currently we only support enabling debug logs on APs. */
  isDebugLogEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** A human-friendly name for the virtual device. */
  label?: InputMaybe<Scalars['String']['input']>;
  /** [Access Points only] The UUID of the radio profile to associate with the access point. */
  radioProfileUUID?: InputMaybe<Scalars['UUID']['input']>;
  /** [Access Points only] The radio settings to update on the access point. */
  radioSettings?: InputMaybe<UpdateRadioSettingsInput>;
  /** [Switches only] The UUID of the switch profile to associate with the switch. */
  switchProfileUUID?: InputMaybe<Scalars['UUID']['input']>;
  /**
   * Overwrite the tags for a virtual device (will delete any existing tags not specified in the list).
   * Currently only APs support tags.
   */
  tagUUIDs?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type UpdateVirtualDeviceTagInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Input type for creating or updating a network onboarding field delegation. */
export type UpsertNetworkOnboardingFieldDelegationInput = {
  /** Description of the delegation. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Email of the user to whom the field is delegated. */
  email: Scalars['String']['input'];
};

/** A user is a person or machine that has access to Meter. */
export type User = {
  __typename?: 'User';
  /** The email address of the user. */
  email: Scalars['String']['output'];
  /** The first name of the user. */
  firstName?: Maybe<Scalars['String']['output']>;
  /** The unique identifier for the user. */
  id: Scalars['ID']['output'];
  /** The last name of the user. */
  lastName?: Maybe<Scalars['String']['output']>;
};

/**
 * Input type for specifying a user's role assignment.
 *
 * Allows for adding or replacing a role assignment for a user.
 */
export type UserRoleInput = {
  /**
   * The UUID of the network for network-specific roles.
   *
   * Omit for company-wide or global roles.
   */
  networkUUID?: InputMaybe<Scalars['UUID']['input']>;
  /** The name of the role to assign or remove. */
  roleName: RoleName;
};

/** A VLAN (virtual LAN) is a logical network segment that allows network devices to communicate as if they were physically connected to the same network. */
export type Vlan = {
  __typename?: 'VLAN';
  /** The unique identifier for the VLAN. */
  UUID: Scalars['UUID']['output'];
  /** The captive portal associated with this VLAN. */
  captivePortal?: Maybe<CaptivePortal>;
  /** The timestamp indicating when the VLAN was created. */
  createdAt: Scalars['DateTime']['output'];
  /** An optional description of the VLAN. */
  description?: Maybe<Scalars['String']['output']>;
  /** The DHCP rule associated with this VLAN. */
  dhcpRule?: Maybe<DhcpRule>;
  /** Specifies the protocol for assigning IPv4 addresses to clients on this VLAN. */
  ipV4ClientAssignmentProtocol?: Maybe<ClientAssignmentProtocol>;
  /** Specifies the IPv4 gateway address for this VLAN. This value should not include a netmask. */
  ipV4ClientGateway?: Maybe<Scalars['IPV4']['output']>;
  /** Specifies the IPv4 prefix length (subnet mask) for this VLAN. */
  ipV4ClientPrefixLength?: Maybe<Scalars['Int']['output']>;
  /** Specifies the protocol for assigning IPv6 addresses to clients on this VLAN. */
  ipV6ClientAssignmentProtocol?: Maybe<ClientAssignmentProtocol>;
  /** Specifies the IPv6 gateway address for this VLAN. This value should not include a netmask. */
  ipV6ClientGateway?: Maybe<Scalars['IPV6']['output']>;
  /** Specifies the IPv6 prefix length (subnet mask) for this VLAN. */
  ipV6ClientPrefixLength?: Maybe<Scalars['Int']['output']>;
  /** Only one VLAN per network can be the default VLAN. Untagged packets that don't belong to any configured VLANs are assigned to the default one. */
  isDefault: Scalars['Boolean']['output'];
  /** If true, the VLAN is enabled and can be used in the network. */
  isEnabled: Scalars['Boolean']['output'];
  /** If true, this VLAN is internal to the network and should not be displayed to or editable by the customer. */
  isInternal?: Maybe<Scalars['Boolean']['output']>;
  /** If true, this VLAN participates in multicast/mDNS reflection. */
  isMulticastReflectionEnabled: Scalars['Boolean']['output'];
  /** A human-friendly name to identify the VLAN, like "guest". Unique per network. */
  name: Scalars['String']['output'];
  /** The UUID of the network to which the VLAN belongs. */
  networkUUID: Scalars['UUID']['output'];
  /** VLANs explicitly allowed to inter-communicate with this VLAN. Effectively equivalent to firewall rules allowing communication between this VLAN and all of these VLANs. */
  permittedInterVLANCommunicationVLANs: Array<Vlan>;
  /** The timestamp indicating the last time the VLAN was updated. */
  updatedAt: Scalars['DateTime']['output'];
  /** The VLAN ID. Must be between 1 and 4094 and is unique per network. */
  vlanID: Scalars['Int']['output'];
};

/** Input to specify a pair of VLANs for permitted inter-VLAN communication. */
export type VlanPairInput = {
  /** The UUID of the first VLAN in the pair. */
  vlan1UUID: Scalars['UUID']['input'];
  /** The UUID of the second VLAN in the pair. */
  vlan2UUID: Scalars['UUID']['input'];
};

/** Response type containing valid channels for a radio band. */
export type ValidChannelsResponse = {
  __typename?: 'ValidChannelsResponse';
  /** The radio band for which the valid channels are provided. */
  band: RadioBand;
  /** A list of valid channels for the specified radio band. */
  channels: Array<Scalars['Int']['output']>;
};

/** A virtual device is a logical representation of a physical device; configuration is associated with the virtual device. */
export type VirtualDevice = {
  /** The unique identifier (UUID) of the virtual device. */
  UUID: Scalars['UUID']['output'];
  /** The timestamp when the virtual device was first created in the system. */
  createdAt: Scalars['DateTime']['output'];
  /** An optional description for the virtual device. */
  description?: Maybe<Scalars['String']['output']>;
  /** The model name of the virtual device. */
  deviceModel: DeviceModel;
  /** The type of the virtual device. */
  deviceType: VirtualDeviceType;
  /** The hardware device associated with this virtual device, if any. */
  hardwareDevice?: Maybe<HardwareDevice>;
  /** Indicates whether the virtual device's console port is enabled. */
  isConsoleEnabled: Scalars['Boolean']['output'];
  /** Indicates whether debug logging is enabled on the virtual device. */
  isDebugLogEnabled: Scalars['Boolean']['output'];
  /** A human-friendly name for the device. */
  label: Scalars['String']['output'];
  /** The network that the virtual device belongs to. */
  network: Network;
  /** The unique identifier (UUID) of the network the virtual device belongs to. */
  networkUUID: Scalars['UUID']['output'];
  /** The current NOS firmware version that the virtual device is configured to run. */
  nosVersion?: Maybe<ConfiguredNosVersion>;
  /** The identifier of the NOS firmware version that the virtual device is configured to run. */
  nosVersionID: Scalars['Int']['output'];
  /** The NOS firmware version that the virtual device is scheduled to upgrade to. */
  pendingNosVersion?: Maybe<PendingNosVersion>;
  /** The timestamp when the virtual device was last updated in the system. */
  updatedAt: Scalars['DateTime']['output'];
  /** The uptime from our time series database for the device. */
  uptime?: Maybe<Scalars['Duration']['output']>;
};

/** Represents the history of NOS versions scheduled and applied to a virtual device. */
export type VirtualDeviceNosHistory = {
  __typename?: 'VirtualDeviceNosHistory';
  /** Timestamp when the NOS version was actually applied to the virtual device. */
  appliedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The NOS version scheduled or applied to the virtual device. */
  nosVersion: NosVersion;
  /** Timestamp when the NOS version was scheduled to be applied to the virtual device. */
  scheduledAt: Scalars['DateTime']['output'];
  /** Current status of the NOS version application on the virtual device (e.g., 'scheduled', 'in progress', 'completed', 'failed'). */
  status: Scalars['String']['output'];
};

/** A virtual device tag is an attribute that can be applied to many virtual devices to group them together for bulk-actions and UI filtering. */
export type VirtualDeviceTag = {
  __typename?: 'VirtualDeviceTag';
  UUID: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  networkUUID: Scalars['UUID']['output'];
};

/** VirtualDeviceType enumerates the different types of virtual devices that Meter deploys. */
export enum VirtualDeviceType {
  /** Represents an Access Point virtual device type. */
  AccessPoint = 'ACCESS_POINT',
  /** Represents a Controller virtual device type. */
  Controller = 'CONTROLLER',
  /** Represents an Observer virtual device type. */
  Observer = 'OBSERVER',
  /** Represents a Power Distribution Unit virtual device type. */
  PowerDistributionUnit = 'POWER_DISTRIBUTION_UNIT',
  /** Represents a Switch virtual device type. */
  Switch = 'SWITCH',
}

/** Enumeration of Wide Area Network (WAN) interfaces. */
export enum Wan {
  /** WAN interface 0. */
  Wan0 = 'wan0',
  /** WAN interface 1. */
  Wan1 = 'wan1',
}

/** Details about a WAN connection. */
export type WanConnection = {
  __typename?: 'WANConnection';
  /** The list of external IP addresses associated with this WAN connection. */
  externalAddresses: Array<Scalars['String']['output']>;
  /** The internet service plan provider for this WAN connection. */
  internetServicePlanProvider?: Maybe<InternetServicePlanProvider>;
  /** The port number of the WAN connection. */
  port: Scalars['Int']['output'];
};

/** Properties for WAN down events. */
export type WanDownProperties = {
  __typename?: 'WANDownProperties';
  /** The virtual device associated with the WAN down event. */
  virtualDevice: VirtualDevice;
};

/** Represents the direction of the WAN speed test. */
export enum WanSpeedtestDirection {
  /** Download direction. */
  Down = 'DOWN',
  /** For simplicity in schema, latency is unintuitively considered a direction. */
  Latency = 'LATENCY',
  /** Unspecified direction. */
  Unspecified = 'UNSPECIFIED',
  /** Upload direction. */
  Up = 'UP',
}

/** Represents a WAN (Wide Area Network) speed test job. */
export type WanSpeedtestJob = NetworkJob & {
  __typename?: 'WANSpeedtestJob';
  /** The unique identifier for the job. */
  UUID: Scalars['UUID']['output'];
  /** The base URL used for the speed test. */
  baseURL: Scalars['String']['output'];
  /** The time at which the job was completed. */
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The maximum number of bytes to transfer during the speed test. */
  maxTransferBytes?: Maybe<Scalars['Int']['output']>;
  /** The unique identifier of the network associated with this job. */
  networkUUID: Scalars['UUID']['output'];
  /** The physical interface on which the speed test is run. */
  phyInterface: PhyInterface;
  /** The results of the speed test job. */
  results?: Maybe<Array<WanSpeedtestJobResult>>;
  /** The time at which the job was scheduled. */
  scheduledAt: Scalars['DateTime']['output'];
  /** The current status of the speed test. */
  status?: Maybe<WanSpeedtestStatus>;
};

/** Represents the result of a single WAN speed test measurement. */
export type WanSpeedtestJobResult = {
  __typename?: 'WANSpeedtestJobResult';
  /** The speed test direction (e.g., UP, DOWN, LATENCY). */
  direction: WanSpeedtestDirection;
  /** The duration in nanoseconds that this result took. Null for LATENCY results. */
  durationNs?: Maybe<Scalars['Int']['output']>;
  /** Indicates whether the test failed. */
  failed: Scalars['Boolean']['output'];
  /** The round-trip times (RTTs) in nanoseconds for each HTTP request in this result. */
  httpRTTsNs: Array<Scalars['Int']['output']>;
  /** The measured speed in megabits per second (Mbps). Null for LATENCY results. */
  mbps?: Maybe<Scalars['Float']['output']>;
  /** The number of bytes transferred in this result. Null for LATENCY results. */
  transferBytes?: Maybe<Scalars['Int']['output']>;
};

/** Represents the status of a WAN speed test. */
export enum WanSpeedtestStatus {
  /** Indicates the test is not running. */
  Ok = 'OK',
  /** Indicates the test is currently running. */
  Running = 'RUNNING',
  /** Status is unknown. */
  Unknown = 'UNKNOWN',
}

/** Properties for WAN status change events. */
export type WanStatusChangeProperties = {
  __typename?: 'WANStatusChangeProperties';
  /** The list of active WAN connections. */
  activeWANConnections: Array<Maybe<WanConnection>>;
  /** The virtual device associated with the WAN status change. */
  virtualDevice: VirtualDevice;
};

/** Properties for WAN up events. */
export type WanUpProperties = {
  __typename?: 'WANUpProperties';
  /** The virtual device associated with the WAN up event. */
  virtualDevice: VirtualDevice;
};

/** Observer settings contain configuration for running wireless observer tests. */
export type WifiObserverSetting = {
  __typename?: 'WifiObserverSetting';
  /** The UUID of the SSID to use for the Observer test. */
  SSIDUUID: Scalars['UUID']['output'];
  /** The radio band on which the test should be run (e.g., 2.4GHz, 5GHz). */
  band: RadioBand;
  /** List of commands to run during the observer test. */
  commands?: Maybe<Array<WosObserverCommand>>;
  /** Duration of the test in milliseconds. */
  durationMsec: Scalars['Int']['output'];
  /** The UUID of the network to which this Wifi Observer Setting belongs. */
  networkUUID: Scalars['UUID']['output'];
  /** 802.1X password for authentication. */
  password8021X?: Maybe<Scalars['String']['output']>;
  /** The UUID of the virtual device (access point) that the observer will target or get results for. */
  targetVirtualDeviceUUID?: Maybe<Scalars['UUID']['output']>;
  /** 802.1X username for authentication. */
  username8021X?: Maybe<Scalars['String']['output']>;
  /** The UUID of the virtual device on which to run the observer test. */
  virtualDeviceUUID: Scalars['UUID']['output'];
};

/**
 * A single snapshot of client connectivity metrics for a single client.
 * Not an average over a time period or across multiple clients.
 */
export type WirelessClient = {
  __typename?: 'WirelessClient';
  /** When the client associated with the AP. */
  associatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The radio band the client is connected to. */
  band: RadioBand;
  /** The channel number the client is using. */
  channel: Scalars['Int']['output'];
  /** The MAC address of the client device. */
  macAddress: Scalars['MacAddress']['output'];
  /** The latest connectivity metrics of the client. */
  metrics: ClientMetricsTimeseriesValue;
  /** The UUID of the network the client is connected to. */
  networkUUID: Scalars['UUID']['output'];
  /** List of mDNS service types that this client offers (e.g., printer, AirPlay, etc.). */
  serviceTypes?: Maybe<Array<ServiceType>>;
  /** The SSID that the client interacted with. */
  ssid: Ssid;
  /** The name of the SSID that the client interacted with. */
  ssidName: Scalars['String']['output'];
  /** The AP virtual device that the client interacted with. */
  virtualDevice: AccessPointVirtualDevice;
  /** The UUID of the AP virtual device the client is connected to. */
  virtualDeviceUUID: Scalars['UUID']['output'];
};

/** A statistic that indicates when a client connected to an AP, disassociated from an AP, or failed to connect to an AP. */
export type WirelessClientConnectionEvent = {
  __typename?: 'WirelessClientConnectionEvent';
  /** The radio band that the client interacted with. */
  band: RadioBand;
  /** The type of connection event. */
  eventType: WirelessClientConnectionEventType;
  /** If the event type is `FAILED`, explains which step of the connection process failed. */
  failedStep?: Maybe<WirelessClientConnectionFailedStep>;
  /** Whether the connection event was part of a fast transition (802.11r) roam. */
  is80211rRoam: Scalars['Boolean']['output'];
  /** The MAC address of the client. */
  macAddress: Scalars['MacAddress']['output'];
  /** If the event type is `CONNECTED`, the AP virtual device that the client was previously connected to. */
  previousVirtualDeviceConnectedTo?: Maybe<AccessPointVirtualDevice>;
  /** If the event type is `CONNECTED`, the UUID of the AP virtual device the client was previously connected to. */
  previousVirtualDeviceUUIDConnectedTo?: Maybe<Scalars['UUID']['output']>;
  /** If the event type is `DISASSOCIATED` or `FAILED`, the 802.11 reason code for the event. */
  reasonCode?: Maybe<Scalars['Int']['output']>;
  /** If the event type is `DISASSOCIATED`, how long the client was associated with the AP before disassociating. */
  sessionDuration?: Maybe<Scalars['Duration']['output']>;
  /** The SSID that the client interacted with. */
  ssid?: Maybe<Ssid>;
  /** The UUID of the SSID that the client interacted with. */
  ssidUUID?: Maybe<Scalars['UUID']['output']>;
  /** If the event type is `CONNECTED`, how long it took for the client to connect to the AP. */
  timeToConnect?: Maybe<Scalars['Duration']['output']>;
  /** The timestamp of the event. */
  timestamp: Scalars['DateTime']['output'];
  /** The AP virtual device that the client interacted with. */
  virtualDevice: AccessPointVirtualDevice;
  /** The UUID of the AP virtual device that the client interacted with. */
  virtualDeviceUUID: Scalars['UUID']['output'];
};

export enum WirelessClientConnectionEventType {
  /** The client successfully connected to the AP. */
  Connected = 'CONNECTED',
  /** The client failed to receive an IP address from the DHCP server. */
  DhcpFailed = 'DHCP_FAILED',
  /** The client successfully received an IP address from the DHCP server. */
  DhcpOk = 'DHCP_OK',
  /** The client disassociated from the AP. */
  Disassociated = 'DISASSOCIATED',
  /** The client attempted to connect to the AP but failed. */
  Failed = 'FAILED',
}

export enum WirelessClientConnectionFailedStep {
  /** The association packet exchange in the 802.11 handshake protocol failed. */
  Failed_80211Association = 'FAILED_80211_ASSOCIATION',
  /** The authentication packet exchange in the 802.11 handshake protocol failed. */
  Failed_80211Authentication = 'FAILED_80211_AUTHENTICATION',
  /** The 4-way EAPOL handshake failed. */
  FailedAuthentication = 'FAILED_AUTHENTICATION',
  /** The client declined the IP address from the DHCP server. */
  FailedDhcpDecline = 'FAILED_DHCP_DECLINE',
  /** The DHCP server denied the client's request for an IP address. */
  FailedDhcpNak = 'FAILED_DHCP_NAK',
  /** The DHCP server did not respond to the client's request for an IP address. */
  FailedDhcpTimeout = 'FAILED_DHCP_TIMEOUT',
  /** The connection failed due to other reasons. */
  FailedOther = 'FAILED_OTHER',
}

/** A command to be executed by the Wifi Observer during the test. */
export type WosObserverCommand = {
  __typename?: 'WosObserverCommand';
  /** The type of target (IPv4, IPv6, or hostname). */
  commandTargetType: WosObserverCommandTargetType;
  /** The type of command to run (e.g., PING, DOWNLOAD). */
  commandType: WosObserverCommandType;
  /** An index to match with the test results. */
  index: Scalars['Int']['output'];
  /** Interval between command executions in milliseconds. */
  intervalMsec: Scalars['Int']['output'];
  /** An index indicating the order of execution; commands with the same index run in parallel. */
  orderIndex: Scalars['Int']['output'];
  /** The target of the command, such as an IP address or hostname. */
  target: Scalars['String']['output'];
  /** Timeout for the command execution in milliseconds. */
  timeoutMsec: Scalars['Int']['output'];
};

/** Input type for defining a Wifi Observer Command. */
export type WosObserverCommandInput = {
  /** The type of target for the command. */
  commandTargetType: WosObserverCommandTargetType;
  /** The type of command to run. */
  commandType: WosObserverCommandType;
  /** An index to match with the test results. */
  index: Scalars['Int']['input'];
  /** Interval between command executions in milliseconds. */
  intervalMsec: Scalars['Int']['input'];
  /** An index indicating the order of execution; commands with the same index run in parallel. */
  orderIndex: Scalars['Int']['input'];
  /** The target of the command. */
  target: Scalars['String']['input'];
  /** Timeout for the command execution in milliseconds. */
  timeoutMsec: Scalars['Int']['input'];
};

/** Enumeration of possible target types for the Wifi Observer command. */
export enum WosObserverCommandTargetType {
  Hostname = 'HOSTNAME',
  Ipv4 = 'IPV4',
  Ipv6 = 'IPV6',
}

/** Enumeration of possible command types for the Wifi Observer. */
export enum WosObserverCommandType {
  Dns = 'DNS',
  Download = 'DOWNLOAD',
  Iperf = 'IPERF',
  Ping = 'PING',
}

/** Radio interfaces available for packet capture. */
export enum WosPacketCaptureCommandMonitor {
  /** 2.4 GHz radio interface. */
  Radio_2G = 'RADIO_2G',
  /** 5 GHz radio interface. */
  Radio_5G = 'RADIO_5G',
  /** 6 GHz radio interface. */
  Radio_6G = 'RADIO_6G',
  /** Scanning radio interface. */
  RadioScan = 'RADIO_SCAN',
  /** Unspecified radio interface. */
  RadioUnspecified = 'RADIO_UNSPECIFIED',
}

export type UseRolesQueryQueryVariables = Exact<{
  companySlug?: InputMaybe<Scalars['String']['input']>;
}>;

export type UseRolesQueryQuery = {
  __typename?: 'Query';
  roles: Array<{
    __typename: 'RoleAssignment';
    companySlug?: string | null;
    name: RoleName;
    networkUUID?: string | null;
  }>;
};

export const UseRolesQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UseRolesQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'companySlug' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'companySlug' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'companySlug' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companySlug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'networkUUID' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UseRolesQueryQuery, UseRolesQueryQueryVariables>;
