import { z } from 'zod';

import type { CaptivePortalForNetworkQueryQuery } from '../../../gql/graphql';
import {
  CaptivePortalDisplaySettingsInputSchema,
  CaptivePortalSettingsInputSchema,
} from '../../../gql/zod-types';
import { isFQDN } from '../../../utils/fqdn';

function checkHexCode(hex: string | null | undefined) {
  if (hex === null || hex === undefined) return true;
  return /^#?([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(hex);
}

function isValidURL(val: string | null | undefined): boolean {
  // We must provide additional safety checks for the URL, since zod's `url` method is not
  // strict enough. For example, zod will allow `http:/domain.com` (note the single slash)
  // as a valid URL, but we want to reject it.
  if (!val) return true;
  try {
    let valid = true;
    valid = z.string().url().safeParse(val).success;
    const u = new URL(val);
    valid = valid && u.hostname !== '';
    const urlString = u.toString();
    // `URL.toString()` can add a trailing slash to the URL when there's no path. If a
    // trailing slash is present in the stringfied URL, and it's not present in the input,
    // we need to check that the stringified URL matches the input minus the trailing slash.
    if (urlString.charAt(urlString.length - 1) === '/' && val.charAt(val.length - 1) !== '/') {
      valid = valid && urlString.slice(0, -1) === val;
    }
    return valid;
  } catch {
    return false;
  }
}

export const CaptivePortalDisplayInputSchema = CaptivePortalDisplaySettingsInputSchema.extend({
  callToActionColor: z.string().nullish().refine(checkHexCode, {
    message: 'Invalid hex color',
  }),
  fontColor: z.string().nullish().refine(checkHexCode, {
    message: 'Invalid hex color',
  }),
  backgroundColor: z.string().nullish().refine(checkHexCode, {
    message: 'Invalid hex color',
  }),
}).nullish();

type CaptivePortalDisplayInput = z.infer<typeof CaptivePortalDisplayInputSchema>;

export const CaptivePortalInputSchema = CaptivePortalSettingsInputSchema.omit({
  allowedHosts: true,
})
  .extend({
    name: z.string().min(1),
    redirectURL: z.string().url().nullish().refine(isValidURL, {
      message: 'Invalid url',
    }),
    allowedHosts: z
      .array(z.string())
      .nullish()
      .refine(
        (hosts) => {
          let isValid: boolean = true;
          // handle edge case where the user adds a bunch of newline characters
          // or types and then deletes characters in the field resulting in empty string(s).
          if (hosts?.every((host) => host === '')) {
            return isValid;
          }
          hosts?.forEach((host) => {
            isValid = isValidURL(host.trim()) || isFQDN(host.trim());
          });
          return isValid;
        },
        {
          message:
            'Allowed hosts must be a newline-separated list of valid URLs. Each URL may optionally start with a scheme (e.g., https), must include a hostname (IP address or domain name), and may optionally be followed by a port.',
        },
      ),
    displaySettings: z.lazy(() => CaptivePortalDisplayInputSchema),
    isExternal: z.boolean(),
  })
  .refine(
    ({ isExternal, externalPortalURL }) => {
      if (isExternal) {
        return isValidURL(externalPortalURL);
      }
      return true;
    },
    {
      message: 'External portal URL must be a valid URL.',
      path: ['externalPortalURL'],
    },
  );

export type CaptivePortalInputSchemaType = z.infer<typeof CaptivePortalInputSchema>;

export type CaptivePortal = Omit<
  Exclude<CaptivePortalForNetworkQueryQuery['captivePortalForNetwork'], undefined | null>,
  '__typename'
>;

export function captivePortalToInput(captivePortal: CaptivePortal) {
  const {
    callToAction,
    displaySettings: displaySettingsSource,
    logoImageS3Key,
    name,
    redirectURL,
    isEnabled,
    externalPortalURL,
    allowedHosts: allowedHostsSource,
    authLifetimeSec,
    termsAndConditions,
    vlans,
  } = captivePortal;
  const displaySettings: CaptivePortalDisplayInput = {
    hidePoweredByMeter: displaySettingsSource?.hidePoweredByMeter ?? false,
    callToActionColor: displaySettingsSource?.callToActionColor ?? '',
    backgroundColor: displaySettingsSource?.backgroundColor ?? '',
    fontColor: displaySettingsSource?.fontColor ?? '',
  };

  return {
    callToAction,
    displaySettings,
    logoImageS3Key,
    name: name ?? '',
    redirectURL,
    externalPortalURL,
    authLifetimeSec,
    allowedHosts: allowedHostsSource ?? undefined,
    termsAndConditions,
    vlanUUIDs: vlans?.map((vlan) => vlan.UUID) ?? [],
    isExternal: !!externalPortalURL,
    isEnabled,
  };
}
